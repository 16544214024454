import { Navigate, Outlet } from "react-router-dom";
import PropTypes from "prop-types";
function PrivateRoute({ auth }) {
  return auth ? <Outlet /> : <Navigate to="/" />;
}

PrivateRoute.propTypes = {
  auth: PropTypes.bool.isRequired,
};

export default PrivateRoute;
