import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import qrService from "./qrService";
import { toast } from "react-toastify";

const verificationCode = process.env.REACT_APP_VERIFICATION_TOKEN;

const initialState = {
  allQrCodeTemplates: [],
  isLoading: false,
  isError: false,
  isSuccess: false,
  message: "",
};

// Add New Qr Code Template
export const addQrCodeTemplate = createAsyncThunk(
  "auth/addQrCodeTemplate",
  async (data, thunkAPI) => {
    try {
      await qrService.addQrCodeTemplate(data);
      const res = await qrService.getAllQrCodeTemplates({
        verificationCode: verificationCode,
      });
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.error);
    }
  }
);

// Edit Qr Code Template
export const editQrCodeTemplate = createAsyncThunk(
  "auth/editQrCodeTemplate",
  async (data, thunkAPI) => {
    try {
      await qrService.editQrCodeTemplate(data);
      const res = await qrService.getAllQrCodeTemplates({
        verificationCode: verificationCode,
      });
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.error);
    }
  }
);

// Delete Qr Code Template
export const deleteQrCodeTemplate = createAsyncThunk(
  "auth/deleteQrCodeTemplate",
  async (data, thunkAPI) => {
    try {
      await qrService.deleteQrCodeTemplate(data);
      const res = await qrService.getAllQrCodeTemplates({
        verificationCode: verificationCode,
      });
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.error);
    }
  }
);

// Get All Sticker
export const getAllQrCodeTemplates = createAsyncThunk(
  "auth/getAllQrCodeTemplates",
  async (data, thunkAPI) => {
    try {
      const res = await qrService.getAllQrCodeTemplates(data);
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.error);
    }
  }
);

export const qrCode = createSlice({
  name: "qrCode",
  initialState,
  reducers: {
    reset: (state) => {
      state.isLoading = false;
      state.isError = false;
      state.message = "";
      state.isSuccess = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(addQrCodeTemplate.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(addQrCodeTemplate.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.allQrCodeTemplates = action.payload;
        toast.success("Qr Code Template added successfully.");
      })
      .addCase(addQrCodeTemplate.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        toast.error(action.payload);
      })
      .addCase(editQrCodeTemplate.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(editQrCodeTemplate.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.allQrCodeTemplates = action.payload;
        toast.success("Qr Code Template updated successfully.");
      })
      .addCase(editQrCodeTemplate.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        toast.error(action.payload);
      })
      .addCase(deleteQrCodeTemplate.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteQrCodeTemplate.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.allQrCodeTemplates = action.payload;
        toast.success("Qr Code Template deleted successfully.");
      })
      .addCase(deleteQrCodeTemplate.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        toast.error(action.payload);
      })
      .addCase(getAllQrCodeTemplates.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAllQrCodeTemplates.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.allQrCodeTemplates = action.payload;
      })
      .addCase(getAllQrCodeTemplates.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        toast.error(action.payload);
      });
  },
});

export const { reset } = qrCode.actions;
export default qrCode.reducer;
