import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import Happy from '../../assets/images/icons/happy.svg';
import Dontknow from '../../assets/images/icons/dontknow.svg';
import Sad from '../../assets/images/icons/sad.svg';
import logoBlue from '../../assets/images/icons/GoRateLogo.png';

//=========Redux
import { useSelector, useDispatch } from 'react-redux';
import { getOneDTP } from '../../redux/auth/authSlice';
import { addReview } from '../../redux/review/reviewSlice';
import axios from 'axios';
import { toast } from 'react-toastify';

import './review.css';
import api from '../../utility/api';
import { question } from '../../redux/question/questionSlice';

//======Utils
const verificationCode = process.env.REACT_APP_VERIFICATION_TOKEN;
const BASE_URL_IMAGES = process.env.REACT_APP_BASE_URL_IMAGE;
const BASE_URL = process.env.REACT_APP_BASE_URL;

const Review = () => {
  let dispatch = useDispatch();
  let { slug } = useParams();

  const [language, setLanguage] = useState();

  //=======useSelector Data=====================
  const { oneDTPData, isLoading } = useSelector((state) => {
    return state.auth;
  });

  let reachedMaxVotes =
    oneDTPData && oneDTPData?.dataTouchPoint?.maxVotes === oneDTPData?.reviews;
  //=========Get one Data Touchpoint =================
  useEffect(() => {
    dispatch(
      getOneDTP({
        verificationCode,
        slug,
      })
    );
  }, [dispatch, slug]);

  //=========Review of IP address and Slug =================================
  const [ipReviewData, setIpReviewData] = useState();

  useEffect(() => {
    try {
      getIpReview();
    } catch (error) {}
  }, [slug]);

  const getIpReview = async () => {
    const res = await axios.post(`${BASE_URL}/review/getIpReview`, {
      verificationCode,
      slug,
    });
    if (res?.data) {
      setIpReviewData(res.data);
    }
  };

  //=============Review Steps ============================
  const [ratingStep, setRatingStep] = useState(true);
  const [reasonStep, setReasonStep] = useState(false);
  const [showResult, setShowResult] = useState(false);
  const [comment, setComment] = useState('');
  const [redirectionLink, setRedirectionLink] = useState(false);

  //=============rating Clicked ============
  const [ratingOption, setRatingOptions] = useState(0);

  const ratingOptions = [
    { value: 1, label: 'Sad', src: Sad },
    { value: 2, label: 'Neutral', src: Dontknow },
    { value: 3, label: 'Happy', src: Happy },
  ];

  const redirectToExternalLinkOptionally = (rating = null) => {
    const clickedRating = rating ? rating : ratingOption;
    const redirectUrls = oneDTPData?.dataTouchPoint?.redirectUrls;
    if (redirectUrls?.length) {
      for (const redirectUrl of redirectUrls) {
        if (clickedRating === redirectUrl?.forRating) {
          if (redirectUrl?.isActive && redirectUrl?.url) {
            setRedirectionLink(redirectUrl?.url);
            window.location.href = redirectUrl?.url;
          }
        }
      }
    }
  };

  const handleRatingClicked = (option) => {
    setComment('');
    const reviewBody = {
      verificationCode: verificationCode,
      dataTouchPointId: oneDTPData?.dataTouchPoint?._id,
      rating: option,
      comment: comment ? comment : '',
      questionQrCode: oneDTPData?.dataTouchPoint?.question,
      answerReason: selectedReason,
      maxReviewHours: oneDTPData?.dataTouchPoint?.maxReviewHours,
    };
    dispatch(addReview(reviewBody));

    setRatingOptions(option);
    setRatingStep(false);

    const reasons =
      oneDTPData?.dataTouchPoint?.dtpType === 'Customer Experience'
        ? oneDTPData?.dataTouchPoint?.customerReasons
        : oneDTPData?.dataTouchPoint?.employeeReasons;

    if (reasons?.length !== 0) {
      setReasonStep(true);
      setShowResult(false);
    } else if (reasons?.length === 0) {
      setReasonStep(false);
      setShowResult(true);
      redirectToExternalLinkOptionally(option);
    }
  };

  //==============Options for reasons=======================
  let reasonOptions = oneDTPData?.dataTouchPoint?.reasons;
  const [selectedReason, setSelectedReason] = useState('');
  //=============Reason Clicked ============
  const handleReasonClicked = (reason, reasonId) => {
    const reviewBody = {
      verificationCode: verificationCode,
      dataTouchPointId: oneDTPData?.dataTouchPoint?._id,
      rating: ratingOption,
      comment: comment ? comment : '',
      questionQrCode: oneDTPData?.dataTouchPoint?.question,
      answerReason: reason,
      reasonId: reasonId,
      maxReviewHours: oneDTPData?.dataTouchPoint?.maxReviewHours,
    };
    dispatch(addReview(reviewBody));
    setRatingStep(false);
    setReasonStep(false);
    setShowResult(true);
    getIpReview();
    if (reason)
      if (selectedReason?.includes(reason) && Array.isArray(selectedReason)) {
        setSelectedReason(selectedReason?.filter((item) => item !== reason));
      } else {
        setSelectedReason(reason);
      }

    redirectToExternalLinkOptionally();
  };

  //========Setting Old values ===================
  useEffect(() => {
    setRatingOptions(ipReviewData?.rating);
    setSelectedReason(ipReviewData?.answerReason);
  }, [ipReviewData]);

  //=========Resetting state on Success ===========\

  // useEffect(() => {
  //   if (isSuccess) {
  //     // After a successful review, refetch the data touchpoint
  //     dispatch(getOneDTP({ verificationCode, slug }));
  //   }

  //   dispatch(reset());
  // }, [dispatch, isSuccess, slug, verificationCode]);
  // // ==========Refetch data touchpoint on Success ===========
  const [maxReviewHours, setMaxReviewHours] = useState();
  //-----GET-------
  const getReviewSettings = async () => {
    try {
      const res = await api.post('/reviewHoursSetting/get', {
        verificationCode,
      });
      if (res.data) {
        setMaxReviewHours(res.data?.maxReviewHours);
      }
    } catch (error) {}
  };

  useEffect(() => {
    getReviewSettings();
    return () => {};
  }, []);

  const getQuestionByLanguage = (questions, dtpType) => {
    if (!questions) {
      return;
    }
    const languageCode = navigator?.language?.toLowerCase().split('-')[0];
    let foundQuestion;
    switch (languageCode) {
      case 'en':
        foundQuestion = questions.find(
          (question) =>
            question?.language === 'English' &&
            (!dtpType || question?.inputType === dtpType)
        );
        break;
      case 'es':
        foundQuestion = questions.find(
          (question) =>
            question?.language === 'Spanish' &&
            (!dtpType || question?.inputType === dtpType)
        );
        break;
      case 'it':
        foundQuestion = questions.find(
          (question) =>
            question?.language === 'Italian' &&
            (!dtpType || question?.inputType === dtpType)
        );
        break;
      case 'fr':
        foundQuestion = questions.find(
          (question) =>
            question?.language === 'French' &&
            (!dtpType || question?.inputType === dtpType)
        );
        break;
      case 'pt':
        foundQuestion = questions.find(
          (question) =>
            question?.language === 'Portuguese' &&
            (!dtpType || question?.inputType === dtpType)
        );
        break;
      default:
        foundQuestion = questions.find(
          (question) =>
            question?.language === 'English' &&
            (!dtpType || question?.inputType === dtpType)
        );
        break;
    }

    if (!foundQuestion) {
      foundQuestion = questions.find(
        (question) =>
          question?.language === 'English' &&
          (!dtpType || question?.inputType === dtpType)
      );
    }

    return foundQuestion;
  };

  const getReasonsByLanguage = () => {
    const reasons =
      oneDTPData?.dataTouchPoint?.dtpType === 'Customer Experience'
        ? oneDTPData?.dataTouchPoint?.customerReasons
        : oneDTPData?.dataTouchPoint?.employeeReasons;

    let foundReasons = [];
    const languageCode = navigator?.language?.toLowerCase().split('-')[0];

    for (let i = 0; i < reasons?.length; i++) {
      switch (languageCode) {
        case 'en':
          reasons[i]?.reasons?.find((reason) => {
            const founded = reason?.language === 'English' ? reason : null;
            if (founded) {
              foundReasons.push({
                reason: founded?.reason,
                reasonId: reasons[i]?._id,
              });
            }
          });
          break;
        case 'es':
          reasons[i]?.reasons?.find((reason) => {
            const founded = reason?.language === 'Spanish' ? reason : null;
            if (founded) {
              foundReasons.push({
                reason: founded?.reason,
                reasonId: reasons[i]?._id,
              });
            }
          });
          break;
        case 'it':
          reasons[i]?.reasons?.find((reason) => {
            const founded = reason?.language === 'Italian' ? reason : null;
            if (founded) {
              foundReasons.push({
                reason: founded?.reason,
                reasonId: reasons[i]?._id,
              });
            }
          });
          break;
        case 'fr':
          reasons[i]?.reasons?.find((reason) => {
            const founded = reason?.language === 'French' ? reason : null;
            if (founded) {
              foundReasons.push({
                reason: founded?.reason,
                reasonId: reasons[i]?._id,
              });
            }
          });
          break;
        case 'pt':
          reasons[i]?.reasons?.find((reason) => {
            const founded = reason?.language === 'Portuguese' ? reason : null;
            if (founded) {
              foundReasons.push({
                reason: founded?.reason,
                reasonId: reasons[i]?._id,
              });
            }
          });
          break;
        default:
          reasons[i]?.reasons?.find((reason) => {
            const founded = reason?.language === 'English';
            foundReasons.push({
              reason: founded?.reason,
              reasonId: reasons[i]?._id,
            });
          });
          break;
      }
    }
    return foundReasons;
  };
  console.log('review');

  const getLanguageSpecificQuestion = () => {
    const allQuestions = oneDTPData?.questions;
    const questionObj =
      oneDTPData?.dataTouchPoint?.dtpType === 'Employee Sentiment'
        ? oneDTPData?.dataTouchPoint?.employeeQuestion
        : oneDTPData?.dataTouchPoint?.customerQuestion;

    if (!questionObj?.uuid) {
      return questionObj?.question;
    } else if (questionObj?.uuid) {
      const languageCode = navigator?.language?.toLowerCase().split('-')[0];

      const userCurrentLanguage =
        languageCode === 'en'
          ? 'English'
          : languageCode === 'es'
          ? 'Spanish'
          : languageCode === 'it'
          ? 'Italian'
          : languageCode === 'fr'
          ? 'French'
          : languageCode === 'pt'
          ? 'Portuguese'
          : 'English';

      const foundedQuestion = allQuestions?.find(
        (question) =>
          question?.uuid === questionObj?.uuid &&
          question?.language === userCurrentLanguage
      );

      if (!foundedQuestion) {
        return questionObj?.question;
      }
      return foundedQuestion?.question;
    }
  };

  return (
    <>
      <div className='container'>
        <div className='row' id='reviewSec'>
          {isLoading ? (
            <>
              <div className='wrapper-2 w-50'>
                <h1>Loading...</h1>
              </div>
            </>
          ) : (
            <>
              {!reachedMaxVotes ? (
                <div className='col-md-8'>
                  <div
                    className='reviewsection'
                    id='Dectection'
                    style={{ display: ratingStep ? 'block' : 'none' }}
                  >
                    <h5 className='text-center fw-600'>
                      {getLanguageSpecificQuestion()}
                    </h5>
                    <div
                      className='mt-4 mob-flex  '
                      style={{
                        justifyContent: 'space-evenly',
                      }}
                    >
                      {ratingOptions.map((option) => (
                        <img
                          key={option.value}
                          src={option.src}
                          alt={`${option.label} Customer`}
                          className={`img-fluid d-block reviewImg ${
                            ratingOption === option.value ? 'selected' : ''
                          }`}
                          onClick={() => handleRatingClicked(option.value)}
                        />
                      ))}
                    </div>
                    <p className='text-black text-center mt-3'>
                      {/* Business Logo:{" "} */}
                      <img
                        src={
                          BASE_URL_IMAGES +
                          oneDTPData?.dataTouchPoint?.business?.logo
                        }
                        alt='Logo'
                        onError={(e) => (e.target.src = '/assets/user.png')}
                        className=' img-fluid d-block m-auto reviewImglogo'
                        style={{
                          width: '150px',
                          height: '60px',
                          textAlign: 'center',
                          objectFit: 'contain',
                        }}
                      />
                    </p>

                    <p className='text-black text-center'>
                      Powered By:
                      <img
                        src={logoBlue}
                        alt='Our Logo'
                        className='img-fluid d-block m-auto reviewImglogo'
                      />
                    </p>
                  </div>

                  <div
                    className='reviewsection'
                    id='issue'
                    style={{ display: reasonStep ? 'block' : 'none' }}
                  >
                    <h5 className='text-center fw-600'>
                      {/* {oneDTPData?.dataTouchPoint?.reasonQuestion} */}
                      {
                        getQuestionByLanguage(
                          oneDTPData?.reasonQuestions[0]?.reasonQuestion
                        )?.Qreason
                      }
                    </h5>
                    <div className='mt-3'>
                      <div className='justify-content-center d-flex'>
                        <div className='mob-flex' id='mobFlex'>
                          {getReasonsByLanguage()?.map((option) => (
                            <div
                              key={option?.reasonId}
                              onClick={() =>
                                handleReasonClicked(
                                  option?.reason,
                                  option?.reasonId
                                )
                              }
                              className={`reasonOption optionStyle ${
                                selectedReason === option?.reason
                                  ? 'selected text-white'
                                  : ''
                              }`}
                            >
                              {option?.reason}
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                    <p className='text-black text-center mt-3'>
                      <img
                        src={
                          BASE_URL_IMAGES +
                          oneDTPData?.dataTouchPoint?.business?.logo
                        }
                        alt='Logo'
                        onError={(e) => (e.target.src = '/assets/user.png')}
                        className=' img-fluid d-block m-auto reviewImglogo'
                        style={{
                          width: '150px',
                          height: '60px',
                          textAlign: 'center',
                          objectFit: 'contain',
                        }}
                      />
                    </p>
                    <p className='text-black text-center'>
                      Powered By:{' '}
                      <img
                        src={logoBlue}
                        alt='Our Logo'
                        className='img-fluid d-block m-auto reviewImglogo'
                      />
                    </p>
                  </div>

                  <div
                    className='content'
                    style={{ display: showResult ? 'block' : 'none' }}
                  >
                    <div className='text-center'>
                      <h2>Vote Submitted !</h2>
                      {redirectionLink && (
                        <h5 className='text-success'>Redirecting...</h5>
                      )}
                      <p className='text-black text-center'>
                        Powered By:{' '}
                        <img
                          src={logoBlue}
                          alt='Our Logo'
                          className='img-fluid d-block m-auto reviewImglogo'
                        />
                      </p>
                    </div>
                  </div>
                </div>
              ) : (
                <div className='wrapper-2 w-50'>
                  <h1>Sorry !</h1>
                  <p>
                    <p>Service is currently unavailable.</p>
                  </p>
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default Review;
