import api, { apiWithFormData } from "../../utility/api";

// Add New  Sector
const addSector = async (data) => {
  const response = await api.post("/sector/create", data);
  return response;
};

// Upload  Sector File
const uploadSector = async (data) => {
  const response = await apiWithFormData.post("/sector/uploadSector", data);
  return response;
};

// Edit Sector
const editSector = async (data) => {
  const response = await api.put("/sector/update", data);
  return response;
};

// Get All Sectors
const getAllSectors = async (data) => {
  const response = await api.post("/sector/getAll", data);
  return response;
};

const sectorService = {
  getAllSectors,
  addSector,
  uploadSector,
  editSector,
};

export default sectorService;
