import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import logoBlue from '../../assets/images/icons/GoRateLogo.png';

//=========Redux
import { useSelector, useDispatch } from 'react-redux';
import { getOneDTP } from '../../redux/auth/authSlice';
import { Trans } from 'react-i18next';

import './review.css';

//======Utils
const verificationCode = process.env.REACT_APP_VERIFICATION_TOKEN;
const BASE_URL_IMAGES = process.env.REACT_APP_BASE_URL_IMAGE;

const ReviewResult = () => {
  let dispatch = useDispatch();
  let { slug } = useParams();

  //=======useSelector Data=====================
  const { oneDTPData } = useSelector((state) => {
    return state.auth;
  });

  //=========Get one Data Touchpoint =================
  useEffect(() => {
    dispatch(
      getOneDTP({
        verificationCode,
        slug,
      })
    );
  }, [dispatch, slug]);

  let totalNPS = oneDTPData && oneDTPData?.PPL30Days - oneDTPData?.DPL30Days;

  //=========Resetting state on Success ===========\

  // Update "series" state based on the fetched "oneDTPData"
  //=============Chart Styles and Data
  const [series, setSeries] = useState([
    oneDTPData?.PPL30Days ? oneDTPData?.PPL30Days : 0,
    oneDTPData?.NPL30Days ? oneDTPData?.NPL30Days : 0,
    oneDTPData?.DPL30Days ? oneDTPData?.DPL30Days : 0,
  ]);

  const totalValue = series.reduce((total, value) => total + value, 0);
  useEffect(() => {
    const updatedSeries = [
      oneDTPData?.PPL30Days ? oneDTPData?.PPL30Days : 0,
      oneDTPData?.NPL30Days ? oneDTPData?.NPL30Days : 0,
      oneDTPData?.DPL30Days ? oneDTPData?.DPL30Days : 0,
    ];

    setSeries(updatedSeries);
  }, [oneDTPData]);
  // ==========Refetch data touchpoint on Success ===========

  return (
    <>
      <div className='container'>
        <div className='row' id='reviewSec'>
          <div className='col-md-8'>
            <div id='result' className='reviewsection'>
              <p className='text-center'>
                <Trans>NPS Last 30 Days</Trans>
              </p>
              <div className='row justify-content-center'>
                <div className='col-md-6'>
                  <div
                    className=' d-flex align-items-center justify-content-center mx-auto'
                    style={{
                      width: '250px',
                      height: '250px',
                      backgroundColor:
                        totalNPS < 0
                          ? 'red'
                          : totalNPS >= 0 && totalNPS < 30
                          ? 'orange'
                          : totalNPS >= 30 && totalNPS < 50
                          ? '#ecec46'
                          : totalNPS >= 50 && totalNPS < 70
                          ? 'lightgreen'
                          : totalNPS >= 70 && totalNPS <= 100
                          ? 'green'
                          : 'black',
                    }}
                  >
                    {totalNPS !== null && (
                      <h1
                        style={{
                          fontSize: '100px', // Adjust the font size as needed
                          color: 'white',
                        }}
                      >
                        {totalNPS}
                      </h1>
                    )}
                  </div>
                </div>
              </div>
              <p className='text-black text-center mt-3'>
                <img
                  src={
                    BASE_URL_IMAGES + oneDTPData?.dataTouchPoint?.business?.logo
                  }
                  alt='Logo'
                  onError={(e) => (e.target.src = '/assets/user.png')}
                  className=' img-fluid d-block m-auto reviewImglogo'
                  style={{
                    width: '150px',
                    height: '60px',
                    textAlign: 'center',
                    objectFit: 'contain',
                  }}
                />
              </p>

              <p className='text-black text-center'>
                Powered By:{' '}
                <img
                  src={logoBlue}
                  alt='Our Logo'
                  className='img-fluid d-block m-auto reviewImglogo'
                />
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ReviewResult;
