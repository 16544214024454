import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import subscriptionService from "./SubscriptionService.js";
import { toast } from "react-toastify";

const verificationCode = process.env.REACT_APP_VERIFICATION_TOKEN;

const initialState = {
  allSubscriptions: [],
  isLoading: false,
  isError: false,
  isSuccess: false,
  message: "",
  isLoadingAddSubscription:false
};

// Add New  addSubscription (Admin)
export const addSubscription = createAsyncThunk(
  "auth/addSubscription",
  async (data, thunkAPI) => {
    try {
      const res = await subscriptionService.addSubscription(data);
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.error);
    }
  }
);

// Get All Subscriptions
export const getAllSubscriptions = createAsyncThunk(
  "auth/getAllSubscriptions",
  async (data, thunkAPI) => {
    try {
      const res = await subscriptionService.getAllSubscriptions(data);
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.error);
    }
  }
);

//   deleteSubscription (Admin)
export const deleteSubscription = createAsyncThunk(
  "auth/deleteSubscription",
  async (data, thunkAPI) => {
    try {
      await subscriptionService.deleteSubscription(data);
      const res = await subscriptionService.getAllSubscriptions({
        verificationCode: verificationCode,
      });
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.error);
    }
  }
);

//   Edit Subscription by (Admin)
export const editSubscription = createAsyncThunk(
  "auth/editSubscription",
  async (data, thunkAPI) => {
    try {
      await subscriptionService.editSubscription(data);
      const res = await subscriptionService.getAllSubscriptions({
        verificationCode: verificationCode,
      });
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.error);
    }
  }
);

export const subscription = createSlice({
  name: "subscription",
  initialState,
  reducers: {
    reset: (state) => {
      state.isLoading = false;
      state.isError = false;
      state.message = "";
      state.isSuccess = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllSubscriptions.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAllSubscriptions.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.allSubscriptions = action.payload;
      })
      .addCase(getAllSubscriptions.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        toast.error(action.payload);
      })
      .addCase(addSubscription.pending, (state) => {
        state.isLoadingAddSubscription = true;
      })
      .addCase(addSubscription.fulfilled, (state, action) => {
        state.isLoadingAddSubscription = false;
        state.isSuccess = true;
        state.allSubscriptions = action.payload;
        toast.success("Subsciption added successfully.");
      })
      .addCase(addSubscription.rejected, (state, action) => {
        state.isLoadingAddSubscription = false;
        state.isError = true;
        toast.error(action.payload);
      })
      .addCase(deleteSubscription.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteSubscription.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.allSubscriptions = action.payload;
        toast.success("Subscription successfully deleted");
      })
      .addCase(deleteSubscription.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        toast.error(action.payload);
      })
      .addCase(editSubscription.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(editSubscription.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.allSubscriptions = action.payload;
        toast.success("Subscription successfully updated");
      })
      .addCase(editSubscription.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        toast.error(action.payload);
      });
  },
});

export const { reset } = subscription.actions;
export default subscription.reducer;
