import React, { useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { Link, useNavigate, useParams } from 'react-router-dom';
import moment from 'moment';
import { useMyContext } from '../../../..';
import { Trans } from 'react-i18next';
//=========Redux===========
import { useSelector, useDispatch } from 'react-redux';
import { getOneBusiness } from '../../../../redux/auth/authSlice';
import { getAllCities } from '../../../../redux/CountryAndCities/CountryAndCitySlice';
const verificationCode = process.env.REACT_APP_VERIFICATION_TOKEN;
const BASE_URL_IMAGES = process.env.REACT_APP_BASE_URL_IMAGE;
const REACT_APP_BASE_URL_QR_CODE = process.env.REACT_APP_BASE_URL_QR_CODE;
const View = () => {
  let navigate = useNavigate();
  let dispatch = useDispatch();
  let { id } = useParams();

  const { oneBusinessData } = useSelector((state) => {
    return state.auth;
  });
  const { getCountries, getCities, isCitiesLoading } = useSelector(
    (state) => state.CountryAndCitySlice
  );

  // console.log('one buisnessdata', oneBusinessData);

  useEffect(() => {
    if (oneBusinessData) {
      dispatch(getAllCities(oneBusinessData?.business?.country));
    }
    return () => {};
  }, [oneBusinessData]);
  //============Details of single Business==============

  useEffect(() => {
    let body = {
      verificationCode,
      businessId: id,
    };
    dispatch(getOneBusiness(body));
  }, [id, dispatch]);

  //========Handle Back=================
  const handleBackToTable = (arg) => {
    navigate(`/admin/users`);
  };

  return (
    <>
      {oneBusinessData && (
        <div className="container" id="viewUser">
          <div className="main-body">
            <div className="row gutters-sm">
              <div className="col-md-12 d-flex justify-content-left">
                <button onClick={handleBackToTable} className="btn btnPrint">
                  {' '}
                  <FontAwesomeIcon icon={faArrowLeft} />{' '}
                </button>
              </div>
              <div className="col-md-3 mb-3">
                <div className="card text-center">
                  <div className="card-body">
                    <div className="flex-column  text-center">
                      <img
                        src={BASE_URL_IMAGES + oneBusinessData?.business?.logo}
                        onError={(e) => (e.target.src = '/assets/user.png')}
                        alt="Admin"
                        className="rounded-circle"
                        width="150"
                        height="150"
                      />
                      <div className="mt-4">
                        <h4>{oneBusinessData?.business?.name_of_business}</h4>
                        <p className="text-secondary mb-1 text-capitalize">
                          {oneBusinessData?.business?.role}
                        </p>
                        <p className="text-muted font-size-sm">
                          {oneBusinessData?.business?.email}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-9">
                <div className="card mb-3">
                  <div className="card-body">
                    <div className="row">
                      <div className="col-sm-3">
                        <h6 className="mb-0">Business</h6>
                      </div>
                      <div className="col-sm-9 text-secondary">
                        <span>
                          {oneBusinessData?.business?.name_of_business}
                        </span>
                      </div>
                    </div>

                    <hr />

                    {oneBusinessData?.business?.invoiceAddress && (
                      <>
                        <div className="row">
                          <div className="col-sm-3">
                            <h6 className="mb-0">Address</h6>
                          </div>
                          <div className="col-sm-9 text-secondary">
                            <span>
                              {oneBusinessData?.business?.invoiceAddress}
                            </span>
                          </div>
                        </div>

                        <hr />
                      </>
                    )}

                    {oneBusinessData?.business?.postalCode && (
                      <>
                        <div className="row">
                          <div className="col-sm-3">
                            <h6 className="mb-0">
                              <Trans>Postal Code</Trans>
                            </h6>
                          </div>
                          <div className="col-sm-9 text-secondary">
                            <span>{oneBusinessData?.business?.postalCode}</span>
                          </div>
                        </div>

                        <hr />
                      </>
                    )}

                    {oneBusinessData?.business?.city && (
                      <>
                        <div className="row">
                          <div className="col-sm-3">
                            <h6 className="mb-0">City</h6>
                          </div>
                          <div className="col-sm-9 text-secondary">
                            <span>
                              {/* {oneBusinessData?.business?.city} */}
                              {getCities
                                ?.filter((item) => {
                                  return (
                                    item?._id ===
                                    oneBusinessData?.business?.city
                                  );
                                })
                                .map((filteredItem, index) => (
                                  <div key={index}>
                                    <p>{filteredItem?.name_of_city}</p>
                                  </div>
                                ))}
                            </span>
                          </div>
                        </div>
                        <hr />
                      </>
                    )}
                    {oneBusinessData?.business?.country && (
                      <>
                        <div className="row">
                          <div className="col-sm-3">
                            <h6 className="mb-0">
                              <Trans>Country</Trans>
                            </h6>
                          </div>
                          <div className="col-sm-9 text-secondary">
                            <span>
                              {/* {oneBusinessData?.business?.country} */}
                              {getCountries?.countries
                                ?.filter((item) => {
                                  return (
                                    item?.countryId ===
                                    oneBusinessData?.business?.country
                                  );
                                })
                                .map((filteredItem, index) => (
                                  <div key={index}>
                                    <p>{filteredItem?.country}</p>
                                  </div>
                                ))}
                            </span>
                          </div>
                        </div>
                        <hr />
                      </>
                    )}
                    {oneBusinessData?.payments?.totalAmountSpent !== 0 && (
                      <>
                        <div className="row">
                          <div className="col-sm-3">
                            <h6 className="mb-0">Total Payments</h6>
                          </div>
                          <div className="col-sm-9 text-secondary">
                            <span>
                              {oneBusinessData?.payments?.totalAmountSpent} €
                            </span>
                          </div>
                        </div>
                        <hr />
                      </>
                    )}
                    {oneBusinessData?.business?.bankDetails && (
                      <>
                        <div className="row">
                          <div className="col-sm-3">
                            <h6 className="mb-0">Bank Name</h6>
                          </div>
                          <div className="col-sm-9 text-secondary">
                            <span>
                              {oneBusinessData?.business?.bankDetails?.bankName}
                            </span>
                          </div>
                        </div>
                        <hr />

                        <div className="row">
                          <div className="col-sm-3">
                            <h6 className="mb-0">Account Holder Name</h6>
                          </div>
                          <div className="col-sm-9 text-secondary">
                            <span>
                              {
                                oneBusinessData?.business?.bankDetails
                                  ?.accountHolder
                              }
                            </span>
                          </div>
                        </div>
                        <hr />

                        <div className="row">
                          <div className="col-sm-3">
                            <h6 className="mb-0">Account Number</h6>
                          </div>
                          <div className="col-sm-9 text-secondary">
                            <span>
                              {
                                oneBusinessData?.business?.bankDetails
                                  ?.accountNumber
                              }
                            </span>
                          </div>
                        </div>
                        <hr />
                      </>
                    )}

                    {oneBusinessData?.business?.notes.length > 0 && (
                      <div className="row">
                        <div className="col-sm-12">
                          <h6 className="mb-2">
                            <Trans>Notes</Trans>
                          </h6>
                        </div>
                        {oneBusinessData?.business?.notes.map((note, i) => (
                          <>
                            <div className="col-sm-12 text-secondary" key={i}>
                              <span>{i + 1}.</span>
                              <span className="ms-3">{note?.text}</span>
                              <br />{' '}
                              <small
                                span
                                className="ms-4 ps-1"
                                style={{ color: '#1F3C76', fontWeight: '600' }}
                              >
                                {moment(note.timestamp).format('D MMMM, YYYY')}
                              </small>
                            </div>
                            {i !==
                              oneBusinessData.business?.notes.length - 1 && (
                              <hr />
                            )}
                          </>
                        ))}
                        <hr />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>

            {/* //============Current Subscription ============================= */}

            {oneBusinessData?.currentSubscription && (
              <div className="row">
                <div className="col-md-12">
                  <h4 className="py-3">Current Subscription</h4>
                  <div className="table-responsive">
                    <table className="table">
                      <thead>
                        <tr>
                          <th className="text-start">Subscription Name</th>
                          <th className="text-center">Price</th>
                          <th className="text-center">Subscription Type</th>
                          <th className="text-center">
                            <Trans>Start Date</Trans>
                          </th>
                          <th className="text-center">
                            <Trans>End Date</Trans>
                          </th>
                          {/* <th className="text-center">Currency Type</th> */}
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td className="text-start">
                            {oneBusinessData?.currentSubscription?.name}
                          </td>
                          <td className="text-center">
                            {oneBusinessData?.currentSubscription?.price +
                              ' ' +
                              oneBusinessData?.currentSubscription
                                ?.currencyType}
                          </td>
                          <td className="text-center">
                            {
                              oneBusinessData?.currentSubscription
                                ?.subscriptionType
                            }
                          </td>
                          <td className="text-center">
                            {moment(
                              oneBusinessData?.currentSubscription.startDate
                            ).format('D MMMM, YYYY')}
                          </td>
                          <td className="text-center">
                            {moment(
                              oneBusinessData?.currentSubscription.endDate
                            ).format('D MMMM, YYYY')}
                          </td>
                          {/* <td className="text-center">
                            {oneBusinessData?.currentSubscription?.currencyType}
                          </td> */}
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            )}

            {/* //============Datatouchpoints ============================= */}

            {oneBusinessData && oneBusinessData?.dataTouchPoint && (
              <>
                <div className="row">
                  <div className="col-md-12">
                    <h4 className="py-3">
                      <Trans>Data Touchpoints</Trans>
                    </h4>
                    <div className="table-responsive">
                      <table className="table">
                        <thead>
                          <tr>
                            <th className="text-start">
                              <Trans>Name</Trans>
                            </th>
                            <th className="text-center">
                              <Trans>Email</Trans>
                            </th>
                            <th className="text-center">
                              <Trans>Contact number</Trans>
                            </th>
                            <th className="text-center">
                              <Trans>Measuring</Trans>
                            </th>
                            <th className="text-center">QR</th>
                            <th className="text-center">
                              <Trans>Status</Trans>
                            </th>
                            <th className="text-center">
                              <Trans>Creation Date</Trans>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {oneBusinessData?.dataTouchPoints.map((dtp, i) => (
                            <tr key={i}>
                              <td className="text-start">{dtp?.name}</td>
                              <td className="text-center">{dtp?.email}</td>
                              <td className="text-center">
                                {dtp?.contactNumber}
                              </td>
                              <td className="text-center">{dtp?.dtpType}</td>
                              <td className="text-center">
                                <img
                                  src={REACT_APP_BASE_URL_QR_CODE + dtp?.qrCode}
                                  alt="Logo"
                                  onError={(e) =>
                                    (e.target.src = '/assets/user.png')
                                  }
                                  style={{
                                    width: '20px',
                                    height: '20px',
                                    cursor: 'pointer',
                                  }}
                                />
                              </td>
                              <td className="text-center">
                                {' '}
                                {dtp?.approvedDTP === true ? (
                                  <span className="text-success badge p-1">
                                    Active
                                  </span>
                                ) : (
                                  <span className="text-danger badge p-1">
                                    Block
                                  </span>
                                )}{' '}
                              </td>
                              <td className="text-center">
                                {moment(dtp?.createdAt).format('D MMMM, YYYY')}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <hr />
              </>
            )}

            {oneBusinessData &&
              oneBusinessData?.reviews &&
              oneBusinessData?.reviews.length > 0 && (
                <>
                  <div className="row">
                    <div className="col-md-12">
                      <h4 className="py-3">Reviews</h4>
                      <div className="table-responsive">
                        <table className="table">
                          <thead>
                            <tr>
                              <th className="text-start">Data Touchpoint</th>
                              <th className="text-center">Selected Question</th>
                              <th className="text-center">Selected Reason</th>
                              <th className="text-center">IP Address</th>
                              <th className="text-center">Ratings</th>
                              {/* <th className="text-center">Comment</th> */}
                              <th className="text-center">
                                <Trans>Creation Date</Trans>
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {oneBusinessData?.reviews.map((reivew, i) => (
                              <tr key={i}>
                                <td className="text-start">
                                  {reivew?.DataTouchPoint.name}
                                </td>
                                <td className="text-center">
                                  {reivew?.questionQrCode}
                                </td>
                                <td className="text-center">
                                  {reivew?.answerReason}
                                </td>
                                <td className="text-center">
                                  {reivew?.ipAddress}
                                </td>
                                <td className="text-center">
                                  {reivew?.rating}
                                </td>
                                {/* <td className="text-center">
                                  {reivew?.comment}
                                </td> */}

                                <td className="text-center">
                                  {moment(reivew?.createdAt).format(
                                    'D MMMM, YYYY h:mm:ss A'
                                  )}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  <hr />
                </>
              )}
          </div>
        </div>
      )}
    </>
  );
};

export default View;
