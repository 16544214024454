import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheck,
  faMoneyBill,
  faSearch,
  faShop,
} from "@fortawesome/free-solid-svg-icons";
import { Modal, Button, Form } from "react-bootstrap";
import { Link } from "react-router-dom";

//=========Redux===========
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import DataTable from "react-data-table-component";
import Loader from "../../../../Loader";
import { RotatingLines } from "react-loader-spinner";

import moment from "moment";
// import { Country, City } from "country-state-city";
import {
  getAllStickerRequests,
  reset,
  stickerPayment,
} from "../../../../redux/stickerRequest/stickerRequestSlice";
import { useMyContext } from '../../../..';
import { Trans } from 'react-i18next';
import ReactLoading from 'react-loading';
// import "./csetting.css";

//===Utils
const verificationCode = process.env.REACT_APP_VERIFICATION_TOKEN;

const PaymentComponent = () => {
  let dispatch = useDispatch();

  const { allStickerRequests, isSuccess, isLoading } = useSelector((state) => {
    return state.stickerRequest;
  });

 
  //=========Get All Invoices======
  useEffect(() => {
    dispatch(getAllStickerRequests({ verificationCode }));
  }, [dispatch]);

  //===========Country and Cities Selection START=================================
  const [selectedCountry, setSelectedCountry] = useState("");
  const [cities, setCities] = useState([]);
  const [CitiesLoading, setCitiesLoading] = useState(false)
  const handleCountryChange = async (event) => {
    const countryCode = event.target.value;
    setSelectedCountry(countryCode);
   
    setCitiesLoading(true)
    try {
      const response = await fetch('https://countriesnow.space/api/v0.1/countries/cities', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ "country": countryCode }),
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      // Handle the response data
      const responseData = await response.json();
    
      setCities(responseData?.data)
      setCitiesLoading(false)
    } catch (error) {
      console.error('Error during POST request:', error);
    }
  };
  //===========Country and Cities Selection END=================================

  //=================================Card Validations START=================================
  //========CVV  =================================

  const [cvv, setCvv] = useState("");

  const handleCvvChange = (e) => {
    const value = e.target.value;
    const regex = /^\d{0,3}$/; // accepts 0-3 digits
    if (regex.test(value)) {
      setCvv(value);
    }
  };

  //========Expiry Card Checks =================================
  const [expiryDate, setExpiryDate] = useState("");
  const [expiryCardMessage, setExpiryCardMessage] = useState("");
  const [isExpired, setIsExpired] = useState(false);

  const handleExpiryDateChange = (event) => {
    let input = event.target.value;

    // Remove any non-numeric characters
    input = input.replace(/[^\d]/g, "");
    const expiryMonth = parseInt(input.substring(0, 2));
    const expiryYear = parseInt(input.substring(3));
    const currentYear = new Date().getFullYear() % 100;
    const currentMonth = new Date().getMonth() + 1;

    // Check if the expiry month is greater than 12 and set it to 12
    if (expiryMonth > 12) {
      input = "12/" + input.substring(2);
    }

    if (
      expiryYear < currentYear ||
      (expiryYear === currentYear && expiryMonth < currentMonth)
    ) {
      setIsExpired(true);
    } else {
      setIsExpired(false);
    }

    // Format the expiry date with a slash after 2 digits
    if (input.length > 2) {
      input = input.slice(0, 2) + "/" + input.slice(2);
    }

    setExpiryDate(input);
  };

  const validateExpiryDate = () => {
    const [expiryMonth, expiryYear] = expiryDate.split("/");

    // Check if the expiry date is valid and more than the current date
    const now = new Date();
    const expiry = new Date(`20${expiryYear}`, expiryMonth - 1, 1);
    if (expiry <= now || isNaN(expiry)) {
      setExpiryCardMessage("Invalid expiry date");
    }

    return "";
  };

  //=======Credit Card No Added by Alex
  let [validCardNo, setValidCardNo] = useState(false);
  const [cardNumber, setCardNumber] = useState("");

  const handleCardNoChange = (event) => {
    let { value } = event.target;
    value = value.replace(/\D/g, "");
    // only allow 16 digits
    if (value.length > 16) {
      value = value.slice(0, 16);
    }
    value = value.replace(/(\d{4})/g, "$1-");
    // remove trailing dash
    value = value.replace(/-$/, "");
    setCardNumber(value);
    // check if number is valid using Luhn's algorithm
    const luhnCheck = (ccNum) => {
      let checkSum = 0;
      let isEven = false;

      for (let i = ccNum.length - 1; i >= 0; i--) {
        let digit = parseInt(ccNum.charAt(i));
        if (isEven) {
          digit *= 2;
          if (digit > 9) {
            digit -= 9;
          }
        }
        checkSum += digit;
        isEven = !isEven;
      }
      return checkSum % 10 === 0;
    };

    if (luhnCheck(value)) {
      setValidCardNo(true);
    } else {
      setValidCardNo(false);
    }
  };

  //=================================Card Validations END=================================
  const [paymentData, setPaymentData] = useState({});

  const [showModalPayment, setShowModalPayment] = useState(false);
  const handlePaymentClose = () => setShowModalPayment(false);
  const handlePayPopUp = (data) => {
    setPaymentData(data);
    setShowModalPayment(true);
  };

  const [priceAfterVat, setPriceAfterVat] = useState(0);

  const [formValues, setFormValues] = useState({
    address: "",
    city: "",
    zip: "",
    vat: 0,
    currencyType: "€",
    agreeTerms: false,
  });

  const { address, city, zip, vat, currencyType, agreeTerms } = formValues;

  const calculateVATValue = () => {
    if (!isNaN(paymentData?.totalPrice) && !isNaN(vat)) {
      const priceNum = parseFloat(paymentData && paymentData?.totalPrice);
      const vatNum = parseFloat(vat);
      const vatAmount = (priceNum * vatNum) / 100;
      setPriceAfterVat((priceNum + vatAmount).toFixed(2));
    }
  };

  useEffect(() => {
    calculateVATValue();
  }, [paymentData?.totalPrice, vat]);

  useEffect(() => {
    setFormValues({
      address: paymentData?.shippingAddress,
      zip: paymentData?.postalCode,
    });
  }, [paymentData]);

  const handlePaymentChange = (e) => {
    const { name, value, type, checked } = e.target;
    const fieldValue = type === "checkbox" ? checked : value;
    setFormValues((prevValues) => ({ ...prevValues, [name]: fieldValue }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (cardNumber == "" || cvv == "" || expiryDate == "") {
      toast.warning("Please Fill All required Fields.");
      return;
    }

    if (cardNumber.length < 19) {
      toast.error("Card Number should be 16 digits.");
      return;
    }

    if (expiryDate.length < 7) {
      toast.error("Date Should be in MM/YYYY format.");
      return;
    }
    if (cvv.length < 3) {
      toast.error("Cvv Number should be 3 digits.");
      return;
    }

    let body = {
      verificationCode: verificationCode,
      stickerId: paymentData?._id,
      cardNumber: cardNumber,
      cvv: cvv,
      expiryDate: expiryDate,
      amount: paymentData?.totalPrice,
      units: paymentData?.units,
      currency: currencyType,
      address: address,
      city: city,
      country: selectedCountry,
      zip: zip,
      agreeTerms: agreeTerms,
      vat,
      priceAfterVat,
      product: paymentData?.qrCodeTemplate?.name + " " + "Template",
    };
    dispatch(stickerPayment(body));
  };

  //=============Payment Form And Api End================

  //==========================Table======================//

  //-------------------DATA TABLE START ---------------------------------
  const [pending, setPending] = useState(true);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setPending(false);
    }, 500);
    return () => clearTimeout(timeout);
  }, []);

  const columns = [
    {
      name: <Trans>Requested Template</Trans>,
      selector: (row) => row?.qrCodeTemplate?.name,
      sortable: true,
      center: true,
      width: "200px",
    },
    {
      name: <Trans>Requested Size</Trans>,
      selector: (row) => row?.stickerSize,
      sortable: true,
      center: true,
      width: "180px",
    },
    {
      name: <Trans>Units</Trans>,
      selector: (row) => row?.units,
      sortable: true,
      center: true,
    },
    {
      name: <Trans>Total Amount</Trans>,
      selector: (row) => row.totalPrice + " " + "€",
      sortable: true,
      center: true,
      width: "205px",
    },

    {
      name: <Trans>Creation Date</Trans>,
      selector: (row) => moment(row.createdAt).format("DD-MM-YYYY"),
      sortable: true,
      center: true,
      width: "200px",
    },
    {
      name: <Trans>Status</Trans>,
      selector: (row) =>
        row?.status === true ? (
          <span className="text-white badge bg-success p-1"><Trans>Paid</Trans></span>
        ) : (
          <span className="text-white badge bg-warning p-1"><Trans>Pending</Trans></span>
        ),
      sortable: true,
      center: true,
    },
  ];

  const DataTableActionButtons = ({ rowData }) => (
    <>
      {rowData?.status === true && (
        <button
          type="button"
          className="btn btn-success btn-sm px-2"
          title="Paid"
          disabled
        >
          <Trans>Pay</Trans>
        </button>
      )}

      {rowData?.status !== true && (
        <button
          type="button"
          className="btn btn-primary btn-sm px-2"
          onClick={() => handlePayPopUp(rowData)}
          title="Pay"
        >
          <FontAwesomeIcon icon={faMoneyBill} /> Pay
        </button>
      )}
    </>
  );

  //  Internally, customStyles will deep merges your customStyles with the default styling.
  const customStyles = {
    rows: {
      style: {
        minHeight: "60px",
      },
    },
    headCells: {
      style: {
        fontSize: "14px",
        color: "#B5B7C0",
        textAlign: "center",
      },
    },
    cells: {
      style: {
        textAlign: "center", // Align regular cell content to center
        color: "#292D32 !important",
        fontWeight: "600",
      },
    },
  };

  //-------------------DATA TABLE END---------------------------------

  //============ReSetting Data om success================
  const [payLoad, setPayLoad] = useState(false);

  useEffect(() => {
    if (isSuccess) {
      setFormValues({
        address: "",
        city: "",
        zip: "",
        amount: 0,
        currencyType: "€",
        agreeTerms: false,
      });
      setCardNumber("");
      setExpiryDate("");
      setCvv("");
      setSelectedCountry("");
      setPriceAfterVat(0);
      handlePaymentClose();
      setPayLoad(false);
    }

    dispatch(reset());
  }, [isSuccess]);

  useEffect(() => {
    if (isLoading) {
      setPayLoad(true);
    }
    dispatch(reset());
  }, [isLoading]);
  const { language, setLanguage } = useMyContext();

  const [AllCountry, setAllCountry] = useState()
  const fetchData = async () => {
    try {
      await fetch(`https://countriesnow.space/api/v0.1/countries`).then(async response => {
        let data = await response.json()
        
        setAllCountry(data.data)
      })

    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    fetchData()
  }, [])

  return (
    <>
      <div className="card shadow-sm my-2">
        <div className="card-header">
          <div className="d-flex justify-content-between">
            <div className="w-100">
              <h4 className="mt-1"><Trans>Payments</Trans></h4>
            </div>

            <Link className="btn btn-primary text-nowrap" to="/company/shop">
              <FontAwesomeIcon icon={faShop} /><Trans>Go To Shop</Trans>
            </Link>
          </div>
        </div>
        <div className="card-body">
          <div className="row">
            <div className="col-md-12">
              <div className="mt-2 outerWrapperTable" style={{ overflowX: "auto" }}>
                {allStickerRequests && (
                  <DataTable
                    columns={columns}
                    data={allStickerRequests}
                    progressPending={pending}
                    progressComponent={<Loader />}
                    customStyles={customStyles}
                    pagination
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/*================ MODALS START================= */}

      {/*  Payment Modal  */}
      <Modal
        show={showModalPayment}
        onHide={handlePaymentClose}
        centered
        dialogClassName="custom-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title>Payment Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form className="row g-1" onSubmit={handleSubmit}>
            <Form.Group className="col-md-6" controlId="cardNumber">
              <Form.Label>Card Number *</Form.Label>
              <input
                className="form-control"
                type="text"
                placeholder="XXXX-XXXX-XXXX-XXXX"
                value={cardNumber}
                onChange={handleCardNoChange}
              />
            </Form.Group>

            <Form.Group className="col-md-6" controlId="expiryDate">
              <Form.Label>Expiry Date *</Form.Label>
              <input
                type="text"
                className="form-control"
                placeholder="MM / YYYY"
                id="expiryDate"
                name="expiryDate"
                value={expiryDate}
                maxLength="7"
                onChange={handleExpiryDateChange}
                onBlur={validateExpiryDate}
              />
              {isExpired && <span className="text-danger">{"Expired"}</span>}
            </Form.Group>

            <Form.Group className="col-md-6" controlId="ccv">
              <Form.Label>CVV *</Form.Label>
              <Form.Control
                type="number"
                maxLength={3}
                name="cvv"
                value={cvv}
                onChange={handleCvvChange}
                placeholder="123"
              />
            </Form.Group>
            <div className="row">
              {paymentData && (
                <div className="col-md-5">
                  <div className="form-group">
                    <label className="form-label">Amount *</label>
                    <input
                      type="number"
                      className="form-control"
                      value={paymentData?.totalPrice}
                      readOnly
                    />
                  </div>
                </div>
              )}

              <div className="col-md-3">
                <label className="form-label"><Trans>VAT</Trans> %</label>
                <div className="form-group">
                  <input
                    type="number"
                    value={vat}
                    name="vat"
                    onChange={handlePaymentChange}
                    className="form-control"
                  />
                </div>
              </div>
              {vat !== undefined && (
                <div className="col-md-4">
                  <label className="form-label">Price After VAT</label>
                  <div className="form-group">
                    <input
                      type="number"
                      name="priceAfterVat"
                      value={priceAfterVat}
                      readOnly
                      className="form-control"
                    />
                  </div>
                </div>
              )}
            </div>

            <Form.Group className="col-md-6" controlId="address">
              <Form.Label>Billing Address</Form.Label>
              <Form.Control
                type="text"
                name="address"
                value={address}
                onChange={handlePaymentChange}
              />
            </Form.Group>

            <div className="col-md-6">
              <div className="form-group">
                <label className="form-label"><Trans>Country</Trans></label>
                <select
                  className="form-select"
                  name="country"
                  value={selectedCountry}
                  onChange={handleCountryChange}
                >
                  <option value=""><Trans>Select from list</Trans>...</option>

                  {AllCountry?.map((country) => (
                    <option key={country.country} value={country.country}>
                      {country.country}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <label className="form-label"><Trans>City</Trans></label>
                {CitiesLoading ? <div className="form-control d-flex justify-content-center">
                  <ReactLoading type={"spinningBubbles"} color={"#000000"} height={24} width={17} />
                </div> :
                  <select
                    className="form-select"
                    type="text"
                    name="city"
                    value={city}
                    onChange={handlePaymentChange}
                  >
                    <option value=""><Trans>Select from list</Trans>...</option>
                    {cities.map((city, index) => (
                      <option key={index} value={city}>
                        {city}
                      </option>
                    ))}
                  </select>
                }
              </div>
            </div>
            <Form.Group className="col-md-6" controlId="zip">
              <Form.Label><Trans>Postal Code</Trans></Form.Label>
              <Form.Control
                type="text"
                name="zip"
                value={zip}
                onChange={handlePaymentChange}
              />
            </Form.Group>

            <Form.Group controlId="agreeTerms">
              <Form.Check
                type="checkbox"
                name="agreeTerms"
                label="Please confirm you agree to our terms and conditions and privacy policy."
                checked={agreeTerms}
                onChange={handlePaymentChange}
              />
            </Form.Group>

            <div className="my-4 d-flex justify-content-end">
              {payLoad ? (
                <RotatingLines
                  strokeColor="grey"
                  strokeWidth="5"
                  animationDuration="0.75"
                  height={50}
                  width={50}
                  visible={true}
                />
              ) : (
                <Button variant="primary" type="submit" onClick={handleSubmit}>
                  Pay Now
                </Button>
              )}
            </div>
          </Form>
        </Modal.Body>
      </Modal>
      {/*  Payment Modal  End*/}
    </>
  );
};

export default PaymentComponent;
