import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import stickerService from "./stickerService";
import { toast } from "react-toastify";

const verificationCode = process.env.REACT_APP_VERIFICATION_TOKEN;

const initialState = {
  allStickers: [],
  isLoading: false,
  isError: false,
  isSuccess: false,
  message: "",
  isLoadingAddSticker:false
};

// Add New Sticker
export const addSticker = createAsyncThunk(
  "auth/addSticker",
  async (data, thunkAPI) => {
    try {
      const res = await stickerService.addSticker(data);
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.error);
    }
  }
);

// Edit Sticker
export const editSticker = createAsyncThunk(
  "auth/editSticker",
  async (data, thunkAPI) => {
    try {
      await stickerService.editSticker(data);
      const res = await stickerService.getAllStickers({
        verificationCode: verificationCode,
      });
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.error);
    }
  }
);

// Get All Sticker
export const getAllStickers = createAsyncThunk(
  "auth/getAllStickers",
  async (data, thunkAPI) => {
    try {
      const res = await stickerService.getAllStickers(data);
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.error);
    }
  }
);

export const sticker = createSlice({
  name: "sticker",
  initialState,
  reducers: {
    reset: (state) => {
      state.isLoading = false;
      state.isError = false;
      state.message = "";
      state.isSuccess = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllStickers.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAllStickers.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.allStickers = action.payload;
      })
      .addCase(getAllStickers.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        toast.error(action.payload);
      })
      .addCase(addSticker.pending, (state) => {
        state.isLoadingAddSticker = true;
      })
      .addCase(addSticker.fulfilled, (state, action) => {
        state.isLoadingAddSticker = false;
        state.isSuccess = true;
        state.allStickers = action.payload;
        toast.success("Sticker added successfully.");
      })
      .addCase(addSticker.rejected, (state, action) => {
        state.isLoadingAddSticker = false;
        state.isError = true;
        toast.error(action.payload);
      })
      .addCase(editSticker.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(editSticker.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.allStickers = action.payload;
        toast.success("Sticker updated successfully.");
      })
      .addCase(editSticker.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        toast.error(action.payload);
      });
  },
});

export const { reset } = sticker.actions;
export default sticker.reducer;
