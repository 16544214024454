import React, { useState, useEffect } from "react";
import { NavLink, Routes, Route, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import whileLogo from "../../../assets/images/icons/GoRateWhite.png";

//========Routes========
import NotFound from "../../../NotFound";
import Main from "./frontdash/Main";
import Users from "./users/Users";
import Subscriptions from "./subscribtion/Subscriptions";
// import Bench from "./bench/Bench";
import Touchpoints from "./touch/Touchpoints";
import Report from "./reports/Report";
import Sales from "./sales/Sales";
import Sticker from "./sticker/Sticker";
import Support from "./support/Support";
import Setting from "./setting/Setting";
import View from "../Admin/users/View";

//=========Import Toasts========
import { toast } from "react-toastify";

//==============Import Redux =================
import { useDispatch } from "react-redux";
import { logout, reset } from "../../../redux/auth/authSlice";
import Admins from "./admins/Admins";
import AdditionalDTPRequest from "./AdditionalDTPRequest/AdditionalDTPRequest";
import DtpView from "./touch/DtpView";

//=Icons
import {
  MdOutlineDashboard,
  MdDataSaverOff,
  MdOutlineSettings,
} from "react-icons/md";
import { BiGitBranch, BiSolidReport, BiSticker } from "react-icons/bi";
import { BsChatRight, BsGraphUpArrow, BsShop } from "react-icons/bs";
import { LiaUsersSolid, LiaUserShieldSolid } from "react-icons/lia";
import { LuFileText } from "react-icons/lu";

import { useMyContext } from '../../..';

import TermsAndCondition from "./termAndCondition/TermAndCondition";

// import "./dashboard.css";

import { Trans } from 'react-i18next';
import CountriesAndCities from "../CountriesAndCities/CountriesAndCities";

const Dashboard = () => {
  const { language, setLanguage } = useMyContext();
  const [lan, setLan] = useState(language)

  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    setLan(language)
  }, [language])

  const [activeLink, setActiveLink] = useState("");
  const [activeSubMenu, setActiveSubMenu] = useState(null);
  const [sidebarVisible, setSidebarVisible] = useState(false);

  const handleClick = (link) => {
    setSidebarVisible(false);
    setActiveLink(link);
  };

  //Humgurger Menu
  const toggleSidebar = () => {
    setSidebarVisible(!sidebarVisible);
  };

  const sidebar = [
    {
      link: "",
      text: <Trans>Dashboard</Trans>,
      icon: <MdOutlineDashboard />,
    },
    {
      link: "users",
      text: <Trans>CompanyAndUsers</Trans>,
      icon: <LiaUsersSolid />,
    },
    {
      link: "admins",
      text: <Trans>Admins</Trans>,
      icon: <LiaUserShieldSolid />,
    },
    {
      link: "data-touchpoints",
      text: <Trans>Data Touchpoints</Trans>,
      icon: <MdDataSaverOff />,
    },
    // {
    //   link: "benchmarks",
    //   text: "Benchmarks",
    // icon: <BiGitBranch />,
    // },
    {
      link: "sticker-requests",
      text: <Trans>StickerRequest</Trans>,
      icon: <BiSticker />,
    },
    // {
    //   link: "aditional-dtp-requests",
    //   text: "Additional DTP Requests",
    // },
    {
      link: "shop",
      text: <Trans>Shop</Trans>,
      icon: <BsShop />,
    },

    {
      link: "sales",
      text: <Trans>Sales</Trans>,
      icon: <BsGraphUpArrow />,
    },
    {
      link: "support",
      text: <Trans>Support</Trans>,
      icon: <BsChatRight />,
    },
    {
      link: "report",
      text: <Trans>Report</Trans>,
      icon: <BiSolidReport />,
    },
    {
      link: "setting",
      text: <Trans>Settings</Trans>,
      icon: <MdOutlineSettings />,
    },
    {
      link: "termAndCondition",
      text: <Trans>Terms&Conditions</Trans>,
      icon: <LuFileText />,
    },
    {
      link: "countries-cities",
      text: <Trans>Countries & Cities</Trans>,
      icon: <LuFileText />,
    },
  ];

  //==============Logout Handler Submit=====================
  const handleLogout = () => {
    dispatch(logout());
    navigate("/");
    dispatch(reset());
    toast.success("Logout successfully !");
  };

  return (
    <>
      <section id="dashboardSidebar">
        <div className="container-fluid">
          <div className="row">
            <div className="p-0" id="SideBArWid">
              <div className="profile-sidebar p-0 rounded-0">
                <div className="logo py-2">
                  <h3 className="logoHeading pt-3">
                    <img src={whileLogo} alt="" width={120} />
                  </h3>
                  <div className="menu-icon" onClick={toggleSidebar}>
                    <FontAwesomeIcon icon={faBars} />
                  </div>
                </div>

                <div className={`sidebar ${sidebarVisible ? "show" : ""}`}>
                  <div className=" py-2 mobile_logo_hide">
                    <h3 className=" pt-3 text-center">
                      <img
                        src={whileLogo}
                        alt=""
                        width={200}
                      // className=""
                      />
                    </h3>
                  </div>
                  <div
                    className={`sidebar-links ${sidebarVisible ? "pt-4" : ""}`}
                  >
                    <div className="sidebar-links-container">
                      {" "}
                      {/* Add container element */}
                      {sidebar &&
                        sidebar.map((item, index) => (
                          <div key={index}>
                            {item.isDropdown ? (
                              <div className="divSideBar">
                                <div
                                  onClick={() => {
                                    setActiveSubMenu(
                                      activeSubMenu === item.link
                                        ? null
                                        : item.link
                                    );
                                  }}
                                  className={
                                    activeLink === `${item.link}`
                                      ? "activeSideBar"
                                      : ""
                                  }
                                  inline={item.isDropdown ? "true" : false}
                                >
                                  <span className="fs-5 me-2">{item.icon}</span>

                                  {item.text}
                                </div>
                                {activeSubMenu === item.link && (
                                  <div style={{ paddingLeft: "1.5rem" }}>
                                    {item.submenu.map(
                                      (submenuItem, submenuIndex) => (
                                        <NavLink
                                          key={submenuIndex}
                                          to={`${submenuItem.link}`}
                                          onClick={() =>
                                            handleClick(submenuItem.link)
                                          }
                                          className={
                                            activeLink === `${submenuItem.link}`
                                              ? "activeSideBar"
                                              : ""
                                          }
                                          inline={
                                            item.isDropdown ? "true" : false
                                          }
                                        >
                                          {submenuItem.text}
                                        </NavLink>
                                      )
                                    )}
                                  </div>
                                )}
                              </div>
                            ) : (
                              <NavLink
                                to={`${item.link}`}
                                onClick={() => handleClick(item.link)}
                                className={
                                  activeLink === `${item.link}`
                                    ? "activeSideBar"
                                    : ""
                                }
                                inline={item.isDropdown ? "true" : false}
                              >
                                <span className="fs-5 me-2">{item.icon}</span>
                                {item.text}
                              </NavLink>
                            )}
                          </div>
                        ))}
                      <div className="logout-button desk-none">
                        <button
                          className="btn btn-white"
                          onClick={handleLogout}
                        >
                          <Trans>Log out</Trans>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-9 mt-2" id="dashRIght">
              <Routes>
                <Route path="" index element={<Main />} />
                <Route path="/users" index element={<Users />} />
                <Route path="/admins" index element={<Admins />} />
                <Route path="/shop" index element={<Subscriptions />} />
                {/* <Route path="/benchmarks" index element={<Bench />} /> */}
                <Route
                  path="/data-touchpoints"
                  index
                  element={<Touchpoints />}
                />
                <Route path="/report" index element={<Report />} />
                <Route path="/sales" index element={<Sales />} />
                <Route
                  path="/aditional-dtp-requests"
                  index
                  element={<AdditionalDTPRequest />}
                />
                <Route path="/sticker-requests" index element={<Sticker />} />
                <Route path="/support" index element={<Support />} />
                <Route path="/setting" index element={<Setting />} />
                <Route path="/countries-cities" index element={<CountriesAndCities />} />
                
                <Route
                  path="/termAndCondition"
                  index
                  element={<TermsAndCondition />}
                />

                <Route path="/businessDetail/:id" index element={<View />} />
                <Route
                  path="/datatouchpoint/:slug"
                  index
                  element={<DtpView />}
                />

                <Route path="*" element={<NotFound />} />
              </Routes>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Dashboard;
