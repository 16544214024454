import api from '../../utility/api';

// Add New  Reason
const addReason = async (data) => {
  const response = await api.post('/reason/create', data);
  return response;
};

const addReasonsToDtp = async (data) => {
  const response = await api.post('/reason/add_to_dtp', data);
  return response;
};

// Edit Reason
const editReason = async (data) => {
  const response = await api.post('/reason/update', data);
  return response;
};

// Delete Reason
const deleteReason = async (data) => {
  const response = await api.post('/reason/delete', data);
  return response;
};

// Get All Reasons
const getAllReasons = async (data) => {
  const response = await api.post('/reason/getAll', data);
  return response;
};

const reasonService = {
  getAllReasons,
  addReason,
  editReason,
  deleteReason,
  addReasonsToDtp,
};

export default reasonService;
