import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import stickerRequestService from "./stickerRequestService";
import { toast } from "react-toastify";

const verificationCode = process.env.REACT_APP_VERIFICATION_TOKEN;

const initialState = {
  allStickerRequests: [],
  isLoading: false,
  isError: false,
  isSuccess: false,
  message: "",
  isLoadingAddStickerRequest:false
};

// Add New  Sticker Request
export const addStickerRequest = createAsyncThunk(
  "auth/addStickerRequest",
  async (data, thunkAPI) => {
    try {
      const res = await stickerRequestService.addStickerRequest(data);
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.error);
    }
  }
);

// Update Status approve/sent Sticker Requests (For Admin Now)
export const updateStickerRequest = createAsyncThunk(
  "auth/updateStickerRequest",
  async (data, thunkAPI) => {
    try {
      const res = await stickerRequestService.updateStickerRequest(data);
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.error);
    }
  }
);

// Get All Sticker Requests  (For Admin Now)
export const getAllStickerRequests = createAsyncThunk(
  "auth/getAllStickerRequests",
  async (data, thunkAPI) => {
    try {
      const res = await stickerRequestService.getAllStickerRequests(data);
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.error);
    }
  }
);

// Create Sticker Note by Admin
export const createStickerNote = createAsyncThunk(
  "auth/createStickerNote",
  async (data, thunkAPI) => {
    try {
      await stickerRequestService.createStickerNote(data);
      const res = await stickerRequestService.getAllStickerRequests({
        verificationCode: verificationCode,
      });
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.error);
    }
  }
);

// Sticker Payment
export const stickerPayment = createAsyncThunk(
  "auth/stickerPayment",
  async (data, thunkAPI) => {
    try {
      const res = await stickerRequestService.stickerPayment(data);
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.error);
    }
  }
);

export const stickerRequest = createSlice({
  name: "stickerRequest",
  initialState,
  reducers: {
    reset: (state) => {
      state.isLoading = false;
      state.isError = false;
      state.message = "";
      state.isSuccess = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllStickerRequests.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAllStickerRequests.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.allStickerRequests = action.payload?.reverse();
      })
      .addCase(getAllStickerRequests.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        toast.error(action.payload);
      })
      .addCase(updateStickerRequest.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateStickerRequest.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.allStickerRequests = action.payload?.reverse();
        toast.success("Sent successfully");
      })
      .addCase(updateStickerRequest.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        toast.error(action.payload);
      })
      .addCase(stickerPayment.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(stickerPayment.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.allStickerRequests = action.payload;
        toast.success("Paid successfully");
      })
      .addCase(stickerPayment.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        toast.error(action.payload);
      })
      .addCase(addStickerRequest.pending, (state) => {
        state.isLoadingAddStickerRequest = true;
      })
      .addCase(addStickerRequest.fulfilled, (state, action) => {
        state.isLoadingAddStickerRequest = false;
        state.isSuccess = true;
        toast.success(action.payload.message);
      })
      .addCase(addStickerRequest.rejected, (state, action) => {
        state.isLoadingAddStickerRequest = false;
        state.isError = true;
        toast.error(action.payload);
      })
      .addCase(createStickerNote.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createStickerNote.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.allStickerRequests = action.payload;
        toast.success("Note added successfully ");
      })
      .addCase(createStickerNote.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        toast.error(action.payload);
      });
  },
});

export const { reset } = stickerRequest.actions;
export default stickerRequest.reducer;
