import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSearch,
  faDownload,
  faArrowAltCircleRight,
} from "@fortawesome/free-solid-svg-icons";
import * as XLSX from "xlsx";

import { useMyContext } from '../../../..';
import { Trans } from 'react-i18next';

//===========Data table =================
import DataTable from "react-data-table-component";
//=====Redux
import { useSelector, useDispatch } from "react-redux";
import { allDtpReports } from "../../../../redux/review/reviewSlice";

import Loader from "../../../../Loader";
import ExportCSVButton from "../../../../common/ExportCSVButton";
// import "./report.css";

//======Utils
const verificationCode = process.env.REACT_APP_VERIFICATION_TOKEN;

const Report = () => {
  let dispatch = useDispatch();

  const { allReports } = useSelector((state) => {
    return state.review;
  });

  //-=-==-=-==-===All data touch points Reports ============
  useEffect(() => {
    dispatch(
      allDtpReports({
        verificationCode,
      })
    );
  }, [dispatch]);

  //====================Table Data Starts =================
  //-------------------DATA TABLE START -------------------
  const [minEndDate, setMinEndDate] = useState("");
  const [selectedRangeNPS, setSelectedRangeNPS] = useState([]);

  const [dateRange, setDateRange] = useState({
    startDate: "",
    endDate: "",
  });

  const { startDate, endDate } = dateRange;

  const handleRangeChange = (e) => {
    const { name, value } = e.target;
    if (name === "startDate") {
      setMinEndDate(value);
    }
    setDateRange({ ...dateRange, [e.target.name]: e.target.value });
  };

  const handleApplyDateRange = () => {
    const npsData = [];

    allReports &&
      allReports.forEach((item) => {
        let totalPromoters = 0;
        let totalDetractors = 0;
        let totalReviews = 0;

        item.reviews.forEach((review) => {
          if (review.createdAt >= startDate && review.createdAt <= endDate) {
            totalReviews++;
            if (review.rating >= 3) {
              totalPromoters++;
            } else if (review.rating <= 1) {
              totalDetractors++;
            }
          }
        });

        const nps =
          totalReviews !== 0
            ? ((totalPromoters - totalDetractors) / totalReviews) * 100
            : 0;

        // Push an object with dataTouchPointId and NPS value to npsData array
        npsData.push({ dataTouchPointId: item.dataTouchPointId, nps });
      });

    setSelectedRangeNPS(npsData);
  };

  const [pending, setPending] = useState(true);

  //====Search Field
  const [searchQuery, setSearchQuery] = useState("");

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      setPending(false);
    }, 500);
    return () => clearTimeout(timeout);
  }, []);

  // const NPSSelectedPeriodCell = ({ row }) => {
  //   const dataTouchPointId = row?.dataTouchPointId;
  //   const npsSelectedPeriod = selectedRangeNPS?.[dataTouchPointId] || 0;

  //   return <span>{npsSelectedPeriod}</span>;
  // };
  //Search Field START
  const filteredData = searchQuery
    ? allReports.filter(
      (item) =>
        item.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
        item.business?.name_of_business
          .toLowerCase()
          .includes(searchQuery.toLowerCase()) ||
        item.business?.email
          .toLowerCase()
          .includes(searchQuery.toLowerCase()) ||
        item.business?.sector
          .toLowerCase()
          .includes(searchQuery.toLowerCase())
    )
    : allReports;

  //Search Field END

  const columns = [
    {
      name: <Trans>Data Touchpoint</Trans>,
      selector: (row) => row.name,
      sortable: true,
      center: true,
      width: "170px",
    },
    {
      name: <Trans>Business Name</Trans>,
      selector: (row) => row?.business?.name_of_business,
      sortable: true,
      center: true,
      width: "170px",
    },

    {
      name: <Trans>Email</Trans>,
      selector: (row) => row?.business?.email,
      sortable: true,
      center: true,
      width: "200px",
    },
    {
      name: <Trans>Sector</Trans>,
      selector: (row) => row?.business?.sector,
      sortable: true,
      center: true,
      width: "160px",
    },
    {
      name: <Trans>Measuring</Trans>,
      selector: (row) => row?.measuring,
      sortable: true,
      center: true,
      width: "180px",
    },

    {
      name: <Trans>NPS 30d</Trans>,
      selector: (row) => row?.npsLast30Days,
      sortable: true,
      center: true,
      cell: (row) => {
        const npsValue = row?.npsLast30Days;

        let color = "";

        if (npsValue < 0) {
          color = "red";
        } else if (npsValue >= 0 && npsValue < 30) {
          color = "lightcoral";
        } else if (npsValue >= 30 && npsValue < 50) {
          color = "lightgreen";
        } else if (npsValue >= 50 && npsValue < 70) {
          color = "mediumseagreen";
        } else if (npsValue >= 70 && npsValue <= 100) {
          color = "green";
        }

        return <span style={{ color: color }}>{row?.npsLast30Days}</span>;
      },
    },
    {
      name: <Trans>NPS YTD</Trans>,
      selector: (row) => row?.npsYearToDate,
      sortable: true,
      center: true,
      width: "170px",
      cell: (row) => {
        const npsValue = row?.npsYearToDate;

        let color = ""; // Default color

        if (npsValue < 0) {
          color = "red"; // Less than 0: Red
        } else if (npsValue >= 0 && npsValue < 30) {
          color = "lightcoral";
        } else if (npsValue >= 30 && npsValue < 50) {
          color = "lightgreen";
        } else if (npsValue >= 50 && npsValue < 70) {
          color = "mediumseagreen";
        } else if (npsValue >= 70 && npsValue <= 100) {
          color = "green";
        }

        return <span style={{ color: color }}>{row?.npsLast30Days}</span>;
      },
    },

    {
      name: <Trans>NPS Custom Period</Trans>,
      selector: (row) => {
        const nps = selectedRangeNPS.find(
          (npsData) => npsData.dataTouchPointId === row.dataTouchPointId
        );

        const npsValue = nps ? nps.nps : 0;

        let color = "";

        if (npsValue < 0) {
          color = "red";
        } else if (npsValue >= 0 && npsValue < 30) {
          color = "lightcoral";
        } else if (npsValue >= 30 && npsValue < 50) {
          color = "lightgreen";
        } else if (npsValue >= 50 && npsValue < 70) {
          color = "mediumseagreen";
        } else if (npsValue >= 70 && npsValue <= 100) {
          color = "green";
        }

        return <span style={{ color: color }}>{npsValue.toFixed(0)}</span>;
      },
      sortable: true,
      center: true,
      width: "190px",
    },
  ];

  //  Internally, customStyles will deep merges your customStyles with the default styling.
  const customStyles = {
    rows: {
      style: {
        minHeight: "60px",
      },
    },
    headCells: {
      style: {
        fontSize: "14px",
        color: "#B5B7C0",
        textAlign: "center",
      },
    },
    cells: {
      style: {
        textAlign: "center", // Align regular cell content to center
        color: "#292D32 !important",
        fontWeight: "600",
      },
    },
  };

  //-------Export CSV ----------------
  function downloadExcel(array) {
    const worksheet = XLSX.utils.json_to_sheet(array);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

    const filename = "Reports.xlsx";

    XLSX.writeFile(workbook, filename);
  }

  const Export = ({ onExport }) => (
    <Link
      className="btn btn-secondary mb-2 mx-2"
      onClick={() => onExport(filteredData)}
    >
      <FontAwesomeIcon icon={faDownload} className="btnIcon" />
      <Trans>Export</Trans>
    </Link>
  );

  const actionsMemo = React.useMemo(
    () => <Export onExport={() => downloadExcel(filteredData)} />,
    []
  );
  //-------------------DATA TABLE END---------------------------------

  const { language, setLanguage } = useMyContext();
  return (
    <>
      <div className="container-fluid my-3">
        <div className="row g-3">
          <div className="col-md-1">
            <h3 className="d-block align-items-center fw-500"><Trans>Reports</Trans></h3>
          </div>
          <div className="col-md-3">
            <div className="search-field search-bar position-relative">
              <input
                type="text"
                placeholder={language === "en" ? "Search" : "Búsqueda"}
                className="dashboardinput"
                id="userSearch"
                value={searchQuery}
                onChange={handleSearchChange}
              />
              <span className="IconSearchInReport">
                <FontAwesomeIcon icon={faSearch} className="usersearch" />
              </span>
            </div>
          </div>
          <div className="col-md-2">
            <div className="form-group">
              <input
                type="date"
                className="form-control m-0"
                name="startDate"
                value={startDate}
                onChange={handleRangeChange}
                style={{ height: "42px" }}
              />
            </div>
          </div>
          <div className="col-md-2">
            <div className="form-group">
              <input
                type="date"
                className="form-control m-0"
                name="endDate"
                value={endDate}
                min={minEndDate} // Add the min attribute to disable dates earlier than the minimum selectable end date
                onChange={handleRangeChange}
                style={{ height: "42px" }}
              />
            </div>
          </div>
          <div className="col-md-2 ">
            <Link
              onClick={handleApplyDateRange}
              className="btn btn-secondary w-100"
              style={{ height: "42px" }}
            >
              <FontAwesomeIcon
                icon={faArrowAltCircleRight}
                className="btnIcon"
              />
              <span className="mbl-none"><Trans>Apply</Trans></span>
            </Link>

          </div>
          <div className="col-md-2">
            <ExportCSVButton data={allReports} filename={"exported_data.csv"} />
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="mt-2 outerWrapperTable" style={{ overflowX: "auto" }}>
              {allReports && (
                <DataTable
                  columns={columns}
                  data={filteredData}
                  progressPending={pending}
                  progressComponent={<Loader />}
                  actions={actionsMemo}
                  customStyles={customStyles}
                  pagination
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Report;
