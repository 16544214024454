import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import questionService from "./questionService";
import { toast } from "react-toastify";

const verificationCode = process.env.REACT_APP_VERIFICATION_TOKEN;

const initialState = {
  allQuestions: [],
  isLoading: false,
  isError: false,
  isSuccess: false,
  message: "",
  deleteQuestion:null,
  isLoadingAddQuestion:false
};

// Add New  Question
export const addQuestion = createAsyncThunk(
  "auth/addQuestion",
  async (data, thunkAPI) => {
    try {
      const ResAddQuestion = await questionService.addQuestion(data);
      const res = await questionService.getAllQuestions({
        verificationCode: verificationCode,
      });
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.error);
    }
  }
);

// Edit  Question
export const editQuestion = createAsyncThunk(
  "auth/editQuestion",
  async (data, thunkAPI) => {
    try {
      await questionService.editQuestion(data);
      const res = await questionService.getAllQuestions({
        verificationCode: verificationCode,
      });
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.error);
    }
  }
);

// Get All Question
export const getAllQuestions = createAsyncThunk(
  "auth/getAllQuestions",
  async (data, thunkAPI) => {
    try {
      const res = await questionService.getAllQuestions(data);
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.error);
    }
  }
);

// delete Question
export const handlerDeleteReason = createAsyncThunk(
  "auth/deleteQuestion",
  async (data, thunkAPI) => {
    try {
      const ResDelete = await questionService.deleteQuestion(data);
      const res = await questionService.getAllQuestions({verificationCode : process.env.REACT_APP_VERIFICATION_TOKEN});
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.error);
    }
  }
);




export const question = createSlice({
  name: "question",
  initialState,
  reducers: {
    reset: (state) => {
      state.isLoading = false;
      state.isError = false;
      state.message = "";
      state.isSuccess = false;
    },
    resetDelete: (state) => {
      state.deleteQuestion = false;
    },

  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllQuestions.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAllQuestions.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.allQuestions = action.payload.reverse();
      })
      .addCase(getAllQuestions.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        toast.error(action.payload);
      })

      .addCase(handlerDeleteReason.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(handlerDeleteReason.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.allQuestions = action.payload.reverse();
        toast.success("Successfully deleted Question")
      })
      .addCase(handlerDeleteReason.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        toast.error(action.payload);
      })
      .addCase(addQuestion.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(addQuestion.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.allQuestions = action.payload.reverse();
        toast.success("Question added successfully.");
      })
      .addCase(addQuestion.rejected, (state, action) => {
        state.isLoadingAddQuestion = false;
        state.isError = true;
        toast.error(action.payload);
      })
      .addCase(editQuestion.pending, (state) => {
        state.isLoadingAddQuestion = true;
      })
      .addCase(editQuestion.fulfilled, (state, action) => {
        state.isLoadingAddQuestion = false;
        state.isSuccess = true;
        state.allQuestions = action.payload;
        toast.success("Question updated successfully.");
      })
      .addCase(editQuestion.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        toast.error(action.payload);
      });
  },
});

export const { reset , resetDelete } = question.actions;
export default question.reducer;
