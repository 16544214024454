import React, { useEffect, useState } from 'react';
import { NavLink, Routes, Route, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import whileLogo from '../../../assets/images/icons/GoRateWhite.png';

import { useTranslation } from 'react-i18next';

import { useMyContext } from '../../..';
import { Trans } from 'react-i18next';

import Main from '../company/frontdash/Main';
import Report from '../company/reports/Report';
import Support from '../company/support/Support';
import Csetting from '../company/setting/Csetting';
import Csubscription from '../company/subscription/Csubscription';
import CTouchPoints from '../company/touchpoints/CTouchPoints';
import CBench from '../company/bench/CBench';

import { Elements } from '@stripe/react-stripe-js';

//=Icons
import {
  MdOutlineDashboard,
  MdDataSaverOff,
  MdOutlineSettings,
} from 'react-icons/md';
import { BiGitBranch, BiSolidReport } from 'react-icons/bi';
import { BsChatRight } from 'react-icons/bs';
import { LuFileText } from 'react-icons/lu';

//=========Import Toasts========
import { toast } from 'react-toastify';

//==============Import Redux =================
import { useDispatch, useSelector } from 'react-redux';
import { getBenchSettings, logout, reset } from '../../../redux/auth/authSlice';
import DTPView from './touchpoints/DTPView';
import { BsShop } from 'react-icons/bs';
import TermsAndCondition from './termAndCondition/TermAndCondition';
import { loadStripe } from '@stripe/stripe-js';

// import "./dashboard.css";
const verificationCode = process.env.REACT_APP_VERIFICATION_TOKEN;

const Dashboard = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const stripePromise = loadStripe(
    process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY
  );

  const { language, setLanguage } = useMyContext();

  const { showBench } = useSelector((state) => {
    return state.auth;
  });

  useEffect(() => {
    dispatch(getBenchSettings({ verificationCode }));
  }, [dispatch]);

  const [activeLink, setActiveLink] = useState('');
  const [activeSubMenu, setActiveSubMenu] = useState(null);
  const [sidebarVisible, setSidebarVisible] = useState(false);

  const handleClick = (link) => {
    setSidebarVisible(false);
    setActiveLink(link);
  };

  //Humgurger Menu
  const toggleSidebar = () => {
    setSidebarVisible(!sidebarVisible);
  };

  const sidebar = [
    {
      link: '',
      text: <Trans>Dashboard</Trans>,
      icon: <MdOutlineDashboard />,
    },
    {
      link: 'touchpoints',
      text: <Trans>Data Touchpoints</Trans>,
      icon: <MdDataSaverOff />,
    },
    ...(showBench
      ? [
          {
            link: 'benchmarks',
            text: <Trans>Benchmarks</Trans>,
            icon: <BiGitBranch />,
          },
        ]
      : []),

    {
      link: 'report',
      text: <Trans>Reports</Trans>,
      icon: <BiSolidReport />,
    },
    {
      link: 'shop',
      text: <Trans>Shop</Trans>,
      icon: <BsShop />,
    },

    {
      link: 'support',
      text: <Trans>Support</Trans>,
      icon: <BsChatRight />,
    },
    {
      link: 'setting',
      text: <Trans>Settings</Trans>,
      icon: <MdOutlineSettings />,
    },
    {
      link: 'termAndCondition',
      text: <Trans>Terms&Conditions</Trans>,
      icon: <LuFileText />,
    },
  ];

  //==============Logout Handler Submit=====================
  const handleLogout = () => {
    dispatch(logout());
    // navigate("/company/login");
    navigate('/');

    dispatch(reset());
    toast.success('Logout successfully !');
  };

  return (
    <>
      <section id="dashboardSidebar">
        <div className="container-fluid">
          <div className="row">
            <div className="p-0" id="SideBArWid">
              <div className="profile-sidebar card shadow-sm">
                <div className="logo py-2">
                  <h3 className="logoHeading pt-3">
                    {' '}
                    <img src={whileLogo} alt="" width={120} />
                  </h3>
                  <div className="menu-icon" onClick={toggleSidebar}>
                    <FontAwesomeIcon icon={faBars} />
                  </div>
                </div>
                <div className={`sidebar ${sidebarVisible ? 'show' : ''}`}>
                  <div className=" py-2 mobile_logo_hide">
                    <h3 className=" pt-3">
                      {' '}
                      <img src={whileLogo} alt="" width={200} />
                    </h3>
                  </div>
                  <div className="sidebar-links">
                    <div className="sidebar-links-container">
                      {' '}
                      {/* Add container element */}
                      {sidebar &&
                        sidebar.map((item, index) => (
                          <div key={index}>
                            {item.isDropdown ? (
                              <div className="divSideBar">
                                <div
                                  onClick={() => {
                                    setActiveSubMenu(
                                      activeSubMenu === item.link
                                        ? null
                                        : item.link
                                    );
                                  }}
                                  className={
                                    activeLink === `${item.link}`
                                      ? 'activeSideBar'
                                      : ''
                                  }
                                  inline={item.isDropdown ? 'true' : false}
                                >
                                  <span className="fs-5 me-2">{item.icon}</span>
                                  <span className="p-1">{item.text}</span>
                                </div>
                                {activeSubMenu === item.link && (
                                  <div style={{ paddingLeft: '1.5rem' }}>
                                    {item.submenu.map(
                                      (submenuItem, submenuIndex) => (
                                        <NavLink
                                          key={submenuIndex}
                                          to={`${submenuItem.link}`}
                                          onClick={() =>
                                            handleClick(submenuItem.link)
                                          }
                                          className={
                                            activeLink === `${submenuItem.link}`
                                              ? 'activeSideBar'
                                              : ''
                                          }
                                          inline={
                                            item.isDropdown ? 'true' : false
                                          }
                                        >
                                          {submenuItem.text}
                                        </NavLink>
                                      )
                                    )}
                                  </div>
                                )}
                              </div>
                            ) : (
                              <NavLink
                                to={`${item.link}`}
                                onClick={() => handleClick(item.link)}
                                className={
                                  activeLink === `${item.link}`
                                    ? 'activeSideBar'
                                    : ''
                                }
                                inline={item.isDropdown ? 'true' : false}
                              >
                                <span className="fs-5 me-2">{item.icon}</span>
                                {item.text}
                              </NavLink>
                            )}
                          </div>
                        ))}{' '}
                      <div className="logout-button">
                        <button
                          className="btn btn-white"
                          onClick={handleLogout}
                        >
                          <Trans>Log out</Trans>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-9 mt-2" id="dashRIght">
              <Routes>
                <Route path="" index element={<Main />} />
                <Route path="/report" index element={<Report />} />
                <Route path="/support" index element={<Support />} />
                <Route path="/setting" index element={<Csetting />} />
                <Route
                  path="/shop"
                  index
                  element={
                    <Elements stripe={stripePromise}>
                      <Csubscription />
                    </Elements>
                  }
                />
                <Route path="/touchpoints" index element={<CTouchPoints />} />
                <Route path="/datatouchpoint/:slug" element={<DTPView />} />

                <Route path="/benchmarks" index element={<CBench />} />
                <Route
                  path="/termAndCondition"
                  index
                  element={<TermsAndCondition />}
                />
              </Routes>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Dashboard;
