import React, { Suspense, createContext, useContext, useState, useEffect } from "react";
import ReactDOM from "react-dom/client";
import App from "./App.jsx";
import { initReactI18next } from 'react-i18next';
import i18n from 'i18next';

//=================Redux =============================
import { Provider } from "react-redux";
import { store } from "./redux/store.js";

import { PersistGate } from "redux-persist/integration/react";
import { persistStore } from "redux-persist";
let persistor = persistStore(store);

import enJson from "./translations/en.json"
import esJson from "./translations/es.json"
import { Button } from "react-bootstrap";

//=================Redux =============================


// Context api working
const MyContext = createContext();


export const MyContextProvider = ({ children }) => {
  // Define your state and functions here
  const [language, setLanguage] = useState('en');
  //  language switcher initialization
  i18n
    .use(initReactI18next)
    .init({
      resources: {
        es: {
          translation: esJson,
        },
        en: {
          translation: enJson,
        },
      },
      lng: language, // Set the default language to Spanish
      fallbackLng: 'en', // Use English as the fallback language
      interpolation: {
        escapeValue: false, // React already safely escapes strings
      },
    });

  return (
    <MyContext.Provider value={{ language, setLanguage }}>
      {children}
    </MyContext.Provider>
  );
};

export const useMyContext = () => {
  return useContext(MyContext);
};

ReactDOM.createRoot(document.getElementById("root")).render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <Suspense fallback={<span>Loading...</span>}>
        <MyContextProvider>
          <App />
        </MyContextProvider>
      </Suspense>
    </PersistGate>
  </Provider>
);
