import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import additionalDtpService from "./additionalDtpService";
import { toast } from "react-toastify";

const verificationCode = process.env.REACT_APP_VERIFICATION_TOKEN;

const initialState = {
  allAdditionalDTPReq: [],
  isLoading: false,
  isError: false,
  isSuccess: false,
  message: "",
};

// Add More DTP Request
export const addMoreDTPReq = createAsyncThunk(
  "auth/addMoreDTPReq",
  async (data, thunkAPI) => {
    try {
      const res = await additionalDtpService.addMoreDTPReq(data);
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.error);
    }
  }
);

// Update Status More DTP Requsts
export const updateMoreDTPRequest = createAsyncThunk(
  "auth/updateMoreDTPRequest",
  async (data, thunkAPI) => {
    try {
      const res = await additionalDtpService.updateMoreDTPRequest(data);
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.error);
    }
  }
);

// Get All More DTP Requsts (For Admin Now)
export const getAllMoreDTPRequst = createAsyncThunk(
  "auth/getAllMoreDTPRequst",
  async (data, thunkAPI) => {
    try {
      const res = await additionalDtpService.getAllMoreDTPRequst(data);
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.error);
    }
  }
);

// Create note for More DTP Requst by Admin
export const createMoreDTPNote = createAsyncThunk(
  "auth/createMoreDTPNote",
  async (data, thunkAPI) => {
    try {
      await additionalDtpService.createMoreDTPNote(data);
      const res = await additionalDtpService.getAllMoreDTPRequst({
        verificationCode: verificationCode,
      });
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.error);
    }
  }
);

// // Edit Sticker Request
// export const editStickerRequest = createAsyncThunk(
//   "auth/editStickerRequest",
//   async (data, thunkAPI) => {
//     try {
//       await stickerRequestService.editStickerRequest(data);
//       const res = await stickerRequestService.getAllStickerRequests({
//         verificationCode: verificationCode,
//       });
//       return res.data;
//     } catch (error) {
//       return thunkAPI.rejectWithValue(error.response.data.error);
//     }
//   }
// );

export const additionalDtpRequest = createSlice({
  name: "additionalDtpRequest",
  initialState,
  reducers: {
    reset: (state) => {
      state.isLoading = false;
      state.isError = false;
      state.message = "";
      state.isSuccess = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllMoreDTPRequst.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAllMoreDTPRequst.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.allAdditionalDTPReq = action.payload;
      })
      .addCase(getAllMoreDTPRequst.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        toast.error(action.payload);
      })
      .addCase(updateMoreDTPRequest.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateMoreDTPRequest.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.allAdditionalDTPReq = action.payload;
        toast.success("Approved successfully");
      })
      .addCase(updateMoreDTPRequest.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        toast.error(action.payload);
      })
      .addCase(addMoreDTPReq.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(addMoreDTPReq.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        toast.success(action.payload.message);
      })
      .addCase(addMoreDTPReq.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        toast.error(action.payload);
      })
      .addCase(createMoreDTPNote.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createMoreDTPNote.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.allAdditionalDTPReq = action.payload;
        toast.success("Note added successfully ");
      })
      .addCase(createMoreDTPNote.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        toast.error(action.payload);
      });

    //   .addCase(editStickerRequest.pending, (state) => {
    //     state.isLoading = true;
    //   })
    //   .addCase(editStickerRequest.fulfilled, (state, action) => {
    //     state.isLoading = false;
    //     state.isSuccess = true;
    //     state.allStickerRequests = action.payload;
    //     toast.success("Sticker Request updated successfully.");
    //   })
    //   .addCase(editStickerRequest.rejected, (state, action) => {
    //     state.isLoading = false;
    //     state.isError = true;
    //     toast.error(action.payload);
    //   });
  },
});

export const { reset } = additionalDtpRequest.actions;
export default additionalDtpRequest.reducer;
