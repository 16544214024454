import React, { useState } from 'react'
import { Alert, Button } from 'react-bootstrap';
import { Trans } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { HandlerAddCities, HandlerAddCountries, HandlerDeleteCity, getAllCountries, resetCities, resetCountries, resetDeleteCountries } from '../../../redux/CountryAndCities/CountryAndCitySlice';
import { useEffect } from 'react';
import { toast } from 'react-toastify';
import Loader from '../../../Loader';
import Select from "react-select";
import DataTable from "react-data-table-component";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faSearch,
    faDownload,
    faArrowAltCircleRight,
    faEdit,
    faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import { useMyContext } from '../../..';
const CountriesAndCities = () => {
    const [Countries, setCountries] = useState("")
    const [ValidationCountries, setValidationCountries] = useState(false)
    const [Cities, setCities] = useState("")
    const [ValidationCities, setValidationCities] = useState(false)
    const [SelectedCountry, setSelectedCountry] = useState()
    const [CountrySelectedValidation, setCountrySelectedValidation] = useState(false)
    const { loadingCountries, AddCountries, loadingCities, AddCities, getCountries, DeleteCountry, loadingDeleteCountry } = useSelector((state) => state.CountryAndCitySlice);

    let dispatch = useDispatch();

    function HandlerCountriesAdding(e) {
        e.preventDefault();
        if (!Countries) {
            setValidationCountries(true)
        } else {
            dispatch(HandlerAddCountries({
                name_of_country: Countries
            }))
        }
    }
    function HandlerCityAdding(e) {
        e.preventDefault();
        if (!SelectedCountry) {
            setCountrySelectedValidation(true)
        } else if (!Cities) {
            setValidationCities(true)
        }
        else {
            dispatch(HandlerAddCities({
                name_of_city: Cities,
                countryId: SelectedCountry,
            }))
        }
    }
    useEffect(() => {
        if (AddCountries) {
            toast.success("Successfully Added Country")
            dispatch(getAllCountries())
            setCountries("")
        }
        dispatch(resetCountries())
        return () => { }
    }, [AddCountries])

    useEffect(() => {
        if (AddCities) {
            toast.success("Successfully Added City")
            dispatch(getAllCountries())
            setCities("")
        }
        dispatch(resetCountries())
        dispatch(resetCities())
        return () => { }
    }, [AddCities])

    useEffect(() => {
        dispatch(getAllCountries())
        return () => { }
    }, [])

    function handlerReturnCity(CityList) {
        let name = "";
        let length = 0
        for (let i = 0; i < CityList.length; i++) {
            length++
            if (i > 0) {
                name += " , ";
            }
            name += CityList[i]?.name;
            if (length === 10) {
                break
            }
        }
        return name;
    }



    const showAlert = (id) => {
        Swal.fire({
            title: "Are you sure you would like to delete it?",
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: "#DD6B55",
            confirmButtonText: "Yes!",
            closeOnConfirm: false
        }).then((result) => {
            dispatch(HandlerDeleteCity(id))
        });
    };

    useEffect(() => {
        if (DeleteCountry) {
            toast.success("Successfully Delete Country")
            dispatch(getAllCountries())
        }
        dispatch(resetDeleteCountries())
        return () => { }
    }, [DeleteCountry])

    const actions = [
        // {
        //     icon: <FontAwesomeIcon className="btn btnPrint p-0 fs-6" icon={faEdit} />,
        //     tooltip: "Edit",
        //     onClick: (event, rowData) => {
        //     },
        // },
        {
            icon: (
                <FontAwesomeIcon
                    className="btn btnPrint p-0 ms-2 fs-6"
                    icon={faTrash}
                />
            ),
            tooltip: "Delete",
            onClick: (event, rowData) => {
                showAlert(rowData?.countryId)
            },
        },
    ];
    const DataTableActionButtons = ({ actions, rowData }) => (
        <>
            {actions.map((action, index) => (
                <button
                    className="btn btn-sm"
                    key={index}
                    onClick={(event) => action.onClick(event, rowData)}
                >
                    {action.icon}
                </button>
            ))}
        </>
    );
    const columns = [
        {
            name: <Trans>Countries</Trans>,
            selector: (row) => row.country,
            sortable: true,
            center: true,
            width: "170px",
        },
        {
            name: <Trans>Cities</Trans>,
            selector: (row) => handlerReturnCity(row?.cities),
            sortable: true,
            center: true,
            width: "170px",
        },
        {
            name: <Trans>Actions</Trans>,
            cell: (row) => <DataTableActionButtons actions={actions} rowData={row} />,
            ignoreRowClick: true,
            allowOverflow: true,
            button: true,
            // center: true,
        },
    ];
    //  Internally, customStyles will deep merges your customStyles with the default styling.
    const customStyles = {
        rows: {
            style: {
                minHeight: "60px",
            },
        },
        headCells: {
            style: {
                fontSize: "14px",
                color: "#B5B7C0",
                textAlign: "center",
            },
        },
        cells: {
            style: {
                textAlign: "center", // Align regular cell content to center
                color: "#292D32 !important",
                fontWeight: "600",
            },
        },
    };


    function downloadExcel(array) {
        const worksheet = XLSX.utils.json_to_sheet(array);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
        const filename = "Reports.xlsx";
        XLSX.writeFile(workbook, filename);
    }

    const Export = ({ onExport }) => (
        <Link
            className="btn btn-secondary mb-2 mx-2"
            onClick={() => onExport(filteredData)}
        >
            <FontAwesomeIcon icon={faDownload} className="btnIcon" />
            <Trans>Export</Trans>
        </Link>
    );

    const actionsMemo = React.useMemo(
        () => <Export onExport={() => downloadExcel(filteredData)} />,
        []
    );
    const [searchQuery, setSearchQuery] = useState("");

    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
    };
    const { language, setLanguage } = useMyContext();
    // Apply the filter based on the input text
    const filteredData = searchQuery
        ? getCountries?.countries?.filter(
            (item) => item?.country.toLowerCase().includes(searchQuery.toLowerCase())
        )
        : getCountries?.countries;
    return (
        <div>
            <div className="container-fluid my-3">
                <div className="row">
                    <div className="col-12 col-lg-12">
                        <h3 className="d-block align-items-center fw-500"><Trans>Countries and Cities</Trans></h3>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-md-6'>
                        <h3 className="d-block align-items-center fw-500"><Trans>Add Countries</Trans></h3>
                        <form className="row g-3" onSubmit={HandlerCountriesAdding}>
                            <div className="row mt-3">
                                <div className="col-md-12">
                                    <label className="form-label">
                                        <Trans>Country Name</Trans>
                                    </label>
                                    <input
                                        type="text"
                                        value={Countries}
                                        name="Countries"
                                        placeholder='Countries'
                                        onChange={(e) => {
                                            setCountries(e.target.value)
                                            setValidationCountries(false)
                                        }}
                                        className="form-control"
                                    />
                                    {ValidationCountries && <Alert variant='danger'>Please Add Country Name</Alert>}
                                    {loadingCountries ? <div className='LoadingButton'>  loading</div>
                                        : <Button
                                            type="submit"
                                            className="btn btn-primary"
                                        >Add Country</Button>}
                                </div>
                            </div>
                        </form>
                    </div>
                    <div className='col-md-6'>
                        <h3 className="d-block align-items-center fw-500"><Trans>Add Cities</Trans></h3>
                        <form className="row g-3" onSubmit={HandlerCityAdding}>
                            <div className="row mt-3">
                                <div className="col-md-12">
                                    <div className='mb-3'>
                                        <label className="form-label">
                                            <Trans>Select Country Name</Trans>
                                        </label>
                                        <Select
                                            isClearable={true}
                                            onChange={(e) => {
                                                setSelectedCountry(e?.value)
                                                setCountrySelectedValidation(false)
                                            }}
                                            options={getCountries?.countries?.map((country) => ({
                                                label: country?.country,
                                                value: country?.countryId,
                                            }))}
                                        />
                                        {CountrySelectedValidation && <Alert variant='danger'>Please Select Country Name</Alert>}
                                    </div>
                                    <label className="form-label">
                                        <Trans>City Name</Trans>
                                    </label>
                                    <input
                                        type="text"
                                        value={Cities}
                                        name="Countries"
                                        placeholder='City Name..'
                                        onChange={(e) => {
                                            setCities(e.target.value)
                                            setValidationCities(false)
                                        }}
                                        className="form-control"
                                    />
                                    {ValidationCities && <Alert variant='danger'>Please Add City Name</Alert>}
                                    {loadingCities ? <div className='LoadingButton'>  loading</div>
                                        : <Button
                                            type="submit"
                                            className="btn btn-primary"
                                        >Add City</Button>}
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                <div className="row  mt-3">
                    <div className="search-field search-bar position-relative">
                        <input
                            type="text"
                            placeholder={language === "en" ? "Search" : "Búsqueda"}
                            className="dashboardinput"
                            id="userSearch"
                            value={searchQuery}
                            onChange={handleSearchChange}
                        />
                        <span className="outerWrapperSearchIcon">
                            <FontAwesomeIcon icon={faSearch} className="usersearchInCountriesAndCity" />
                        </span>
                    </div>
                    <div className="my-3 outerWrapperDataTableInCountries ">
                        <div className='outerWrapperTable'>
                            <DataTable
                                columns={columns}
                                data={filteredData}
                                progressPending={loadingCountries}
                                progressComponent={<Loader />}
                                actions={actionsMemo}
                                customStyles={customStyles}
                                pagination
                            />
                        </div>
                    </div>
                </div>
            </div >
        </div >
    )
}

export default CountriesAndCities