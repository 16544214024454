import React from "react";

const NotFound = () => {
  return (
    <div>
      <div>
        <h1>404 - Page not found</h1>
        <p>The requested page could not be found.</p>
      </div>
    </div>
  );
};

export default NotFound;
