import React, { useState, useEffect } from "react";
import goRateLogo from "../../assets/images/icons/GoRateLogo.png";
import { Link, useNavigate } from "react-router-dom";

//===================Redux===================
import { useSelector, useDispatch } from "react-redux";
import { userLogin, reset } from "../../redux/auth/authSlice";
import "./login.css";    
         
//===============Verfication Code =================     
const verificationCode = process.env.REACT_APP_VERIFICATION_TOKEN;


const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [PasswordVisible, setPasswordVisible] = useState(true)
  const { isError, isSuccess, isLoading, profile } = useSelector((state) => {
    return state.auth;
  });

  //========Login Credentials =================

  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });

  const { email, password } = formData;

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleLogin = (e) => {
    e.preventDefault();
    const loginData = { verificationCode: verificationCode, email, password };
  
    dispatch(userLogin(loginData));
    dispatch(reset());
  };

  //============== USE EFFECTS START=============================

  useEffect(() => {
    if (profile) {
      if (profile.role === "admin") {
        navigate("/admin");
      }
      if (profile.role === "business") {
        navigate("/company");
      }
      if (profile.role === "dataTP") {
        navigate("/data-touchpoint");
      }
    }
    dispatch(reset());
  }, [profile, isError, isSuccess, navigate, dispatch]);
  //============== USE EFFECTS END=============================

  return (
    <div className="outerWrapperContainer">
      <div className="container">
        <div className="row justify-content-center align-items-center vh-100 ">
          <div className="col-md-6 col-sm-12 mx-auto">
            <div className="logincontent ">
              <div>
                <div className="text-center my-3">
                  <img
                    src={goRateLogo}
                    alt="Welcome"
                    className="img-fluid"
                    style={{
                      width: "250px",
                      height: "60px",
                      objectFit: "contain",
                    }}
                  />
                </div>
                {/* <h1 className="fw-500 text-center my-auto">
                  Welcome{" "}
                  <img
                    src={HI}
                    alt="Welcome"
                    className="img-fluid"
                    style={{
                      width: "80px",
                      height: "80px",
                    }}
                  />
                </h1>
                <p className=" text-nowrap text-center">
                  Please enter your user details to log in
                </p> */}

                <form className="row" onSubmit={handleLogin}>
                  <div className="col-md-12">
                    <label className="form-label">Email</label>
                    <input
                      type="email"
                      className="form-control"
                      name="email"
                      value={formData.email}
                      onChange={handleChange}
                      id="inputEmail4"
                    />
                  </div>
                  <div className="col-md-12 position-relative">
                    <label className="form-label">Password</label>
                    <input
                      type={PasswordVisible ? "password" : "text"}
                      className="form-control"
                      name="password"
                      value={formData.password}
                      onChange={handleChange}
                      id="inputPassword4"
                    />
                     {PasswordVisible ? <img src="/assets/icons/EyeView.svg"
                        alt="DropDownIcon"
                        className='DropDownIconsInputField DatePickerDropDown c_pointer EyeIconsInAddressInSignUp'
                        onClick={() => {
                          setPasswordVisible(!PasswordVisible)
                        }}
                      /> :
                        <img src="/assets/icons/EyeViewNot.svg"
                          alt="DropDownIcon"
                          className='DropDownIconsInputField DatePickerDropDown c_pointer EyeIconsInAddressInSignUp'
                          onClick={() => {
                            setPasswordVisible(!PasswordVisible)
                          }}
                        />}
                  </div>
                  <div className="col-md-12">
                    <div className="d-flex justify-content-between py-3">
                      <div className="">
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            id="gridCheck"
                          />
                          <label
                            className="form-check-label mbl-text"
                            for="gridCheck"
                          >
                            Remember me
                          </label>
                        </div>
                      </div>
                      <Link
                        to="/forgetPassword"
                        className="text-black  fw-600 mbl-text text-nowrap"
                      >
                        Forgot password
                      </Link>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <button
                      className="btn btn-warning w-100"
                      onClick={handleLogin}
                    >
                      Sign in
                    </button>
                  </div>
                  <h5 className="text-center my-3">
                    <u>OR</u>
                  </h5>
                  <h6 className="text-center ">
                    <Link
                      to="/company/register"
                      className="text-black  fw-600 mbl-text text-nowrap"
                    >
                      <u> Sign up</u>
                    </Link>{" "}
                    as a Business
                  </h6>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
