import Form from 'react-bootstrap/Form';

function Checkbox({ id, label, disabled, onChange, defaultChecked, ...rest }) {
  return (
    <Form>
      <div key={id || ''} className='mb-3 custom-checkbox'>
        <Form.Check
          {...rest}
          type='checkbox'
          id={id || 'default-checkbox'}
          label={label || ''}
          disabled={disabled}
          onChange={onChange}
          defaultChecked={defaultChecked}
        />
      </div>
    </Form>
  );
}

export default Checkbox;
