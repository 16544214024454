import api from "../../utility/api";

// Add New  Stickers
const addSticker = async (data) => {
  const response = await api.post("/sticker/create", data);
  return response;
};

// Edit Sticker
const editSticker = async (data) => {
  const response = await api.put("/sticker/update", data);
  return response;
};

// Get All Stickers
const getAllStickers = async (data) => {
  const response = await api.post("/sticker/getAll", data);
  return response;
};

const questionService = {
  getAllStickers,
  addSticker,
  editSticker,
};

export default questionService;
