import React, { useState, useEffect } from 'react';

//=========Redux===========
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';

import PaymentForm from './PaymentForm';

//===Utils

const PaymentConfiguration = () => {
  const stripePromise = loadStripe(
    process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY
  );

  return (
    <>
      <Elements
        stripe={stripePromise}
        options={{ mode: 'setup', currency: 'eur' }}
      >
        <PaymentForm />
      </Elements>
    </>
  );
};

export default PaymentConfiguration;
