import React from "react";
import { ProgressBar } from "react-loader-spinner";

const Loader = () => {
  return (
    <div>
      <ProgressBar
        height={80}
        width={80}
        color="#1F3C76"
        visible={true}
        ariaLabel="progress-bar-loading"
        wrapperStyle={{}}
        wrapperClass="progress-bar-wrapper"
        borderColor="#4A88CA"
        barColor="#4A88CA"
      />
    </div>
  );
};

export default Loader;
