import { CSVLink } from 'react-csv';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faDownload,
} from "@fortawesome/free-solid-svg-icons";
import { Trans } from 'react-i18next';
import { Link } from 'react-router-dom';
const ExportCSVButton = ({ data, filename }) => {
    // Convert data to CSV format
    const csvData = data?.map(item => Object.values(item));
    // Create a CSV file
    const csvContent = csvData?.map(row => row.join(',')).join('\n');
    const csvBlob = new Blob([csvContent], { type: 'text/csv' });
    const csvUrl = URL.createObjectURL(csvBlob);

    return (
        <CSVLink data={csvData} filename={filename}>
            <div className="btn btn-secondary w-100">
                <FontAwesomeIcon icon={faDownload} className="btnIcon" />
                <Trans>Export</Trans>
            </div>
        </CSVLink>
    );
};

export default ExportCSVButton
