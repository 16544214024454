import React, { useState, useEffect, useRef } from "react";
import $ from "jquery";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleLeft,
  faSearch,
  faPaperclip,
  faArrowLeftLong,
  faImage,
  faPlus,
  faEnvelope,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";

import { useMyContext } from '../../../..';
import { Trans } from 'react-i18next';
//=========Redux===========
import { useSelector, useDispatch } from "react-redux";
import { getAllBusinesses } from "../../../../redux/auth/authSlice";
import axios from "axios";

// import "./support.css";    

const verificationCode = process.env.REACT_APP_VERIFICATION_TOKEN;
const BASE_URL_IMAGES = process.env.REACT_APP_BASE_URL_IMAGE;
const CHAT_IMAGES = process.env.REACT_APP_CHAT_IMAGE;
const BASE_URL = process.env.REACT_APP_BASE_URL;

//============socket Import============
import { io } from "socket.io-client";
import moment from "moment/moment";

var connectionOptions = {
  "force new connection": true,
  reconnectionAttempts: "Infinity",
  timeout: 10000,
};

export var socket;
socket = io.connect(process.env.REACT_APP_SOCKET_URL, {
  ...connectionOptions,
  debug: true,
});

if (socket) {
  socket.onopen = () => {
  };
} else {
}

const Support = () => {
  const dispatch = useDispatch();

  const { allBusinessData, profile } = useSelector((state) => {
    return state.auth;
  });

  //------Add User----
  useEffect(() => {
    socket.emit("addUser", profile?._id);
  }, [profile?._id]);

  //===============Get all Business================

  useEffect(() => {
    dispatch(getAllBusinesses({ verificationCode }));
  }, [dispatch]);

  //==========Search filter for users===================
  const [searchQuery, setSearchQuery] = useState("");

  const filteredData = searchQuery
    ? allBusinessData.filter((item) =>
      item.name_of_business.toLowerCase().includes(searchQuery.toLowerCase())
    )
    : allBusinessData;

  //===========Get Conversation based on business Id====
  const [conversations, setConversations] = useState([]);
  const [currentUser, setCurrentUser] = useState();


  const handleConversation = async (e, userData) => {
    e.preventDefault();
    setCurrentUser(userData);
  };

  //=============ONCLICK Automatically Get Conversation==============

  useEffect(() => {
    if (currentUser) {
      const getConversations = async () => {
        try {
          const rest = await axios.post(`${BASE_URL}/conversation/getConv`, {
            verificationCode,
            Id: currentUser._id,
          });
          setConversations(rest.data);
          // const messagesid = document.getElementById("scrollhere");
          // $("#chatBody").animate({ scrollTop: messagesid.offsetTop }, "fast");
        } catch (err) {
        }
      };
      getConversations();
    }
  }, [currentUser?._id]);
  //=============ONCLICK Automatically Get Conversation END==============

  //===========Create New Message Start=====================
  const [newMessage, setNewMessage] = useState("");
  const [attachment, setAttachment] = useState([]);
  const [imagePreviews, setImagePreviews] = useState([]);
  const [Selected, setSelected] = useState(null)

  const handleFileChange = (e) => {
    const files = Array.from(e.target.files);
    setAttachment(files);

    // Create image previews
    const imageFiles = files.filter((file) => file.type.startsWith("image/"));
    const previews = imageFiles.map((file) => URL.createObjectURL(file));
    setImagePreviews(previews);
  };

  const handleRemove = (index) => {
    const updatedAttachments = [...attachment];
    updatedAttachments.splice(index, 1);
    setAttachment(updatedAttachments);

    // Remove the corresponding image preview
    const updatedPreviews = [...imagePreviews];
    updatedPreviews.splice(index, 1);
    setImagePreviews(updatedPreviews);
  };

  const handleSendMessage = async (e) => {
    e.preventDefault();
    try {
      const formData = new FormData();
      formData.append("verificationCode", verificationCode);

      formData.append("senderId", profile?._id);
      formData.append(
        "receiverId",
        currentUser?._id === profile?._id ? profile?._id : currentUser?._id
      );
      formData.append("text", newMessage);
      formData.append("type", "admin");

      if (attachment !== null) {
        for (let i = 0; i < attachment.length; i++) {
          formData.append("attachments", attachment[i]);
        }
      }

      await axios.post(`${BASE_URL}/conversation/addNewMessage`, formData);

      socket.emit("send_Msg", {
        senderId: profile?._id,
        text: newMessage,
        receiverId:
          currentUser._id === profile?._id ? profile?._id : currentUser._id,
        type: "admin",
      });

      const rest = await axios.post(`${BASE_URL}/conversation/getConv`, {
        verificationCode,
        Id: currentUser._id,
      });
      setConversations(rest.data);
      setNewMessage("");
      setAttachment([]);
      setImagePreviews([]);

      // const messagesid = document.getElementById("scrollhere");
      // $("#chatBody").animate({ scrollTop: messagesid.offsetTop }, "fast");
    } catch (err) {
    }
  };

  const onKeyPress = async (e) => {
    if (e.key === "Enter" && newMessage !== "") {
      handleSendMessage(e);
    }
  };

  const lastMessageRef = useRef(null);

  useEffect(() => {
    // 👇️ scroll to bottom every time messages change
    lastMessageRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [conversations, currentUser?._id]);

  //===========Create New Message END=====================
  //========================Get messages throught socket start=================
  const [mapid, setMapid] = useState();
  useEffect(() => {
    if (mapid !== undefined) {
      let newCall = async () => {
        const rest = await axios.post(`${BASE_URL}/conversation/getConv`, {
          verificationCode,
          Id: currentUser._id,
        });

        setConversations(rest.data);
        // const messagesid = document.getElementById("scrollhere");
        // $("#chatbody").animate({ scrollTop: messagesid.offsetTop }, "fast");
      };
      newCall();
    }
  }, [currentUser?._id]);

  socket.on("msgRecieve", (data) => {

    setMapid(data);
  });

  //=====Setting Messages Dates======

  const getMessageDate = (timestamp) => {
    const messageDate = moment(timestamp);
    const currentDate = moment();

    if (messageDate.isSame(currentDate, "day")) {
      return "Today";
    } else if (
      messageDate.isSame(currentDate.clone().subtract(1, "days"), "day")
    ) {
      return "Yesterday";
    } else {
      return messageDate.format("MMMM Do"); // For other dates, display full date like "July 31st"
    }
  };

  const { language, setLanguage } = useMyContext();

  return (
    <>
      <section className="" id="chatbox">
        <div className="container-fluid">
          <div className="row d-flex justify-content-end">
            <div className="col-md-4 mb-3">
              <div className="bg-white rounded shadow supportHeight">
                <div className="chatlist p-2">
                  <div className="modal-dialog-scrollable">
                    <div className="chat-header mt-2">
                      <div className="msg-search">
                        <h4>Messages</h4>
                        {/* <div>
                          <Link className="add" to="#">
                            <FontAwesomeIcon
                              icon={faArrowLeftLong}
                              className="chaticon"
                            />
                          </Link>
                          <Link className="add" to="#">
                            <FontAwesomeIcon
                              className="ms-3 chaticon"
                              icon={faPlus}
                            />
                          </Link>
                        </div> */}
                      </div>
                      <div className="">
                        <div className="input-group">
                          <input
                            type="text"
                            className="form-control searchField mb-0"
                            placeholder={language === "en" ? "Search" : "Búsqueda"}
                            value={searchQuery}
                            onChange={(e) => setSearchQuery(e.target.value)}
                          />
                          <div className="input-group-append">
                            <FontAwesomeIcon
                              icon={faSearch}
                              className="searchicon"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="modal-content">
                      <div className="modal-body mt-3">
                        <div className="chat-lists">
                          <div className="tab-content" id="myTabContent">
                            <div
                              className="tab-pane fade show active"
                              id="Open"
                              role="tabpanel"
                              aria-labelledby="Open-tab"
                            >
                              <div className="chat-list">
                                {allBusinessData &&
                                  filteredData.map((business, i) => (
                                    <Link
                                      //  to="#"
                                      key={i}
                                      className={`d-flex align-items-center chathover ${Selected === i ? "ActiveChathover" : ""}`}
                                      onClick={(e) => {
                                        handleConversation(e, business)
                                        setSelected(i)
                                      }
                                      }
                                    >
                                      <div className="flex-shrink-0">
                                        <img
                                          className="rounded-circle"
                                          width={40}
                                          height={40}
                                          src={BASE_URL_IMAGES + business?.logo}
                                          alt="user img"
                                          onError={(e) =>
                                            (e.target.src = "/assets/user.png")
                                          }
                                        />
                                        {/* <span className="active"></span> */}
                                      </div>
                                      <div className="flex-grow-1 ms-2">
                                        <h3>{business?.name_of_business}</h3>
                                      </div>
                                      <p>04:15 Am</p>
                                    </Link>
                                  ))}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-8">
              {currentUser && conversations && (
                <div className="bg-white rounded shadow">
                  <div className=" p-2 d-flex flex-column">
                    <div className="modal-dialog-scrollable">
                      <div className="msg-head">
                        <div className="row py-2 mx-2">
                          <div className="col-12">
                            <div className="d-flex align-items-center">
                              <span className="chat-icon">
                                <FontAwesomeIcon icon={faAngleLeft} />
                              </span>
                              <div className="flex-shrink-0">
                                <img
                                  src={BASE_URL_IMAGES + currentUser?.logo}
                                  alt="Logo"
                                  onError={(e) => (e.target.src = "/assets/user.png")}
                                  className="rounded-circle"
                                  width={40}
                                  height={40}
                                />
                              </div>
                              <div className="flex-grow-1 ms-2">
                                <h3 className="supportHeadn fw-500">
                                  {currentUser?.name_of_business}
                                </h3>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <hr className="m-0" style={{ color: "#94A3B8" }} />
                      <div
                        className="modal-content flex-grow-1 MinHeight"
                        id="chatBody"

                      // ref={lastMessageRef}
                      >
                        <div className="modal-body">
                          <div className="msg-body">
                            <ul>
                              {conversations &&
                                conversations.map((conv, i) => (
                                  <>
                                    {i === 0 || // Render the header for the first message
                                      getMessageDate(conv.createdAt) !==
                                      getMessageDate(
                                        conversations[i - 1]?.createdAt
                                      ) ? (
                                      <li>
                                        <div className="divider">
                                          <h6>
                                            {getMessageDate(conv.createdAt)}
                                          </h6>
                                        </div>
                                      </li>
                                    ) : null}
                                    {conv?.senderId?._id !== profile?._id && (
                                      <li
                                        className="sender"
                                        key={i}
                                        ref={
                                          i === conversations.length - 1
                                            ? lastMessageRef
                                            : null
                                        }
                                      >
                                        <div className="flex-shrink-0 d-flex align-items-baseline gap-1">
                                          {" "}
                                          <img
                                            src={
                                              BASE_URL_IMAGES +
                                              currentUser?.logo
                                            }
                                            alt="Logo"
                                            onError={(e) =>
                                              (e.target.src = "/assets/user.png")
                                            }
                                            className="rounded-circle"
                                            width={40}
                                            height={40}
                                          />
                                          {/* <img
                                            className="img-fluid w-40"
                                            src={Profile}
                                            alt="user img"
                                          /> */}
                                          {conv?.text && <p>{conv?.text} </p>}
                                          {conv?.images.map((url, i) => {
                                            return (
                                              <div
                                                className="col-1 mx-1"
                                                style={{ padding: "4px" }}
                                                key={i}
                                              >
                                                <div
                                                  style={{
                                                    display: "flex",
                                                    flexDirection: "row",
                                                    padding: "4px",
                                                  }}
                                                >
                                                  <img
                                                    src={CHAT_IMAGES + url}
                                                    style={{
                                                      width: "50px",
                                                      height: "50px",
                                                      borderRadius: "7px",
                                                      objectFit: "cover",
                                                    }}
                                                    onError={(e) =>
                                                    (e.target.src =
                                                      "/assets/user.png")
                                                    }
                                                    alt=""
                                                  />
                                                </div>
                                              </div>
                                            );
                                          })}
                                        </div>
                                        <span className="time">
                                          {" "}
                                          {moment(conv?.createdAt).format(
                                            " h:mm a"
                                          )}
                                        </span>
                                      </li>
                                    )}
                                    {conv?.senderId?._id === profile?._id && (
                                      <li
                                        className="repaly"
                                        ref={
                                          i === conversations.length - 1
                                            ? lastMessageRef
                                            : null
                                        }
                                      >
                                        <div className="flex-shrink-0">
                                          <img
                                            src={
                                              BASE_URL_IMAGES +
                                              conv?.senderId?.adminLogo
                                            }
                                            alt="Logo"
                                            onError={(e) =>
                                              (e.target.src = "/assets/user.png")
                                            }
                                            className="rounded-circle"
                                            width={40}
                                            height={40}
                                          />
                                        </div>
                                        {conv?.text && <p>{conv?.text} </p>}
                                        <div className=" row">
                                          {conv?.images.map((url, i) => {
                                            return (
                                              <div
                                                className="col-1 mx-1"
                                                style={{ padding: "4px" }}
                                                key={i}
                                              >
                                                <div
                                                  style={{
                                                    display: "flex",
                                                    flexDirection: "row",
                                                    padding: "4px",
                                                  }}
                                                >
                                                  <img
                                                    src={CHAT_IMAGES + url}
                                                    style={{
                                                      width: "50px",
                                                      height: "50px",
                                                      borderRadius: "7px",
                                                      objectFit: "cover",
                                                    }}
                                                    onError={(e) =>
                                                    (e.target.src =
                                                      "/assets/user.png")
                                                    }
                                                    alt=""
                                                  />
                                                </div>
                                              </div>
                                            );
                                          })}
                                        </div>

                                        <span className="time">
                                          {" "}
                                          {moment(conv?.createdAt).format(
                                            "h:mm a"
                                          )}
                                        </span>
                                      </li>
                                    )}
                                  </>
                                ))}
                            </ul>
                            <div id="scrollhere"></div>
                          </div>
                        </div>
                      </div>

                      <div className="send-box mt-auto">
                        <div className="d-flex">
                          {imagePreviews.map((preview, index) => (
                            <div key={index}>
                              <img
                                src={preview}
                                alt={`Preview ${index}`}
                                className="rounded-circle "
                                width={50}
                                height={50}
                              />
                              <FontAwesomeIcon
                                icon={faXmark}
                                className="imgCrossIcon"
                                onClick={() => handleRemove(index)}
                              />
                            </div>
                          ))}
                        </div>
                        <h6 className="answer"><Trans>Answer</Trans></h6>
                        <form >
                          <input
                            type="text"
                            className="form-control textArea"
                            aria-label="message…"
                            placeholder="Write Your Message…"
                            value={newMessage}
                            onKeyPress={onKeyPress}
                            onChange={(e) => setNewMessage(e.target.value)}
                          />

                          <div
                            className="d-flex justify-content-between"
                            id="chatButton"
                          >
                            <div className="">
                              <button
                                type="button"
                                className="btn clip m-none"
                                onClick={() =>
                                  document
                                    .getElementById(
                                      "upload_signature_file_edit_customer"
                                    )
                                    .click()
                                }
                              >
                                <FontAwesomeIcon
                                  icon={faPaperclip}
                                  className="sendicon"
                                />
                              </button>
                              <input
                                type="file"
                                accept="image/*,.pdf"
                                name="upload_signature_file"
                                id="upload_signature_file_edit_customer"
                                aria-autocomplete="list"
                                autoCorrect="off"
                                style={{ display: "none" }}
                                multiple
                                onClick={(event) => {
                                  event.target.value = null;
                                }}
                                onChange={handleFileChange}
                              />
                            </div>

                            {/* {attachment?.length === 0 ? (
                <span className="input-group-text"></span>
              ) : (
                <span
                  className="input-group-text"
                  style={{ fontSize: "11px" }}
                >
                  {attachment?.length} Files Selected
                </span>
              )} */}
                            <div className="">
                              <Link
                                onClick={handleSendMessage}
                                className="btn btn-sm btn-primary d-block m-auto"
                              >
                                <Trans>Send</Trans>
                              </Link>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {!currentUser && conversations.length === 0 && (
                <div
                  style={{
                    height: "50vh",
                    textAlign: "center",
                  }}
                  className="d-flex justify-content-center align-items-center"
                >
                  <h3 className="mt-5 ">
                    <FontAwesomeIcon icon={faEnvelope} className="me-2" />
                    Inbox is Empty
                  </h3>
                </div>
              )}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Support;
