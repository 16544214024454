import React, { useState, useEffect } from 'react';
import moment from 'moment';

//===========Loader =====================
import Loader from '../../../../Loader';
import { useMyContext } from '../../../..';
import { Trans } from 'react-i18next';
import Select from 'react-select';
//===========Data table =================
import DataTable from 'react-data-table-component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faSearch,
  faPlus,
  faEye,
  faEdit,
  faTrash,
  faCheck,
  faBan,
} from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import { Button, Modal, Form } from 'react-bootstrap';
//=========Redux===========
import { useSelector, useDispatch } from 'react-redux';
import {
  approveBusinessStatus,
  disApproveBusiness,
  createBusineeNote,
  createBusiness,
  deleteBusiness,
  editCompanySettings,
  getAllBusinesses,
  reset,
} from '../../../../redux/auth/authSlice';
import { getAllSectors } from '../../../../redux/sector/sectorSlice';
// import { Country, City } from "country-state-city";

import { toast } from 'react-toastify';
import ReactLoading from 'react-loading';
import ExportCSVButton from '../../../../common/ExportCSVButton';
import { getAllCities } from '../../../../redux/CountryAndCities/CountryAndCitySlice';
import ButtonLoading from '../../../../common/ButtonLoading';

// import "./user.css";

//==Url Utilities
const verificationCode = process.env.REACT_APP_VERIFICATION_TOKEN;
const BASE_URL_IMAGES = process.env.REACT_APP_BASE_URL_IMAGE;

const Users = () => {
  const { language, setLanguage } = useMyContext();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [CitiesLoading, setCitiesLoading] = useState(false);
  const {
    allBusinessData,
    isSuccess,
    isLoading,
    isLoadingBusinessNote,
    isLoadingCreateNewBusiness,
  } = useSelector((state) => {
    return state.auth;
  });
  const { allSectors } = useSelector((state) => {
    return state.sector;
  });

  const { getCountries, getCities, isCitiesLoading } = useSelector(
    (state) => state.CountryAndCitySlice
  );

  const [SelectPaymentMethod, setSelectPaymentMethod] = useState();

  useEffect(() => {
    dispatch(getAllBusinesses({ verificationCode }));
    dispatch(getAllSectors({ verificationCode }));
  }, [dispatch]);

  //===========Country and Cities Selection START=================================
  const [selectedCountry, setSelectedCountry] = useState('');

  const handleCountryChange = async (event) => {
    const countryCode = event.target.value;
    setSelectedCountry(countryCode);
    setCitiesLoading(true);
    try {
      dispatch(getAllCities(countryCode));
    } catch (error) {
      console.error('Error during POST request:', error);
    }
  };
  //===========Country and Cities Selection END=================================

  //=========Setting Data to Gridjs================
  // const [companyData, setCompanyData] = useState([]); // Initialize with null or an initial value

  //============== Modals State ===================

  const [showModal, setShowModal] = useState(false);
  const [showModalNotes, setShowModalNotes] = useState(false);
  const handleCloseModal = () => setShowModal(false);
  const [PasswordVisible2, setPasswordVisible2] = useState(true);
  const [PasswordVisible, setPasswordVisible] = useState(true);

  const handleShowModal = () => {
    setShowModal(true);
  };
  //==========================Table======================//
  //Add Notes Modal
  const [note, setNote] = useState('');
  const [busineeId, setBusineeId] = useState('');

  const handleCloseNotes = () => {
    setShowModalNotes(false);
  };

  const handleOpenNotes = (id) => {
    if (id) {
      setBusineeId(id);
    }
    setShowModalNotes(true);
  };

  //========Create New Business ===================

  const [formData, setFormData] = useState({
    name_of_business: '',
    sector: '',
    password: '',
    email: '',
    invoiceAddress: '',
    vat: '',
    postalCode: '',
    city: '',
    contactNumber: '',
    termAndCondition: true,
  });

  const [logo, setLogo] = useState(null);
  const handleLogoChange = (e) => {
    setLogo(e.target.files[0]);
  };
  const [preview, setPreview] = useState(null);

  // create a preview as a side effect, whenever selected file is changed
  useEffect(() => {
    if (!logo) {
      setPreview(undefined);
      return;
    }
    const objectUrl = URL.createObjectURL(logo);
    setPreview(objectUrl);

    // free memory when ever this component is unmounted
    return () => URL.revokeObjectURL(objectUrl);
  }, [logo]);

  const {
    name_of_business,
    sector,
    password,
    email,
    invoiceAddress,
    vat,
    postalCode,
    city,
    contactNumber,
    termAndCondition,
  } = formData;

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    const newValue = type === 'checkbox' ? checked : value;
    setFormData({
      ...formData,
      [name]: newValue,
    });
  };

  const handleCreateBusiness = async (e) => {
    e.preventDefault();
    var businessData = new FormData();
    businessData.append('verificationCode', verificationCode);
    businessData.append('name_of_business', name_of_business);
    businessData.append('sector', sector);
    businessData.append('password', password);
    businessData.append('email', email);
    businessData.append('invoiceAddress', invoiceAddress);
    businessData.append('vat', vat);
    businessData.append('postalCode', postalCode);
    businessData.append('city', city);
    businessData.append('country', selectedCountry);
    businessData.append('contactNumber', contactNumber);
    businessData.append('termAndCondition', termAndCondition);
    businessData.append('logo', logo);
    businessData.append('paymentMethod', SelectPaymentMethod?.value);

    dispatch(createBusiness(businessData));
  };

  //========Create New Business end===================

  //============ Update Business Start ============
  const [showEditModal, setShowEditModal] = useState(false);
  const handleCloseEditModal = () => setShowEditModal(false);

  const [editData, setEditData] = useState(null);

  const handleEditChange = (e) => {
    const { name, value, type, checked } = e.target;
    const newValue = type === 'checkbox' ? checked : value;
    setEditData({
      ...editData,
      [name]: newValue,
    });
  };

  const handleEditComapnyPop = (comp) => {
    setEditData(comp);
    setSelectedCountry(comp?.country);
    if (comp?.paymentMethod === 'card') {
      setSelectPaymentMethod([{ label: 'Card', value: 'card' }]);
    } else {
      setSelectPaymentMethod([
        { label: 'Bank Transfer', value: 'bank_transfer' },
      ]);
    }
    // setSelectedButton(comp?.lookingFor);
    setShowEditModal(true);
  };

  const handleCompanyUpdate = async (e) => {
    e.preventDefault();

    var businessData = new FormData();
    businessData.append('verificationCode', verificationCode);
    businessData.append('businessId', editData?._id);
    businessData.append('name_of_business', editData?.name_of_business);
    businessData.append('sector', editData?.sector);
    businessData.append(
      'password',
      editData?.password ? editData?.password : ''
    );
    businessData.append('email', editData?.email);
    businessData.append('invoiceAddress', editData?.invoiceAddress);
    businessData.append('vat', editData?.vat);
    businessData.append('postalCode', editData?.postalCode);
    businessData.append('city', editData?.city);
    businessData.append('country', selectedCountry);
    businessData.append('contactNumber', editData?.contactNumber);
    businessData.append('termAndCondition', editData?.termAndCondition);
    businessData.append('logo', logo);
    businessData.append('paymentMethod', SelectPaymentMethod?.value);
    dispatch(editCompanySettings(businessData));
  };

  //============ Update Business End ============

  //===========Reset Data on Success =====================
  useEffect(() => {
    if (isSuccess) {
      setFormData({
        name_of_business: '',
        sector: '',
        password: '',
        email: '',
        invoiceAddress: '',
        vat: '',
        postalCode: '',
        city: '',
        // country: "",
        contactNumber: '',
        termAndCondition: true,
      });
      setLogo('');
      setPreview('');
      setSelectedCountry('');
      setShowModal(false);
      setShowEditModal(false);
      setNote('');
      dispatch(reset());
    }
  }, [isSuccess]);

  //=========View Business Data ==============
  const handleBusinessView = (arg) => {
    navigate(`/admin/businessDetail/${arg}`);
  };

  //=============Search Field================

  const [searchQuery, setSearchQuery] = useState('');
  const [sortingFilter, setSortingFilter] = useState('Newest');
  const [filteredData, setFilteredData] = useState(allBusinessData); // Initialize with all data

  useEffect(() => {
    let newData = [...allBusinessData]; // Make a copy of the original data

    switch (sortingFilter) {
      case 'Newest':
        newData.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
        break;
      case 'Sector':
        newData.sort((a, b) => a.sector.localeCompare(b.sector));
        break;
      case 'Status':
        newData.sort(
          (a, b) => (a.status === true ? -1 : 1) - (b.status === true ? -1 : 1)
        );
        break;
      case 'A-Z':
        newData.sort((a, b) =>
          a.name_of_business.localeCompare(b.name_of_business)
        );
        break;
      case 'Z-A':
        newData.sort((a, b) =>
          b.name_of_business.localeCompare(a.name_of_business)
        );
        break;
      default:
        break;
    }

    if (searchQuery) {
      newData = newData.filter((item) =>
        item.name_of_business.toLowerCase().includes(searchQuery.toLowerCase())
      );
    }

    setFilteredData(newData);
  }, [allBusinessData, sortingFilter, searchQuery]);

  function handleSearchChange(event) {
    setSearchQuery(event.target.value);
  }

  function handleFilterChange(event) {
    setSortingFilter(event.target.value);
  }

  //==========Handle Delete Busines= =================================
  const handleDeleteBusiness = (event, id) => {
    event.preventDefault();
    let body = {
      verificationCode: verificationCode,
      businessId: id,
    };
    dispatch(deleteBusiness(body));
  };

  //============Handler for update business status to Active===========
  const handleBusinessStatus = (e, id) => {
    e.preventDefault();
    let body = {
      verificationCode: verificationCode,
      businessId: id,
    };
    dispatch(disApproveBusiness(body));
  };

  //============Handler for update business status to Active===========
  const handleActiveStatus = (e, id) => {
    e.preventDefault();
    let body = {
      verificationCode: verificationCode,
      businessId: id,
    };
    dispatch(approveBusinessStatus(body));
  };

  //===============Hanlde Add Notes to Business====================
  const handleCreateNote = (event) => {
    event.preventDefault();
    if (!note.trim()) {
      return toast.error('Please enter a note!');
    }
    let body = {
      verificationCode: verificationCode,
      businessId: busineeId,
      note: note,
    };
    dispatch(createBusineeNote(body));
    setShowModalNotes(false);
  };

  //-------------------DATA TABLE START ---------------------------------

  const [pending, setPending] = useState(true);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setPending(false);
    }, 500);
    return () => clearTimeout(timeout);
  }, []);

  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       const response = await fetch('https://portal.gorate.io/api/country/create', {
  //         method: 'POST',
  //         headers: {
  //           'Content-Type': 'application/json',
  //           // Add any other headers if needed
  //         },
  //         body: JSON.stringify({name_of_country:"Pakistan"}),
  //       });
  //       const result = await response.json();
  //       setData(result);
  //     } catch (error) {
  //     }
  //   };
  //   fetchData();
  // }, []);

  const actions = [
    {
      icon: <FontAwesomeIcon className="btn btnPrint p-0 fs-6" icon={faEye} />,
      tooltip: 'View',
      onClick: (event, rowData) => handleBusinessView(rowData._id),
    },
    {
      icon: <FontAwesomeIcon className="btn btnPrint p-0 fs-6" icon={faEdit} />,
      tooltip: 'Edit',
      onClick: (event, rowData) => handleEditComapnyPop(rowData),
    },
    {
      icon: (
        <FontAwesomeIcon
          className="btn btnPrint p-0 ms-2 fs-6"
          icon={faTrash}
        />
      ),
      tooltip: 'Delete',
      onClick: (event, rowData) => handleDeleteBusiness(event, rowData._id),
    },
  ];

  const DataTableActionButtons = ({ actions, rowData }) => (
    <>
      {actions.map((action, index) => (
        <button
          className="btn  btn-sm"
          key={index}
          onClick={(event) => action.onClick(event, rowData)}
        >
          {action.icon}
        </button>
      ))}
    </>
  );

  const columns = [
    {
      name: <Trans>Logo</Trans>,
      selector: (row) => (
        <img
          src={BASE_URL_IMAGES + row?.logo}
          alt="Logo"
          // onError={(e) => (e.target.src = "/assets/user.png")}
          className="rounded-circle"
          style={{
            width: '40px',
            height: '40px',
            textAlign: 'center',
          }}
        />
      ),
      sortable: true,
      width: '90px',
      center: true,
    },
    {
      name: <Trans>Business Name</Trans>,
      selector: (row) => row.name_of_business,
      sortable: true,
      width: '170px',
      center: true,
    },

    {
      name: <Trans>Email</Trans>,
      selector: (row) => row.email,
      sortable: true,
      width: '180px',
      center: true,
    },
    {
      name: <Trans>Sector</Trans>,
      selector: (row) => row.sector,
      sortable: true,
    },
    {
      name: <Trans>Status</Trans>,
      selector: (row) =>
        row?.adminApprove === true ? (
          <span className="text-success badge p-1">Active</span>
        ) : (
          <span className="text-danger badge p-1">Block</span>
        ),
      sortable: true,
      center: true,
    },
    {
      name: <Trans>Role</Trans>,
      selector: (row) => <span className="text-capitalize">{row.role}</span>,
      sortable: true,
      center: true,
    },
    {
      name: <Trans>Creation Date</Trans>,
      selector: (row) => moment(row.Email).format('DD-MM-YYYY'),
      sortable: true,
      width: '150px',
      center: true,
    },
    {
      name: <Trans>Add Notes</Trans>,
      center: true,

      selector: (row) => (
        <>
          <button
            type="button"
            className="btn btnPrint fs-6 "
            onClick={(e) => handleOpenNotes(row?._id)}
          >
            <FontAwesomeIcon icon={faPlus} />{' '}
          </button>
          {/* {row?.adminApprove && (
            <button
              type="button"
              className="btn btnPrint fs-6"
              onClick={(e) => handleBusinessStatus(e, row?._id)}
              title="ban"
            >
              <FontAwesomeIcon icon={faBan} style={{ color: "red" }} />{" "}
            </button>
          )}
          {row?.adminApprove !== true && (
            <button
              type="button"
              className="btn btnPrint fs-6"
              onClick={(e) => handleActiveStatus(e, row?._id)}
              title="active"
            >
              <FontAwesomeIcon icon={faCheck} />{" "}
            </button>
          )} */}
        </>
      ),
    },
    {
      name: <Trans>Actions</Trans>,
      cell: (row) => <DataTableActionButtons actions={actions} rowData={row} />,
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
      // center: true,
    },
  ];
  //  Internally, customStyles will deep merges your customStyles with the default styling.
  const customStyles = {
    rows: {
      style: {
        minHeight: '60px',
      },
      getCountries,
    },
    headCells: {
      style: {
        fontSize: '14px',
        color: '#B5B7C0',
        textAlign: 'center',
      },
    },
    cells: {
      style: {
        textAlign: 'center', // Align regular cell content to center
        color: '#292D32 !important',
        fontWeight: '600',
      },
    },
  };
  //-------------------DATA TABLE END---------------------------------

  return (
    <>
      <div className="container-fluid my-3">
        <div className="row">
          <div className="col-md-3">
            <h3 className="d-flex align-items-center mt-1 fw-500">
              Businesses and Users
            </h3>
          </div>
          <div className="col-md-4">
            <div className="search-field">
              <div className="search-bar">
                <input
                  type="text"
                  placeholder="Search by business name..."
                  className="dashboardinput"
                  id="userSearch"
                  value={searchQuery}
                  onChange={handleSearchChange}
                />
                <span className="search-icon">
                  <FontAwesomeIcon icon={faSearch} className="usersearch" />
                </span>
              </div>
            </div>
          </div>
          <div className="col-md-2 col-5">
            <div className="d-flex bg-grey">
              <span className="selectFeature">Sort by:</span>
              <form>
                <div className="form-group">
                  <select
                    id="priceLow"
                    value={sortingFilter}
                    onChange={handleFilterChange}
                  >
                    <option value="Newest">Newest</option>
                    <option value="Sector">Sector</option>
                    <option value="Status">
                      <Trans>Status</Trans>
                    </option>
                    <option value="A-Z">Name A-Z</option>
                    <option value="Z-A">Name Z-A</option>
                  </select>
                </div>
              </form>
            </div>
          </div>
          {/* <div className="col-12 col-md-2 px-0 px-md-2">
            <select onChange={(e) => setLanguage(e.target.value)}>
              <option value={"en"} selected={language == "en"} >English</option>
              <option value={"es"} selected={language == "es"} >Spanish</option>
            </select>
          </div> */}
          <div className="col-md-3 col-5 d-flex justify-content-end">
            <Button
              className="btn btn-primary text-nowrap"
              onClick={handleShowModal}
            >
              <Trans>New Businessss</Trans>
            </Button>
            <div className="px-2">
              <ExportCSVButton
                data={allBusinessData}
                filename={'exported_data.csv'}
              />
            </div>
          </div>
        </div>

        {/* <div className="row">
          <div className="col-md-12">
            <div className="d-flex justify-content-between">
              <h3 className="d-flex align-items-center m-0 fw-500">
                Business and Users
              </h3>
              <Button
                className="btn btn-primary px-5"
                onClick={handleShowModal}
              >
                New User
              </Button>
            </div>
          </div>
        </div>
        <div
          className="row justify-content-end"
          style={{ marginTop: "2.0rem" }}
        >
          <div className="col-md-4">
            <div className="search-field">
              <div className="search-bar">
                <input
                  type="text"
                  placeholder="Search by company name..."
                  className="dashboardinput"
                  id="userSearch"
                  value={searchQuery}
                  onChange={handleSearchChange}
                />
                <span className="search-icon">
                  <FontAwesomeIcon icon={faSearch} className="usersearch" />
                </span>
              </div>
            </div>
          </div>
          <div className="col-md-3">
            <div className="d-flex bg-grey">
              <span className="selectFeature">Sort by:</span>
              <form>
                <div className="form-group">
                  <select
                    id="priceLow"
                    value={sortingFilter}
                    onChange={handleFilterChange}
                  >
                    <option value="Newest">Newest</option>
                    <option value="Sector">Sector</option>
                    <option value="Status">Status</option>
                    <option value="A-Z">Name A-Z</option>
                    <option value="Z-A">Name Z-A</option>
                  </select>
                </div>
              </form>
            </div>
          </div>
        </div> */}
        <div className="row">
          <div className="col-md-12">
            {filteredData.length > 0 && (
              <div id="grid-container" className="outerWrapperTable">
                <DataTable
                  columns={columns}
                  data={filteredData}
                  progressPending={pending}
                  progressComponent={<Loader />}
                  customStyles={customStyles}
                  pagination
                />
              </div>
            )}
          </div>
        </div>
      </div>

      {/* Create New Business */}

      <Modal size="lg" show={showModal} onHide={handleCloseModal} centered>
        <Modal.Header closeButton>
          <Modal.Title>
            {' '}
            <Trans>New Business</Trans>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="container-fluid">
            <form onSubmit={handleCreateBusiness}>
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <label className="form-label">
                      <Trans>Business Name</Trans>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter name"
                      name="name_of_business"
                      value={name_of_business}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label className="form-label">Sector</label>
                    <select
                      className="form-control"
                      name="sector"
                      value={sector}
                      onChange={handleChange}
                    >
                      <option value="">
                        <Trans>Select from list</Trans>...
                      </option>
                      {allSectors &&
                        allSectors.length > 0 &&
                        allSectors.map((sec, i) => (
                          <option key={i} value={sec?.sector}>
                            {sec?.sector}
                          </option>
                        ))}
                    </select>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6 position-relative">
                  <div className="form-group">
                    <label className="form-label">
                      <Trans>Password</Trans>
                    </label>
                    <input
                      type={PasswordVisible ? 'password' : 'text'}
                      name="password"
                      value={password}
                      onChange={handleChange}
                      className="form-control"
                    />
                    {PasswordVisible ? (
                      <img
                        src="/assets/icons/EyeView.svg"
                        alt="DropDownIcon"
                        className="DropDownIconsInputField DatePickerDropDown c_pointer EyeIconsInAddressInSignUp"
                        onClick={() => {
                          setPasswordVisible(!PasswordVisible);
                        }}
                      />
                    ) : (
                      <img
                        src="/assets/icons/EyeViewNot.svg"
                        alt="DropDownIcon"
                        className="DropDownIconsInputField DatePickerDropDown c_pointer EyeIconsInAddressInSignUp"
                        onClick={() => {
                          setPasswordVisible(!PasswordVisible);
                        }}
                      />
                    )}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label className="form-label">Email</label>
                    <input
                      type="email"
                      className="form-control"
                      placeholder="Enter email"
                      name="email"
                      value={email}
                      onChange={handleChange}
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <label className="form-label">
                      <Trans>Country</Trans>
                    </label>
                    <select
                      className="form-select"
                      name="selectCountry"
                      value={selectedCountry}
                      onChange={handleCountryChange}
                    >
                      <option value="">
                        <Trans>Select from list</Trans>
                      </option>
                      {getCountries?.countries?.map((country) => (
                        <option
                          key={country.countryId}
                          value={country.countryId}
                        >
                          {country.country}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label className="form-label">City</label>
                    {isCitiesLoading ? (
                      <div className="form-control d-flex justify-content-center">
                        <ReactLoading
                          type={'spinningBubbles'}
                          color={'#000000'}
                          height={24}
                          width={17}
                        />
                      </div>
                    ) : (
                      <select
                        className="form-select"
                        name="city"
                        value={city}
                        onChange={handleChange}
                      >
                        <option value="">
                          <Trans>Select from list</Trans>
                        </option>
                        {getCities?.map((city, index) => (
                          <option key={index} value={city?._id}>
                            {city?.name_of_city}
                          </option>
                        ))}
                      </select>
                    )}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className=" col-md-6">
                  <div className="form-group">
                    <label className="form-label">
                      <Trans>Invoicing Address</Trans>
                    </label>
                    <input
                      type="text"
                      name="invoiceAddress"
                      value={invoiceAddress}
                      onChange={handleChange}
                      className="form-control"
                    />
                  </div>
                </div>
                <div className=" col-md-6">
                  <div className="form-group">
                    <label className="form-label">
                      <Trans>VAT ID</Trans>
                    </label>
                    <input
                      type="text"
                      name="vat"
                      value={vat}
                      onChange={handleChange}
                      className="form-control"
                    />
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <label className="form-label">
                      <Trans>Contact number</Trans>
                    </label>
                    <input
                      type="text"
                      name="contactNumber"
                      value={contactNumber}
                      onChange={handleChange}
                      className="form-control"
                    />
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="form-group">
                    <label className="form-label">
                      <Trans>Postal Code</Trans>
                    </label>
                    <input
                      type="text"
                      name="postalCode"
                      value={postalCode}
                      onChange={handleChange}
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="col-md-12">
                  <label className="form-label">
                    <Trans>Select Payment Method</Trans>
                  </label>
                  <Select
                    id="multiple-select-field"
                    value={SelectPaymentMethod}
                    onChange={(e) => setSelectPaymentMethod(e)}
                    options={[
                      { value: 'card', label: 'Card' },
                      { value: 'bank_transfer', label: 'Bank Transfer' },
                    ]}
                  />
                </div>
              </div>

              <div className="row">
                <div className={`${preview ? 'col-md-9' : 'col-md-12'}`}>
                  <div className="form-group">
                    <label className="form-label">
                      <Trans>Upload Logo</Trans>
                    </label>
                    <input
                      type="file"
                      name="logo"
                      className="form-control"
                      accept="image/*"
                      onChange={handleLogoChange}
                    />
                  </div>
                </div>

                {logo && (
                  <div className={preview ? 'col-md-3' : 'col-md-0'}>
                    <div className="form-group mt-2">
                      <img
                        className="rounded-circle"
                        width={70}
                        height={70}
                        src={preview}
                        alt=""
                      />
                    </div>
                  </div>
                )}
              </div>
              <div className="form-check mb-3">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="gridCheck"
                  name="termAndCondition"
                  value={termAndCondition}
                  onChange={handleChange}
                  checked={termAndCondition === true ? true : false}
                />
                <label className="form-check-label mbl-text" for="gridCheck">
                  Accept terms and conditions
                </label>
              </div>
              <div className="d-flex justify-content-end">
                <div className="d-flex justify-content-between gap-2">
                  <button
                    type="button"
                    className="btn btn-secondary w-100"
                    onClick={handleCloseModal}
                  >
                    Cancel
                  </button>
                  {isLoadingCreateNewBusiness ? (
                    <div>
                      {' '}
                      <ButtonLoading />
                    </div>
                  ) : (
                    <button
                      type="submit"
                      className="btn btn-primary d-block m-auto"
                      onClick={handleCreateBusiness}
                    >
                      <Trans>Submit</Trans>
                    </button>
                  )}
                </div>
              </div>
            </form>
          </div>
        </Modal.Body>
      </Modal>

      {/* Create New Business End*/}

      {/* Edit/Update Business Start*/}

      <Modal
        size="lg"
        show={showEditModal}
        onHide={handleCloseEditModal}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Update Business</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {editData && (
            <form onSubmit={handleCompanyUpdate}>
              <div className="container-fluid">
                <div>
                  {' '}
                  <div className="text-center mb-3">
                    <img
                      className="rounded-circle"
                      width={70}
                      height={70}
                      src={BASE_URL_IMAGES + editData?.logo}
                      alt=""
                      // onError={(e) => (e.target.src = "/assets/user.png")}
                      style={{
                        objectFit: 'cover',
                      }}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label className="form-label">
                        <Trans>Business Name</Trans>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter name"
                        name="name_of_business"
                        value={editData?.name_of_business}
                        onChange={handleEditChange}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label className="form-label">Sector</label>
                      <select
                        className="form-control"
                        name="sector"
                        value={editData.sector}
                        onChange={handleEditChange}
                      >
                        <option>
                          <Trans>Select from list</Trans>...
                        </option>
                        {allSectors &&
                          allSectors.length > 0 &&
                          allSectors.map((sec, i) => (
                            <option key={i} value={sec?.sector}>
                              {sec?.sector}
                            </option>
                          ))}
                      </select>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label className="form-label">Email</label>
                      <input
                        type="email"
                        className="form-control"
                        placeholder="Enter email"
                        name="email"
                        value={editData?.email}
                        onChange={handleEditChange}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group position-relative">
                      <label className="form-label">
                        <Trans>Password</Trans>
                      </label>
                      <input
                        type={PasswordVisible2 ? 'password' : 'text'}
                        name="password"
                        value={editData?.password}
                        onChange={handleEditChange}
                        className="form-control"
                      />
                      {PasswordVisible2 ? (
                        <img
                          src="/assets/icons/EyeView.svg"
                          alt="DropDownIcon"
                          className="DropDownIconsInputField DatePickerDropDown c_pointer EyeIconsInAddressInSignUp"
                          onClick={() => {
                            setPasswordVisible2(!PasswordVisible2);
                          }}
                        />
                      ) : (
                        <img
                          src="/assets/icons/EyeViewNot.svg"
                          alt="DropDownIcon"
                          className="DropDownIconsInputField DatePickerDropDown c_pointer EyeIconsInAddressInSignUp"
                          onClick={() => {
                            setPasswordVisible2(!PasswordVisible2);
                          }}
                        />
                      )}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label className="form-label">
                        <Trans>Invoicing Address</Trans>
                      </label>
                      <input
                        type="text"
                        name="invoiceAddress"
                        value={editData.invoiceAddress}
                        onChange={handleEditChange}
                        className="form-control"
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label className="form-label">
                        <Trans>VAT ID</Trans>
                      </label>
                      <input
                        type="text"
                        name="vat"
                        value={editData.vat}
                        onChange={handleEditChange}
                        className="form-control"
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label className="form-label">
                        <Trans>Contact number</Trans>
                      </label>
                      <input
                        type="text"
                        name="contactNumber"
                        value={editData?.contactNumber}
                        onChange={handleEditChange}
                        className="form-control"
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label className="form-label">
                        <Trans>Postal Code</Trans>
                      </label>
                      <input
                        type="text"
                        name="postalCode"
                        value={editData.postalCode}
                        onChange={handleEditChange}
                        className="form-control"
                      />
                    </div>
                  </div>

                  <div className="col-md-12">
                    <label className="form-label">
                      <Trans>Select Payment Method</Trans>
                    </label>
                    <Select
                      id="multiple-select-field"
                      options={[
                        { value: 'card', label: 'Card' },
                        { value: 'bank_transfer', label: 'Bank Transfer' },
                      ]}
                      value={SelectPaymentMethod}
                      onChange={(e) => {
                        setSelectPaymentMethod(e);
                      }}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className={`${preview ? 'col-md-9' : 'col-md-12'}`}>
                    <div className="form-group">
                      <label className="form-label">
                        <Trans>Upload Logo</Trans>
                      </label>
                      <input
                        type="file"
                        name="logo"
                        className="form-control"
                        accept="image/*"
                        onChange={handleLogoChange}
                      />
                    </div>
                  </div>
                  {logo && (
                    <div className={preview ? 'col-md-3' : 'col-md-0'}>
                      <div className="form-group mt-2">
                        <img
                          className="rounded-circle"
                          width={70}
                          height={70}
                          src={preview}
                          alt=""
                        />
                      </div>
                    </div>
                  )}
                </div>
                <div className="form-check mb-3">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="gridCheck"
                    name="termAndCondition"
                    value={editData.termAndCondition}
                    onChange={handleEditChange}
                    checked={editData.termAndCondition === true ? true : false}
                  />
                  <label className="form-check-label mbl-text" for="gridCheck">
                    Accept terms and conditions
                  </label>
                </div>
                <div className="d-flex justify-content-end">
                  <div className="d-flex justify-content-between gap-2">
                    <button
                      type="button"
                      className="btn btn-secondary w-100"
                      onClick={handleCloseEditModal}
                    >
                      Cancel
                    </button>
                    {isLoading ? (
                      <div>
                        {' '}
                        <ButtonLoading />
                      </div>
                    ) : (
                      <button
                        type="submit"
                        className="btn btn-primary d-block w-100"
                        onClick={handleCompanyUpdate}
                      >
                        Update
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </form>
          )}
        </Modal.Body>
      </Modal>

      {/* Edit/Update Business End*/}

      {/* Add Note for Business Start*/}

      <Modal show={showModalNotes} onHide={handleCloseNotes}>
        <Modal.Header closeButton>
          <Modal.Title>Add Note</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleCreateNote}>
            <Form.Group controlId="noteTextArea">
              <Form.Control
                as="textarea"
                rows={3}
                value={note}
                onChange={(e) => setNote(e.target.value)}
              />
            </Form.Group>
            <div className="d-flex justify-content-end">
              <div className="d-flex justify-content-between gap-2">
                <button
                  type="button"
                  className="btn btn-secondary w-100"
                  onClick={handleCloseNotes}
                >
                  Cancel
                </button>
                {isLoadingBusinessNote ? (
                  <div>
                    {' '}
                    <ButtonLoading />
                  </div>
                ) : (
                  <Button
                    type="submit"
                    className="d-block ms-auto"
                    onClick={handleCreateNote}
                  >
                    <Trans>Submit</Trans>
                  </Button>
                )}
              </div>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
      {/* Add Note for Business End*/}
    </>
  );
};

export default Users;
