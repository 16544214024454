import React, { useState, useEffect } from "react";
import { Button, Modal, Tab, Tabs, Form } from "react-bootstrap";

import moment from "moment";

//===========Loader =====================
import Loader from "../../../../Loader";
//===========Data table =================
import DataTable from "react-data-table-component";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSearch,
  faEdit,
  faTrash,
  faCheck,
} from "@fortawesome/free-solid-svg-icons";
//=========Redux===========
import { useSelector, useDispatch } from "react-redux";
import {
  createAdmin,
  deleteAdmin,
  getAllAdmins,
  reset,
  updateAdminSettings,
} from "../../../../redux/auth/authSlice";

import { useMyContext } from '../../../..';
import { Trans } from 'react-i18next';
import ExportCSVButton from "../../../../common/ExportCSVButton";
import ButtonLoading from "../../../../common/ButtonLoading";

const verificationCode = process.env.REACT_APP_VERIFICATION_TOKEN;
const BASE_URL_IMAGES = process.env.REACT_APP_BASE_URL_IMAGE;

const Admins = () => {
  //   const navigate = useNavigate();
  const dispatch = useDispatch();

  const { allAdmins, profile, isSuccess , isLoadingCreateAdmin } = useSelector((state) => {
    return state.auth;
  });

  //========Get All Admins =================
  useEffect(() => {
    dispatch(getAllAdmins({ verificationCode }));
  }, [dispatch]);

  //============== Modals State ===================

  const [showAddModal, setShowAddModal] = useState(false);
  const handleCloseAddModal = () => setShowAddModal(false);
  const handleShowAddModal = () => setShowAddModal(true);
  const [PasswordVisible, setPasswordVisible] = useState(true)
  //==========================Table======================//

  //========Create New Admin ===================

  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
  });

  const [logo, setLogo] = useState(null);
  const handleLogoChange = (e) => {
    setLogo(e.target.files[0]);
  };
  const [preview, setPreview] = useState(null);

  // create a preview as a side effect, whenever selected file is changed
  useEffect(() => {
    if (!logo) {
      setPreview(undefined);
      return;
    }
    const objectUrl = URL.createObjectURL(logo);
    setPreview(objectUrl);

    // free memory when ever this component is unmounted
    return () => URL.revokeObjectURL(objectUrl);
  }, [logo]);

  const { firstName, lastName, email, password } = formData;

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleCreateAdmin = async (e) => {
    e.preventDefault();
    var adminData = new FormData();
    adminData.append("verificationCode", verificationCode);
    adminData.append("firstName", firstName);
    adminData.append("lastName", lastName);
    adminData.append("password", password);
    adminData.append("email", email);
    adminData.append("adminLogo", logo ? logo : "");
    dispatch(createAdmin(adminData));
  };

  //========Create New Admin end===================

  //============ Update Admin Start ============
  const [showEditModal, setShowEditModal] = useState(false);
  const handleCloseEditModal = () => setShowEditModal(false);

  const [editData, setEditData] = useState(null);

  const handleEditChange = (e) => {
    setEditData({ ...editData, [e.target.name]: e.target.value });
  };

  const handleEditAdminPop = (adminData) => {
    setEditData(adminData);
    setShowEditModal(true);
  };

  const handleAdminUpdate = async (e) => {
    e.preventDefault();

    var updateData = new FormData();
    updateData.append("verificationCode", verificationCode);
    updateData.append("adminId", editData?._id);
    updateData.append("firstName", editData?.firstName);
    updateData.append("lastName", editData?.lastName);
    updateData.append("password", editData?.password ? editData?.password : "");
    updateData.append("email", editData?.email);
    updateData.append("adminLogo", logo);
    dispatch(updateAdminSettings(updateData));
  };

  //============ Update Admin End ============

  //===========Reset Data on Success =====================
  useEffect(() => {
    if (isSuccess) {
      setFormData({
        firstName: "",
        lastName: "",
        password: "",
        email: "",
      });
      setLogo("");
      setPreview("");
      setShowAddModal(false);
      setShowEditModal(false);
      dispatch(reset());
    }
  }, [isSuccess]);

  //==========Handle Delete Admin= =================================
  const handleDeleteAdmin = (event, id) => {
    event.preventDefault();
    let body = {
      verificationCode: verificationCode,
      adminId: id,
    };
    dispatch(deleteAdmin(body));
  };

  //============Handler for update Admin status to Active===========
  //   const handleAdminStatus = (e, id) => {
  //     e.preventDefault();
  //     let body = {
  //       verificationCode: verificationCode,
  //       businessId: id,
  //     };
  //     dispatch(approveAdminStatus(body));
  //   };

  //-------------------DATA TABLE START ---------------------------------

  const [pending, setPending] = useState(true);

  // Apply the filter based on the input text
  //=============Search Field================
  const [searchQuery, setSearchQuery] = useState("");

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const filteredData = searchQuery
    ? allAdmins.filter(
      (item) =>
        item.firstName.toLowerCase().includes(searchQuery.toLowerCase()) ||
        item.lastName.toLowerCase().includes(searchQuery.toLowerCase()) ||
        item.email.toLowerCase().includes(searchQuery.toLowerCase())
    )
    : allAdmins;

  useEffect(() => {
    const timeout = setTimeout(() => {
      setPending(false);
    }, 500);
    return () => clearTimeout(timeout);
  }, []);

  const actions = [
    {
      icon: <FontAwesomeIcon className="btn btnPrint p-0 fs-6" icon={faEdit} />,
      tooltip: "Edit",
      onClick: (event, rowData) => handleEditAdminPop(rowData),
    },
    {
      icon: (
        <FontAwesomeIcon
          className="btn btnPrint p-0 ms-2 fs-6"
          icon={faTrash}
        />
      ),
      tooltip: "Delete",
      onClick: (event, rowData) => handleDeleteAdmin(event, rowData._id),
    },
  ];

  const DataTableActionButtons = ({ actions, rowData }) => (
    <>
      {actions.map((action, index) => (
        <button
          className="btn btn-sm"
          key={index}
          onClick={(event) => action.onClick(event, rowData)}
        >
          {action.icon}
        </button>
      ))}
    </>
  );

  const columns = [
    {
      name: <Trans>Logo</Trans>,
      selector: (row) => (
        <img
          src={BASE_URL_IMAGES + row?.adminLogo}
          alt="Logo"
          onError={(e) => (e.target.src = "/assets/user.png")}
          className="rounded-circle"
          style={{
            width: "40px",
            height: "40px",
            textAlign: "center",
          }}
        />
      ),
      sortable: true,
      center: true,
    },
    {
      name: <Trans>First Name</Trans>,
      selector: (row) => row.firstName,
      sortable: true,
      center: true,
    },
    {
      name: <Trans>Last Name</Trans>,
      selector: (row) => row.lastName,
      sortable: true,
      center: true,
    },

    {
      name: <Trans>Email</Trans>,
      selector: (row) => row.email,
      sortable: true,
      center: true,
      width: "20%",
    },

    {
      name: <Trans>Role</Trans>,
      selector: (row) => <span className="text-capitalize">{row.role}</span>,
      sortable: true,
      center: true,
    },
    {
      name: <Trans>Creation Date</Trans>,
      selector: (row) => moment(row.createdAt).format("DD-MM-YYYY"),
      sortable: true,
      center: true,
    },
    profile?.isSuperAdmin
      ? {
        name: <Trans>Actions</Trans>,
        cell: (row) => (
          <DataTableActionButtons actions={actions} rowData={row} />
        ),
        ignoreRowClick: true,
        allowOverflow: true,
        button: true,
        center: true,
      }
      : "",
  ].filter((column) => column !== "");

  //  Internally, customStyles will deep merges your customStyles with the default styling.
  const customStyles = {
    rows: {
      style: {
        minHeight: "60px",
      },
    },
    headCells: {
      style: {
        fontSize: "14px",
        color: "#B5B7C0",
        textAlign: "center",
      },
    },
    cells: {
      style: {
        textAlign: "center", // Align regular cell content to center
        color: "#292D32 !important",
        fontWeight: "600",
      },
    },
  };
  const { language, setLanguage } = useMyContext();
  //-------------------DATA TABLE END---------------------------------
  return (
    <>
      <div className="container-fluid my-3">
        <div className="row">
          <div className="col-3 col-md-2">
            <h3 className="d-flex align-items-center m-0 fw-500 mt-1">
              <Trans>Admins</Trans>
            </h3>
          </div>
          <div className="col-9 col-md-4">
            <div className="search-field">
              <div className="search-bar">
                <input
                  type="text"
                  placeholder="Search by name , email ..."
                  className="dashboardinput"
                  id="userSearch"
                  value={searchQuery}
                  onChange={handleSearchChange}
                />
                <span className="search-icon">
                  <FontAwesomeIcon icon={faSearch} className="usersearch" />
                </span>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-6  d-flex justify-content-end">
            {profile?.isSuperAdmin && (
              <Button
                className="btn btn-primary px-5"
                onClick={handleShowAddModal}
              >
                New Admin
              </Button>
            )}
            <div className="px-2">
              <ExportCSVButton data={allAdmins} filename={"exported_data.csv"} />
            </div>
          </div>
        </div>

        {/* <div className="row">
          <div className="col-md-12">
            <div className="d-flex justify-content-between">
              <h3 className="d-flex align-items-center m-0 fw-500">Admins</h3>
              {profile?.isSuperAdmin && (
                <Button
                  className="btn btn-primary px-5"
                  onClick={handleShowAddModal}
                >
                  New Admin
                </Button>
              )}
            </div>
          </div>
        </div> */}
        <div
          className="row justify-content-end"
        >
          {/* <div className="col-md-4">
            <div className="search-field">
              <div className="search-bar">
                <input
                  type="text"
                  placeholder="Search by name , email ..."
                  className="dashboardinput"
                  id="userSearch"
                  value={searchQuery}
                  onChange={handleSearchChange}
                />
                <span className="search-icon">
                  <FontAwesomeIcon icon={faSearch} className="usersearch" />
                </span>
              </div>
            </div>
          </div> */}
        </div>
        <div className="row">
          <div className="col-md-12">
            {allAdmins && (
              <div id="grid-container" className="outerWrapperTable"> 
                <DataTable
                  columns={columns}
                  data={filteredData}
                  progressPending={pending}
                  progressComponent={<Loader />}
                  customStyles={customStyles}
                  pagination
                />
              </div>
            )}
          </div>
        </div>
      </div>
      {/* Create New Admin */}
      <Modal show={showAddModal} onHide={handleCloseAddModal} centered>
        <Modal.Header closeButton>
          <Modal.Title>New Admin</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="container-fluid">
            <form onSubmit={handleCreateAdmin}>
              <div className="row">
                <div className="col-md-12">
                  <div className="form-group">
                    <label className="form-label"><Trans>First Name</Trans></label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter first name"
                      name="firstName"
                      value={firstName}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-group">
                    <label className="form-label"><Trans>Last Name</Trans></label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter last name"
                      name="lastName"
                      value={lastName}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-group">
                    <label className="form-label"><Trans>Email</Trans></label>
                    <input
                      type="email"
                      className="form-control"
                      placeholder="Enter email"
                      name="email"
                      value={email}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-group position-relative">
                    <label className="form-label"><Trans>Password</Trans></label>
                    <input
                      type={PasswordVisible ? "password" : "text"}
                      name="password"
                      value={password}
                      onChange={handleChange}
                      className="form-control"
                    />
                    {PasswordVisible ? <img src="/assets/icons/EyeView.svg"
                        alt="DropDownIcon"
                        className='DropDownIconsInputField DatePickerDropDown c_pointer EyeIconsInAddressInSignUp'
                        onClick={() => {
                          setPasswordVisible(!PasswordVisible)
                        }}
                      /> :
                        <img src="/assets/icons/EyeViewNot.svg"
                          alt="DropDownIcon"
                          className='DropDownIconsInputField DatePickerDropDown c_pointer EyeIconsInAddressInSignUp'
                          onClick={() => {
                            setPasswordVisible(!PasswordVisible)
                          }}
                        />}
                  </div>
                </div>
              </div>

              <div className="row">
                <div className={`${preview ? "col-md-9" : "col-md-12"}`}>
                  <div className="form-group">
                    <label className="form-label"><Trans>Upload Logo</Trans></label>
                    <input
                      type="file"
                      name="logo"
                      className="form-control"
                      accept="image/*"
                      onChange={handleLogoChange}
                    />
                  </div>
                </div>

                {logo && (
                  <div className={preview ? "col-md-3" : "col-md-0"}>
                    <div className="form-group mt-2">
                      <img
                        className="rounded-circle"
                        width={70}
                        height={70}
                        src={preview}
                        alt=""
                      />
                    </div>
                  </div>
                )}
              </div>
              <div className="d-flex justify-content-end mt-3">
                <div className="d-flex justify-content-between gap-2">
                  <button
                    type="button"
                    className="btn btn-secondary w-100"
                    onClick={handleCloseAddModal}
                  >
                    Cancel
                  </button>
                  {isLoadingCreateAdmin ? <div> <ButtonLoading /></div> : <button
                    type="submit"
                    className="btn btn-primary d-block m-auto"
                    onClick={handleCreateAdmin}
                  >
                    <Trans>Submit</Trans>
                  </button>
                  }
                </div>
              </div>

            </form>
          </div>
        </Modal.Body>
      </Modal>

      {/* Create New Admin End*/}

      {/* Edit/Update Admin Start*/}

      <Modal show={showEditModal} onHide={handleCloseEditModal} centered>
        <Modal.Header closeButton>
          <Modal.Title>Update Admin</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {editData && (
            <div className="container-fluid">
              <form onSubmit={handleAdminUpdate}>
                <div className="row ">
                  <div className="d-flex justify-content-center mb-3">
                    <img
                      className="rounded-circle"
                      width={70}
                      height={70}
                      src={BASE_URL_IMAGES + editData?.adminLogo}
                      onError={(e) => (e.target.src = "/assets/user.png")}
                      alt=""
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <div className="form-group">
                      <label className="form-label"><Trans>First Name</Trans></label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter first name"
                        name="firstName"
                        value={editData?.firstName}
                        onChange={handleEditChange}
                      />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group">
                      <label className="form-label"><Trans>Last Name</Trans></label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter last name"
                        name="lastName"
                        value={editData?.lastName}
                        onChange={handleEditChange}
                      />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group">
                      <label className="form-label"><Trans>Email</Trans></label>
                      <input
                        type="email"
                        className="form-control"
                        placeholder="Enter email"
                        name="email"
                        value={editData?.email}
                        onChange={handleEditChange}
                      />
                    </div>
                  </div>
                  {/* <div className="col-md-12">
                  <div className="form-group">
                    <label className="form-label">Password</label>
                    <input
                      type="password"
                      name="password"
                      value={editData?.firstName}
                        onChange={handleEditChange}
                      className="form-control"
                    />
                  </div>
                </div> */}
                </div>

                <div className="row">
                  <div className={`${preview ? "col-md-9" : "col-md-12"}`}>
                    <div className="form-group">
                      <label className="form-label"><Trans>Upload Logo</Trans></label>
                      <input
                        type="file"
                        name="logo"
                        className="form-control"
                        accept="image/*"
                        onChange={handleLogoChange}
                      />
                    </div>
                  </div>

                  {logo && (
                    <div className={preview ? "col-md-3" : "col-md-0"}>
                      <div className="form-group mt-2">
                        <img
                          className="rounded-circle"
                          width={70}
                          height={70}
                          src={preview}
                          alt=""
                        />
                      </div>
                    </div>
                  )}
                </div>

                <button
                  type="submit"
                  className="btn btn-primary d-block m-auto"
                  onClick={handleAdminUpdate}
                >
                  Update
                </button>
              </form>
            </div>
          )}
        </Modal.Body>
      </Modal>

      {/* Edit/Update Admin End*/}
    </>
  );
};

export default Admins;
