import api from "../../utility/api";

// Add More DTP Request
const addMoreDTPReq = async (data) => {
  const response = await api.post("/additionalDTPRequest/create", data);
  return response;
};

// Get All More DTP Requsts
const getAllMoreDTPRequst = async (data) => {
  const response = await api.post("/additionalDTPRequest/getAll", data);
  return response;
};

// Update Status More DTP Requsts
const updateMoreDTPRequest = async (data) => {
  const response = await api.put("/additionalDTPRequest/updateStatus", data);
  return response;
};

// Create note for More DTP Requst by Admin
const createMoreDTPNote = async (data) => {
  const response = await api.post("/additionalDTPRequest/addNote", data);
  return response;
};

// Edit Sticker Request
// const editStickerRequest = async (data) => {
//   const response = await api.put("/stickerRequest/update", data);
//   return response;
// };

const additionalDtpService = {
  addMoreDTPReq,
  updateMoreDTPRequest,
  getAllMoreDTPRequst,
  createMoreDTPNote,
  //   editStickerRequest,
};

export default additionalDtpService;
