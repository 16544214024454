import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import paymentService from "./paymentService";
import { toast } from "react-toastify";

const verificationCode = process.env.REACT_APP_VERIFICATION_TOKEN;

const initialState = {
  allInvoices: [],
  isLoading: false,
  isError: false,
  isSuccess: false,
  message: "",
  UpdatePaymentStatus:null
};

// Add New  Payment
export const addPayment = createAsyncThunk(
  "auth/addPayment",
  async (data, thunkAPI) => {
    try {
      await paymentService.addPayment(data);
      const res = await paymentService?.getAllInvoices({
        verificationCode: verificationCode,
      });
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.error);
    }
  }
);

// Update Payment Status
export const HandlerUpdateStatus = createAsyncThunk(
  "auth/UpdateStatus",
  async (data, thunkAPI) => {
    try {
      await paymentService.handlerUpdatePaymentStatus(data);
      const res = await paymentService.getAllInvoices({verificationCode : process.env.REACT_APP_VERIFICATION_TOKEN});
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.error);
    }
  }
);



// Get All Invoices
export const getAllInvoices = createAsyncThunk(
  "auth/getAllInvoices",
  async (data, thunkAPI) => {
    try {
      const res = await paymentService.getAllInvoices(data);
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.error);
    }
  }
);

export const payment = createSlice({
  name: "payment",
  initialState,
  reducers: {
    reset: (state) => {
      state.isLoading = false;
      state.isError = false;
      state.message = "";
      state.isSuccess = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllInvoices.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAllInvoices.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.allInvoices = action?.payload?.invoices?.length > 0 ? action?.payload?.invoices.reverse() : [];
      })
      .addCase(getAllInvoices.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        toast.error(action.payload);
      })

      .addCase(addPayment.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(addPayment.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.allInvoices = action.payload?.reverse();
        toast.success("Payment added successfully.");
      })
      .addCase(addPayment.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        toast.error(action.payload);
      })

      .addCase(HandlerUpdateStatus.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(HandlerUpdateStatus.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.allInvoices = action?.payload?.invoices?.length > 0 ? action?.payload?.invoices.reverse() : [];
        toast.success("Successfully Update Payment Status");
      })
      .addCase(HandlerUpdateStatus.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        toast.error(action.payload);
      });

      
  },
});

export const { reset } = payment.actions;
export default payment.reducer;
