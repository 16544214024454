import React, { useState, useEffect } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import moment from "moment";

import { useMyContext } from '../../../..';
import { Trans } from 'react-i18next';

//===========Data table =================
import DataTable from "react-data-table-component";

//=========Redux===========
import { useSelector, useDispatch } from "react-redux";
import {
  createMoreDTPNote,
  getAllMoreDTPRequst,
  reset,
  updateMoreDTPRequest,
} from "../../../../redux/additionalDtpRequest/additionalDtpSlice";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSearch,
  faDownload,
  faPlus,
  faBan,
  faCheck,
} from "@fortawesome/free-solid-svg-icons";
//---import Loader
import Loader from "../../../../Loader";
import { toast } from "react-toastify";

//======Utils
const verificationCode = process.env.REACT_APP_VERIFICATION_TOKEN;

const AdditionalDTPRequest = () => {
  const dispatch = useDispatch();

  const { allAdditionalDTPReq, isSuccess } = useSelector((state) => {
    return state.additionalDtpRequest;
  });

  //=======Get All Sticker Requests =================
  useEffect(() => {
    dispatch(getAllMoreDTPRequst({ verificationCode }));
  }, [dispatch]);

  //==========Update Sticker Requests Status=================
  //============Handler for update Data Touchpoint status to Active===========
  // const handleUpdateRequest = (e, data) => {
  //   e.preventDefault();
  //   let body = {
  //     verificationCode: verificationCode,
  //     businessId: data.business?._id,
  //     reqId: data._id,
  //     units: data.units,
  //   };
  //   dispatch(updateMoreDTPRequest(body));
  // };

  //==========================Table======================//
  //Add Notes Modal
  const [showModalNotes, setShowModalNotes] = useState(false);

  const [note, setNote] = useState("");
  const [stickerId, setStickerId] = useState("");
  //===Array from Sticker
  const [stickerNotes, setStickerNotes] = useState([]);

  const handleCloseNotes = () => {
    setShowModalNotes(false);
  };

  const handleOpenNotes = (notes, id) => {
    if (id) {
      setStickerId(id);
    }
    setStickerNotes(notes);
    setShowModalNotes(true);
  };

  const handleCreateNote = (event) => {
    event.preventDefault();
    if (!note.trim()) {
      return toast.error("Please enter a note!");
    }
    let body = {
      verificationCode: verificationCode,
      reqId: stickerId,
      note: note,
    };
    dispatch(createMoreDTPNote(body));
  };

  //-------------------DATA TABLE START ------------------

  const [pending, setPending] = useState(true);

  //====Search Field
  const [searchQuery, setSearchQuery] = useState("");

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      setPending(false);
    }, 500);
    return () => clearTimeout(timeout);
  }, []);

  const DataTableActionButtons = ({ actions, rowData }) => (
    <>
      {rowData?.status !== true && (
        <button
          type="button"
          className="btn btnPrint fs-6"
          // onClick={(e) => handleUpdateRequest(e, rowData)}
          disabled
          title="not approve"
        >
          <FontAwesomeIcon icon={faCheck} className="badge bg-success py-2  " />{" "}
        </button>
      )}
      {rowData?.status === true && (
        <button
          type="button"
          className="btn btnPrint fs-6 border-0"
          disabled
          title="approve"
        >
          <FontAwesomeIcon className="badge bg-danger py-2 " icon={faBan} />{" "}
        </button>
      )}
    </>
  );

  const columns = [
    {
      name: "Business Name",
      selector: (row) => row?.business?.name_of_business,
      sortable: true,
      center: true,
    },

    {
      name: "Email",
      selector: (row) => row.business.email,
      sortable: true,
      center: true,
    },
    {
      name: "Subscription",
      selector: (row) => row.selectedSubscription?.name,
      sortable: true,
      center: true,
    },

    {
      name: "Total Amount",
      selector: (row) => "€" + row.totalPrice,
      sortable: true,
      center: true,
    },
    {
      name: "Requested Units",
      selector: (row) => row.units,
      sortable: true,
      center: true,
    },
    {
      name: "Request Date",
      selector: (row) => moment(row.createdAt).format("DD-MM-YYYY"),
      sortable: true,
      center: true,
    },
    {
      name: "Additional Note",

      selector: (row) => (
        <>
          <button
            type="button"
            className="btn btnPrint fs-6 border-0"
            title="Add Sticker Note"
            onClick={(e) => handleOpenNotes(row?.notes, row?._id)}
          >
            <FontAwesomeIcon icon={faPlus} />{" "}
          </button>
        </>
      ),
      center: true,
    },

    {
      name: "Status",
      cell: (row) => <DataTableActionButtons rowData={row} />,
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
      center: true,
    },
  ];

  //  Internally, customStyles will deep merges your customStyles with the default styling.
  const customStyles = {
    rows: {
      style: {
        minHeight: "60px",
      },
    },
    headCells: {
      style: {
        fontSize: "14px",
        color: "#B5B7C0",
        textAlign: "center",
      },
    },
    cells: {
      style: {
        textAlign: "center", // Align regular cell content to center
        color: "#292D32 !important",
        fontWeight: "600",
      },
    },
  };

  // Apply the filter based on the input text
  const filteredData = searchQuery
    ? allAdditionalDTPReq.filter(
      (item) =>
        item.business.name_of_business
          .toLowerCase()
          .includes(searchQuery.toLowerCase()) ||
        item.business.email
          .toLowerCase()
          .includes(searchQuery.toLowerCase()) ||
        item?.business.invoiceAddress
          .toLowerCase()
          .includes(searchQuery.toLowerCase())
    )
    : allAdditionalDTPReq;

  //Search Field END

  //-------Export CSV ----------------
  function convertArrayOfObjectsToCSV(array) {
    let result;

    const columnDelimiter = ",";
    const lineDelimiter = "\n";
    const keys = Object.keys(allAdditionalDTPReq[0]);

    result = "";
    result += keys.join(columnDelimiter);
    result += lineDelimiter;

    array.forEach((item) => {
      let ctr = 0;
      keys.forEach((key) => {
        if (ctr > 0) result += columnDelimiter;

        result += item[key];

        ctr++;
      });
      result += lineDelimiter;
    });

    return result;
  }

  function downloadCSV(array) {
    const link = document.createElement("a");
    let csv = convertArrayOfObjectsToCSV(array);
    if (csv == null) return;

    const filename = "export.csv";

    if (!csv.match(/^data:text\/csv/i)) {
      csv = `data:text/csv;charset=utf-8,${csv}`;
    }

    link.setAttribute("href", encodeURI(csv));
    link.setAttribute("download", filename);
    link.click();
  }

  const Export = ({ onExport }) => (
    <Link
      className="btn btn-secondary mb-2 mx-2"
      onClick={(e) => onExport(e.target.value)}
    >
      <FontAwesomeIcon icon={faDownload} className="btnIcon" />
      Export
    </Link>
  );
  const actionsMemo = React.useMemo(
    () => <Export onExport={() => downloadCSV(allAdditionalDTPReq)} />,
    []
  );

  //-------------------DATA TABLE END---------------------------------

  //================Resetting data on success-------------------------
  useEffect(() => {
    if (isSuccess) {
      setNote("");
      handleCloseNotes();
    }
    dispatch(reset());
  }, [isSuccess]);
  const { _language, setLanguage } = useMyContext();
  return (
    <>
      <div className="container-fluid my-3">
        <div className="row">
          <div className="col-md-4">
            {" "}
            <h3 className="align-items-center m-0 fw-500 mt-1">
              Additional DTP Request
            </h3>
          </div>
          <div className="col-md-4">
            <div className="search-field search-bar">
              <input
                type="text"
                placeholder={_language === "en" ? "Search" : "Búsqueda"}
                className="dashboardinput"
                id="userSearch"
                value={searchQuery}
                onChange={handleSearchChange}
              />
              <span className="search-icon">
                <FontAwesomeIcon icon={faSearch} className="usersearch" />
              </span>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-12">
            <div className="mt-2 outerWrapperTable" style={{ overflowX: "auto" }}>
              {allAdditionalDTPReq && (
                <DataTable
                  columns={columns}
                  data={filteredData}
                  progressPending={pending}
                  progressComponent={<Loader />}
                  actions={actionsMemo}
                  customStyles={customStyles}
                  pagination
                />
              )}
            </div>
          </div>
        </div>
      </div>

      {/*------------------------------------------MODALS START--------------------------------------------- */}

      {/* Add Note for Sticker Start*/}

      <Modal show={showModalNotes} onHide={handleCloseNotes}>
        <Modal.Header closeButton>
          <Modal.Title>Add Note</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleCreateNote}>
            <Form.Group controlId="noteTextArea">
              <Form.Control
                as="textarea"
                rows={3}
                value={note}
                onChange={(e) => setNote(e.target.value)}
              />
            </Form.Group>
            <Button
              type="submit"
              className="d-block ms-auto"
              onClick={handleCreateNote}
            >
              <Trans>Submit</Trans>
            </Button>
            {stickerNotes && stickerNotes.length > 0 && (
              <div className="row my-3">
                <div className="table-responsive">
                  <table className="table">
                    <thead>
                      <tr>
                        <th className="text-start">Note</th>
                        <th className="text-center">Created At</th>
                      </tr>
                    </thead>
                    <tbody>
                      {stickerNotes.map((note, i) => (
                        <tr>
                          <td className="text-start">{note?.text}</td>

                          <td className="text-center">
                            {moment(note.timestamp).format("D MMMM, YYYY")}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            )}
          </Form>
        </Modal.Body>
      </Modal>
      {/* Add Note for Sticker End*/}
    </>
  );
};

export default AdditionalDTPRequest;
