import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import reasonService from './reasonService';
import { toast } from 'react-toastify';

const verificationCode = process.env.REACT_APP_VERIFICATION_TOKEN;

const initialState = {
  allReasons: [],
  isLoading: false,
  isError: false,
  isSuccess: false,
  message: '',
};

// Add New  Reason
export const addReason = createAsyncThunk(
  'auth/addReason',
  async (data, thunkAPI) => {
    try {
      const res = await reasonService.addReason(data);
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.error);
    }
  }
);

// Edit  Reason
export const editReason = createAsyncThunk(
  'auth/editReason',
  async (data, thunkAPI) => {
    try {
      await reasonService.editReason(data);
      const res = await reasonService.getAllReasons({
        verificationCode: verificationCode,
      });
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.error);
    }
  }
);

// Delete  Reason
export const deleteReason = createAsyncThunk(
  'auth/deleteReason',
  async (data, thunkAPI) => {
    try {
      await reasonService.deleteReason(data);
      const res = await reasonService.getAllReasons({
        verificationCode: verificationCode,
      });
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.error);
    }
  }
);

// Get All Question
export const getAllReasons = createAsyncThunk(
  'auth/getAllReasons',
  async (data, thunkAPI) => {
    try {
      const res = await reasonService.getAllReasons(data);
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.error);
    }
  }
);

export const reason = createSlice({
  name: 'reason',
  initialState,
  reducers: {
    reset: (state) => {
      state.isLoading = false;
      state.isError = false;
      state.message = '';
      state.isSuccess = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllReasons.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAllReasons.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.allReasons = action.payload;
      })
      .addCase(getAllReasons.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        toast.error(action.payload);
      })
      .addCase(addReason.pending, (state) => {
        state.isLoadingAddReason = true;
      })
      .addCase(addReason.fulfilled, (state, action) => {
        state.isLoadingAddReason = false;
        state.isSuccess = true;
        state.allReasons = action.payload;
        toast.success('Reason added successfully.');
      })
      .addCase(addReason.rejected, (state, action) => {
        state.isLoadingAddReason = false;
        state.isError = true;
        toast.error(action.payload);
      })
      .addCase(editReason.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(editReason.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.allReasons = action.payload;
        toast.success('Reason updated successfully.');
      })
      .addCase(editReason.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        toast.error(action.payload);
      })
      .addCase(deleteReason.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteReason.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.allReasons = action.payload;
        toast.success('Reason deleted successfully.');
      })
      .addCase(deleteReason.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        toast.error(action.payload);
      });
  },
});

export const { reset } = reason.actions;
export default reason.reducer;
