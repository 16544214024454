import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import getCountriesAndCities from "./CountryAndCity";
import { toast } from "react-toastify";
const verificationCode = process.env.REACT_APP_VERIFICATION_TOKEN;
const initialState = {
  getCountries: [],
  getCities: [],
  isLoading: false,
  isError: false,
  isBmSuccess: false,
  message: "",
  isCitiesLoading: false,
  loadingCountries: false,
  loadingCities: false,
  DeleteCountry:null,
  loadingDeleteCountry:null
};

// get Countries
export const getAllCountries = createAsyncThunk(
  "countriesCities/getCountries",
  async (data, thunkAPI) => {
    try {
      const res = await getCountriesAndCities.getCountries({
        verificationCode: verificationCode,
      });
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.error);
    }
  }
);

// get Cities
export const getAllCities = createAsyncThunk(
  "countriesCities/getCities",
  async (data, thunkAPI) => {
    try {
      const res = await getCountriesAndCities.getCities(data);
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.error);
    }
  }
);

// Add Country
export const HandlerAddCountries = createAsyncThunk(
  "countriesCities/AddCountries",
  async (data, thunkAPI) => {
    try {
      const res = await getCountriesAndCities.addCountries(data);
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.error);
    }
  }
);
// Add Cities
export const HandlerAddCities = createAsyncThunk(
  "countriesCities/AddCities",
  async (data, thunkAPI) => {
    try {
      const res = await getCountriesAndCities.addCities(data);
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.error);
    }
  }
);

// Add Cities
export const HandlerDeleteCity = createAsyncThunk(
  "countriesCities/DeleteCountry",
  async (data, thunkAPI) => {
    try {
      const res = await getCountriesAndCities.deleteCountry(data);
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.error);
    }
  }
);




export const benchmark = createSlice({
  name: "CountriesAndCities",
  initialState,
  reducers: {
    resetBMData: (state) => {
      state.isLoading = false;
      state.isError = false;
      state.message = "";
      state.isBmSuccess = false;
    },
    resetCountries: (state) => {
      state.AddCountries = "";
    },
    resetCities: (state) => {
      state.AddCities = "";
    },
    resetDeleteCountries: (state) => {
      state.DeleteCountry = "";
    },
    
  },
  extraReducers: (builder) => {
    builder
      // for countries
      .addCase(getAllCountries.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAllCountries.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isBmSuccess = true;
        state.getCountries = action.payload;
      })
      .addCase(getAllCountries.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        toast.error(action.payload);
      })

      //   for cities
      .addCase(getAllCities.pending, (state) => {
        state.isCitiesLoading = true;
      })
      .addCase(getAllCities.fulfilled, (state, action) => {
        state.isCitiesLoading = false;
        state.isBmSuccess = true;
        state.getCities = action.payload;
      })
      .addCase(getAllCities.rejected, (state, action) => {
        state.isCitiesLoading = false;
        state.isError = true;
        toast.error(action.payload);
      })

      //   for Add Countries
      .addCase(HandlerAddCountries.pending, (state) => {
        state.loadingCountries = true;
      })
      .addCase(HandlerAddCountries.fulfilled, (state, action) => {
        state.isBmSuccess = true;
        state.AddCountries = action.payload;
        state.loadingCountries = false;
      })
      .addCase(HandlerAddCountries.rejected, (state, action) => {
        state.loadingCountries = false;
        state.isError = true;
        toast.error(action.payload);
      })
      //   for Add Cities
      .addCase(HandlerAddCities.pending, (state) => {
        state.loadingCities = true;
      })
      .addCase(HandlerAddCities.fulfilled, (state, action) => {
        state.isBmSuccess = true;
        state.AddCities = action.payload;
        state.loadingCities = false;
      })
      .addCase(HandlerAddCities.rejected, (state, action) => {
        state.loadingCities = false;
        state.isError = true;
        toast.error(action.payload);
      })

      //   delete Country
      .addCase(HandlerDeleteCity.pending, (state) => {
        state.loadingDeleteCountry = true;
      })
      .addCase(HandlerDeleteCity.fulfilled, (state, action) => {
        state.DeleteCountry = action.payload;
        state.loadingDeleteCountry = false;
      })
      .addCase(HandlerDeleteCity.rejected, (state, action) => {
        state.loadingDeleteCountry = false;
        state.isError = true;
        toast.error(action.payload);
      })

    
  },
});

export const { resetBMData, resetCountries, resetCities , resetDeleteCountries } = benchmark.actions;
export default benchmark.reducer;
