import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import sectorService from "./sectorService";
import { toast } from "react-toastify";

const verificationCode = process.env.REACT_APP_VERIFICATION_TOKEN;

const initialState = {
  allSectors: [],
  isLoading: false,
  isError: false,
  isSuccess: false,
  message: "",
  isLoadingUploadSector:false,
  isLoadingAddSector:false
};

// Add New  Sector
export const addSector = createAsyncThunk(
  "auth/addSector",
  async (data, thunkAPI) => {
    try {
      const res = await sectorService.addSector(data);
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.error);
    }
  }
);

// Upload  Sector
export const uploadSector = createAsyncThunk(
  "auth/uploadSector",
  async (data, thunkAPI) => {
    try {
      const res = await sectorService.uploadSector(data);
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.error);
    }
  }
);

// Edit  Sector
export const editSector = createAsyncThunk(
  "auth/editSector",
  async (data, thunkAPI) => {
    try {
      await sectorService.editSector(data);
      const res = await sectorService.getAllSectors({
        verificationCode: verificationCode,
      });
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.error);
    }
  }
);

// Get All Sectors
export const getAllSectors = createAsyncThunk(
  "auth/getAllSectors",
  async (data, thunkAPI) => {
    try {
      const res = await sectorService.getAllSectors(data);
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.error);
    }
  }
);

export const sector = createSlice({
  name: "sector",
  initialState,
  reducers: {
    reset: (state) => {
      state.isLoading = false;
      state.isError = false;
      state.message = "";
      state.isSuccess = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllSectors.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAllSectors.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.allSectors = action.payload;
      })
      .addCase(getAllSectors.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        toast.error(action.payload);
      })
      .addCase(addSector.pending, (state) => {
        state.isLoadingAddSector = true;
      })
      .addCase(addSector.fulfilled, (state, action) => {
        state.isLoadingAddSector = false;
        state.isSuccess = true;
        state.allSectors = action.payload;
        toast.success("Sector added successfully.");
      })
      .addCase(addSector.rejected, (state, action) => {
        state.isLoadingAddSector = false;
        state.isError = true;
        toast.error(action.payload);
      })

      .addCase(uploadSector.pending, (state) => {
        state.isLoadingUploadSector = true;
      })
      .addCase(uploadSector.fulfilled, (state, action) => {
        state.isLoadingUploadSector = false;
        state.isSuccess = true;
        state.allSectors = action.payload;
        toast.success("Sectors uploaded successfully.");
      })
      .addCase(uploadSector.rejected, (state, action) => {
        state.isLoadingUploadSector = false;
        state.isError = true;
        toast.error(action.payload);
      })
      .addCase(editSector.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(editSector.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.allSectors = action.payload;
        toast.success("Sector updated successfully.");
      })
      .addCase(editSector.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        toast.error(action.payload);
      });
  },
});

export const { reset } = sector.actions;
export default sector.reducer;
