import React, { useState } from "react";
import { NavLink, Routes, Route, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import whileLogo from "../../../assets/images/icons/logo_white.png";
import SSetting from "../DataTouchpoint/setting/SSetting";
import SDash from "../DataTouchpoint/Sdash/SDash";
//=========Import Toasts========

import { useMyContext } from '../../..';
import { Trans } from 'react-i18next';

import { toast } from "react-toastify";

//==============Import Redux =================
import { useSelector, useDispatch } from "react-redux";
import { logout, reset } from "../../../redux/auth/authSlice";

//=Icons
import { MdOutlineDashboard, MdOutlineSettings } from "react-icons/md";

const Dashboard = () => {
  const { language, setLanguage } = useMyContext();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [activeLink, setActiveLink] = useState("");
  const [activeSubMenu, setActiveSubMenu] = useState(null);
  const [sidebarVisible, setSidebarVisible] = useState(false);

  const handleClick = (link) => {
    setSidebarVisible(false);
    setActiveLink(link);
  };

  //Humgurger Menu

  const toggleSidebar = () => {
    setSidebarVisible(!sidebarVisible);
  };

  const sidebar = [
    {
      link: "",
      text: <Trans>Dashboard</Trans>,
      icon: <MdOutlineDashboard />,
    },
    {
      link: "setting",
      text: <Trans>Settings</Trans>,
      icon: <MdOutlineSettings />,
    },
  ];

  //==============Logout Handler Submit=====================
  const handleLogout = () => {
    dispatch(logout());
    // navigate("/datatouchpoint/login");
    navigate("/");
    dispatch(reset());
    toast.success("Logout successfully !");
  };

  return (
    <>
      <section id="dashboardSidebar">
        <div className="container-fluid">
          <div className="row">
            <div className="p-0" id="SideBArWid">
              <div className="profile-sidebar card shadow-sm">
                <div className="logo py-2">
                  <h3 className="logoHeading pt-3">
                    {" "}
                    <img src={whileLogo} alt="" width={120} />
                  </h3>
                  <div className="menu-icon" onClick={toggleSidebar}>
                    <FontAwesomeIcon icon={faBars} />
                  </div>
                </div>
                <div className={`sidebar ${sidebarVisible ? "show" : ""}`}>
                  <div className=" py-2 mobile_logo_hide">
                    <h3 className="logoHeading pt-3">
                      {" "}
                      <img src={whileLogo} alt="" width={200} />
                    </h3>
                  </div>
                  <div className="sidebar-links">
                    <div className="sidebar-links-container">
                      {" "}
                      {/* Add container element */}
                      {sidebar &&
                        sidebar.map((item, index) => (
                          <div key={index}>
                            {item.isDropdown ? (
                              <div className="divSideBar">
                                <div
                                  onClick={() => {
                                    setActiveSubMenu(
                                      activeSubMenu === item.link
                                        ? null
                                        : item.link
                                    );
                                  }}
                                  className={
                                    activeLink === `${item.link}`
                                      ? "activeSideBar"
                                      : ""
                                  }
                                  inline={item.isDropdown ? "true" : false}
                                >
                                  <span className="fs-5 me-2">{item.icon}</span>
                                  {item.text}
                                </div>
                                {activeSubMenu === item.link && (
                                  <div style={{ paddingLeft: "1.5rem" }}>
                                    {item.submenu.map(
                                      (submenuItem, submenuIndex) => (
                                        <NavLink
                                          key={submenuIndex}
                                          to={`${submenuItem.link}`}
                                          onClick={() =>
                                            handleClick(submenuItem.link)
                                          }
                                          className={
                                            activeLink === `${submenuItem.link}`
                                              ? "activeSideBar"
                                              : ""
                                          }
                                          inline={
                                            item.isDropdown ? "true" : false
                                          }
                                        >
                                          {submenuItem.text}
                                        </NavLink>
                                      )
                                    )}
                                  </div>
                                )}
                              </div>
                            ) : (
                              <NavLink
                                to={`${item.link}`}
                                onClick={() => handleClick(item.link)}
                                className={
                                  activeLink === `${item.link}`
                                    ? "activeSideBar"
                                    : ""
                                }
                                inline={item.isDropdown ? "true" : false}
                              >
                                <span className="fs-5 me-2">{item.icon}</span>
                                {item.text}
                              </NavLink>
                            )}
                          </div>
                        ))}
                      <div className="logout-button" onClick={handleLogout}>
                        <button className="btn btn-white"><Trans>Log out</Trans></button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-9 mt-2" id="dashRIght">
              <Routes>
                <Route path="/setting" index element={<SSetting />} />
                <Route path="" index element={<SDash />} />
              </Routes>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Dashboard;
