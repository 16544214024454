import React, { useState, useEffect, useMemo, useRef, createRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Alert, Modal } from 'react-bootstrap';
import Select from 'react-select';
import moment from 'moment';
import ReactToPrint from 'react-to-print';
// import parse from "html-react-parser";
import { useScreenshot } from 'use-react-screenshot';
import { useMyContext } from '../../../..';
import { Trans } from 'react-i18next';
import ReactLoading from 'react-loading';
import domtoimage from 'dom-to-image';
//===========Data table =================
import DataTable from 'react-data-table-component';
import { BsQrCodeScan } from 'react-icons/bs';
import { FaCode } from 'react-icons/fa';
import { BiSticker } from 'react-icons/bi';
import QRCode from 'react-qr-code';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faSearch,
  faDownload,
  faUpload,
  faPlus,
  faPrint,
  faTrash,
  faCheck,
  faUserPlus,
  faCopy,
  faEye,
} from '@fortawesome/free-solid-svg-icons';
//=========Redux===========
import { useSelector, useDispatch } from 'react-redux';
import {
  addUserToDTP,
  approveDTPStatus,
  createBusinessDTP,
  deleteDTP,
  deleteUserToDTP,
  getAllBusinessDTP,
  getAllSubReq,
  reset,
  updateDefaultCusQuestion,
  updateDefaultEmpQuestion,
} from '../../../../redux/auth/authSlice';
//==============Loader================================
import Loader from '../../../../Loader';
import { getAllQuestions } from '../../../../redux/question/questionSlice';
import { getAllReasons } from '../../../../redux/reason/reasonSlice';
import { getAllStickers } from '../../../../redux/sticker/stickerSlice';
import { getAllQrCodeTemplates } from '../../../../redux/qrCode/qrSlice';
import {
  addStickerRequest,
  reset as sticketRequestReset,
} from '../../../../redux/stickerRequest/stickerRequestSlice';
import { getAllSubscriptions } from '../../../../redux/subscription/subscriptionSlice';
import { toast } from 'react-toastify';
import {
  addMoreDTPReq,
  reset as additionalReqReset,
} from '../../../../redux/additionalDtpRequest/additionalDtpSlice';
import api from '../../../../utility/api';
// import { Country, City } from "country-state-city";
import dashboard from '../../../../assets/images/icons/dashboard.png';
import logoBlue from '../../../../assets/images/icons/GoRateLogo.png';
import logoWhite from '../../../../assets/images/icons/GoRateWhite.png';
import logoBlack from '../../../../assets/images/icons/GoRateBlack.png';
import { getAllQuestionReason } from '../../../../redux/reasonQuestion/reasonQuestionSlice';
import ExportCSVButton from '../../../../common/ExportCSVButton';
import { getAllCities } from '../../../../redux/CountryAndCities/CountryAndCitySlice';
import ButtonLoading from '../../../../common/ButtonLoading';

// import "./ctouch.css";

//======Utils
const verificationCode = process.env.REACT_APP_VERIFICATION_TOKEN;
const REACT_APP_BASE_URL_QR_CODE = process.env.REACT_APP_BASE_URL_QR_CODE;
const BASE_URL_IMAGES = process.env.REACT_APP_BASE_URL_IMAGE;
const CLIENT_URL = process.env.REACT_APP_CLIENT_BASE_URL;

const CTouchPoints = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const componentRef = useRef();
  const [CitiesLoading, setCitiesLoading] = useState(false);
  const [PaymentMethodForSticker, setPaymentMethodForSticker] =
    useState('card');
  const {
    allBusinessDTP,
    isSuccess,
    profile,
    isLoadingCreateBusinessDPT,
    isLoadingAddUserDTP,
    allSubRequests,
  } = useSelector((state) => {
    return state.auth;
  });

  const { allQuestions } = useSelector((state) => {
    return state.question;
  });

  const { allReasons } = useSelector((state) => {
    return state.reason;
  });

  const { allStickers } = useSelector((state) => {
    return state.sticker;
  });

  const { allQrCodeTemplates } = useSelector((state) => {
    return state.qrCode;
  });

  const { allSubscriptions } = useSelector((state) => {
    return state.subscription;
  });

  const { allQuestionReason } = useSelector((state) => {
    return state.reasonQuestion;
  });

  const isStickerRequestSuccess = useSelector((state) => {
    return state.stickerRequest.isSuccess;
  });

  const { isLoadingAddStickerRequest } = useSelector((state) => {
    return state.stickerRequest;
  });

  const isAdditionalDtpRequestSuccess = useSelector((state) => {
    return state.additionalDtpRequest.isSuccess;
  });
  const { getCountries, getCities, isCitiesLoading } = useSelector(
    (state) => state.CountryAndCitySlice
  );

  //============Get All Businesses Data Touchpoints && Get All Questions && Get All Stickers settings && Get AllQr Code Templates =============================
  useEffect(() => {
    dispatch(getAllBusinessDTP({ verificationCode }));
    dispatch(getAllQuestions({ verificationCode }));
    dispatch(getAllStickers({ verificationCode }));
    dispatch(getAllQrCodeTemplates({ verificationCode }));
    dispatch(getAllSubscriptions({ verificationCode }));
    dispatch(getAllReasons({ verificationCode }));
    dispatch(getAllQuestionReason({ verificationCode }));
  }, [dispatch]);

  //===========Country and Cities Selection START=================================
  const [selectedCountry, setSelectedCountry] = useState('');
  const [cities, setCities] = useState([]);

  const handleCountryChange = async (event) => {
    const countryCode = event.target.value;
    setSelectedCountry(countryCode);

    setCitiesLoading(true);
    try {
      dispatch(getAllCities(countryCode));
    } catch (error) {
      console.error('Error during POST request:', error);
    }
  };
  const [AllCountry, setAllCountry] = useState();
  //===========Country and Cities Selection END=================================
  //---------Get user ----------
  const [userData, setUserData] = useState();
  useEffect(() => {
    getUser();
  }, []);

  const getUser = async () => {
    try {
      const user = await api.post('/user/getUser', {
        verificationCode,
      });
      if (user) {
        setUserData(user?.data);
      }
    } catch (error) {
      console.error(error);
    }
  };
  //---------Get user END----------

  //============Get All Reasons with option and select START =============================
  // const [optionReasons, setOptionReasons] = useState([]);
  const [reasonsVal, setReasonsVal] = useState('');

  // if (profile?.employeeReasons?.length !== 0) {
  //   profile?.employeeReasons?.forEach((item) => {
  //     finalEmpReasonVal.push(item.value);
  //   });
  // }

  // useEffect(() => {
  //   const options = allReasons?.map((reas) => ({
  //     value: reas.reason,
  //     label: reas.reason,
  //   }));
  //   setOptionReasons(options);
  // }, [allReasons]);

  const handleReasonOptChange = (e) => {
    setReasonsVal(e);
  };

  //============Get All Reasons with option and select END=============================

  //===========Stickers Request  API & MODAL START=================
  const [reqType, setRequestType] = useState('');
  const [dtpId, setDTPId] = useState('');
  const [totalPrice, setTotalPrice] = useState(0);

  const [showRequestTempModal, setShowRequestTempModal] = useState(false);
  const handleRequestTempClose = () => setShowRequestTempModal(false);
  const [OpenModalBankTransfer, setOpenModalBankTransfer] = useState(false);

  function handleClose(params) {
    setOpenModalBankTransfer(false);
  }

  function handleSubmitBankTransfer(e) {
    e.preventDefault();
  }
  const handleRequestTempShow = (id, reqType) => {
    let templateIdToSet = '';

    if (reqType === 'Customer Experience') {
      templateIdToSet = profile.customerDefaultQr
        ? profile.customerDefaultQr._id
        : '';
    } else if (reqType === 'Employee Sentiment') {
      templateIdToSet = profile.employeeDefaultQr
        ? profile.employeeDefaultQr._id
        : '';
    }

    setStickerVales((prevFormData) => ({
      ...prevFormData,
      templateId: templateIdToSet || prevFormData.templateId,
    }));

    setRequestType(reqType);
    setDTPId(id);
    setShowRequestTempModal(true);
  };

  const [stickerValues, setStickerVales] = useState({
    templateId: '',
    stickerSize: '',
    units: 0,
    shippingAddress: '',
    stickerCity: '',
    stickerPostalCode: '',
  });

  const {
    templateId,
    stickerSize,
    units,
    shippingAddress,
    stickerCity,
    stickerPostalCode,
  } = stickerValues;

  const handleOnChangeSticker = (e) => {
    setStickerVales({ ...stickerValues, [e.target.name]: e.target.value });
  };

  // Calculate total value based on the selected sticker size and units
  const calculateTotalValue = () => {
    const selectedSticker =
      allStickers &&
      allStickers.find((sticker) => sticker.size === stickerSize);
    const stickerPrice = selectedSticker ? selectedSticker.pricePlusVat : 0;
    const total = parseFloat(stickerPrice) * parseFloat(units);
    setTotalPrice(isNaN(total) ? 0 : total.toFixed(2));
  };

  useEffect(() => {
    calculateTotalValue();
  }, [stickerSize, units]);

  const handleRequestSticker = (e) => {
    e.preventDefault();
    let body = {
      verificationCode: verificationCode,
      DataTouchPoint: dtpId,
      qrCodeTemplate: templateId,
      stickerSize: stickerSize,
      units: units,
      shippingAddress: shippingAddress,
      city: stickerCity,
      country: selectedCountry,
      postalCode: stickerPostalCode,
      totalPrice: totalPrice,
      paymentMethod:
        profile.paymentMethod === 'card' ? 'card' : 'bank_transfer',
    };
    dispatch(addStickerRequest(body));
  };

  //===========Stickers Request  API & MODAL END=================

  //===============Qr Code Modal================
  //===Selected type for account  alex
  const [selectedQRButton, setSelectedQRButton] = useState('Template QrCode');

  // const handleQRButtonClick = (buttonName) => {
  //   setSelectedQRButton(buttonName);
  // };

  const [isShowQRImage, setIsShowQRImage] = useState(false);
  const [qrCodeImage, setQrCodeImage] = useState('');
  const [qrTemplate, setQrTemplate] = useState({});

  const [dtpSlug, setDTPSlug] = useState('');
  const [Name, setName] = useState('');

  const handleImageClick = (imageUrl, slug, tempType, name) => {
    setSelectedQRButton('Template QrCode');

    if (tempType === 'Customer Experience') {
      if (profile && profile?.customerDefaultQr !== undefined) {
        setQrTemplate(profile?.customerDefaultQr);
      }
    }
    if (tempType === 'Employee Sentiment') {
      if (profile && profile?.employeeDefaultQr !== undefined) {
        setQrTemplate(profile?.employeeDefaultQr);
      }
    }
    setName(name);
    setQrCodeImage(imageUrl);
    setDTPSlug(slug);
    setIsShowQRImage(true);
  };

  const onClickSlug = () => {
    navigate(`/review/${dtpSlug}`);
  };

  //=====  Download QR code Template=====

  //  const downLoadTemplateQr = (tempImg) => {
  //   const downloadLink = document.createElement("a");
  //   downloadLink.href = REACT_APP_BASE_URL_QR_CODE + tempImg;
  //   downloadLink.download = tempImg;
  //   downloadLink.click();
  // };

  //=============Add New Datatouch point =============================
  const [showAddDTPModal, setShowAddDTPModal] = useState(false);
  const handleCloseAddDTPModal = () => setShowAddDTPModal(false);
  const handleShowAddDTPModal = () => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      language: profile.defaultLanguage || prevFormData.language,
      question: profile.employeeQuestion
        ? profile.employeeQuestion.question
        : profile.customerQuestion?.question || prevFormData.question,
    }));
    setShowAddDTPModal(true);
  };

  //===Selected type for account
  const [selectedButton, setSelectedButton] = useState('Customer Experience');

  const handleButtonClick = (buttonName) => {
    setSelectedButton(buttonName);
  };

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    password: '',
    contactNumber: '',
    location: '',
    city: '',
    // country: "Spain",
    postalCode: '',
    // maxVotes: 0,
    language: profile.defaultLanguage || '', // Set default value from profile.language
    question:
      profile.employeeQuestion && selectedButton === 'Customer Experience'
        ? profile.employeeQuestion.question
        : profile.customerQuestion?.question || '',
    description: '',
  });

  const {
    name,
    email,
    password,
    contactNumber,
    location,
    city,
    // country,
    postalCode,
    // maxVotes,
    language,
    question,
    description,
  } = formData;

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  //================
  let [finalCusReasonVal, setFinalCusReasonVal] = useState([]);
  let [finalEmpReasonVal, setFinalEmpReasonVal] = useState([]);

  useEffect(() => {
    const customerExperienceReasons = allReasons?.filter(
      (reason) => reason.type === 'Customer Experience'
    );
    const employeeSentimentReasons = allReasons?.filter(
      (reason) => reason.type === 'Employee Sentiment'
    );

    // console.log('customer experience', customerExperienceReasons);

    let custTemp = [];
    let empTemp = [];

    if (customerExperienceReasons.length !== 0) {
      for (let i = 0; i < customerExperienceReasons.length; i++) {
        customerExperienceReasons[i].reasons.map((item) => {
          if (item.language === language) {
            custTemp.push(item.reason);
          }
        });
      }
      setFinalCusReasonVal(custTemp);
    }

    if (employeeSentimentReasons.length !== 0) {
      for (let i = 0; i < employeeSentimentReasons.length; i++) {
        employeeSentimentReasons[i].reasons.map((item) => {
          if (item.language === language) {
            empTemp.push(item.reason);
          }
        });
      }
      setFinalEmpReasonVal(empTemp);
    }
  }, [allReasons, language]);

  //=======================Reason Question Added by Comapny alex===========
  const [questionReason, setQuestionReason] = useState('');

  useEffect(() => {
    if (allQuestionReason && allQuestionReason.length !== 0) {
      const foundLanguage = allQuestionReason[0]?.reasonQuestion.find(
        (reason) => reason.language === language
      );

      if (foundLanguage) {
        setQuestionReason(foundLanguage.Qreason);
      }
    }
  }, [allQuestionReason, language]);
  // let reasonQ = allQuestionReason && allQuestionReason.length !==0 &&

  const handleCreateDTP = async (e) => {
    e.preventDefault();
    console.log('****');
    if (selectedButton === 'Customer Experience' && !customerDefaultQues) {
      return toast.error('Please select customer experience question');
    }
    if (selectedButton === 'Employee Sentiment' && !employeeDefaultQues) {
      return toast.error('Please select employee sentiment question');
    }
    let body = {
      verificationCode: verificationCode,
      name,
      email,
      password,
      contactNumber,
      location,
      city,
      country: selectedCountry,
      postalCode,
      maxVotes: profile && profile.selectedSubscription?.maxVoteAllowed,
      language,
      question:
        selectedButton === 'Customer Experience'
          ? customerDefaultQues
          : employeeDefaultQues,
      reasonQuestion: questionReason,
      reasons:
        selectedButton === 'Customer Experience'
          ? finalCusReasonVal
          : finalEmpReasonVal,
      description,
      dtpType: selectedButton,
      defaultLanguage: profile.defaultLanguage || '',
    };
    console.log('handle create dtp***', { body });
    dispatch(createBusinessDTP(body));
  };

  //=============Add New Datatouch point END=============================

  //============Handler for update Data Touchpoint status to Active===========
  const handleDTPStatus = (e, id) => {
    e.preventDefault();
    let body = {
      verificationCode: verificationCode,
      dataTPId: id,
    };
    dispatch(approveDTPStatus(body));
  };
  console.log({
    finalCusReasonVal,
    finalEmpReasonVal,
    questionReason,
    profile,
  });

  //==========Handle Delete Datatouch point =================================
  const handleDeleteDTP = (event, id) => {
    event.preventDefault();
    let body = {
      verificationCode: verificationCode,
      DtpId: id,
    };
    dispatch(deleteDTP(body));
  };

  //============Handle Get More Data touch points===================
  const [addMoreDTP, setAddMoreDTP] = useState(false);
  const handleCloseAddMoreDTP = () => setAddMoreDTP(false);
  const handleShowAddMoreDTP = () => setAddMoreDTP(true);

  const [subTotalPrice, setSubTotalPrice] = useState(0);
  const [DTPNumber, setDTPNumber] = useState(0);

  // Calculate total value based on the selected sticker size and units
  const calculateTotalSubVal = () => {
    const selectedSub =
      allSubscriptions &&
      allSubscriptions.find(
        (sub) => sub._id === profile?.selectedSubscription?._id
      );
    const subPrice = selectedSub ? selectedSub.priceAfterVat : 0;
    const totalVal = parseFloat(subPrice) * parseFloat(DTPNumber);
    setSubTotalPrice(isNaN(totalVal) ? 0 : totalVal);
  };

  useEffect(() => {
    calculateTotalSubVal();
  }, [profile?.selectedSubscription?._id, DTPNumber]);

  const handleRequestMoreDTP = (event) => {
    event.preventDefault();
    if (DTPNumber === 0) {
      return toast.warning('Please enter number for datatouchpoint.');
    }
    let body = {
      verificationCode: verificationCode,
      units: Number(DTPNumber),
      selectedSubscription:
        profile?.selectedSubscription && profile?.selectedSubscription._id,
      totalPrice: subTotalPrice,
      reqType: 'Data TouchPoint',
    };
    dispatch(addMoreDTPReq(body));
  };

  //==========================Add User to  DTP Start======================
  const [addUserDTPId, setAddUserDTPId] = useState('');
  const [showAddUserModal, setShowAddUserModal] = useState(false);
  const [accessEmails, setAccessEmails] = useState([]);
  const [PasswordVisible2, setPasswordVisible2] = useState(true);
  const [PasswordVisible, setPasswordVisible] = useState(true);
  const [addUserFormValue, setAddUserFormValue] = useState({
    addUserEmail: '',
    addUserPassword: '',
  });

  const { addUserEmail, addUserPassword } = addUserFormValue;

  const handleChangeAddUser = (e) => {
    setAddUserFormValue({
      ...addUserFormValue,
      [e.target.name]: e.target.value,
    });
  };

  const handleAddUserDTP = (id, accessEmails) => {
    setAccessEmails(accessEmails);
    setAddUserDTPId(id);
    setShowAddUserModal(true);
  };

  const handleAddUserToDTP = (e) => {
    e.preventDefault();
    if (addUserEmail === '') {
      return toast.warning('Please enter email for datatouchpoint.');
    }
    if (addUserPassword === 0) {
      return toast.warning('Please enter password for datatouchpoint.');
    }
    let body = {
      verificationCode: verificationCode,
      email: addUserEmail,
      password: addUserPassword,
      id: addUserDTPId,
    };
    dispatch(addUserToDTP(body));
  };

  //==========================Add User to  DTP end========================

  //==============Delete User from DTP START======================
  const handleDeleteDTPUser = (e, userEmail) => {
    e.preventDefault();

    let body = {
      verificationCode: verificationCode,
      email: userEmail,
      id: addUserDTPId,
    };
    dispatch(deleteUserToDTP(body));
  };
  //==============Delete User from DTP END========================

  //=========View Business Data ==============
  const handleDTPView = (arg) => {
    navigate(`/company/datatouchpoint/${arg}`);
  };

  //==========================Table======================//

  //-------------------DATA TABLE START ---------------------------------
  const [pending, setPending] = useState(true);

  //====Search Field
  const [searchQuery, setSearchQuery] = useState('');

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      setPending(false);
    }, 500);
    return () => clearTimeout(timeout);
  }, []);

  const actions = [
    {
      icon: <FontAwesomeIcon className='btn btnPrint p-0 fs-6' icon={faEye} />,
      tooltip: 'View',
      onClick: (event, rowData) => handleDTPView(rowData?.slug),
    },
    {
      icon: (
        <FontAwesomeIcon
          className='btn btnPrint p-0 ms-2 fs-6'
          icon={faTrash}
        />
      ),
      tooltip: 'Delete',
      onClick: (event, rowData) => handleDeleteDTP(event, rowData._id),
    },
  ];

  function parseEmbeddedCode(embeddedCode) {}

  const DataTableActionButtons = ({ actions, rowData }) => (
    <>
      {rowData?.approvedDTP !== true && (
        <button
          type='button'
          className='btn btnPrint fs-6'
          onClick={(e) => handleDTPStatus(e, rowData?._id)}
          title='Active'
        >
          <FontAwesomeIcon icon={faCheck} />{' '}
        </button>
      )}
      {actions.map((action, index) => (
        <button
          className='btn btn-sm'
          key={index}
          onClick={(event) => action.onClick(event, rowData)}
        >
          {action.icon}
        </button>
      ))}
    </>
  );

  //==========alex
  // const parseIntoPlain = (htmlString) => {
  //   // Create a temporary div element to parse the HTML
  //   const tempDiv = document.createElement("div");
  //   tempDiv.innerHTML = htmlString;

  //   // Extract the desired elements and content
  //   const h1Content = tempDiv.querySelector("h1").textContent;
  //   const images = Array.from(tempDiv.querySelectorAll("img"));
  //   const links = Array.from(tempDiv.querySelectorAll("a"));

  //   // Create the desired format
  //   // Create the desired format
  //   let desiredFormat = `${h1Content}\n`;

  //   for (let i = 0; i < images.length; i++) {
  //     const imgSrc = images[i].getAttribute("src");
  //     const linkText = links[i].textContent;

  //     // Construct the desired format with the image and link text
  //     desiredFormat += `<img src="${imgSrc}" alt="${linkText}" />`;
  //   }
  //   return desiredFormat;
  // };

  const columns = [
    {
      name: 'Name',
      selector: (row) => row.name,
      sortable: true,
      center: true,
      width: '180px',
    },

    {
      name: 'Email',
      selector: (row) => row.email,
      sortable: true,
      center: true,
      width: '200px',
    },
    {
      name: 'Description',
      selector: (row) => row.description,
      sortable: true,
      center: true,
      width: '200px',
    },
    {
      name: 'Status',
      selector: (row) =>
        row?.approvedDTP === true ? (
          <span className='text-success badge p-1'>Active</span>
        ) : (
          <span className='text-danger badge p-1'>Block</span>
        ),
      sortable: true,
      center: true,
    },
    {
      name: 'Measuring',
      selector: (row) => <span className='text-capitalize'>{row.dtpType}</span>,
      sortable: true,
      center: true,
      width: '180px',
    },
    {
      name: 'Total Votes',
      selector: (row) => row?.totalReviewsCount,
      sortable: true,
      center: true,
      width: '210px',
    },
    {
      name: 'Creation Date',
      selector: (row) => moment(row.createdAt).format('DD-MM-YYYY'),
      sortable: true,
      center: true,
      width: '170px',
    },
    {
      name: 'Collect Reviews',
      width: '300px',

      selector: (row) => (
        <>
          <button
            className='btn btn-sm border'
            onClick={() => {
              handleImageClick(row.qrCode, row?.slug, row.dtpType, row?.name);
            }}
          >
            <BsQrCodeScan />
          </button>
          <button
            className='btn btn-sm border mx-2'
            onClick={() => {
              navigator.clipboard.writeText(row?.embededCode);
              // navigator.clipboard.writeText(parseIntoPlain(row?.embededCode));
              toast.success('Embeded code Copied Successfully');
            }}
          >
            {/* <BiLinkAlt /> */}
            <FaCode />
          </button>
          <button
            className='btn btn-sm border'
            onClick={() => {
              navigator.clipboard.writeText(row?.liveUrlNPS);
              toast.success('Live NPS url Copied Successfully');
            }}
          >
            <img
              src={'/assets/dashboard.png'}
              style={{
                width: '18px',
                height: '18px',
                cursor: 'pointer',
              }}
            />
          </button>
        </>
      ),
      center: true,
    },
    {
      name: 'Request Stickers',
      selector: (row) => (
        <button
          type='button'
          className='btn btnPrint'
          onClick={() => {
            handleRequestTempShow(row._id, row.dtpType);
            setPaymentMethodForSticker(row?.business?.paymentMethod);
          }}
        >
          <BiSticker />
          {/* <FontAwesomeIcon icon={faPrint} /> */}
        </button>
      ),
      center: true,
      width: '180px',
    },
    {
      name: 'Add User',
      selector: (row) => (
        <button
          type='button'
          className='btn btnPrint'
          onClick={() => handleAddUserDTP(row._id, row?.accessEmails)}
        >
          <FontAwesomeIcon icon={faUserPlus} />
        </button>
      ),
      center: true,
      width: '180px',
    },
    {
      name: 'Actions',
      cell: (row) => <DataTableActionButtons actions={actions} rowData={row} />,
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
      center: true,
    },
  ];

  //=======Export to CSV============
  function convertArrayOfObjectsToCSV(array) {
    let result;

    const columnDelimiter = ',';
    const lineDelimiter = '\n';
    const keys = Object.keys(allBusinessDTP?.dataTouchPoints[0]);

    result = '';
    result += keys.join(columnDelimiter);
    result += lineDelimiter;

    array.forEach((item) => {
      let ctr = 0;
      keys.forEach((key) => {
        if (ctr > 0) result += columnDelimiter;

        result += item[key];

        ctr++;
      });
      result += lineDelimiter;
    });

    return result;
  }

  function downloadCSV(array) {
    const link = document.createElement('a');
    let csv = convertArrayOfObjectsToCSV(array);
    if (csv == null) return;

    const filename = 'export.csv';

    if (!csv.match(/^data:text\/csv/i)) {
      csv = `data:text/csv;charset=utf-8,${csv}`;
    }

    link.setAttribute('href', encodeURI(csv));
    link.setAttribute('download', filename);
    link.click();
  }

  const Export = ({ onExport }) => (
    <div className='btn btn-secondary mb-2 mx-2 ' id='DataForExport'>
      <Link onClick={(e) => onExport(e.target.value)} id='DataForExport'>
        <FontAwesomeIcon icon={faDownload} className='btnIcon' />
        <Trans>Export</Trans>
      </Link>
    </div>
  );
  const actionsMemo = React.useMemo(
    () => (
      <Export
        id='DataForExport'
        onExport={() => downloadCSV(allBusinessDTP?.dataTouchPoints)}
      />
    ),
    []
  );

  //  Internally, customStyles will deep merges your customStyles with the default styling.
  const customStyles = {
    rows: {
      style: {
        minHeight: '60px',
      },
    },
    headCells: {
      style: {
        fontSize: '14px',
        color: '#B5B7C0',
        textAlign: 'center',
      },
    },
    cells: {
      style: {
        textAlign: 'center', // Align regular cell content to center
        color: '#292D32 !important',
        fontWeight: '600',
      },
    },
  };

  // Apply the filter based on the input text
  const filteredData = searchQuery
    ? allBusinessDTP?.dataTouchPoints.filter(
        (item) =>
          item.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
          item.email.toLowerCase().includes(searchQuery.toLowerCase())
      )
    : allBusinessDTP?.dataTouchPoints;

  //Search Field END

  //-------------------DATA TABLE END---------------------------------

  //===========Reset Data on Success =====================
  useEffect(() => {
    if (isSuccess) {
      setFormData({
        name: '',
        email: '',
        password: '',
        contactNumber: '',
        location: '',
        city: '',
        // country: "Spain",
        postalCode: '',
        // maxVotes: 0,
        language: '',
        question: '',
        description: '',
      });
      setAddUserFormValue({
        addUserEmail: '',
        addUserPassword: '',
      });
      setSelectedButton('Customer Experience');
      setReasonsVal('');
      setCustomerDefaultQues('');
      setEmployeeDefaultQues('');
      setShowAddDTPModal(false);
      // setOptionReasons([]);
      setShowAddUserModal(false);

      dispatch(reset());
    }
  }, [isSuccess]);
  //===========Reset Data on Success =====================
  useEffect(() => {
    if (isStickerRequestSuccess) {
      setStickerVales({
        templateId: '',
        stickerSize: '',
        units: 0,
        shippingAddress: '',
        stickerCity: '',
        // stickerCountry: "Spain",
        stickerPostalCode: '',
      });
      setRequestType('');
      setDTPId('');
      setTotalPrice(0);

      handleRequestTempClose();
      dispatch(sticketRequestReset());
    }
  }, [isStickerRequestSuccess]);

  useEffect(() => {
    if (isAdditionalDtpRequestSuccess) {
      setDTPNumber(0);
      handleCloseAddMoreDTP();
      navigate('/company/shop');
    }
    dispatch(additionalReqReset());
  }, [isAdditionalDtpRequestSuccess, navigate]);

  useEffect(() => {
    dispatch(getAllSubReq({ verificationCode }));
    return () => {};
  }, []);

  function handlerGetDTP() {
    if (allSubscriptions) {
      let number = allSubRequests
        .map((item, index) => {
          if (item?.reqType === 'Touchpoints') {
            if (new Date(item?.selectedSubscription?.endDate) > new Date()) {
              return item?.selectedSubscription?.maxVoteAllowed || 0;
            }
          }
          return 0; // return 0 for items that don't meet the condition
        })
        .reduce((sum, maxVoteAllowed) => sum + maxVoteAllowed, 0);
      return number;
    }
  }
  //=========Computed value for the subscription DTP used START=============
  // const allowedDTP = useMemo(() => {
  //   return profile?.selectedSubscription?.allowedDTP ?? 0;
  // }, [profile?.selectedSubscription]);
  const totalDTPReq = useMemo(() => {
    return userData?.user?.totalDTPReq ?? 0;
  }, [userData?.user]);

  // const result = allowedDTP + totalDTPReq;
  //=========Computed value for the subscription DTP used END=============

  //=====  Download Image QR code =====

  const downLoadQrCode = (tempImg) => {
    const downloadLink = document.createElement('a');
    downloadLink.href = REACT_APP_BASE_URL_QR_CODE + tempImg;
    downloadLink.download = tempImg;
    downloadLink.click();
  };

  const { language: _language, setLanguage } = useMyContext();
  const ref = createRef(null);
  const [image, takeScreenshot] = useScreenshot();
  const getImage = () => takeScreenshot(ref.current);
  const sendData = () => {
    const node = document.getElementById('capture');
    // Adding a delay of 1000 milliseconds (adjust as needed)
    setTimeout(() => {
      domtoimage
        .toPng(node)
        .then(function (dataUrl) {
          // setIsModalOpen(false);
          const downloadLink = document.createElement('a');
          downloadLink.href = dataUrl;
          downloadLink.download = 'goRate.io.jpg';
          downloadLink.click();
          setIsShowQRImage(false);
        })
        .catch(function (error) {
          console.error('Error capturing image:', error);
        });
    }, 100);
  };

  const [MaxVote, setMaxVote] = useState();
  const [MaxReviews, setMaxReviews] = useState();

  const [customerDefaultQues, setCustomerDefaultQues] = useState('');
  const [employeeDefaultQues, setEmployeeDefaultQues] = useState('');

  const handleCusDefaultQues = (e) => {
    setCustomerDefaultQues(e.target.value);
    if (e.target.value === '') {
      return toast.error('Please select a default question');
    } else {
      const question = allQuestions.find((q) => q._id === e.target.value);
      setCustomerDefaultQues(question);
    }
  };

  const handleEmpDefaultQues = (e) => {
    setEmployeeDefaultQues(e.target.value);
    if (e.target.value === '') {
      return toast.error('Please select a default question');
    } else {
      const question = allQuestions.find((q) => q._id === e.target.value);
      setEmployeeDefaultQues(question);
    }
  };

  useEffect(() => {
    if (allBusinessDTP) {
      let newValue = 0;
      let newValueReview = 0;
      for (let i = 0; i < allSubscriptions.length; i++) {
        if (allSubscriptions[i].subscriptionType === 'Touchpoints') {
          newValue = +newValue + allSubscriptions[i].maxVoteAllowed;
        }
      }
      for (let i = 0; i < allBusinessDTP?.dataTouchPoints.length; i++) {
        newValueReview =
          newValueReview + allBusinessDTP?.dataTouchPoints[i].totalReviewsCount;
      }

      setMaxVote(newValue);
      setMaxReviews(newValueReview);
    }

    return () => {};
  }, [allBusinessDTP]);

  return (
    <>
      <div className='container-fluid my-3'>
        <div className='row'>
          <div className='col-md-3'>
            <h3 className=' mt-1 fw-500 text-nowrap'>
              <Trans>Data Touchpoints</Trans>
            </h3>
          </div>
          <div className='col-md-4'>
            <div className='search-field search-bar'>
              <input
                type='text'
                s
                placeholder={_language === 'en' ? 'Search' : 'Búsqueda'}
                className='dashboardinput'
                id='userSearch'
                value={searchQuery}
                onChange={handleSearchChange}
              />
              <span className='search-icon'>
                <FontAwesomeIcon icon={faSearch} className='usersearch' />
              </span>
            </div>
          </div>
          <div className='col-md-5'>
            <div className='row g-3'>
              <div className='col-md-4'>
                <Link
                  className='btn btn-primary text-nowrap w-100'
                  onClick={handleShowAddDTPModal}
                >
                  <FontAwesomeIcon icon={faPlus} className='btnIcon' />{' '}
                  Touchpoint
                </Link>
              </div>
              <div className='col-md-4'>
                <Link to='#' className='btn btn-secondary w-100 text-nowrap'>
                  <FontAwesomeIcon icon={faUpload} className='btnIcon' />
                  <Trans>Upload</Trans>
                </Link>
              </div>
              <div className='col-md-4'>
                <div className='w-100'>
                  <ExportCSVButton
                    data={allBusinessDTP?.dataTouchPoints}
                    filename={'exported_data.csv'}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='row mt-2 '>
          <div className='col-md-4'>
            <div className='d-flex'>
              <h6 className='threeOut'>
                {(allBusinessDTP?.dataTouchPoints || []).reduce(
                  (sum, dtp) => sum + (dtp.totalReviewsCount || 0),
                  0
                )}{' '}
                /
                <span className='ms-1'>
                  {allSubRequests
                    .map((item, index) => {
                      if (item?.reqType === 'Touchpoints') {
                        if (
                          new Date(item?.selectedSubscription?.endDate) >
                          new Date()
                        ) {
                          return (
                            item?.selectedSubscription?.maxVoteAllowed || 0
                          );
                        }
                      }
                      return 0; // return 0 for items that don't meet the condition
                    })
                    .reduce((sum, maxVoteAllowed) => sum + maxVoteAllowed, 0)}
                </span>
              </h6>

              {/* {profile?.selectedSubscription &&
                profile?.selectedSubscription.subscriptionType && (
                  <Link
                    className="getMore ms-3"
                    onClick={() => handleShowAddMoreDTP()}
                  >
                    Get Additional
                  </Link>
                )} */}
            </div>
          </div>
          {allBusinessDTP?.dataTouchPoints?.length > 0 && (
            <div className='col-md-4'>
              {parseInt(
                (allBusinessDTP?.dataTouchPoints || []).reduce(
                  (sum, dtp) => sum + (dtp.totalReviewsCount || 0),
                  0
                )
              ) === parseInt(handlerGetDTP()) && (
                <Alert className='p-1' variant='danger'>
                  Maximum limit exceed please subscribe from{' '}
                  <Link to={'/company/shop'}>here</Link>{' '}
                </Alert>
              )}
            </div>
          )}
        </div>
        <div className='row'>
          <div className='col-md-12'>
            <div
              className='mt-2 outerWrapperTable'
              style={{ overflowX: 'auto' }}
            >
              {allBusinessDTP?.dataTouchPoints && (
                <DataTable
                  columns={columns}
                  data={filteredData}
                  progressPending={pending}
                  actions={actionsMemo}
                  progressComponent={<Loader />}
                  customStyles={customStyles}
                  searchText={searchQuery} // Pass searchText
                  onSearch={setSearchQuery} // Pass setSearchText
                  pagination
                />
              )}
            </div>
          </div>
        </div>
      </div>

      {/* MODALS START */}

      {/* //======================ADD MORE DTP REQUEST Start ====================== */}
      <Modal show={addMoreDTP} onHide={handleCloseAddMoreDTP}>
        <Modal.Header closeButton>
          <Modal.Title>
            Add More <Trans>Data Touchpoints</Trans>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='row'>
            <div className='col-md-12'>
              <label className='form-label'>Subscription</label>
              <select
                className='form-select'
                defaultValue={profile?.selectedSubscription?._id || ''}
              >
                {allSubscriptions &&
                  allSubscriptions
                    .filter(
                      (sub) => sub?._id === profile?.selectedSubscription?._id
                    )
                    .map((sub, i) => (
                      <option value={sub._id} key={i}>
                        {sub?.name} (
                        <span className='text-success'>{`Unit Price ${sub?.perUnitPrice}`}</span>
                        )
                      </option>
                    ))}
              </select>
            </div>
            <div className='col-md-12'>
              <div className='form-group'>
                <label className='form-label'>Number of Data touchpoints</label>
                <input
                  type='number'
                  className='form-control'
                  placeholder='Enter number of datatouchpoints'
                  name='DTPNumber'
                  value={DTPNumber}
                  onChange={(e) => setDTPNumber(e.target.value)}
                />
              </div>
            </div>
            <div className='col-md-6 mx-auto mt-3'>
              <h3 className='text-center fw-500'>Total : € {subTotalPrice}</h3>
            </div>

            <div className='mx-auto d-flex justify-content-center my-3'>
              <button
                className='btn btn-sm btn-primary'
                onClick={handleRequestMoreDTP}
              >
                <Trans>Submit</Trans>
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      {/* //======================ADD MORE DTP REQUEST End ====================== */}
      {/* //======================CREATE datatouch Point Start ====================== */}
      <Modal size='lg' show={showAddDTPModal} onHide={handleCloseAddDTPModal}>
        <Modal.Header closeButton>
          <Modal.Title>Add Data Touchpoint</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='container-fluid'>
            <form onSubmit={handleCreateDTP}>
              <div className='row'>
                {(!profile?.customerQuestion ||
                  !profile?.employeeQuestion ||
                  allReasons?.length === 0) && (
                  <div className='col-md-12'>
                    <Alert className='p-1'>
                      Remember to select the Question and create the Reasons
                      (Customer & Employee) for the vote in Settings
                    </Alert>
                  </div>
                )}

                <div className='col-md-6'>
                  <div className='form-group'>
                    <label className='form-label'>Touchpoint Name</label>
                    <input
                      type='text'
                      className='form-control'
                      placeholder='Enter name'
                      name='name'
                      value={name}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className='col-md-6'>
                  <div className='form-group'>
                    <label className='form-label'>Email</label>
                    <input
                      type='email'
                      className='form-control'
                      placeholder='Enter email'
                      name='email'
                      value={email}
                      onChange={handleChange}
                    />
                  </div>
                </div>
              </div>
              <div className='row'>
                <div className='col-md-6'>
                  <div className='form-group position-relative'>
                    <label className='form-label'>Password</label>
                    <input
                      type={PasswordVisible ? 'password' : 'text'}
                      name='password'
                      value={password}
                      onChange={handleChange}
                      className='form-control'
                    />
                    {PasswordVisible ? (
                      <img
                        src='/assets/icons/EyeView.svg'
                        alt='DropDownIcon'
                        className='DropDownIconsInputField DatePickerDropDown c_pointer EyeIconsInAddressInSignUp'
                        onClick={() => {
                          setPasswordVisible(!PasswordVisible);
                        }}
                      />
                    ) : (
                      <img
                        src='/assets/icons/EyeViewNot.svg'
                        alt='DropDownIcon'
                        className='DropDownIconsInputField DatePickerDropDown c_pointer EyeIconsInAddressInSignUp'
                        onClick={() => {
                          setPasswordVisible(!PasswordVisible);
                        }}
                      />
                    )}
                  </div>
                </div>
                <div className='col-md-6'>
                  <div className='form-group'>
                    <label className='form-label'>
                      <Trans>Contact number</Trans>
                    </label>
                    <input
                      type='text'
                      name='contactNumber'
                      value={contactNumber}
                      onChange={handleChange}
                      className='form-control'
                    />
                  </div>
                </div>
                <div className='col-md-6'>
                  <div className='form-group'>
                    <label className='form-label'>
                      <Trans>Address</Trans>
                    </label>
                    <input
                      type='text'
                      name='location'
                      value={location}
                      onChange={handleChange}
                      className='form-control'
                    />
                  </div>
                </div>
                <div className='col-md-6'>
                  <div className='form-group'>
                    <label className='form-label'>Country</label>
                    <select
                      className='form-select'
                      name='selectedCountry'
                      value={selectedCountry}
                      onChange={handleCountryChange}
                    >
                      <option value=''>
                        <Trans>Select from list</Trans>...
                      </option>
                      {getCountries?.countries?.map((country) => (
                        <option
                          key={country.countryId}
                          value={country.countryId}
                        >
                          {country.country}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className='col-md-6'>
                  <div className='form-group'>
                    <label className='form-label'>City</label>{' '}
                    {isCitiesLoading ? (
                      <div className='form-control d-flex justify-content-center'>
                        <ReactLoading
                          type={'spinningBubbles'}
                          color={'#000000'}
                          height={24}
                          width={17}
                        />
                      </div>
                    ) : (
                      <select
                        className='form-control'
                        value={city}
                        name='city'
                        onChange={handleChange}
                      >
                        <option value=''>
                          <Trans>Select from list</Trans>...
                        </option>
                        {getCities?.map((city, index) => (
                          <option key={index} value={city?._id}>
                            {city?.name_of_city}
                          </option>
                        ))}
                      </select>
                    )}
                  </div>
                </div>
                <div className='col-md-6'>
                  <div className='form-group'>
                    <label className='form-label'>
                      <Trans>Postal Code</Trans>
                    </label>
                    <input
                      type='text'
                      name='postalCode'
                      value={postalCode}
                      onChange={handleChange}
                      className='form-control'
                    />
                  </div>
                </div>

                {/* <div className="col-md-3">
                  <div className="form-group">
                    <label className="form-label text-nowrap">Max. Votes</label>
                    <input
                      type="number"
                      name="maxVotes"
                      value={maxVotes}
                      onChange={handleChange}
                      className="form-control"
                    />
                  </div>
                </div> */}

                <div className='col-md-6 d-none'>
                  <div className='form-group'>
                    <label className='form-label'>
                      <Trans>Select Language</Trans>
                    </label>{' '}
                    <select
                      className='form-control'
                      value={language}
                      name='language'
                      onChange={handleChange}
                    >
                      <option>
                        <Trans>Select from list</Trans>...
                      </option>
                      <option value='English'>English</option>
                      <option value='Italian'>Italian</option>
                      <option value='French'>French</option>
                      <option value='Spanish'>Spanish</option>
                      <option value='Portuguese'>Portuguese</option>
                    </select>
                  </div>
                </div>
                <div className='col-md-12  d-none'>
                  <div className='form-group'>
                    <label className='form-label'>Select Question</label>
                    <select
                      className='form-control'
                      value={question}
                      name='question'
                      onChange={handleChange}
                    >
                      <option>
                        <Trans>Select from list</Trans>...
                      </option>
                      {allQuestions &&
                        allQuestions
                          .filter((question) => question.language === language)
                          .map((question) => (
                            <option
                              key={question._id}
                              value={question.question}
                            >
                              {question.question}{' '}
                              <span>({question.language})</span>
                            </option>
                          ))}
                    </select>
                  </div>
                </div>

                {/* d-none */}
                <div className='col-md-12 d-none'>
                  <div className='form-group mb-2'>
                    <label className='form-label'>Select Reason</label>{' '}
                    <Select
                      id='multiple-select-field'
                      isMulti
                      // options={optionReasons}
                      onChange={handleReasonOptChange}
                      value={reasonsVal}
                    />
                  </div>
                </div>
              </div>

              <div className='row'>
                <div className='col-md-12'>
                  <div className='form-group'>
                    <label className='form-label'>Description</label>
                    <textarea
                      className='form-control'
                      name='description'
                      value={description}
                      onChange={handleChange}
                      rows={3}
                      placeholder='Enter description'
                    />
                  </div>
                </div>
              </div>

              <div className='row my-3'>
                <label className='form-label mb-2'>
                  <Trans>Measuring</Trans>
                </label>
                <div className='d-flex justify-content-left align-items-start'>
                  <button
                    type='button'
                    className={`btn btn-logfeedBack rounded-pill ${
                      selectedButton == 'Customer Experience'
                        ? 'selected'
                        : 'unSelected'
                    }`}
                    onClick={() => handleButtonClick('Customer Experience')}
                  >
                    <Trans> Customer Experience</Trans>
                  </button>
                  <button
                    type='button'
                    className={`btn btn-logfeedBack rounded-pill ms-3 ${
                      selectedButton == 'Employee Sentiment'
                        ? 'selected'
                        : 'unSelected'
                    }`}
                    onClick={() => handleButtonClick('Employee Sentiment')}
                  >
                    <Trans>EmployeeSentiment</Trans>
                  </button>
                </div>
              </div>
              {selectedButton === 'Customer Experience' ? (
                <div className='col-12 col-md-12'>
                  <label className='form-label fw-bold text-nowrap'>
                    <Trans>Customer Experience Voting Question</Trans>
                  </label>
                  <select
                    className='form-select'
                    value={customerDefaultQues?._id || ''}
                    onChange={handleCusDefaultQues}
                  >
                    <option value=''>
                      <Trans>Select from list</Trans>...
                    </option>
                    {allQuestions &&
                      allQuestions.map((question) => {
                        if (
                          question.inputType === 'Customer Experience'
                          // && question.language === defaultLanguage
                        ) {
                          return (
                            <option key={question._id} value={question._id}>
                              {question.question}{' '}
                              <span>({question.language})</span>
                            </option>
                          );
                        }
                        return null;
                      })}
                  </select>
                </div>
              ) : (
                <div className='col-12 col-md-12'>
                  <label className='form-label fw-bold'>
                    <Trans>Employee Sentiment Voting Question</Trans>
                  </label>
                  <select
                    className='form-select'
                    value={employeeDefaultQues?._id || ''}
                    onChange={handleEmpDefaultQues}
                  >
                    <option value=''>
                      <Trans>Select from list</Trans>...
                    </option>
                    {allQuestions &&
                      allQuestions.map((question) => {
                        if (
                          question.inputType === 'Employee Sentiment'
                          // && question.language === defaultLanguage
                        ) {
                          return (
                            <option key={question._id} value={question._id}>
                              {question.question}{' '}
                              <span>({question.language})</span>
                            </option>
                          );
                        }
                        return null;
                      })}
                  </select>
                </div>
              )}

              <div className='d-flex justify-content-end mt03'>
                <div className='d-flex justify-content-between gap-2'>
                  <button
                    type='button'
                    className='btn btn-secondary w-100'
                    onClick={handleCloseAddDTPModal}
                  >
                    Cancel
                  </button>
                  {isLoadingCreateBusinessDPT ? (
                    <div>
                      {' '}
                      <ButtonLoading />
                    </div>
                  ) : (
                    <button
                      type='submit'
                      className='btn btn-primary d-block'
                      onClick={handleCreateDTP}
                      // disabled={
                      //   !profile?.customerQuestion || !profile?.employeeQuestion
                      // }
                    >
                      Create
                    </button>
                  )}
                </div>
              </div>
            </form>
          </div>
        </Modal.Body>
      </Modal>
      {/* //======================CREATE datatouch Point End ====================== */}

      {/* //======================Request QR code Stickers Start ====================== */}

      <Modal
        show={showRequestTempModal}
        onHide={handleRequestTempClose}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <Trans>Request QR Stickers</Trans>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {profile && !profile?.customerId && (
            <Alert className='p-1  text-center'>
              Please configure payment details in{' '}
              <Link
                to='/company/setting'
                style={{ textDecoration: 'underline' }}
              >
                settings
              </Link>{' '}
              .
            </Alert>
          )}
          {profile && profile?.customerId && (
            <Alert className='p-1  text-center'>
              Payment will be done automatically on Request
            </Alert>
          )}
          <form>
            <div className='row'>
              <div className='col-md-12'>
                <label className='form-label'>
                  <Trans>QR Template</Trans>
                </label>
                <select
                  className='form-select'
                  name='templateId'
                  value={templateId}
                  onChange={handleOnChangeSticker}
                >
                  <option>
                    <Trans>Select from list</Trans>...
                  </option>
                  {allQrCodeTemplates &&
                    allQrCodeTemplates
                      .filter((template) => template?.qrCodeType === reqType)
                      .map((template, i) => (
                        <option value={template._id} key={i}>
                          {template?.name}
                        </option>
                      ))}
                </select>
              </div>
            </div>
            <div className='row'>
              <div className='col-md-6'>
                <label className='form-label'>Sticker Size</label>
                <select
                  className='form-select'
                  name='stickerSize'
                  value={stickerSize}
                  onChange={handleOnChangeSticker}
                >
                  <option>
                    <Trans>Select from list</Trans>...
                  </option>
                  {allStickers &&
                    allStickers.map((sticker, i) => (
                      <option key={i} value={sticker?.size}>
                        {sticker?.size} (
                        <span className='text-success'>
                          Unit Price {sticker?.pricePlusVat}
                        </span>
                        ){' '}
                      </option>
                    ))}
                </select>
              </div>
              <div className='col-md-6'>
                <label className='form-label'>
                  <Trans>Units</Trans>
                </label>
                <input
                  type='number'
                  name='units'
                  value={units}
                  onChange={handleOnChangeSticker}
                  className='form-control'
                />
              </div>
            </div>

            <div className='row'>
              <div className='col-md-12'>
                <label className='form-label'>
                  <Trans>Shipping Address</Trans>
                </label>
                <input
                  type='text'
                  className='form-control'
                  name='shippingAddress'
                  value={shippingAddress}
                  onChange={handleOnChangeSticker}
                />
              </div>
              <div className='col-md-6'>
                <div className='form-group'>
                  <label className='form-label'>Country</label>
                  <select
                    className='form-select'
                    name='selectedCountry'
                    value={selectedCountry}
                    onChange={handleCountryChange}
                  >
                    <option value=''>
                      <Trans>Select from list</Trans>...
                    </option>

                    {AllCountry?.map((country) => (
                      <option key={country.country} value={country.country}>
                        {country.country}
                      </option>
                    ))}
                    {getCountries?.countries?.map((country) => (
                      <option key={country.countryId} value={country.countryId}>
                        {country.country}
                      </option>
                    ))}
                  </select>
                </div>
              </div>

              <div className='col-md-6'>
                <div className='form-group'>
                  <label className='form-label'>City</label>
                  {isCitiesLoading ? (
                    <div className='form-control d-flex justify-content-center'>
                      <ReactLoading
                        type={'spinningBubbles'}
                        color={'#000000'}
                        height={24}
                        width={17}
                      />
                    </div>
                  ) : (
                    <select
                      className='form-select'
                      name='stickerCity'
                      value={stickerCity}
                      onChange={handleOnChangeSticker}
                    >
                      <option value=''>
                        <Trans>Select from list</Trans>...
                      </option>
                      {cities.map((city, index) => (
                        <option key={index} value={city}>
                          {city}
                        </option>
                      ))}
                      {getCities?.map((city, index) => (
                        <option key={index} value={city?._id}>
                          {city?.name_of_city}
                        </option>
                      ))}
                    </select>
                  )}
                </div>
              </div>
            </div>
            <div className='row'>
              <div className='col-md-6'>
                <label className='form-label'>
                  <Trans>Postal Code</Trans>
                </label>
                <input
                  type='text'
                  className='form-control'
                  name='stickerPostalCode'
                  value={stickerPostalCode}
                  onChange={handleOnChangeSticker}
                />
              </div>
              <div className='col-md-6'>
                <label className='form-label'>
                  <Trans>Total Price</Trans>
                </label>
                <h3 className='text-center text-nowrap fw-600'>
                  Total : € {totalPrice}
                </h3>
              </div>
            </div>
            <div className='row'>
              <div className='col-md-12'>
                <div className='d-flex justify-content-end'>
                  <div className='d-flex justify-content-between gap-2'>
                    <button
                      type='button'
                      className='btn btn-secondary w-100'
                      onClick={handleRequestTempClose}
                    >
                      Cancel
                    </button>
                    {isLoadingAddStickerRequest ? (
                      <div>
                        {' '}
                        <ButtonLoading />
                      </div>
                    ) : (
                      <div>
                        <Link
                          className='btn btn-primary w-100'
                          style={{ float: 'right' }}
                          onClick={handleRequestSticker}
                        >
                          {profile.paymentMethod === 'card' ? 'Card' : 'Bank'}
                        </Link>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </form>
        </Modal.Body>
      </Modal>
      {/* //======================Request QR Stickers code End ====================== */}

      {/* //======================QR Code View Start alex====================== */}
      <Modal show={isShowQRImage} onHide={() => setIsShowQRImage(false)}>
        <Modal.Header closeButton>
          <Modal.Title>QR Code</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            {selectedQRButton === 'TouchPoint QrCode' && (
              <div id='capture'>
                <div className='d-flex justify-content-center'>
                  <button
                    className='btn btn-primary btn-sm '
                    onClick={() => downLoadQrCode(qrCodeImage)}
                  >
                    <FontAwesomeIcon icon={faDownload} />{' '}
                  </button>
                </div>
                <img
                  src={REACT_APP_BASE_URL_QR_CODE + qrCodeImage}
                  alt='Selected Product'
                  className='d-block m-auto mb-5 img-responsive'
                />

                {dtpSlug ? (
                  <div className='row'>
                    <div className='d-flex'>
                      <input
                        className='form-control'
                        type='email'
                        placeholder={`${CLIENT_URL}/review/${dtpSlug}`}
                        defaultValue={`${CLIENT_URL}/review/${dtpSlug}`}
                        disabled={true}
                      />
                      <button
                        type='button'
                        className='btn'
                        onClick={() => {
                          navigator.clipboard.writeText(
                            `${CLIENT_URL}/review/${dtpSlug}`
                          );
                          toast.success('Voting url Copied Successfully');
                        }}
                      >
                        <FontAwesomeIcon icon={faCopy} />
                      </button>
                    </div>
                  </div>
                ) : (
                  <></>
                )}

                <div className='mx-auto d-flex justify-content-center my-3'>
                  <button
                    className='btn btn-sm btn-primary'
                    onClick={onClickSlug}
                  >
                    <Trans>Preview Link</Trans>
                  </button>
                </div>
              </div>
            )}
            {selectedQRButton === 'Template QrCode' && (
              <div id='capture'>
                {qrTemplate && (
                  <div className='' ref={componentRef}>
                    <div
                      className='section-to-print'
                      style={{
                        backgroundColor: qrTemplate.bgColor,
                      }}
                    >
                      <div className='text-center'>
                        <h6
                          style={{
                            color: qrTemplate?.fgColor,
                            fontSize: `${qrTemplate?.fgTextSize}px`,
                            overflow: 'hidden',
                          }}
                        >
                          {qrTemplate?.fgText}
                        </h6>
                        <div className='d-flex gap-2 p-3'>
                          <QRCode
                            value={`${CLIENT_URL}/review/${dtpSlug}`}
                            fgColor={qrTemplate?.fgColor}
                            bgColor={qrTemplate?.bgColor}
                            style={{
                              width: '100%',
                            }}
                          />
                          <h6
                            className='vertical-text vertical-text m-0'
                            style={{
                              color: qrTemplate?.fgColor,
                            }}
                          >
                            {Name}
                          </h6>
                        </div>
                        {qrTemplate?.companyLogo && (
                          <div className=''>
                            <img
                              src={BASE_URL_IMAGES + qrTemplate?.companyLogo}
                              // width={120}
                              // height={60}
                              alt='QR code'
                              className='img-fluid w-50 qr-logo-img'
                              // style={{
                              //   objectFit: "cover",
                              // }}
                            />
                          </div>
                        )}
                        <div className='mt-1'>
                          <h6
                            className='mb-0'
                            style={{
                              color: qrTemplate?.fgColor,
                            }}
                          >
                            Powered By
                          </h6>
                          <div className='text-center'>
                            <img
                              src={
                                qrTemplate?.poweredByLogo === 'white'
                                  ? logoWhite
                                  : qrTemplate?.poweredByLogo === 'blue'
                                  ? logoBlue
                                  : qrTemplate?.poweredByLogo === 'black'
                                  ? logoBlack
                                  : null
                              }
                              alt='Our Logo'
                              className='img-fluid w-20 text-center'
                              // style={{
                              //   objectFit: "cover",
                              // }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
        </Modal.Body>
        {selectedQRButton === 'Template QrCode' && (
          <Modal.Footer>
            <div className='d-flex justify-content-end'>
              <div className='d-flex justify-content-between gap-2'>
                <button
                  type='button'
                  className='btn btn-secondary w-100'
                  onClick={() => setIsShowQRImage(false)}
                >
                  Cancel
                </button>
                <button className='btn btn-primary' onClick={sendData}>
                  Download
                </button>
              </div>
            </div>
          </Modal.Footer>
        )}
      </Modal>
      {/* //======================QR Code View End ====================== */}

      {/* //======================Add User to DTP Start ====================== */}
      <Modal show={showAddUserModal} onHide={() => setShowAddUserModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Add User to Data Touchpoint</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <>
            <div className='row'>
              <div className='col-md-6'>
                <div className='form-group'>
                  <label className='form-label'>Email</label>
                  <input
                    type='email'
                    className='form-control'
                    placeholder='Enter email'
                    name='addUserEmail'
                    value={addUserEmail}
                    onChange={handleChangeAddUser}
                  />
                </div>
              </div>
              <div className='col-md-6'>
                <div className='form-group position-relative'>
                  <label className='form-label'>Password</label>
                  <input
                    type={PasswordVisible2 ? 'password' : 'text'}
                    className='form-control'
                    placeholder='Enter password'
                    name='addUserPassword'
                    value={addUserPassword}
                    onChange={handleChangeAddUser}
                  />
                  {PasswordVisible2 ? (
                    <img
                      src='/assets/icons/EyeView.svg'
                      alt='DropDownIcon'
                      className='DropDownIconsInputField DatePickerDropDown c_pointer EyeIconsInAddressInSignUp'
                      onClick={() => {
                        setPasswordVisible2(!PasswordVisible2);
                      }}
                    />
                  ) : (
                    <img
                      src='/assets/icons/EyeViewNot.svg'
                      alt='DropDownIcon'
                      className='DropDownIconsInputField DatePickerDropDown c_pointer EyeIconsInAddressInSignUp'
                      onClick={() => {
                        setPasswordVisible2(!PasswordVisible2);
                      }}
                    />
                  )}
                </div>
              </div>
            </div>
            <div className='d-flex justify-content-end'>
              <div className='d-flex justify-content-between gap-2'>
                <button
                  type='button'
                  className='btn btn-secondary w-100'
                  onClick={() => setShowAddUserModal(false)}
                >
                  Cancel
                </button>
                {isLoadingAddUserDTP ? (
                  <div>
                    {' '}
                    <ButtonLoading />
                  </div>
                ) : (
                  <button
                    className='btn btn-sm btn-primary'
                    onClick={handleAddUserToDTP}
                  >
                    <Trans>Save</Trans>
                  </button>
                )}
              </div>
            </div>
            {accessEmails.length !== 0 && (
              <div className='mt-4'>
                <h6>Access Email to Data Touchpoint</h6>
                {accessEmails.map((data, i) => (
                  <>
                    <div
                      class='col-sm-12 text-secondary d-flex justify-content-between'
                      key={i}
                    >
                      <span>{i + 1}.</span>
                      <span className=''>{data?.email}</span>
                      <span
                        className='rounded-circle text-white bg-danger text-center cursor-pointer'
                        onClick={(e) => handleDeleteDTPUser(e, data?.email)}
                        style={{
                          width: '20px',
                        }}
                      >
                        <FontAwesomeIcon
                          icon={faTrash}
                          style={{
                            fontSize: '14px',
                          }}
                        />
                      </span>
                      <br />{' '}
                    </div>
                    {i !== accessEmails.length - 1 && <hr />}
                  </>
                ))}
              </div>
            )}
          </>
        </Modal.Body>
      </Modal>
      {/* //======================QR Code View End ====================== */}
    </>
  );
};

export default CTouchPoints;
