import api from '../../utility/api';

// Add New  Questions
const addQuestion = async (data) => {
  const response = await api.post('/question/create', data);
  return response;
};

// Edit Question
const editQuestion = async (data) => {
  const response = await api.put('/question/update', data);
  return response;
};

// Get All Questions
const getAllQuestions = async (data) => {
  const response = await api.post('/question/getAll', data);
  return response;
};
// Get All Questions
const deleteQuestion = async (data) => {
  const response = await api.delete(`/question/${data.id}`);
  return response;
};
const questionService = {
  getAllQuestions,
  addQuestion,
  editQuestion,
  deleteQuestion,
};

export default questionService;
