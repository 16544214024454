import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import { Alert, Button, Modal } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useMyContext } from '../../../..';
import { Trans } from 'react-i18next';

//=========Redux===========
import { useSelector, useDispatch } from 'react-redux';
import {
  addSubscription,
  deleteSubscription,
  getAllSubscriptions,
  editSubscription,
  reset,
} from '../../../../redux/subscription/subscriptionSlice';
import moment from 'moment';
import { toast } from 'react-toastify';
import ButtonLoading from '../../../../common/ButtonLoading';

// import "./sub.css";

//========ENV VARIABLES========
const verificationCode = process.env.REACT_APP_VERIFICATION_TOKEN;

const Sub = () => {
  const dispatch = useDispatch();

  const { allBusinessData } = useSelector((state) => {
    return state.auth;
  });

  const { allSubscriptions, isSuccess, isLoadingAddSubscription } = useSelector(
    (state) => {
      return state.subscription;
    }
  );

  //============Get All Subscriptions =============================

  useEffect(() => {
    dispatch(getAllSubscriptions({ verificationCode }));
  }, [dispatch]);

  const [showSubModal, setShowSubModal] = useState(false);
  const handleCloseSubModal = () => setShowSubModal(false);
  const handleShowSubModal = () => setShowSubModal(true);

  //=================Add New subscription ======================
  const [activeTab, setActiveTab] = useState('Benchmarks'); // Initial active tab

  const handleButtonClick = (selectedTab) => {
    setActiveTab(selectedTab);
  };

  const [priceAfterVat, setPriceAfterVat] = useState(0);

  const [subData, setSubData] = useState({
    business: '',
    name: '',
    description: '',
    price: 0,
    monthlyAnnual: '',
    vat: 0,
    benchMark: 0,
    startDate: '',
    endDate: '',
    currencyType: '€',
    allowedDTP: 0,
    // maxQtn: 0,
    maxVoteAllowed: 0,
    statistics: true,
    emailModule: true,
    isOnePerCompany: true,
    isAutoRenewable: true,
  });

  const {
    business,
    name,
    description,
    price,
    monthlyAnnual,
    vat,
    benchMark,
    startDate,
    endDate,
    currencyType,
    allowedDTP,
    // maxQtn,
    maxVoteAllowed,
    statistics,
    emailModule,
    isOnePerCompany,
    isAutoRenewable,
  } = subData;

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    const newValue = type === 'checkbox' ? checked : value;
    setSubData({ ...subData, [name]: newValue });
  };
  const [Maximum_Number_Of_Bench_Marks, setMaximum_Number_Of_Bench_Marks] =
    useState('');

  const calculateVATValue = () => {
    if (!isNaN(price) && !isNaN(vat)) {
      const priceNum = parseFloat(price);
      const vatNum = parseFloat(vat);
      const vatAmount = (priceNum * vatNum) / 100;
      setPriceAfterVat((priceNum + vatAmount).toFixed(2));
    }
  };

  useEffect(() => {
    calculateVATValue();
  }, [price, vat]);

  //======Submit function

  const handleSaveSubscription = (e) => {
    e.preventDefault();

    const SubscriptionNew = {
      verificationCode: verificationCode,
      business: business ? business : null,
      name,
      price,
      monthlyAnnual,
      vat,
      description,
      subscriptionType: activeTab,
      startDate,
      endDate,
      currencyType,
      benchMark: activeTab === 'Benchmarks' && 1,
      allowedDTP: activeTab === 'Touchpoints' && 1,
      // maxQtn,
      maxVoteAllowed,
      statistics,
      emailModule,
      isOnePerCompany,
      isAutoRenewable,
      priceAfterVat,
    };

    dispatch(addSubscription(SubscriptionNew));
  };

  //========Create New Subscription end===================

  //===========Handle Delete Subscription=============
  const handleDeleteSub = (e, id) => {
    e.preventDefault();
    let body = {
      verificationCode: verificationCode,
      id,
    };
    dispatch(deleteSubscription(body));
  };

  //===============Handle Edit Subscription ===============
  const [showEditModal, setShowEditModal] = useState(false);
  const handleCloseEditModal = () => setShowEditModal(false);

  const [editData, setEditData] = useState(null);

  const handleEditSub = (sub) => {
    setEditData(sub);
    setActiveTab(sub?.subscriptionType);
    setShowEditModal(true);
  };

  const calculateVATEditValue = () => {
    if (editData) {
      if (!isNaN(editData?.price) && !isNaN(editData?.vat)) {
        const priceNum = parseFloat(editData?.price);
        const vatNum = parseFloat(editData?.vat);
        const vatAmount = (priceNum * vatNum) / 100;
        setPriceAfterVat((priceNum + vatAmount).toFixed(2));
      }
    }
  };

  const { language, setLanguage } = useMyContext();

  useEffect(() => {
    calculateVATEditValue();
  }, [editData?.price, editData?.vat, editData]);

  const handleEditChange = (e) => {
    const { name, value, type, checked } = e.target;
    const newValue = type === 'checkbox' ? checked : value;
    setEditData({ ...editData, [name]: newValue });
  };

  const handleUpdateSubscription = (e) => {
    e.preventDefault();

    let body = {
      verificationCode: verificationCode,
      id: editData._id,
      business: editData.business ? editData.business : null,
      name: editData.name,
      price: editData.price,
      monthlyAnnual: editData.monthlyAnnual,
      vat: editData.vat,
      description: editData.description,
      subscriptionType: activeTab,
      benchMark: activeTab === 'Benchmarks' && 1,
      allowedDTP: activeTab === 'Touchpoints' && 1,
      maxVoteAllowed: editData.maxVoteAllowed,
      startDate: editData.startDate,
      endDate: editData.endDate,
      currencyType: editData.currencyType ? editData.currencyType : '€',
      statistics: editData.statistics,
      emailModule: editData.emailModule,
      isOnePerCompany: editData.isOnePerCompany,
      isAutoRenewable: editData.isAutoRenewable,
      perUnitPrice: editData.perUnitPrice ? editData.perUnitPrice : 0,
      priceAfterVat: editData.priceAfterVat
        ? editData.priceAfterVat
        : priceAfterVat,
    };
    dispatch(editSubscription(body));
  };

  //====================Handle subscription package pricing===============
  const [editSubModal, setEditSubModal] = useState(false);
  const handleCloseEditSubModal = () => setEditSubModal(false);

  const [editSubData, setEditSubData] = useState(null);

  const handleEditSubOpenModal = (sub) => {
    setEditSubData(sub);
    setEditSubModal(true);
  };

  const handleEditSubChange = (e) => {
    setEditSubData({ ...editSubData, [e.target.name]: e.target.value });
  };

  const handleUpdatePricingSubscription = (e) => {
    e.preventDefault();
    let body = {
      verificationCode: verificationCode,
      id: editSubData._id,
      business: editSubData.business ? editSubData.business : null,
      name: editSubData.name,
      price: editSubData.price,
      monthlyAnnual: editSubData.monthlyAnnual,
      vat: editSubData.vat,
      description: editSubData.description,
      subscriptionType: editSubData.activeTab,
      benchMark: editSubData.benchMark,
      allowedDTP: editSubData.allowedDTP,
      startDate: editSubData.startDate,
      endDate: editSubData.endDate,
      currencyType: editSubData.currencyType ? editSubData.currencyType : '€',
      statistics: editSubData.statistics,
      emailModule: editSubData.emailModule,
      isOnePerCompany: editSubData.isOnePerCompany,
      isAutoRenewable: editSubData.isAutoRenewable,
      perUnitPrice: editSubData.perUnitPrice,
      priceAfterVat: editSubData.priceAfterVat,
    };
    dispatch(editSubscription(body));
  };

  //===========Reset Data on Success =====================
  useEffect(() => {
    if (isSuccess) {
      setSubData({
        business: '',
        name: '',
        description: '',
        price: 0,
        monthlyAnnual: '',
        vat: 0,
        benchMark: 0,
        startDate: '',
        endDate: '',
        currencyType: '€',
        allowedDTP: 0,
        // maxQtn: 0,
        maxVoteAllowed: 0,
        statistics: true,
        emailModule: true,
        isOnePerCompany: true,
        isAutoRenewable: true,
      });
      setActiveTab('Benchmarks');
      handleCloseEditSubModal();
      setShowSubModal(false);
      setShowEditModal(false);
      dispatch(reset());
    }
  }, [isSuccess]);
  return (
    <>
      <div className="container-fluid my-3">
        <div className="row">
          <div className="col-md-12">
            <div className="d-flex justify-content-between align-items-center">
              <h3 className="d-flex align-items-center fw-500">
                <Trans>Shop</Trans>
              </h3>
              <Button className="btn btn-primary" onClick={handleShowSubModal}>
                <FontAwesomeIcon icon={faPlus} />
                Product
              </Button>
            </div>
          </div>
          {/* <div className="col-12 col-md-2 px-0 px-md-2">
            <select onChange={(e) => setLanguage(e.target.value)}>
              <option value={"en"} selected={language == "en"} >English</option>
              <option value={"es"} selected={language == "es"} >Spanish</option>
            </select>
          </div> */}
        </div>

        <div className="row mt-4">
          {allSubscriptions &&
            allSubscriptions?.map((sub, i) => (
              <div className="col-md-3 col-lg-2 mb-3 d-flex" key={i}>
                <div className="sub-cards" id="sub_card">
                  <div className="d-flex justify-content-between mb-3">
                    <div>
                      <Link
                        className="deleteButton"
                        onClick={(e) => handleDeleteSub(e, sub?._id)}
                      >
                        <FontAwesomeIcon icon={faTrash} />
                      </Link>
                    </div>
                    <div>
                      <Link
                        className="editButton"
                        onClick={() => handleEditSub(sub)}
                      >
                        <FontAwesomeIcon icon={faEdit} />
                      </Link>
                    </div>
                  </div>
                  <h5 className="blueHead text-center fw-600">{sub?.name}</h5>
                  {sub?.subscriptionType === 'Touchpoints' &&
                  sub?.allowedDTP !== 0 ? (
                    <h6 className="text-center">
                      {/* {sub?.allowedDTP}  */}
                      {sub?.description}
                    </h6>
                  ) : (
                    sub?.subscriptionType === 'Benchmarks' && (
                      <h6 className="text-center">
                        Up to {sub?.benchMark} BenchMarks
                      </h6>
                    )
                  )}
                  <h5 className="text-center fw-600">
                    {sub?.priceAfterVat ? sub?.priceAfterVat : sub?.price}
                    <small>
                      {' '}
                      {sub?.currencyType}/{sub?.monthlyAnnual}
                    </small>{' '}
                  </h5>
                  {new Date(sub?.endDate).getTime() > new Date() ? (
                    <></>
                  ) : (
                    <div className="d-flex justify-content-center">
                      <button className="btn btn-sub margnbtn w-75">
                        Expired {moment(sub?.endDate).format('DD/MM/YYYY')}
                      </button>
                    </div>
                  )}
                </div>
              </div>
            ))}
        </div>

        {/* //=====SUbscription Pricing setteings START=========== */}
        {/* <div className="row mt-5 justify-content-between selectBorder">
          <div className="col-md-6">
            <h3 className="fw-500">
              <FontAwesomeIcon icon={faPlus} /> Shop Pricing Setting
            </h3>

            <div className="mt-3">
              <div className="d-flex justify-content-between">
                <h6 className="ms-4">Subscription</h6>
                <h6 className=" ps-3">Price (Per DTP)</h6>
                <h6 className="">Action</h6>
              </div>
              {allSubscriptions &&
                allSubscriptions?.map((sub, i) => (
                  <div className="whiteShadow" key={i}>
                    <div className="d-flex justify-content-between">
                      <div className="">
                        <h6 className="text-muted fw-light">{sub?.name}</h6>
                      </div>
                      <div className="">
                        <span className="ms-3">€ {sub?.perUnitPrice}</span>
                      </div>
                      <div className="">
                        <FontAwesomeIcon
                          icon={faEdit}
                          className="pink cursor-pointer"
                          onClick={() => handleEditSubOpenModal(sub)}
                        />
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div> */}
        {/* //=====SUbscription Pricing setteings END=========== */}
      </div>
      {/* //=====Create New subscription modal=========== */}
      <Modal
        size="lg"
        show={showSubModal}
        onHide={handleCloseSubModal}
        centered
        dialogClassName="custom-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <Trans>
              <h3>New Product</h3>
            </Trans>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={handleSaveSubscription}>
            <div className="row g-3">
              <div className="col-md-6">
                <label className="form-label">
                  <Trans>Name</Trans>
                </label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Customer Experience Restaurants SP & FR"
                  value={name}
                  name="name"
                  onChange={handleChange}
                />
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label className="form-label">
                    <Trans>Select Business</Trans>
                  </label>
                  <select
                    className=" form-control"
                    name="business"
                    value={business}
                    onChange={handleChange}
                  >
                    <option value="">
                      <Trans>Select from list</Trans>...
                    </option>
                    {allBusinessData &&
                      allBusinessData?.map((allBusinesses, i) => (
                        <option value={allBusinesses._id}>
                          {allBusinesses?.name_of_business}
                        </option>
                      ))}
                  </select>
                </div>
              </div>
              {business !== '' && (
                <div className="col-12">
                  <Alert className="p-1">
                    This <Trans>Shop</Trans> will now only be company specific.
                  </Alert>
                </div>
              )}
              <div className="col-md-6">
                <button
                  type="button"
                  className={`btn btn-logfeedBack rounded-pill w-100 ${
                    activeTab === 'Benchmarks' ? 'selected' : 'unSelected'
                  }`}
                  onClick={() => handleButtonClick('Benchmarks')}
                >
                  <Trans>Benchmarks</Trans>
                </button>
              </div>
              <div className="col-md-6">
                <button
                  type="button"
                  className={`btn btn-logfeedBack rounded-pill w-100 ${
                    activeTab === 'Touchpoints' ? 'selected' : 'unSelected'
                  }`}
                  onClick={() => handleButtonClick('Touchpoints')}
                >
                  <Trans>Votes</Trans>
                </button>
              </div>
              {activeTab === 'Benchmarks' && (
                <div className="col-md-12 my-1 d-none">
                  <label className="form-label">
                    Number of Active Benchmark{' '}
                  </label>
                  <input
                    type="number"
                    value={benchMark}
                    name="benchMark"
                    onChange={handleChange}
                    className="form-control"
                  />
                </div>
              )}
              {activeTab === 'Touchpoints' && (
                <>
                  <div className="col-md-12 my-1 d-none">
                    <label className="form-label">
                      Number of Active Touchpoints
                    </label>
                    <input
                      type="number"
                      value={allowedDTP}
                      name="allowedDTP"
                      onChange={handleChange}
                      className="form-control"
                    />
                  </div>
                  <div className="col-md-12  ">
                    <label className="form-label">
                      Maximum Number Of Votes
                    </label>
                    <input
                      type="number"
                      value={maxVoteAllowed}
                      name="maxVoteAllowed"
                      onChange={handleChange}
                      className="form-control"
                    />
                  </div>
                </>
              )}
              {activeTab === 'Benchmarks' && (
                <div className="col-md-12">
                  <label className="form-label">
                    <Trans>Maximum Number Of Benchmarks</Trans>
                  </label>
                  <div className="form-group">
                    <input
                      type="number"
                      v
                      value={Maximum_Number_Of_Bench_Marks}
                      onChange={(e) => {
                        setMaximum_Number_Of_Bench_Marks(e.target.value);
                      }}
                      name="Maximum_Number_Of_Bench_Marks"
                      className="form-control"
                    />
                  </div>
                </div>
              )}
              {/* <div className="col-md-6 my-1 ">
              <label className="form-label">Maximum Quantity Allowed</label>
              <input
                type="number"
                value={maxQtn}
                name="maxQtn"
                onChange={handleChange}
                className="form-control"
              />
            </div> */}
              <h3>
                <Trans>Selling Period</Trans>
              </h3>
              <div className="col-md-6">
                <label className="form-label">
                  <Trans>Start Date</Trans>
                </label>
                <div className="form-group">
                  <input
                    type="date"
                    value={startDate}
                    name="startDate"
                    onChange={handleChange}
                    min={new Date().toISOString().split('T')[0]}
                    className="form-control"
                    id="startDate"
                  />
                </div>
              </div>
              <div className="col-md-6">
                <label className="form-label">
                  <Trans>End Date</Trans>
                </label>

                <div className="form-group">
                  <input
                    type="date"
                    value={endDate}
                    name="endDate"
                    onChange={handleChange}
                    min={new Date().toISOString().split('T')[0]}
                    className="form-control"
                    id="endDate"
                  />
                </div>
              </div>
              <div className="col-md-6">
                <label className="form-label">
                  <Trans>
                    Price without <Trans>VAT</Trans>
                  </Trans>
                </label>
                <div className="form-group">
                  <input
                    type="number"
                    value={price}
                    name="price"
                    onChange={handleChange}
                    className="form-control"
                  />
                </div>
              </div>
              <div className="col-md-6">
                <label className="form-label">
                  <Trans>VAT %</Trans>
                </label>
                <div className="form-group">
                  <input
                    type="number"
                    value={vat}
                    name="vat"
                    onChange={handleChange}
                    className="form-control"
                  />
                </div>
              </div>
              {vat !== 0 && (
                <div className="col-md-3">
                  <label className="form-label">
                    <Trans>Price with VAT</Trans>
                  </label>
                  <div className="form-group">
                    <input
                      type="number"
                      name="priceAfterVat"
                      value={priceAfterVat}
                      readOnly
                      className="form-control"
                    />
                  </div>
                </div>
              )}
              <div className="col-md-12">
                <label className="form-label">
                  <Trans>Payable Monthly/Annual</Trans>
                </label>
                <div className="form-group">
                  <select
                    className="form-select"
                    value={monthlyAnnual}
                    name="monthlyAnnual"
                    onChange={handleChange}
                  >
                    <option value="">Select</option>
                    <option value="Monthly">Monthly</option>
                    <option value="Annual">Annual</option>
                  </select>
                </div>
              </div>
              {/* <div className="col-md-6">
                <div className="form-group">
                  <label className="form-label">Currency</label>
                  <select
                    className="form-select"
                    value={currencyType}
                    name="currencyType"
                    onChange={handleChange}
                  >
                    <option value="€">€</option>
                  </select>
                </div>
              </div> */}
              {activeTab === 'Touchpoints' && (
                <div className="row mb-3">
                  <div className="col-md-6">
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="TouchgridCheck"
                        checked={emailModule}
                        name="emailModule"
                        onChange={handleChange}
                      />
                      <label className="form-label">
                        <Trans>HTML Email Embed</Trans>
                      </label>
                    </div>
                  </div>
                  {/* <div className="col-md-6">
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="TouchgridCheck"
                        checked={statistics}
                        name="statistics"
                        onChange={handleChange}
                      />
                      <label className="form-label"><Trans>Statistic</Trans></label>
                    </div>
                  </div> */}
                </div>
              )}
              {/* <div className="col-md-6">
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="gridCheck"
                    checked={isOnePerCompany}
                    name="isOnePerCompany"
                    onChange={handleChange}
                  />
                  <label className="form-label"><Trans>Limit to 1 per Business</Trans></label>
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="gridCheck"
                    checked={isAutoRenewable}
                    name="isAutoRenewable"
                    onChange={handleChange}
                  />
                  <label className="form-label"><Trans>Automatic Renewal</Trans></label>
                </div>
              </div> */}
              <div className="col-md-12">
                <label className="form-label">
                  <Trans>Description</Trans>
                </label>
                <textarea
                  type="text"
                  value={description}
                  name="description"
                  onChange={handleChange}
                  className="form-control"
                  rows={5}
                />
              </div>
              <div className="d-flex justify-content-end mt-3">
                <div className="d-flex justify-content-between gap-2">
                  <button
                    type="button"
                    className="btn btn-secondary w-100"
                    onClick={handleCloseSubModal}
                  >
                    Cancel
                  </button>
                  {isLoadingAddSubscription ? (
                    <div>
                      {' '}
                      <ButtonLoading />
                    </div>
                  ) : (
                    <button
                      type="button"
                      className="btn btn-primary d-block  ms-auto"
                      onClick={handleSaveSubscription}
                    >
                      <Trans>Save</Trans>
                    </button>
                  )}
                </div>
              </div>
            </div>
          </form>
        </Modal.Body>
      </Modal>

      {/* //=====Update/Edit subscription modal=========== */}
      <Modal
        show={showEditModal}
        onHide={handleCloseEditModal}
        centered
        dialogClassName="custom-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            Edit <Trans>Shop</Trans>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {editData && (
            <form className="row" onSubmit={handleUpdateSubscription}>
              <div className="row">
                <div className="col-md-6">
                  <label className="form-label">Name</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Customer Experience Restaurants SP & FR"
                    value={editData.name}
                    name="name"
                    onChange={handleEditChange}
                  />
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label className="form-label">
                      <Trans>Select Business</Trans>
                    </label>
                    <select
                      className=" form-control"
                      name="business"
                      value={editData.business}
                      onChange={handleEditChange}
                    >
                      <option value="">
                        <Trans>Select from list</Trans>...
                      </option>
                      {allBusinessData &&
                        allBusinessData?.map((allBusinesses, i) => (
                          <option value={allBusinesses._id}>
                            {allBusinesses?.name_of_business}
                          </option>
                        ))}
                    </select>
                  </div>
                </div>
                {editData.business && (
                  <div className="col-12">
                    <Alert className="p-1">
                      <Trans>
                        This subscription will now only be company specific
                      </Trans>
                      .
                    </Alert>
                  </div>
                )}
                <div className="col-md-12">
                  <label className="form-label">Description</label>
                  <input
                    type="text"
                    value={editData.description}
                    onChange={handleEditChange}
                    name="description"
                    className="form-control"
                  />
                </div>
              </div>

              <div className="d-flex justify-content-left align-items-start mt-2">
                <button
                  type="button"
                  className={`btn btn-logfeedBack  rounded-pill ${
                    activeTab === 'Benchmarks' ? 'selected' : ''
                  }`}
                  onClick={() => handleButtonClick('Benchmarks')}
                >
                  <Trans>Benchmarks</Trans>
                </button>
                <button
                  type="button"
                  className={`btn btn-logfeedBack rounded-pill  ms-3 ${
                    activeTab === 'Touchpoints' ? 'selected' : ''
                  }`}
                  onClick={() => handleButtonClick('Touchpoints')}
                >
                  <Trans>Touchpoints</Trans>
                </button>
              </div>

              {activeTab === 'Benchmarks' && (
                <div className="col-md-6 my-1 d-none">
                  <label className="form-label">
                    Number of Active Benchmark{' '}
                  </label>
                  <input
                    type="number"
                    value={editData.benchMark}
                    onChange={handleEditChange}
                    name="benchMark"
                    className="form-control"
                  />
                </div>
              )}

              {activeTab === 'Touchpoints' && (
                <>
                  <div className="col-md-6  my-1 d-none">
                    <label className="form-label">
                      Maximum Number of Touchpoints
                    </label>
                    <input
                      type="number"
                      value={editData.allowedDTP}
                      onChange={handleEditChange}
                      name="allowedDTP"
                      className="form-control"
                    />
                  </div>
                  <div className="col-md-6 my-1 ">
                    <label className="form-label">Maximum No. of Votes</label>
                    <input
                      type="number"
                      value={editData.maxVoteAllowed}
                      name="maxVoteAllowed"
                      onChange={handleEditChange}
                      className="form-control"
                    />
                  </div>
                </>
              )}
              {/* <div className="col-md-6 my-1 ">
                <label className="form-label">Maximum Quantity Allowed</label>
                <input
                  type="number"
                  value={editData?.maxQtn}
                  name="maxQtn"
                  onChange={handleEditChange}
                  className="form-control"
                />
              </div> */}

              <div className="row my-3">
                <div className="col-md-6">
                  <label className="form-label">
                    <Trans>Start Date</Trans>
                  </label>
                  <div className="form-group">
                    <input
                      type="date"
                      value={editData.startDate.split('T')[0]}
                      onChange={handleEditChange}
                      name="startDate"
                      min={new Date().toISOString().split('T')[0]}
                      className="form-control"
                      id="startDate"
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <label className="form-label">
                    <Trans>End Date</Trans>
                  </label>

                  <div className="form-group">
                    <input
                      type="date"
                      value={editData.endDate.split('T')[0]}
                      onChange={handleEditChange}
                      name="endDate"
                      min={new Date().toISOString().split('T')[0]}
                      className="form-control"
                      id="endDate"
                    />
                  </div>
                </div>
              </div>

              <div className="row mb-3">
                <div className="col-md-6">
                  <label className="form-label">
                    <Trans>Price without VAT</Trans>
                  </label>
                  <div className="form-group">
                    <input
                      type="number"
                      v
                      value={editData.price}
                      onChange={handleEditChange}
                      name="price"
                      className="form-control"
                    />
                  </div>
                </div>

                <div className="col-md-3">
                  <label className="form-label">
                    <Trans>VAT</Trans> %
                  </label>
                  <div className="form-group">
                    <input
                      type="number"
                      value={editData.vat}
                      name="vat"
                      onChange={handleEditChange}
                      className="form-control"
                    />
                  </div>
                </div>
                {editData.vat !== 0 && (
                  <div className="col-md-3">
                    <label className="form-label">
                      Price with <Trans>VAT</Trans>
                    </label>
                    <div className="form-group">
                      <input
                        type="number"
                        name="priceAfterVat"
                        value={priceAfterVat}
                        readOnly
                        className="form-control"
                      />
                    </div>
                  </div>
                )}

                <div className="col-md-6">
                  <label className="form-label">
                    <Trans>Payable Monthly/Annual</Trans>
                  </label>
                  <div className="form-group">
                    <select
                      className="form-select"
                      value={editData.monthlyAnnual}
                      name="monthlyAnnual"
                      onChange={handleEditChange}
                    >
                      <option value="">Select ...</option>
                      <option value="Monthly">Monthly</option>
                      <option value="Annual">Annual</option>
                    </select>
                  </div>
                </div>
                {/* <div className="col-md-6">
                  <div className="form-group">
                    <label className="form-label">Currency</label>
                    <select
                      className="form-select"
                      value={editData.currencyType}
                      onChange={handleEditChange}
                      name="currencyType"
                    >
                      <option value="€">€</option>
                    </select>
                  </div>
                </div> */}
              </div>
              {activeTab === 'Touchpoints' && (
                <div className="row mb-3">
                  <div className="col-md-6">
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="TouchgridCheck"
                        checked={editData.emailModule}
                        onChange={handleEditChange}
                        name="emailModule"
                      />
                      <label className="form-label">
                        <Trans>Email Module</Trans>
                      </label>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="TouchgridCheck"
                        checked={editData.statistics}
                        onChange={handleEditChange}
                        name="statistics"
                      />
                      <label className="form-label">Statistic</label>
                    </div>
                  </div>
                </div>
              )}

              <div className="row mb-3">
                <div className="col-md-6">
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="gridCheck"
                      checked={editData.isOnePerCompany}
                      onChange={handleEditChange}
                      name="isOnePerCompany"
                    />
                    <label className="form-label">
                      <Trans>Limit to 1 per Business</Trans>
                    </label>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id="gridCheck"
                      checked={editData.isAutoRenewable}
                      onChange={handleEditChange}
                      name="isAutoRenewable"
                    />
                    <label className="form-label">
                      <Trans>Automatic Renewal</Trans>
                    </label>
                  </div>
                </div>
              </div>
              <button
                type="button"
                className="btn btn-primary d-block mt-3 ms-auto"
                onClick={handleUpdateSubscription}
              >
                Update
              </button>
            </form>
          )}
        </Modal.Body>
      </Modal>

      {/* //=====Update/Edit subscription pricing modal=========== */}
      <Modal
        show={editSubModal}
        onHide={handleCloseEditSubModal}
        centered
        dialogClassName="custom-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            Edit <Trans>Shop</Trans> Pricing
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {editSubData && (
            <form className="row" onSubmit={handleUpdatePricingSubscription}>
              <div className="row">
                <div className="col-md-12">
                  <label className="form-label">Name</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Customer Experience Restaurants SP & FR"
                    value={editSubData.name}
                    name="name"
                    onChange={handleEditSubChange}
                  />
                </div>

                <div className="col-md-6 my-3 ">
                  <label className="form-label text-nowrap">
                    Additional{' '}
                    {editSubData?.subscriptionType === 'Benchmarks'
                      ? 'Benchmark'
                      : 'DTP'}{' '}
                    (Per Price){' '}
                  </label>
                  <input
                    type="number"
                    value={editSubData.perUnitPrice}
                    name="perUnitPrice"
                    className="form-control"
                    onChange={handleEditSubChange}
                  />
                </div>
                <div className="col-md-6 my-3 ">
                  <label className="form-label text-nowrap">
                    Price of Subscription (Per <Trans>Month</Trans>)
                  </label>
                  <input
                    type="number"
                    value={editSubData.price}
                    name="price"
                    className="form-control"
                    onChange={handleEditSubChange}
                  />
                </div>
              </div>
              <button
                type="button"
                className="btn btn-primary d-block mt-3 ms-auto"
                onClick={handleUpdatePricingSubscription}
              >
                Update Pricing
              </button>
            </form>
          )}
        </Modal.Body>
      </Modal>
      {/* //=====Update/Edit subscription pricing modal END=========== */}
    </>
  );
};

export default Sub;
