import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';

import {
  useStripe,
  useElements,
  CardElement,
  PaymentElement,
  IdealBankElement,
  IbanElement,
  Elements,
} from '@stripe/react-stripe-js';
import { Modal, Form, Button, Alert } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCog } from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';
import {
  reset,
  updateCompanyCardDetails,
} from '../../../../redux/auth/authSlice';
import { useSelector, useDispatch } from 'react-redux';
import { Trans } from 'react-i18next';
import ButtonLoading from '../../../../common/ButtonLoading';
import { loadStripe } from '@stripe/stripe-js';
const verificationCode = process.env.REACT_APP_VERIFICATION_TOKEN;

const IBAN_STYLE = {
  base: {
    color: '#32325d',
    fontSize: '16px',
    '::placeholder': {
      color: '#aab7c4',
    },
    ':-webkit-autofill': {
      color: '#32325d',
    },
  },
  invalid: {
    color: '#fa755a',
    iconColor: '#fa755a',
    ':-webkit-autofill': {
      color: '#fa755a',
    },
  },
};

const IBAN_ELEMENT_OPTIONS = {
  supportedCountries: ['SEPA'],
  // Elements can use a placeholder as an example IBAN that reflects
  // the IBAN format of your customer's country. If you know your
  // customer's country, we recommend that you pass it to the Element as the
  // placeholderCountry.
  style: IBAN_STYLE,
};

const PaymentForm = () => {
  const stripe = useStripe();
  const elements = useElements();
  const stripePromise = loadStripe(
    process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY
  );
  let dispatch = useDispatch();
  const { profile, isSuccess, isLoadingUpdateCompanyCardDetails } = useSelector(
    (state) => {
      return state.auth;
    }
  );

  const [Accountholdername, setAccountholdername] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const [accountEmail, setAccountEmail] = useState();
  const [AccountNumber, setAccountNumber] = useState();
  const [AccountDetails, setAccountDetails] = useState();

  const [iban, setIban] = useState();

  //====Configuration====Popup=============

  const [showModalPayment, setShowModalPayment] = useState(false);
  const handlePaymentClose = () => setShowModalPayment(false);

  const handleConfigPopup = () => {
    setShowModalPayment(true);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }
    setIsLoading(true);
    const { token, error } = await stripe.createToken(
      elements.getElement(CardElement)
    );
    const payload = await stripe.createPaymentMethod({
      type: 'card',
      card: elements.getElement(CardElement),
    });
    if (payload && payload.error) {
      toast.error(payload.error?.message);
      setIsLoading(false);
    }
    if (payload && payload?.paymentMethod) {
      let body = {
        verificationCode: verificationCode,
        cardHolder: payload?.paymentMethod?.customer,
        cardNumber: payload?.paymentMethod?.card?.last4,
        cardCvv: token?.card?.address_zip,
        cardMonth: token?.card?.exp_month,
        cardYear: token?.card?.exp_year,
        cardBillingAddress:
          payload?.paymentMethod?.billing_details?.address?.line1,
        cardCountry: payload?.paymentMethod?.billing_details?.address?.country,
        cardCity: payload?.paymentMethod?.billing_details?.address?.city,
        cardZipCode:
          payload?.paymentMethod?.billing_details?.address?.postal_code,
        token: token?.id,
        pmId: payload?.paymentMethod?.id,
        paymentMethod: 'card',
      };
      dispatch(updateCompanyCardDetails(body)).then(() => setIsLoading(false));
    }
  };

  const handleIbanChange = (iban) => {
    setIban(iban);
  };

  async function handlerSubmitBankData(event) {
    event.preventDefault();
    try {
      if (!stripe || !elements) {
        // Stripe.js has not loaded yet. Make sure to disable
        // form submission until Stripe.js has loaded.
        toast.error('stripe not loaded yet');
        return;
      }

      if (!accountEmail || !Accountholdername) {
        toast.error('account holder name and email required');
        return;
      }
      if (!iban || !iban?.complete) {
        toast.error(iban?.error?.message || 'invalid or missing iban');
        return;
      }

      setIsLoading(true);
      const { token, error } = await stripe.createToken(
        elements.getElement(IbanElement),
        {
          currency: 'eur', // Replace 'eur' with the appropriate currency code
        }
      );
      console.log({ token, error });
      if (error) {
        toast.error(error?.message);
        setIsLoading(false);
        return;
      }
      const payload = await stripe.createPaymentMethod({
        type: 'sepa_debit',
        sepa_debit: elements.getElement(IbanElement),
        billing_details: {
          name: profile?.name_of_business || Accountholdername,
          email: profile?.email || accountEmail,
        },
      });
      console.log({ payload });
      if (payload && payload.error) {
        toast.error(payload.error?.message);
        setIsLoading(false);
        return;
      }

      if (payload && payload?.paymentMethod) {
        let body = {
          verificationCode: verificationCode,
          token: token?.id,
          pmId: payload?.paymentMethod?.id,
          paymentMethod: 'bank_transfer',
        };
        dispatch(updateCompanyCardDetails(body)).then(() =>
          setIsLoading(false)
        );
      }
    } catch (error) {
      toast.error(error?.message || 'Payment method error');
      setIsLoading(false);
    }
  }

  useEffect(() => {
    if (isSuccess) {
      handlePaymentClose();
    }
    dispatch(reset());
  }, [isSuccess]);

  return (
    <>
      <div className='d-flex gap-2 my-3 align-items-center'>
        <Link
          className='btn btn-primary text-nowrap '
          onClick={handleConfigPopup}
        >
          <FontAwesomeIcon icon={faCog} className='me-2' />
          <Trans>Payment Settings</Trans>
        </Link>
        <p className='mb-0'>
          {profile && profile?.customerId && profile?.pmId && (
            <Alert className='mb-0 p-1  text-center'>
              <Trans>
                You have already configured your payment detailsssssss{' '}
                {profile?.customerId}
              </Trans>{' '}
              <span>{profile?.cardDetails?.cardNumber}</span>
            </Alert>
          )}
        </p>
      </div>
      {/* Payment Modal */}
      <Modal
        show={showModalPayment}
        onHide={handlePaymentClose}
        centered
        dialogClassName='custom-modal'
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <h4>Payment Details</h4>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <>
            {profile && profile?.customerId && profile?.pmId && (
              <Alert className='p-1  text-center'>
                You have already configured your payment details , want to
                update ?
              </Alert>
            )}
          </>
          <form onSubmit={handleSubmit}>
            <div className='form-group'>
              {profile?.paymentMethod === 'card' ? (
                <div className='card-element-container'>
                  <label className='mb-3'>Card details</label>
                  <CardElement
                    className='card-element'
                    options={{
                      style: {
                        base: {
                          fontSize: '16px',
                          color: '#424770',
                          '::placeholder': {
                            color: '#aab7c4',
                          },
                        },
                        invalid: {
                          color: '#fa755a',
                        },
                      },
                    }}
                  />
                </div>
              ) : (
                // <PaymentElement
                //   options={{
                //     terms: {
                //       card: 'never',
                //       sepaDebit: 'always',
                //     },
                //   }}
                // />
                <div>
                  <form className='row'>
                    <div className='col-md-6'>
                      <label className='form-label'>Account Holder Name</label>
                      <input
                        type='text'
                        className='form-control'
                        name='accountholdername'
                        value={Accountholdername}
                        onChange={(e) => setAccountholdername(e.target.value)}
                        id='inputEmail4'
                      />
                    </div>
                    <div className='col-md-6'>
                      <label className='form-label'>Email</label>
                      <input
                        type='email'
                        className='form-control'
                        name='accountholdername'
                        value={accountEmail}
                        onChange={(e) => setAccountEmail(e.target.value)}
                        id='inputEmail4'
                      />
                    </div>
                    <div className='col-md-12'>
                      <label className='form-label'>Account Number</label>
                      <div className='form-control'>
                        <IbanElement
                          options={IBAN_ELEMENT_OPTIONS}
                          onChange={handleIbanChange}
                        />
                      </div>
                    </div>
                    {/* <div className="col-md-12">
                  <div className="d-flex justify-content-end mt-4">
                    <div className="d-flex justify-content-between gap-2">
                      <button
                        type="button"
                        className="btn btn-secondary w-100"
                        onClick={handleClose}
                      >
                        Cancel
                      </button>
                      {false ? <div> <ButtonLoading /></div>  : <button
                        className="btn btn-warning w-100"
                        onClick={handleSubmitBankTransfer}
                      >
                        Subscribe
                      </button>
                      }
                    </div>
                  </div>
                </div> */}
                  </form>
                </div>
              )}
            </div>
            <div className='d-flex justify-content-end my-3'>
              <div className='d-flex justify-content-between gap-2'>
                <button
                  type='button'
                  className='btn btn-secondary w-100'
                  onClick={handlePaymentClose}
                >
                  Cancel
                </button>
                {isLoading ? (
                  <div>
                    {' '}
                    <ButtonLoading />
                  </div>
                ) : (
                  <div>
                    {profile?.paymentMethod === 'card' ? (
                      <button
                        type='submit'
                        disabled={!stripe || isLoading}
                        className='btn btn-primary '
                      >
                        Save
                      </button>
                    ) : (
                      <button
                        type='Button'
                        className='btn btn-primary '
                        disabled={!stripe || isLoading}
                        onClick={(e) => handlerSubmitBankData(e)}
                      >
                        Save
                      </button>
                    )}
                  </div>
                )}
              </div>
            </div>
          </form>
        </Modal.Body>
      </Modal>
      {/* Payment Modal End */}
    </>
  );
};

export default PaymentForm;
