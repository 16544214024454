import React, { useState, useEffect, useRef, createRef } from 'react';
import { Alert, Button, Modal } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import Select from 'react-select';
import moment from 'moment';
import ReactToPrint from 'react-to-print';
import { useScreenshot } from 'use-react-screenshot';
import domtoimage from 'dom-to-image';
//===========Data table =================
import DataTable from 'react-data-table-component';
//=========Redux===========
import { useSelector, useDispatch } from 'react-redux';
import {
  approveDTPStatus,
  createBusinessDTP,
  getAllBusinessDTP,
  getAllBusinesses,
  reset,
} from '../../../../redux/auth/authSlice';
import { getAllReasons } from '../../../../redux/reason/reasonSlice';
import { getAllQrCodeTemplates } from '../../../../redux/qrCode/qrSlice';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faSearch,
  faDownload,
  faUpload,
  faPlus,
  faPrint,
  faBan,
  faCheck,
  faCopy,
  faEye,
} from '@fortawesome/free-solid-svg-icons';
import { BsQrCodeScan } from 'react-icons/bs';
import { BiSticker } from 'react-icons/bi';

import { FaCode } from 'react-icons/fa';

import logoBlue from '../../../../assets/images/icons/GoRateLogo.png';
import logoWhite from '../../../../assets/images/icons/GoRateWhite.png';
import logoBlack from '../../../../assets/images/icons/GoRateBlack.png';

//import Loader
import Loader from '../../../../Loader';

import { getAllQuestions } from '../../../../redux/question/questionSlice';
import { toast } from 'react-toastify';

import { Trans } from 'react-i18next';

import { useMyContext } from '../../../..';

// import { Country, City } from "country-state-city";
import {
  getAllStickers,
  reset as stickerReset,
} from '../../../../redux/sticker/stickerSlice';
import { addStickerRequest } from '../../../../redux/stickerRequest/stickerRequestSlice';
import { getAllQuestionReason } from '../../../../redux/reasonQuestion/reasonQuestionSlice';
import ReactLoading from 'react-loading';
import axios from 'axios';
import ExportCSVButton from '../../../../common/ExportCSVButton';
import { getAllCities } from '../../../../redux/CountryAndCities/CountryAndCitySlice';
import ButtonLoading from '../../../../common/ButtonLoading';
import QRCode from 'react-qr-code';

// import "./touch.css";

//======Utils
const verificationCode = process.env.REACT_APP_VERIFICATION_TOKEN;
const REACT_APP_BASE_URL_QR_CODE = process.env.REACT_APP_BASE_URL_QR_CODE;
const BASE_URL_IMAGES = process.env.REACT_APP_BASE_URL_IMAGE;
const CLIENT_URL = process.env.REACT_APP_CLIENT_BASE_URL;

const Touchpoints = () => {
  const { language: webLanguage, setLanguage } = useMyContext();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const componentRef = useRef();
  const {
    allBusinessDTP,
    allBusinessData,
    isSuccess,
    isLoadingCreateBusinessDPT,
  } = useSelector((state) => {
    return state.auth;
  });

  const { allQuestions } = useSelector((state) => {
    return state.question;
  });
  const { allReasons } = useSelector((state) => {
    return state.reason;
  });

  const { allStickers } = useSelector((state) => {
    return state.sticker;
  });
  const { allQrCodeTemplates } = useSelector((state) => {
    return state.qrCode;
  });

  const { allQuestionReason } = useSelector((state) => {
    return state.reasonQuestion;
  });

  const isStickerRequestSuccess = useSelector((state) => {
    return state.stickerRequest.isSuccess;
  });
  const { isLoadingAddStickerRequest } = useSelector((state) => {
    return state.stickerRequest;
  });

  const { getCountries, getCities, isCitiesLoading } = useSelector(
    (state) => state.CountryAndCitySlice
  );

  //=======Get All Businessess , Get All Questions , Data Touchpoints =================
  useEffect(() => {
    dispatch(getAllBusinesses({ verificationCode }));
    dispatch(getAllQuestions({ verificationCode }));
    dispatch(getAllBusinessDTP({ verificationCode }));
    dispatch(getAllStickers({ verificationCode }));
    dispatch(getAllQrCodeTemplates({ verificationCode }));
    dispatch(getAllQuestionReason({ verificationCode }));
  }, [dispatch]);

  //===========Country and Cities Selection START=================================
  const [selectedCountry, setSelectedCountry] = useState('');
  const [cities, setCities] = useState([]);
  const [CitiesLoading, setCitiesLoading] = useState(false);

  const handleCountryChange = async (event) => {
    const countryCode = event.target.value;
    setSelectedCountry(countryCode);
    setCitiesLoading(true);
    try {
      dispatch(getAllCities(countryCode));
    } catch (error) {
      console.error('Error during POST request:', error);
    }
  };
  //===========Country and Cities Selection END=================================
  //===Selected type for account  alex
  const [selectedQRButton, setSelectedQRButton] = useState(
    <Trans>Template QR Code</Trans>
  );

  const handleQRButtonClick = (buttonName) => {
    setSelectedQRButton(buttonName);
  };

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState('');
  const [qrTemplate, setQrTemplate] = useState();
  const [clickedBusiness, setClickedBusiness] = useState('');
  const [AllCountry, setAllCountry] = useState();
  const [dtpSlug, setDTPSlug] = useState('');
  const [Name, setName] = useState('');

  const handleImageClick = (imageUrl, slug, tempType, business, name) => {
    setSelectedQRButton('Template QrCode');
    // Customer Experience
    if (tempType === 'Customer Experience') {
      if (business && business?.customerDefaultQr !== undefined) {
        setQrTemplate(business?.customerDefaultQr);
      }
    }
    if (tempType === 'Employee Sentiment') {
      if (business && business?.employeeDefaultQr !== undefined) {
        setQrTemplate(business?.employeeDefaultQr);
      }
    }
    setClickedBusiness(business);
    setSelectedImage(imageUrl);
    setDTPSlug(slug);
    setName(name);
    setIsModalOpen(true);
  };
  const onClickSlug = () => {
    navigate(`/review/${dtpSlug}`);
  };

  //=============Add New Datatouch point =============================
  const [showAddDTPModal, setShowAddDTPModal] = useState(false);
  const handleCloseAddDTPModal = () => setShowAddDTPModal(false);

  const fetchData = async () => {
    try {
      await fetch(`https://countriesnow.space/api/v0.1/countries`).then(
        async (response) => {
          let data = await response.json();
          setAllCountry(data.data);
        }
      );
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };
  const handleShowAddDTPModal = () => {
    fetchData();
    setShowAddDTPModal(true);
  };

  //===Selected type for account
  const [selectedButton, setSelectedButton] = useState('Customer Experience');

  const handleButtonClick = (buttonName) => {
    setSelectedButton(buttonName);
  };

  const [formData, setFormData] = useState({
    businessId: '',
    name: '',
    email: '',
    password: '',
    contactNumber: '',
    location: '',
    city: '',
    // country: "Spain",
    postalCode: '',
    // maxVotes: 0,
    language: '',
    question: '',
    description: '',
  });

  const {
    businessId,
    name,
    email,
    password,
    contactNumber,
    location,
    city,
    // country,
    postalCode,
    // maxVotes,
    language,
    question,
    description,
  } = formData;

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  //============Get All Reasons with option and select START =============================
  const [optionReasons, setOptionReasons] = useState([]);
  const [reasonsVal, setReasonsVal] = useState('');
  const [PasswordVisible, setPasswordVisible] = useState(true);
  let finalReasonVal = [];

  if (reasonsVal) {
    reasonsVal.forEach((item) => {
      finalReasonVal.push(item.value);
    });
  }

  //===All Reason
  useEffect(() => {
    dispatch(getAllReasons({ verificationCode }));
  }, [dispatch]);

  useEffect(() => {
    const options = allReasons
      .filter(
        (reas) => reas.business === businessId && reas.language === language
      )
      .map((reas) => ({
        value: reas.reason,
        label: reas.reason,
      }));
    setOptionReasons(options);
    // Set language and question based on selected business
    const selectedBusiness = allBusinessData.find(
      (business) => business._id === businessId
    );

    if (selectedBusiness) {
      setFormData({
        ...formData,
        language: selectedBusiness.defaultLanguage,
        question: selectedBusiness.employeeQuestion
          ? selectedBusiness.employeeQuestion.question
          : selectedBusiness.customerQuestion?.question,
      });
    }
  }, [businessId, allReasons, language, allBusinessData]);

  const handleReasonOptChange = (e) => {
    setReasonsVal(e);
  };

  // +++++++Default Question , reasons++++++
  // Set language and question based on selected business
  const selectedBusiness = allBusinessData.find(
    (business) => business._id === businessId
  );

  //===Selected Business's Reason ===

  const selectedBusinessReason = allReasons.filter(
    (entry) => entry?.business === businessId
  );

  let [finalCusReasonVal, setFinalCusReasonVal] = useState([]);
  let [finalEmpReasonVal, setFinalEmpReasonVal] = useState([]);

  useEffect(() => {
    const customerExperienceReasons = selectedBusinessReason?.filter(
      (reason) => reason.type === 'Customer Experience'
    );
    const employeeSentimentReasons = selectedBusinessReason?.filter(
      (reason) => reason.type === 'Employee Sentiment'
    );

    let custTemp = [];
    let empTemp = [];

    if (customerExperienceReasons.length !== 0) {
      for (let i = 0; i < customerExperienceReasons.length; i++) {
        customerExperienceReasons[i].reasons.map((item) => {
          if (item.language === language) {
            custTemp.push(item.reason);
          }
        });
      }
      setFinalCusReasonVal(custTemp);
    }

    if (employeeSentimentReasons.length !== 0) {
      for (let i = 0; i < employeeSentimentReasons.length; i++) {
        employeeSentimentReasons[i].reasons.map((item) => {
          if (item.language === language) {
            empTemp.push(item.reason);
          }
        });
      }
      setFinalEmpReasonVal(empTemp);
    }
  }, [allReasons, language]);

  //=======================Reason Question Added by Comapny alex===========

  const [questionReason, setQuestionReason] = useState('');

  const selectedBusinessQReason =
    allQuestionReason &&
    allQuestionReason.filter((entry) => entry?.business === businessId);

  useEffect(() => {
    if (selectedBusinessQReason && selectedBusinessQReason.length === 0) {
      setQuestionReason('');
    }

    if (selectedBusinessQReason && selectedBusinessQReason.length !== 0) {
      const foundLanguage = selectedBusinessQReason[0]?.reasonQuestion.find(
        (reason) => reason.language === language
      );

      if (foundLanguage) {
        setQuestionReason(foundLanguage.Qreason);
      }
    }
  }, [selectedBusinessQReason, language]);

  //============Get All Reasons with option and select END=============================

  const handleCreateDTP = async (e) => {
    e.preventDefault();
    if (businessId === '') {
      return toast.error('Please select company/business!');
    }
    let body = {
      verificationCode: verificationCode,
      businessId,
      name,
      email,
      password,
      contactNumber,
      location,
      city,
      country: selectedCountry,
      postalCode,
      maxVotes:
        selectedBusiness &&
        selectedBusiness.selectedSubscription?.maxVoteAllowed,
      language,
      question:
        selectedButton === 'Customer Experience'
          ? selectedBusiness.customerQuestion?.question
          : selectedBusiness.employeeQuestion.question,
      reasonQuestion: questionReason,
      reasons:
        selectedButton === 'Customer Experience'
          ? finalCusReasonVal
          : finalEmpReasonVal,
      description,
      dtpType: selectedButton,
    };

    dispatch(createBusinessDTP(body));
  };

  //============Handler for update Data Touchpoint status to Active===========
  const handleDTPStatus = (e, id) => {
    e.preventDefault();
    let body = {
      verificationCode: verificationCode,
      dataTPId: id,
    };
    dispatch(approveDTPStatus(body));
  };

  //===========Stickers Request  API & MODAL START=================
  const [reqType, setRequestType] = useState('');
  const [dtpId, setDTPId] = useState('');
  const [businessID, setBusinessID] = useState('');
  const [businessStripeCus, setBusinessStripeCus] = useState('');
  const [totalPrice, setTotalPrice] = useState(0);

  const [showRequestTempModal, setShowRequestTempModal] = useState(false);
  const handleRequestTempClose = () => setShowRequestTempModal(false);
  const handleRequestTempShow = (id, reqType, business) => {
    let templateIdToSet = '';

    if (reqType === 'Customer Experience') {
      templateIdToSet = business.customerDefaultQr
        ? business.customerDefaultQr._id
        : '';
    } else if (reqType === 'Employee Sentiment') {
      templateIdToSet = business.employeeDefaultQr
        ? business.employeeDefaultQr._id
        : '';
    }

    setStickerVales((prevFormData) => ({
      ...prevFormData,
      templateId: templateIdToSet || prevFormData.templateId,
    }));

    setRequestType(reqType);
    setDTPId(id);
    setBusinessID(business._id);
    setBusinessStripeCus(business.customerId);
    setShowRequestTempModal(true);
  };

  const [stickerValues, setStickerVales] = useState({
    templateId: '',
    stickerSize: '',
    units: 0,
    shippingAddress: '',
    stickerCity: '',
    stickerPostalCode: '',
  });

  const {
    templateId,
    stickerSize,
    units,
    shippingAddress,
    stickerCity,
    stickerPostalCode,
  } = stickerValues;

  const handleOnChangeSticker = (e) => {
    setStickerVales({ ...stickerValues, [e.target.name]: e.target.value });
  };

  // Calculate total value based on the selected sticker size and units
  const calculateTotalValue = () => {
    const selectedSticker =
      allStickers &&
      allStickers.find((sticker) => sticker.size === stickerSize);
    const stickerPrice = selectedSticker ? selectedSticker.pricePlusVat : 0;
    const total = parseFloat(stickerPrice) * parseFloat(units);
    setTotalPrice(isNaN(total) ? 0 : total.toFixed(2));
  };

  useEffect(() => {
    calculateTotalValue();
  }, [stickerSize, units]);

  const handleRequestSticker = (e) => {
    e.preventDefault();
    let body = {
      verificationCode: verificationCode,
      businessId: businessID,
      DataTouchPoint: dtpId,
      qrCodeTemplate: templateId,
      stickerSize: stickerSize,
      units: units,
      shippingAddress: shippingAddress,
      city: stickerCity,
      country: selectedCountry,
      postalCode: stickerPostalCode,
      totalPrice: totalPrice,
    };
    dispatch(addStickerRequest(body));
  };

  //===========Stickers Request  API & MODAL END=================

  //=========View Business Data ==============
  const handleDTPView = (arg) => {
    navigate(`/admin/datatouchpoint/${arg}`);
  };

  //===========Reset Data on Success =====================
  useEffect(() => {
    if (isSuccess) {
      setFormData({
        businessId: '',
        name: '',
        email: '',
        password: '',
        contactNumber: '',
        location: '',
        city: '',
        // country: "Spain",
        postalCode: '',
        // maxVotes: 0,
        language: '',
        question: '',
        description: '',
      });
      setSelectedButton('Customer Experience');
      setReasonsVal('');
      setShowAddDTPModal(false);
      dispatch(reset());
    }
  }, [isSuccess]);

  //=============Add New Datatouch point END=============================

  //==========================Table======================//

  //-------------------DATA TABLE START ---------------------------------
  const [pending, setPending] = useState(true);

  //====Search Field
  const [searchQuery, setSearchQuery] = useState('');

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      setPending(false);
    }, 500);
    return () => clearTimeout(timeout);
  }, []);

  const DataTableActionButtons = ({ actions, rowData }) => (
    <>
      {rowData?.slug && (
        <button
          type="button"
          className="btn btnPrint fs-6"
          onClick={() => handleDTPView(rowData?.slug)}
          title="View"
        >
          <FontAwesomeIcon icon={faEye} />{' '}
        </button>
      )}

      {/* {rowData?.approvedDTP !== true ? (
        <button
          type="button"
          className="btn btnPrint fs-6"
          onClick={(e) => handleDTPStatus(e, rowData?._id)}
          title="Active"
        >
          <FontAwesomeIcon icon={faCheck} />{" "}
        </button>
      ) : (
        <button type="button" className="btn btnPrint fs-6 " disabled={true}>
          <FontAwesomeIcon icon={faBan} />{" "}
        </button>
      )} */}
    </>
  );

  const columns = [
    {
      name: <Trans>Name</Trans>,
      selector: (row) => row.name,
      sortable: true,
      center: true,
      width: '170px',
    },
    {
      name: 'Business Name',
      selector: (row) => row?.business?.name_of_business,
      sortable: true,
      center: true,
      width: '170px',
    },

    {
      name: <Trans>Email</Trans>,
      selector: (row) => row.email,
      sortable: true,
      center: true,
      width: '200px',
    },
    {
      name: <Trans>Description</Trans>,
      selector: (row) => row.description,
      sortable: true,
      center: true,
      width: '200px',
    },
    {
      name: <Trans>Status</Trans>,
      selector: (row) =>
        row?.approvedDTP === true ? (
          <span className="text-success badge p-1">Active</span>
        ) : (
          <span className="text-danger badge p-1">Block</span>
        ),
      sortable: true,
      center: true,
    },
    {
      name: <Trans>Measuring</Trans>,
      selector: (row) => <span className="text-capitalize">{row.dtpType}</span>,
      sortable: true,
      center: true,
      width: '180px',
    },
    {
      name: 'Total Votes',
      selector: (row) => row?.totalReviewsCount,
      sortable: true,
      center: true,
      width: '210px',
    },
    {
      name: <Trans>Creation Date</Trans>,
      selector: (row) => moment(row.createdAt).format('DD-MM-YYYY'),
      sortable: true,
      center: true,
      width: '150px',
    },
    {
      name: <Trans>Collect Reviews</Trans>,
      width: '200px',

      selector: (row) => (
        <>
          <button
            className="btn btn-sm border"
            onClick={() => {
              handleImageClick(
                row.qrCode,
                row?.slug,
                row.dtpType,
                row?.business,
                row?.name
              );
            }}
          >
            <BsQrCodeScan />
          </button>
          <button
            className="btn btn-sm border mx-2"
            onClick={() => {
              navigator.clipboard.writeText(row?.embededCode);
              toast.success('Embeded code Copied Successfully');
            }}
          >
            <FaCode />
          </button>
          <button
            className="btn btn-sm border"
            onClick={() => {
              navigator.clipboard.writeText(row?.liveUrlNPS);
              toast.success('Live NPS url Copied Successfully');
            }}
          >
            <img
              src={'/assets/dashboard.png'}
              style={{
                width: '18px',
                height: '18px',
                cursor: 'pointer',
              }}
            />
          </button>
        </>
      ),
      center: true,
    },
    {
      name: <Trans>Request Stickers</Trans>,
      selector: (row) => (
        <button
          type="button"
          className="btn btnPrint"
          onClick={() =>
            handleRequestTempShow(row._id, row.dtpType, row?.business)
          }
        >
          <BiSticker />
          {/* <FontAwesomeIcon icon={faPrint} /> */}
        </button>
      ),
      center: true,
      width: '180px',
    },
    {
      name: <Trans>Actions</Trans>,
      cell: (row) => <DataTableActionButtons rowData={row} />,
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
      center: true,
    },
  ];

  //  Internally, customStyles will deep merges your customStyles with the default styling.
  const customStyles = {
    rows: {
      style: {
        minHeight: '60px',
      },
    },
    headCells: {
      style: {
        fontSize: '14px',
        color: '#B5B7C0',
        textAlign: 'center',
      },
    },
    cells: {
      style: {
        textAlign: 'center', // Align regular cell content to center
        color: '#292D32 !important',
        fontWeight: '600',
      },
    },
  };

  // Apply the filter based on the input text
  const filteredData = searchQuery
    ? allBusinessDTP?.dataTouchPoints.filter(
        (item) =>
          item.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
          item.email.toLowerCase().includes(searchQuery.toLowerCase()) ||
          item?.business.name_of_business
            .toLowerCase()
            .includes(searchQuery.toLowerCase())
      )
    : allBusinessDTP?.dataTouchPoints;

  //Search Field END
  //-------Export CSV ----------------
  function convertArrayOfObjectsToCSV(array) {
    let result;

    const columnDelimiter = ',';
    const lineDelimiter = '\n';
    const keys = Object.keys(allBusinessDTP?.dataTouchPoints[0]);

    result = '';
    result += keys.join(columnDelimiter);
    result += lineDelimiter;

    array.forEach((item) => {
      let ctr = 0;
      keys.forEach((key) => {
        if (ctr > 0) result += columnDelimiter;

        result += item[key];

        ctr++;
      });
      result += lineDelimiter;
    });

    return result;
  }

  function downloadCSV(array) {
    const link = document.createElement('a');
    let csv = convertArrayOfObjectsToCSV(array);
    if (csv == null) return;

    const filename = 'export.csv';

    if (!csv.match(/^data:text\/csv/i)) {
      csv = `data:text/csv;charset=utf-8,${csv}`;
    }

    link.setAttribute('href', encodeURI(csv));
    link.setAttribute('download', filename);
    link.click();
  }

  const Export = ({ onExport }) => (
    <Link
      className="btn btn-secondary mb-2 mx-2 d-none"
      onClick={(e) => onExport(e.target.value)}
    >
      <FontAwesomeIcon icon={faDownload} className="btnIcon" />
      <Trans>Export</Trans>
    </Link>
  );
  const actionsMemo = React.useMemo(
    () => (
      <Export onExport={() => downloadCSV(allBusinessDTP?.dataTouchPoints)} />
    ),
    [webLanguage]
  );

  //-------------------DATA TABLE END---------------------------------
  //=====  Download Image QR code =====

  const downLoadQrCode = (tempImg) => {
    const downloadLink = document.createElement('a');
    downloadLink.href = REACT_APP_BASE_URL_QR_CODE + tempImg;
    downloadLink.download = tempImg;
    downloadLink.click();
  };

  //===========Reset Data on Success =====================

  useEffect(() => {
    if (isStickerRequestSuccess) {
      setStickerVales({
        templateId: '',
        stickerSize: '',
        units: 0,
        shippingAddress: '',
        stickerCity: '',
        stickerPostalCode: '',
      });
      setRequestType('');
      setDTPId('');
      setTotalPrice(0);
      handleRequestTempClose();
      dispatch(stickerReset());
    }
  }, [isStickerRequestSuccess]);
  const [LoadingImage, setLoadingImage] = useState(false);
  const ref = createRef(null);
  const [image, takeScreenshot] = useScreenshot();
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [capturedImageSrc, setCapturedImageSrc] = useState(null);
  useEffect(() => {
    // Function to be called after 1 second
    const yourFunction = () => {
      setLoadingImage(false);
      handleShow();
    };

    // Set up the timer using setTimeout
    const timerId = setTimeout(yourFunction, 1000);

    // Clean up the timer on component unmount
    return () => clearTimeout(timerId);
  }, [LoadingImage]);
  // useEffect(() => {
  //   // Function to capture the content
  //   // Click on the default tab on component mount
  //   document.getElementById('defaultOpen').click();
  // }, []);

  const sendData = () => {
    const node = document.getElementById('capture');
    // Adding a delay of 1000 milliseconds (adjust as needed)
    setTimeout(() => {
      domtoimage
        .toPng(node)
        .then(function (dataUrl) {
          setIsModalOpen(false);
          const downloadLink = document.createElement('a');
          downloadLink.href = dataUrl;
          downloadLink.download = 'goRate.io.jpg';
          downloadLink.click();
        })
        .catch(function (error) {
          console.error('Error capturing image:', error);
        });
    }, 100);
  };
  return (
    <>
      <div className="container-fluid my-3 position-relative">
        {/* <a id="test" href="#"></a> */}
        <div className="row">
          <div className="col-md-4">
            <h3
              className=" mt-1 fw-500 text-nowrap"
              style={{ fontSize: webLanguage == 'es' ? '12px' : '' }}
            >
              <Trans>Data Touchpoint Locations</Trans>
            </h3>
          </div>
          <div className="col-md-3">
            <div className="search-field search-bar">
              <input
                type="text"
                placeholder={webLanguage == 'en' ? 'Search...' : 'Búsqueda…'}
                className="dashboardinput"
                id="userSearch"
                value={searchQuery}
                onChange={handleSearchChange}
              />
              <span className="search-icon">
                <FontAwesomeIcon icon={faSearch} className="usersearch" />
              </span>
            </div>
          </div>
          <div className="col-md-5">
            <div className="row g-3">
              <div className="col-md-4">
                <Link
                  className="btn btn-primary w-100 text-nowrap"
                  onClick={handleShowAddDTPModal}
                  style={{ fontSize: webLanguage == 'es' ? '12px' : '' }}
                >
                  <FontAwesomeIcon icon={faPlus} className="btnIcon" />{' '}
                  <Trans>Touchpoint</Trans>
                </Link>
              </div>
              <div className="col-md-4">
                <Link to="#" className="btn btn-secondary text-nowrap w-100">
                  <FontAwesomeIcon icon={faUpload} className="btnIcon" />
                  <Trans>Upload</Trans>
                </Link>
              </div>
              <div className="col-md-4">
                <ExportCSVButton
                  data={allBusinessDTP?.dataTouchPoints}
                  filename={'exported_data.csv'}
                />
              </div>
            </div>
          </div>
        </div>
        <div></div>
        <div className="row">
          <div className="col-md-12">
            <div className="mt-2" style={{ overflowX: 'auto' }}>
              {/* <div className="col-md-4">
                <div className="search-field search-bar">
                  <input
                    type="text"
                    placeholder={language === "en" ? "Search" : "Búsqueda"}
                    className="dashboardinput"
                    id="userSearch"
                    value={searchQuery}
                    onChange={handleSearchChange}
                  />
                  <span className="search-icon">
                    <FontAwesomeIcon icon={faSearch} className="usersearch" />
                  </span>
                </div>
              </div> */}
              <div className="outerWrapperTable">
                {allBusinessDTP?.dataTouchPoints && (
                  <DataTable
                    columns={columns}
                    data={filteredData}
                    progressPending={pending}
                    progressComponent={<Loader />}
                    actions={actionsMemo}
                    customStyles={customStyles}
                    pagination
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* //======================CREATE datatouch Point Start ====================== */}
      <Modal size="lg" show={showAddDTPModal} onHide={handleCloseAddDTPModal}>
        <Modal.Header closeButton>
          <Modal.Title>Add Data Touchpoint</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="container-fluid">
            <form onSubmit={handleCreateDTP}>
              <div className="row">
                <div className="col-md-12">
                  <div className="form-group">
                    <label className="form-label">
                      <Trans>Select Business</Trans>
                    </label>
                    <select
                      className=" form-control"
                      name="businessId"
                      value={businessId}
                      onChange={handleChange}
                    >
                      <option value="">
                        <Trans>Select from list</Trans>
                      </option>
                      {allBusinessData &&
                        allBusinessData?.map((allBusinesses, i) => (
                          <option value={allBusinesses._id}>
                            {allBusinesses?.name_of_business}
                          </option>
                        ))}
                    </select>
                    {!businessId && (
                      <Alert className="p-1">Please select company</Alert>
                    )}
                  </div>
                  {businessId &&
                    (!selectedBusiness?.customerQuestion ||
                      !selectedBusiness?.employeeQuestion ||
                      selectedBusinessReason.length === 0 ||
                      !selectedBusiness?.reasonQuestion) && (
                      <div className="col-md-12">
                        <Alert className="p-1">
                          The Business does not have default settings for voting
                        </Alert>
                      </div>
                    )}
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <label className="form-label">Name</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter name"
                      name="name"
                      value={name}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label className="form-label">Email</label>
                    <input
                      type="email"
                      className="form-control"
                      placeholder="Enter email"
                      name="email"
                      value={email}
                      onChange={handleChange}
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6 position-relative">
                  <div className="form-group">
                    <label className="form-label">
                      <Trans>Password</Trans>
                    </label>
                    <input
                      type={PasswordVisible ? 'password' : 'text'}
                      name="password"
                      value={password}
                      onChange={handleChange}
                      className="form-control"
                    />
                    {PasswordVisible ? (
                      <img
                        src="/assets/icons/EyeView.svg"
                        alt="DropDownIcon"
                        className="DropDownIconsInputField DatePickerDropDown c_pointer EyeIconsInAddressInSignUp"
                        onClick={() => {
                          setPasswordVisible(!PasswordVisible);
                        }}
                      />
                    ) : (
                      <img
                        src="/assets/icons/EyeViewNot.svg"
                        alt="DropDownIcon"
                        className="DropDownIconsInputField DatePickerDropDown c_pointer EyeIconsInAddressInSignUp"
                        onClick={() => {
                          setPasswordVisible(!PasswordVisible);
                        }}
                      />
                    )}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label className="form-label">
                      <Trans>Contact number</Trans>
                    </label>
                    <input
                      type="text"
                      name="contactNumber"
                      value={contactNumber}
                      onChange={handleChange}
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label className="form-label">
                      <Trans>Address</Trans>
                    </label>
                    <input
                      type="text"
                      name="location"
                      value={location}
                      onChange={handleChange}
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label className="form-label">Country</label>
                    <select
                      className="form-select"
                      name="selectCountry"
                      value={selectedCountry}
                      onChange={handleCountryChange}
                    >
                      <option value="">
                        <Trans>Select from list</Trans>
                      </option>
                      {getCountries?.countries?.map((country) => (
                        <option
                          key={country.countryId}
                          value={country.countryId}
                        >
                          {country.country}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label className="form-label">City</label>{' '}
                    <div>
                      {isCitiesLoading ? (
                        <div className="form-control d-flex justify-content-center">
                          <ReactLoading
                            type={'spinningBubbles'}
                            color={'#000000'}
                            height={24}
                            width={17}
                          />
                        </div>
                      ) : (
                        <select
                          className="form-control"
                          value={city}
                          name="city"
                          onChange={handleChange}
                        >
                          <option value="">
                            <Trans>Select from list</Trans>...
                          </option>
                          {getCities?.map((city, index) => (
                            <option key={index} value={city?._id}>
                              {city?.name_of_city}
                            </option>
                          ))}
                        </select>
                      )}
                    </div>
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="form-group">
                    <label className="form-label">
                      <Trans>Postal Code</Trans>
                    </label>
                    <input
                      type="text"
                      name="postalCode"
                      value={postalCode}
                      onChange={handleChange}
                      className="form-control"
                    />
                  </div>
                </div>
                {/* <div className="col-md-3">
                  <div className="form-group">
                    <label className="form-label text-nowrap">Max. Votes</label>
                    <input
                      type="number"
                      name="maxVotes"
                      value={maxVotes}
                      onChange={handleChange}
                      className="form-control"
                    />
                  </div>
                </div> */}

                <div className="col-md-6 d-none">
                  <div className="form-group">
                    <label className="form-label">Select Language</label>{' '}
                    <select
                      className="form-control"
                      value={language}
                      name="language"
                      onChange={handleChange}
                    >
                      <option value="">
                        <Trans>Select from list</Trans>...
                      </option>
                      <option value="English">English</option>
                      <option value="Italian">Italian</option>
                      <option value="French">French</option>
                      <option value="Spanish">Spanish</option>
                      <option value="Portuguese">Portuguese</option>
                    </select>
                  </div>
                </div>
                <div className="col-md-12 d-none">
                  <div className="form-group">
                    <label className="form-label">Select Question</label>
                    <select
                      className="form-control"
                      value={question}
                      name="question"
                      onChange={handleChange}
                    >
                      <option value="">
                        <Trans>Select from list</Trans>...
                      </option>
                      {allQuestions &&
                        allQuestions
                          .filter((question) => question.language === language)
                          .map((question) => (
                            <option
                              key={question._id}
                              value={question.question}
                            >
                              {question.question}{' '}
                              <span>({question.language})</span>
                            </option>
                          ))}
                    </select>
                  </div>
                </div>

                <div className="col-md-12 d-none">
                  <div className="form-group mb-2">
                    <label className="form-label">Select Reason</label>{' '}
                    <Select
                      id="multiple-select-field"
                      isMulti
                      options={optionReasons}
                      onChange={handleReasonOptChange}
                      value={reasonsVal}
                    />
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-md-12">
                  <div className="form-group">
                    <label className="form-label">Description</label>
                    <textarea
                      className="form-control"
                      name="description"
                      value={description}
                      onChange={handleChange}
                      rows={3}
                      placeholder="Enter description"
                    />
                  </div>
                </div>
              </div>

              <div className="row my-3">
                <label className="form-label mb-2">Measuring</label>
                <div className="d-flex justify-content-left align-items-start">
                  <button
                    type="button"
                    className={`btn btn-logfeedBack rounded-pill ${
                      selectedButton === 'Customer Experience'
                        ? 'selected'
                        : 'unSelected'
                    }`}
                    onClick={() => handleButtonClick('Customer Experience')}
                  >
                    <Trans>Customer Experience</Trans>
                  </button>
                  <button
                    type="button"
                    className={`btn btn-logfeedBack rounded-pill ms-3 ${
                      selectedButton === 'Employee Sentiment'
                        ? 'selected'
                        : 'unSelected'
                    }`}
                    onClick={() => handleButtonClick('Employee Sentiment')}
                  >
                    <Trans>EmployeeSentiment</Trans>
                  </button>
                </div>
              </div>

              <div className="d-flex justify-content-end mt-4">
                <div className="d-flex justify-content-between gap-2">
                  <button
                    type="button"
                    className="btn btn-secondary w-100"
                    onClick={handleCloseAddDTPModal}
                  >
                    Cancel
                  </button>
                  {isLoadingCreateBusinessDPT ? (
                    <div>
                      {' '}
                      <ButtonLoading />
                    </div>
                  ) : (
                    <button
                      type="submit"
                      className="btn btn-primary d-block  mx-auto"
                      onClick={handleCreateDTP}
                    >
                      Create
                    </button>
                  )}
                </div>
              </div>
            </form>
          </div>
        </Modal.Body>
      </Modal>
      {/* //======================CREATE datatouch Point End ====================== */}

      {/* //======================Request QR code Stickers Start ====================== */}

      <Modal
        show={showRequestTempModal}
        onHide={handleRequestTempClose}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <Trans>Request QR Stickers</Trans>{' '}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form>
            <div className="row">
              {businessStripeCus === undefined && (
                <Alert className="p-1  text-center">
                  Business/Business does not set payment method.
                </Alert>
              )}
            </div>
            <div className="row">
              <div className="col-md-6">
                <label className="form-label">
                  <Trans>QR Template </Trans>
                </label>
                <select
                  className="form-select"
                  name="templateId"
                  value={templateId}
                  onChange={handleOnChangeSticker}
                >
                  <option>
                    <Trans>Select from list</Trans>...
                  </option>
                  {allQrCodeTemplates &&
                    allQrCodeTemplates
                      .filter((template) => template?.qrCodeType === reqType)
                      .map((template, i) => (
                        <option value={template._id} key={i}>
                          {template?.name}
                        </option>
                      ))}
                </select>
              </div>
              <div className="col-md-6">
                <label className="form-label">
                  <Trans>Sticker Size</Trans>
                </label>
                <select
                  className="form-select"
                  name="stickerSize"
                  value={stickerSize}
                  onChange={handleOnChangeSticker}
                >
                  <option>
                    <Trans>Select from list</Trans>...
                  </option>
                  {allStickers &&
                    allStickers.map((sticker, i) => (
                      <option key={i} value={sticker?.size}>
                        {sticker?.size} (
                        <span className="text-success">
                          Unit Price {sticker?.pricePlusVat}
                        </span>
                        ){' '}
                      </option>
                    ))}
                </select>
              </div>
              <div className="col-md-6">
                <label className="form-label">
                  <Trans>Units</Trans>
                </label>
                <input
                  type="number"
                  name="units"
                  value={units}
                  onChange={handleOnChangeSticker}
                  className="form-control"
                />
              </div>
              <div className="col-md-6">
                <label className="form-label">
                  <Trans>Shipping Address</Trans>
                </label>
                <input
                  type="text"
                  className="form-control"
                  name="shippingAddress"
                  value={shippingAddress}
                  onChange={handleOnChangeSticker}
                />
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label className="form-label">
                    <Trans>Country</Trans>
                  </label>
                  <select
                    className="form-select"
                    name="selectedCountry"
                    value={selectedCountry}
                    onChange={handleCountryChange}
                  >
                    <option value="">
                      <Trans>Select from list</Trans>
                    </option>
                    {AllCountry?.map((country) => (
                      <option key={country.isoCode} value={country.isoCode}>
                        {country.country}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label className="form-label">
                    <Trans>City</Trans>
                  </label>
                  {CitiesLoading ? (
                    <div className="form-control d-flex justify-content-center">
                      <ReactLoading
                        type={'spinningBubbles'}
                        color={'#000000'}
                        height={24}
                        width={17}
                      />
                    </div>
                  ) : (
                    <select
                      className="form-select"
                      name="stickerCity"
                      value={stickerCity}
                      onChange={handleOnChangeSticker}
                    >
                      <option value="">
                        <Trans>Select from list</Trans>
                      </option>
                      {cities.map((city, index) => (
                        <option key={index} value={city}>
                          {city}
                        </option>
                      ))}
                    </select>
                  )}
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <label className="form-label">
                  <Trans>Postal Code</Trans>
                </label>
                <input
                  type="text"
                  className="form-control"
                  name="stickerPostalCode"
                  value={stickerPostalCode}
                  onChange={handleOnChangeSticker}
                />
              </div>
              <div className="col-md-6">
                <label className="form-label">
                  <Trans>Total Price</Trans>
                </label>
                <h3 className="text-center text-nowrap fw-600">
                  Total : € {totalPrice}
                </h3>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="d-flex justify-content-end">
                  <div className="d-flex justify-content-between gap-2">
                    <button
                      type="button"
                      className="btn btn-secondary w-100"
                      onClick={handleRequestTempClose}
                    >
                      Cancel
                    </button>
                    {isLoadingAddStickerRequest ? (
                      <div>
                        {' '}
                        <ButtonLoading />
                      </div>
                    ) : (
                      <Link
                        className="btn btn-primary"
                        style={{ float: 'right' }}
                        onClick={handleRequestSticker}
                      >
                        <Trans>Request</Trans>
                      </Link>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </form>
        </Modal.Body>
      </Modal>
      {/* //======================Request QR Stickers code End alex====================== */}
      <Modal show={isModalOpen} onHide={() => setIsModalOpen(false)}>
        <Modal.Header closeButton>
          <Modal.Title>
            <Trans>QR Code</Trans>{' '}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            {selectedQRButton === 'TouchPoint QrCode' && (
              <div id="capture">
                <div className="d-flex justify-content-center">
                  <button
                    className="btn btn-primary btn-sm "
                    onClick={() => downLoadQrCode(selectedImage)}
                  >
                    <FontAwesomeIcon icon={faDownload} />{' '}
                  </button>
                </div>
                <img
                  src={REACT_APP_BASE_URL_QR_CODE + selectedImage}
                  alt="Selected Product"
                  className="d-block m-auto mb-5 img-responsive"
                />
                {dtpSlug ? (
                  <div className="row">
                    <div className="d-flex">
                      <input
                        className="form-control"
                        type="email"
                        placeholder={`${CLIENT_URL}/review/${dtpSlug}`}
                        defaultValue={`${CLIENT_URL}/review/${dtpSlug}`}
                        disabled={true}
                      />
                      <button
                        type="button"
                        className="btn"
                        onClick={() => {
                          navigator.clipboard.writeText(
                            `${CLIENT_URL}/review/${dtpSlug}`
                          );
                          toast.success('Voting url Copied Successfully');
                        }}
                      >
                        <FontAwesomeIcon icon={faCopy} />
                      </button>
                    </div>
                  </div>
                ) : (
                  <></>
                )}

                <div className="mx-auto d-flex justify-content-center my-3">
                  <button
                    className="btn btn-sm btn-primary"
                    onClick={onClickSlug}
                  >
                    <Trans>Preview Link</Trans>
                  </button>
                </div>
              </div>
            )}
            {selectedQRButton === 'Template QrCode' && (
              <div id="capture">
                {qrTemplate && (
                  <div className="" ref={componentRef}>
                    <div
                      className="section-to-print"
                      style={{
                        backgroundColor: qrTemplate.bgColor,
                        // width: "200px",
                        // height: "400px",
                      }}
                    >
                      <div className="text-center">
                        <h6
                          style={{
                            color: qrTemplate?.fgColor,
                            fontSize: `${qrTemplate?.fgTextSize}px`,
                            overflow: 'hidden',
                          }}
                        >
                          {qrTemplate?.fgText}
                        </h6>
                        <div className="d-flex gap-2 p-3">
                          <QRCode
                            value={`${CLIENT_URL}/review/${dtpSlug}`}
                            fgColor={qrTemplate?.fgColor}
                            bgColor={qrTemplate?.bgColor}
                            style={{
                              width: '100%',
                            }}
                          />
                          <h6
                            className="vertical-text vertical-text m-0"
                            style={{
                              color: qrTemplate?.fgColor,
                            }}
                          >
                            {Name}
                          </h6>
                        </div>
                        {qrTemplate?.companyLogo && (
                          <div className="">
                            <img
                              src={BASE_URL_IMAGES + qrTemplate?.companyLogo}
                              // width={120}
                              // height={60}
                              alt="QR code"
                              className="img-fluid w-50 qr-logo-img"
                            />
                          </div>
                        )}
                        <div className="mt-1">
                          <h6
                            className="mb-0"
                            style={{
                              color: qrTemplate?.fgColor,
                            }}
                          >
                            Powered By
                          </h6>
                          <div className="text-center">
                            <img
                              src={
                                qrTemplate?.poweredByLogo === 'white'
                                  ? logoWhite
                                  : qrTemplate?.poweredByLogo === 'blue'
                                  ? logoBlue
                                  : qrTemplate?.poweredByLogo === 'black'
                                  ? logoBlack
                                  : null
                              }
                              alt="Our Logo"
                              className="img-fluid w-20 text-center"
                              // style={{
                              //   objectFit: "cover",
                              // }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
        </Modal.Body>
        {selectedQRButton === 'Template QrCode' && (
          <Modal.Footer>
            <div className="d-flex justify-content-between gap-2">
              <button
                type="button"
                className="btn btn-secondary w-100"
                onClick={() => setIsModalOpen(false)}
              >
                Cancel
              </button>
              <button className="btn btn-primary" onClick={sendData}>
                Download
              </button>
            </div>
          </Modal.Footer>
        )}
      </Modal>
    </>
  );
};

export default Touchpoints;
