import api from "../../utility/api";

// Add New  Benchmark
const getCountries = async (data) => {
  const response = await api.get("/country/getall", data);
  return response;
};

// Edit Benchmark
const getCities = async (data) => {
  const response = await api.get(`/city/getByCountry/${data}`,);
  return response;
};

// Add Countries
const addCountries = async (data) => {
  const response = await api.post(`/country/create`,data);
  return response;
};

// Add Cities
const addCities = async (data) => {
  const response = await api.post(`/city/create`,data);
  return response;
};

// deleteCountry
const deleteCountry = async (data) => {
  const response = await api.delete(`/country/${data}`);
  return response;
};

const getCountriesAndCities = {
    getCountries,
    getCities,
    addCountries,
    addCities,
    deleteCountry,
};

export default getCountriesAndCities;
