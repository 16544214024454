import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import reasonQuestionService from "./reasonQuestionService";
import { toast } from "react-toastify";

const verificationCode = process.env.REACT_APP_VERIFICATION_TOKEN;

const initialState = {
  allQuestionReason: [],
  isLoading: false,
  isError: false,
  isReaQSuccess: false,
  message: "",
  isLoadingAddQuestionReason:false
};

// Add New  Question Reason
export const addQuestionReason = createAsyncThunk(
  "auth/addQuestionReason",
  async (data, thunkAPI) => {
    try {
      const res = await reasonQuestionService.addQuestionReason(data);
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.error);
    }
  }
);

// Edit Question Reason
export const editQuestionReason = createAsyncThunk(
  "auth/editQuestionReason",
  async (data, thunkAPI) => {
    try {
      await reasonQuestionService.editQuestionReason(data);
      const res = await reasonQuestionService.getAllQuestionReason({
        verificationCode: verificationCode,
      });
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.error);
    }
  }
);

// Get All Question Reasons
export const getAllQuestionReason = createAsyncThunk(
  "auth/getAllQuestionReason",
  async (data, thunkAPI) => {
    try {
      const res = await reasonQuestionService.getAllQuestionReason(data);
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.error);
    }
  }
);

export const reasonQuestion = createSlice({
  name: "reasonQuestion",
  initialState,
  reducers: {
    reset: (state) => {
      state.isLoading = false;
      state.isError = false;
      state.message = "";
      state.isReaQSuccess = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllQuestionReason.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAllQuestionReason.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isReaQSuccess = true;
        state.allQuestionReason = action.payload;
      })
      .addCase(getAllQuestionReason.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        toast.error(action.payload);
      })
      .addCase(addQuestionReason.pending, (state) => {
        state.isLoadingAddQuestionReason = true;
      })
      .addCase(addQuestionReason.fulfilled, (state, action) => {
        state.isLoadingAddQuestionReason = false;
        state.isReaQSuccess = true;
        state.allQuestionReason = action.payload;
        toast.success("Question for Reason added successfully.");
      })
      .addCase(addQuestionReason.rejected, (state, action) => {
        state.isLoadingAddQuestionReason = false;
        state.isError = true;
        toast.error(action.payload);
      })
      .addCase(editQuestionReason.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(editQuestionReason.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isReaQSuccess = true;
        state.allQuestionReason = action.payload;
        toast.success("Question for Reason updated successfully.");
      })
      .addCase(editQuestionReason.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        toast.error(action.payload);
      });
  },
});

export const { reset } = reasonQuestion.actions;
export default reasonQuestion.reducer;
