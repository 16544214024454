import { useEffect, useState, useRef } from "react";
import axios from "axios";
import { Button, Modal } from "react-bootstrap";

import { toast } from "react-toastify";
import JoditEditor from "jodit-react";

//============redux import============
import { useSelector } from "react-redux";
import api from "../../../../utility/api";

const verificationCode = process.env.REACT_APP_VERIFICATION_TOKEN;
const BASE_URL = process.env.REACT_APP_BASE_URL;

const TermsAndCondition = () => {
  const { profile } = useSelector((state) => {
    return state.auth;
  });

  //=========Fetching Terms and Conditions Start =================================

  const [allTermsAndConditions, setallTermsAndConditions] = useState([]);

  useEffect(() => {
    getTermsAndConditions();
  }, []);

  const getTermsAndConditions = async () => {
    try {
      const res = await axios.post(`${BASE_URL}/termAndCondition/getAll`, {
        verificationCode,
      });

      if (res.data) setallTermsAndConditions(res?.data);
    } catch (err) {
    }
  };
  //=========Fetching Terms and Conditions END =================================

  //---------------------Form Data with change Handler start------------------------
  const [title, setTitle] = useState();
  const [content, setContent] = useState("");

  //---------------------Form Data with change Handler end------------------------

  //-------------------EditorToolbar start----------------
  const editor = useRef(null);
  const config = {
    readonly: false,
    askBeforePasteHTML: false,
    askBeforePasteFromWord: false,
    saveModeInStorage: false,
    disablePlugins:
      "drag-and-drop,file,image,image-processor,image-properties,media,mobile,preview,powered-by-jodit,speech-recognize,sticky,video",
    buttons:
      "bold,italic,underline,strikethrough,ul,ol,spellcheck,cut,copy,paste,selectall,hr,symbol,indent,outdent,left,undo,redo,find,link",
  };

  //-------------------EditorToolbar end----------------

  //-------------------Handle Add Term And Condtion start-----------------------
  const [add_TermsAndConditions, setadd_TermsAndConditions] = useState(false);
  const tog_add_TermsAndConditions = () => {
    setTitle("");
    setContent("");
    setadd_TermsAndConditions(true);
  };

  const handleTermsAndConditions = async (e) => {
    e.preventDefault();

    try {
      const res = await api.post(`${BASE_URL}/termAndCondition/create`, {
        verificationCode,
        title: title,
        content: content,
      });
      if (res.data.success === true) {
        toast.success(res.data?.msg);
        setadd_TermsAndConditions(false);
        getTermsAndConditions();
        setTitle("");
        setContent("");
      } else {
        if (res.data.success === false) {
          toast.error(res.data.message);
        }
      }
    } catch (error) {
      toast.error(error);
    }
  };

  //-------------------Handle Add Term and condition end-----------------------

  //=================Edit Term and condition Modal start==============
  const [editId, setEditId] = useState();
  const [editTermsAndConditions, seteditTermsAndConditions] = useState(false);

  function tog_edit(t) {
    setEditId(t);
    for (let i = 0; i < allTermsAndConditions.length; i++) {
      if (allTermsAndConditions[i]?._id === t) {
        setTitle(allTermsAndConditions[i].title);
        setContent(allTermsAndConditions[i].content);
      }
      seteditTermsAndConditions(true);
    }
  }

  const handlEditTermsAndConditions = async (e) => {
    e.preventDefault();
    if (title === "") {
      return toast.error("Please enter title");
    }

    try {
      const res = await api.put(`${BASE_URL}/termAndCondition/update`, {
        verificationCode,
        id: editId,
        title: title,
        content: content,
      });

      if (res.data.success === true) {
        toast.success(res.data?.msg);
        seteditTermsAndConditions(false);
        getTermsAndConditions();
        setTitle("");
        setContent("");
      } else {
        if (res.data.success === false) {
          toast.error(res.data.message);
        }
      }
    } catch (error) {
      toast.error(error);
    }
  };
  //=================Edit Term and condition Modal end==============
  return (
    <div>
      <section className="profile-infos d-block">
        <div className="container">
          <div className="col-12 col-md-12 mx-auto">
            <div className="container  rounded">
              {allTermsAndConditions.length === 0 && (
                <div className="row my-3 rounded">
                  <div className="infos-btns">
                    <button
                      className="btn btn-primary "
                      onClick={tog_add_TermsAndConditions}
                    >
                      Add Terms & Conditions
                    </button>
                  </div>
                </div>
              )}

              <div className="row mt-4">
                {allTermsAndConditions.length > 0 &&
                  allTermsAndConditions.map((CPolicy, idx) => (
                    <>
                      <h2 className="" key={idx}>
                        {CPolicy.title}
                      </h2>

                      <div className="col-md-12 mx-auto">
                        <div
                          dangerouslySetInnerHTML={{ __html: CPolicy?.content }}
                        />
                      </div>
                      {profile?.role === "admin" && (
                        <div className="infos-btns my-3">
                          <button
                            className="btn btn-primary"
                            onClick={() => tog_edit(CPolicy._id)}
                          >
                            Update
                          </button>
                        </div>
                      )}
                    </>
                  ))}
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Add Term and condition Modal start */}

      {/* //=====Update/Edit Term and condition modal=========== */}
      <Modal
        show={add_TermsAndConditions}
        onHide={() => setadd_TermsAndConditions(false)}
        centered
        dialogClassName="custom-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title>Add Terms & Conditions</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form className="form fv-plugins-bootstrap5 fv-plugins-framework">
            <div className="row">
              <div className="d-flex flex-column col-lg-12 mb-8 fv-row fv-plugins-icon-container">
                <label className="d-flex align-items-center fs-6 fw-semibold mb-2">
                  <span>Title</span>
                </label>
                <input
                  type="text"
                  className="form-control form-control-solid"
                  placeholder="Enter your Terms & Conditions title"
                  name="title"
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                />
                <div className="fv-plugins-message-container invalid-feedback" />
              </div>
            </div>

            <div className="d-flex flex-column mb-8 fv-row fv-plugins-icon-container">
              <label className="fs-6 fw-semibold my-2">Content</label>
              <JoditEditor
                ref={editor}
                value={content}
                config={config}
                onBlur={(newContent) => setContent(newContent)}
              />

              <div className="fv-plugins-message-container invalid-feedback" />
            </div>
            <div className="text-center">
              <div className="infos-btns mx-auto">
                <button
                  className=" my-3 btn btn-primary"
                  onClick={handleTermsAndConditions}
                >
                  Submit
                </button>
              </div>
            </div>
          </form>
        </Modal.Body>
      </Modal>
      {/* //=====Add Term and condition modal END=========== */}

      {/* EDIT Cookie policy start */}

      {/* //=====Update/Edit Term and condition modal=========== */}
      <Modal
        show={editTermsAndConditions}
        onHide={() => seteditTermsAndConditions(false)}
        centered
        dialogClassName="custom-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title>Edit Terms & Conditions</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form className="form fv-plugins-bootstrap5 fv-plugins-framework">
            <div className="row">
              <div className="d-flex flex-column col-lg-12 mb-8 fv-row fv-plugins-icon-container">
                <label className="d-flex align-items-center fs-6 fw-semibold mb-2">
                  <span>Title</span>
                </label>
                <input
                  type="text"
                  className="form-control form-control-solid"
                  placeholder="Enter your Terms & Conditions title"
                  name="title"
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                />
                <div className="fv-plugins-message-container invalid-feedback" />
              </div>
            </div>

            <div className="d-flex flex-column mb-8 fv-row fv-plugins-icon-container">
              <label className="fs-6 fw-semibold my-2">Content</label>
              <JoditEditor
                ref={editor}
                value={content}
                config={config}
                onBlur={(newContent) => setContent(newContent)}
              />

              <div className="fv-plugins-message-container invalid-feedback" />
            </div>
            <div className="text-center">
              <div className="infos-btns mx-auto">
                <button
                  className=" my-3 btn btn-primary"
                  onClick={handlEditTermsAndConditions}
                >
                  Update
                </button>
              </div>
            </div>
          </form>
        </Modal.Body>
      </Modal>
      {/* //=====Update/Edit Term and condition modal END=========== */}
    </div>
  );
};

export default TermsAndCondition;
