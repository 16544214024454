import api from "../../utility/api";

// Add New  Subscription
const addSubscription = async (data) => {
  const response = await api.post("/subscription/create", data);
  return response;
};

// Get All Subscriptions
const getAllSubscriptions = async (data) => {
  const response = await api.post("/subscription/getAll", data);
  return response;
};

// Delete Subscription by Admin
const deleteSubscription = async (data) => {
  const response = await api.post("/subscription/delete", data);
  return response;
};

// Edit Subscription by Admin
const editSubscription = async (data) => {
  const response = await api.put("/subscription/updateSub", data);
  return response;
};

const subscriptionService = {
  getAllSubscriptions,
  addSubscription,
  deleteSubscription,
  editSubscription,
};

export default subscriptionService;
