import React, { useState, useEffect, useRef } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import moment from 'moment';
import logoBlue from '../../../../assets/images/icons/GoRateLogo.png';
import logoWhite from '../../../../assets/images/icons/GoRateWhite.png';
import logoBlack from '../../../../assets/images/icons/GoRateBlack.png';

import ReactToPrint from 'react-to-print';

import { useMyContext } from '../../../..';
import { Trans } from 'react-i18next';

//===========Data table =================
import DataTable from 'react-data-table-component';

//=========Redux===========
import { useSelector, useDispatch } from 'react-redux';
import {
  createStickerNote,
  getAllStickerRequests,
  reset,
  updateStickerRequest,
  //   reset,
} from '../../../../redux/stickerRequest/stickerRequestSlice';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faSearch,
  faDownload,
  faPlus,
  faEye,
  faPrint,
} from '@fortawesome/free-solid-svg-icons';
//---import Loader
import Loader from '../../../../Loader';
import { toast } from 'react-toastify';
import ExportCSVButton from '../../../../common/ExportCSVButton';

// import "./sticker.css";

//======Utils
const verificationCode = process.env.REACT_APP_VERIFICATION_TOKEN;
const BASE_URL_IMAGES = process.env.REACT_APP_BASE_URL_IMAGE;
const REACT_APP_BASE_URL_QR_CODE = process.env.REACT_APP_BASE_URL_QR_CODE;

const Sticker = () => {
  const dispatch = useDispatch();

  const { allStickerRequests, isSuccess } = useSelector((state) => {
    return state.stickerRequest;
  });

  //=======Get All Sticker Requests =================
  useEffect(() => {
    dispatch(getAllStickerRequests({ verificationCode }));
  }, [dispatch]);

  //==========Update Sticker Requests Status=================
  //============Handler for update Data Touchpoint status to Active===========
  const handleUpdateRequest = (e, id) => {
    e.preventDefault();
    let body = {
      verificationCode: verificationCode,
      stickerId: id,
    };
    dispatch(updateStickerRequest(body));
  };

  //===========View Config Data Sticker Data & Modal============
  const [reqData, setReqData] = useState({});

  const [showRequestData, setShowRequestData] = useState(false);
  const handleCloseRequestData = () => setShowRequestData(false);
  const handleShowRequestData = (e, reqData) => {
    e.preventDefault();
    setReqData(reqData);
    setShowRequestData(true);
  };

  //=====  Download Image  =====

  const handleTemImgClicked = (tempImg) => {
    const downloadLink = document.createElement('a');
    downloadLink.href = REACT_APP_BASE_URL_QR_CODE + tempImg;
    downloadLink.download = tempImg;
    downloadLink.click();
  };

  const handleCompanyImgClicked = (logoImg) => {
    const downloadLink = document.createElement('a');
    downloadLink.href = BASE_URL_IMAGES + logoImg;
    downloadLink.download = logoImg;
    downloadLink.click();
    // setComLogoClicked(true);
  };

  //=========================Print QR template Request =========================
  const [reqPrintData, setReqPrintData] = useState({});

  const [showPrintData, setShowPrintData] = useState(false);
  const handleClosePrintData = () => setShowPrintData(false);
  const handleShowPrintData = (e, reqData) => {
    e.preventDefault();
    setReqPrintData(reqData);
    setShowPrintData(true);
  };

  const componentRef = useRef();

  //=========================Print QR template Request =========================

  //==========================Table======================//
  //Add Notes Modal
  const [showModalNotes, setShowModalNotes] = useState(false);

  const [note, setNote] = useState('');
  const [stickerId, setStickerId] = useState('');
  //===Array from Sticker
  const [stickerNotes, setStickerNotes] = useState([]);

  const handleCloseNotes = () => {
    setShowModalNotes(false);
  };

  const handleOpenNotes = (notes, id) => {
    if (id) {
      setStickerId(id);
    }
    setStickerNotes(notes);
    setShowModalNotes(true);
  };

  const handleCreateNote = (event) => {
    event.preventDefault();
    if (!note.trim()) {
      return toast.error('Please enter a note!');
    }
    let body = {
      verificationCode: verificationCode,
      stickerId: stickerId,
      note: note,
    };
    dispatch(createStickerNote(body));
  };

  //-------------------DATA TABLE START ------------------

  const [pending, setPending] = useState(true);

  //====Search Field
  const [searchQuery, setSearchQuery] = useState('');

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      setPending(false);
    }, 500);
    return () => clearTimeout(timeout);
  }, []);

  const DataTableActionButtons = ({ actions, rowData }) => (
    <>
      {rowData?.status !== true && (
        <button
          type="button"
          className="btn btnPrint fs-6"
          // onClick={(e) => handleUpdateRequest(e, rowData?._id)}
          title="Active"
        >
          <span className="badge bg-warning py-2  ">Not Paid</span>
        </button>
      )}
      {rowData?.status === true && (
        <button
          type="button"
          className="btn btnPrint fs-6 border-0"
          disabled
          title="Active"
        >
          <span className="badge bg-success py-2 ">Paid</span>
        </button>
      )}
    </>
  );

  const columns = [
    {
      name: 'Business Name',
      selector: (row) => row?.business?.name_of_business,
      sortable: true,
      center: true,
    },

    {
      name: <Trans>Email</Trans>,
      selector: (row) => row?.business?.email,
      sortable: true,
      center: true,
    },
    {
      name: 'Invoicing Address',
      selector: (row) => row?.business?.invoiceAddress,
      sortable: true,
      center: true,
    },

    {
      name: 'Total Amount',
      selector: (row) => '€ ' + row?.totalPrice,
      sortable: true,
      center: true,
    },
    {
      name: 'Request Date',
      selector: (row) => moment(row?.createdAt).format('DD-MM-YYYY'),
      sortable: true,
      center: true,
    },
    {
      name: 'Sticker Note',

      selector: (row) => (
        <>
          <button
            type="button"
            className="btn btnPrint fs-6 border-0"
            title="Add Sticker Note"
            onClick={(e) => handleOpenNotes(row?.notes, row?._id)}
          >
            <FontAwesomeIcon icon={faPlus} />{' '}
          </button>
        </>
      ),
      center: true,
    },
    {
      name: 'Sticker Template',

      selector: (row) => (
        <>
          <button
            type="button"
            className="btn btnPrint fs-6"
            onClick={(e) => handleShowRequestData(e, row)}
            title="View Config"
          >
            <FontAwesomeIcon icon={faEye} />{' '}
          </button>
          <button
            type="button"
            className="btn btnPrint fs-6"
            onClick={(e) => handleShowPrintData(e, row)}
            title="Print Template"
          >
            <FontAwesomeIcon icon={faPrint} />{' '}
          </button>
        </>
      ),
      center: true,
    },
    {
      name: 'Status',
      cell: (row) => <DataTableActionButtons rowData={row} />,
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
      center: true,
    },
  ];

  //  Internally, customStyles will deep merges your customStyles with the default styling.
  const customStyles = {
    rows: {
      style: {
        minHeight: '60px',
      },
    },
    headCells: {
      style: {
        fontSize: '14px',
        color: '#B5B7C0',
        textAlign: 'center',
      },
    },
    cells: {
      style: {
        textAlign: 'center', // Align regular cell content to center
        color: '#292D32 !important',
        fontWeight: '600',
      },
    },
  };

  // Apply the filter based on the input text
  const filteredData = searchQuery
    ? allStickerRequests.filter(
        (item) =>
          item?.business?.name_of_business
            .toLowerCase()
            .includes(searchQuery.toLowerCase()) ||
          item.business.email
            .toLowerCase()
            .includes(searchQuery.toLowerCase()) ||
          item?.business.invoiceAddress
            .toLowerCase()
            .includes(searchQuery.toLowerCase())
      )
    : allStickerRequests;

  //Search Field END

  //-------Export CSV ----------------
  function convertArrayOfObjectsToCSV(array) {
    let result;

    const columnDelimiter = ',';
    const lineDelimiter = '\n';
    const keys = Object.keys(allStickerRequests[0]);

    result = '';
    result += keys.join(columnDelimiter);
    result += lineDelimiter;

    array.forEach((item) => {
      let ctr = 0;
      keys.forEach((key) => {
        if (ctr > 0) result += columnDelimiter;

        result += item[key];

        ctr++;
      });
      result += lineDelimiter;
    });

    return result;
  }

  function downloadCSV(array) {
    const link = document.createElement('a');
    let csv = convertArrayOfObjectsToCSV(array);
    if (csv == null) return;

    const filename = 'export.csv';

    if (!csv.match(/^data:text\/csv/i)) {
      csv = `data:text/csv;charset=utf-8,${csv}`;
    }

    link.setAttribute('href', encodeURI(csv));
    link.setAttribute('download', filename);
    link.click();
  }

  const Export = ({ onExport }) => (
    <Link
      className="btn btn-secondary mb-2 mx-2"
      onClick={(e) => onExport(e.target.value)}
    >
      <FontAwesomeIcon icon={faDownload} className="btnIcon" />
      <Trans>Export</Trans>
    </Link>
  );
  const actionsMemo = React.useMemo(
    () => <Export onExport={() => downloadCSV(allStickerRequests)} />,
    []
  );

  //-------------------DATA TABLE END---------------------------------

  //================Resetting data on success-------------------------
  useEffect(() => {
    if (isSuccess) {
      setNote('');
      handleCloseNotes();
    }
    dispatch(reset());
  }, [isSuccess]);

  const { language, setLanguage } = useMyContext();

  return (
    <>
      <div className="container-fluid my-3">
        <div className="row">
          <div className="col-md-3">
            {' '}
            <h3 className="align-items-center m-0 fw-500 mt-1 mb-2">
              Stickers Request
            </h3>
          </div>
          <div className="col-md-9">
            <div className="d-flex justify-content-between">
              <div className="search-field search-bar">
                <input
                  type="text"
                  placeholder={language === 'en' ? 'Search' : 'Búsqueda'}
                  className="dashboardinput"
                  id="userSearch"
                  value={searchQuery}
                  onChange={handleSearchChange}
                />
                <span className="search-icon">
                  <FontAwesomeIcon icon={faSearch} className="usersearch" />
                </span>
              </div>
              <p>
                {' '}
                <ExportCSVButton
                  data={allStickerRequests}
                  filename={'exported_data.csv'}
                />
              </p>
            </div>
          </div>
          {/* <div className="col-12 col-md-3 px-0 px-md-2">
            <select onChange={(e) => setLanguage(e.target.value)}>
              <option value={"en"} selected={language == "en"} >English</option>
              <option value={"es"} selected={language == "es"} >Spanish</option>
            </select>
          </div> */}
        </div>

        <div className="row">
          <div className="col-md-12">
            <div
              className="mt-2 outerWrapperTable"
              style={{ overflowX: 'auto' }}
            >
              {allStickerRequests && (
                <DataTable
                  columns={columns}
                  data={filteredData}
                  progressPending={pending}
                  progressComponent={<Loader />}
                  actions={actionsMemo}
                  customStyles={customStyles}
                  pagination
                />
              )}
            </div>
          </div>
        </div>
      </div>

      {/*------------------------------------------MODALS START--------------------------------------------- */}

      {/* ==============View Configuration Template Request START================= */}
      <Modal
        show={showRequestData}
        onHide={handleCloseRequestData}
        centered
        dialogClassName="custom-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title>Requested Sticker Template</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {reqData && reqData?.qrCodeTemplate && (
            <div className="row mx-1 my-3">
              <div
                className="col-12 col-md-5 mx-auto"
                style={{
                  backgroundColor: reqData?.qrCodeTemplate?.bgColor,
                  padding: '5px',
                  borderRadius: '15px',
                }}
              >
                <div className="">
                  <h6
                    style={{
                      color: reqData?.qrCodeTemplate?.fgColor,
                      fontSize: `${reqData?.qrCodeTemplate?.fgTextSize}px`,
                    }}
                  >
                    {reqData?.qrCodeTemplate?.fgText}
                  </h6>
                  <div className="">
                    <img
                      src={
                        REACT_APP_BASE_URL_QR_CODE +
                        reqData?.qrCodeTemplate?.qrCode
                      }
                      alt="QR code"
                      className="img-responsive"
                      style={{
                        objectFit: 'cover',
                        cursor: 'pointer',
                      }}
                    />
                    <span
                      className="vertical-text vertical-text"
                      style={{
                        color: reqData?.fgColor,
                      }}
                    >
                      {reqData?.Name}
                    </span>
                    <button
                      className="btn btn-primary btn-sm m-1 "
                      onClick={() =>
                        handleTemImgClicked(reqData?.qrCodeTemplate?.qrCode)
                      }
                    >
                      <FontAwesomeIcon icon={faDownload} />{' '}
                    </button>
                  </div>
                </div>
                <div className="mt-3">
                  <h6>Business Logo</h6>
                  <img
                    src={BASE_URL_IMAGES + reqData?.qrCodeTemplate?.companyLogo}
                    alt="QR code"
                    className="img-responsive"
                    style={{
                      objectFit: 'cover',
                      filter: 'contrast(1)',
                    }}
                    width={135}
                  />

                  <button
                    className="btn btn-primary btn-sm m-2"
                    onClick={() =>
                      handleCompanyImgClicked(
                        reqData?.qrCodeTemplate?.companyLogo
                      )
                    }
                  >
                    <FontAwesomeIcon icon={faDownload} />{' '}
                  </button>
                </div>
                <div className="mt-3">
                  <h6>Powered By</h6>
                  {reqData?.qrCodeTemplate?.poweredByLogo === 'white' && (
                    <img
                      src={logoWhite}
                      alt="Our Logo"
                      style={{ objectFit: '' }}
                      className="img-fluid d-block w-50"
                    />
                  )}
                  {reqData?.qrCodeTemplate?.poweredByLogo === 'blue' && (
                    <img
                      src={logoBlue}
                      alt="Our Logo"
                      className="img-fluid d-block w-50"
                    />
                  )}
                  {reqData?.qrCodeTemplate?.poweredByLogo === 'black' && (
                    <img
                      src={logoBlack}
                      alt="Our Logo"
                      className="img-fluid d-block w-50"
                    />
                  )}
                </div>
              </div>
              <div className="col-12 col-md-7">
                <div className="d-flex justify-content-between ">
                  <h6>Template Name</h6>
                  <h6>{reqData?.qrCodeTemplate?.name}</h6>
                </div>
                <hr className="mt-2" />
                <div className="d-flex justify-content-between ">
                  <h6>Template Size</h6>
                  <h6>{reqData?.stickerSize}</h6>
                </div>
                <hr className="mt-2" />
                <div className="d-flex justify-content-between ">
                  <h6>
                    <Trans>Units</Trans>
                  </h6>
                  <h6>{reqData?.units}</h6>
                </div>
                <hr className="mt-2" />

                <div className="d-flex justify-content-between">
                  <h6>Background Color</h6>
                  <h6 style={{ color: reqData?.qrCodeTemplate?.bgColor }}>
                    {reqData?.qrCodeTemplate.bgColor}
                  </h6>
                </div>
                <hr className="mt-2" />

                <div className="d-flex justify-content-between">
                  <h6>ForeGround Color</h6>
                  <h6>{reqData?.qrCodeTemplate?.fgColor}</h6>
                </div>
                <hr className="mt-2" />

                <div className="d-flex justify-content-between">
                  <h6>ForeGround Text</h6>
                  <h6>{reqData?.qrCodeTemplate?.fgText}</h6>
                </div>
              </div>
            </div>
          )}
        </Modal.Body>
      </Modal>

      {/* ==============View Configuration Template Request END=================== */}

      {/* ==============Print QR template  START================= */}
      <Modal
        show={showPrintData}
        onHide={handleClosePrintData}
        centered
        dialogClassName="custom-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title>Print Sticker Template</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {reqPrintData && reqPrintData?.qrCodeTemplate && (
            <div className="row " ref={componentRef}>
              <div
                className="col-12 col-md-8 mx-auto section-to-print"
                style={{
                  backgroundColor: reqPrintData?.qrCodeTemplate?.bgColor,
                  // width: "200px",
                  // height: "400px",
                }}
              >
                <div className="text-center">
                  <h6
                    style={{
                      color: reqPrintData?.qrCodeTemplate?.fgColor,
                      fontSize: `${reqPrintData?.qrCodeTemplate?.fgTextSize}px`,
                    }}
                  >
                    {reqPrintData?.qrCodeTemplate?.fgText}
                  </h6>
                  <div className="d-flex">
                    <img
                      src={
                        REACT_APP_BASE_URL_QR_CODE +
                        reqPrintData?.qrCodeTemplate?.qrCode
                      }
                      alt="QR code"
                      style={{
                        width: '100%',
                        height: 'auto',
                      }}
                    />
                    <span
                      className="vertical-text vertical-text"
                      style={{
                        color: reqPrintData?.qrCodeTemplate?.fgColor,
                      }}
                    >
                      {reqPrintData?.qrCodeTemplate?.name
                        ? reqPrintData?.qrCodeTemplate?.name
                        : reqPrintData?.qrCodeTemplate?.Name}
                    </span>
                    {/* <span
                      className="vertical-text"
                      style={{
                        color: reqPrintData?.qrCodeTemplate?.fgColor,
                      }}
                    >
                      {reqPrintData?.qrCodeTemplate?.name}
                    </span> */}
                  </div>
                  {reqPrintData?.qrCodeTemplate?.companyLogo && (
                    <div className="">
                      <img
                        src={
                          BASE_URL_IMAGES +
                          reqPrintData?.qrCodeTemplate?.companyLogo
                        }
                        // width={120}
                        // height={60}
                        alt="QR code"
                        className="img-fluid w-50 qr-logo-img"
                        // style={{
                        //   objectFit: "cover",
                        // }}
                      />
                    </div>
                  )}
                  <div className="">
                    <h6 className="mb-0">Powered By</h6>
                    <div className="text-center">
                      <img
                        src={
                          reqPrintData?.qrCodeTemplate?.poweredByLogo ===
                          'white'
                            ? logoWhite
                            : reqPrintData?.qrCodeTemplate?.poweredByLogo ===
                              'blue'
                            ? logoBlue
                            : reqPrintData?.qrCodeTemplate?.poweredByLogo ===
                              'black'
                            ? logoBlack
                            : null
                        }
                        alt="Our Logo"
                        className="img-fluid w-50 text-center"
                        // style={{
                        //   objectFit: "cover",
                        // }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <ReactToPrint
            trigger={() => <button className="btn btn-primary">Print</button>}
            content={() => componentRef.current}
          />
        </Modal.Footer>
      </Modal>

      {/* ==============Print QR template  END=================== */}

      {/* Add Note for Sticker Start*/}

      <Modal show={showModalNotes} onHide={handleCloseNotes}>
        <Modal.Header closeButton>
          <Modal.Title>Add Note</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleCreateNote}>
            <Form.Group controlId="noteTextArea">
              <Form.Control
                as="textarea"
                rows={3}
                value={note}
                onChange={(e) => setNote(e.target.value)}
              />
            </Form.Group>
            <Button
              type="submit"
              className="d-block ms-auto"
              onClick={handleCreateNote}
            >
              <Trans>Submit</Trans>
            </Button>
            {stickerNotes && stickerNotes.length > 0 && (
              <div className="row my-3">
                <div className="table-responsive">
                  <table className="table">
                    <thead>
                      <tr>
                        <th className="text-start">Note</th>
                        <th className="text-center">Created At</th>
                      </tr>
                    </thead>
                    <tbody>
                      {stickerNotes.map((note, i) => (
                        <tr>
                          <td className="text-start">{note?.text}</td>

                          <td className="text-center">
                            {moment(note.timestamp).format('D MMMM, YYYY')}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            )}
          </Form>
        </Modal.Body>
      </Modal>
      {/* Add Note for Sticker End*/}
    </>
  );
};

export default Sticker;
