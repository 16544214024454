import api from "../../utility/api";

// Add New  Payment
const addPayment = async (data) => {
  const response = await api.post("/payment/createPayment", data);
  return response;
};

// Get All Invoices
const getAllInvoices = async (data) => {
  const response = await api.post("/payment/getAllInvoices", data);
  return response;
};

// Update Payment Status
const handlerUpdatePaymentStatus = async (data) => {
  const response = await api.put(`/payment/updatePaymentStatus/${data.id}`, data.data);
  return response;
};

const paymentService = {
  addPayment,
  getAllInvoices,
  handlerUpdatePaymentStatus,
};

export default paymentService;
