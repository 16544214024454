import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import benchMarkService from "./benchMarkService";
import { toast } from "react-toastify";

const verificationCode = process.env.REACT_APP_VERIFICATION_TOKEN;

const initialState = {
  allBenchmarks: [],
  isLoading: false,
  isError: false,
  isBmSuccess: false,
  message: "",
  isLoadingAddBanchMark:false
};

// Add New  Benchmark
export const addBenchmark = createAsyncThunk(
  "auth/addBenchmark",
  async (data, thunkAPI) => {
    try {
      await benchMarkService.addBenchmark(data);
      const res = await benchMarkService.getAllBenchmarks({
        verificationCode: verificationCode,
      });
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.error);
    }
  }
);

// Edit  Benchmark
export const editBenchmark = createAsyncThunk(
  "auth/editBenchmark",
  async (data, thunkAPI) => {
    try {
      await benchMarkService.editBenchmark(data);
      const res = await benchMarkService.getAllBenchmarks({
        verificationCode: verificationCode,
      });
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.error);
    }
  }
);

// Get All Benchmarks
export const getAllBenchmarks = createAsyncThunk(
  "auth/getAllBenchmarks",
  async (data, thunkAPI) => {
    try {
      const res = await benchMarkService.getAllBenchmarks(data);
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.error);
    }
  }
);

export const benchmark = createSlice({
  name: "benchmark",
  initialState,
  reducers: {
    resetBMData: (state) => {
      state.isLoading = false;
      state.isError = false;
      state.message = "";
      state.isBmSuccess = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllBenchmarks.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAllBenchmarks.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isBmSuccess = true;
        state.allBenchmarks = action.payload;
      })
      .addCase(getAllBenchmarks.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        toast.error(action.payload);
      })
      .addCase(addBenchmark.pending, (state) => {
        state.isLoadingAddBanchMark = true;
      })
      .addCase(addBenchmark.fulfilled, (state, action) => {
        state.isLoadingAddBanchMark = false;
        state.isBmSuccess = true;
        state.allBenchmarks = action.payload;
        toast.success("BenchMark added successfully.");
      })
      .addCase(addBenchmark.rejected, (state, action) => {
        state.isLoadingAddBanchMark = false;
        state.isError = true;
        toast.error(action.payload);
      })
      .addCase(editBenchmark.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(editBenchmark.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isBmSuccess = true;
        state.allBenchmarks = action.payload;
        toast.success("BenchMark updated successfully.");
      })
      .addCase(editBenchmark.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        toast.error(action.payload);
      });
  },
});

export const { resetBMData } = benchmark.actions;
export default benchmark.reducer;
