import api, { apiWithFormData } from "../../utility/api";

// Add Qr code template for customer and employee  Business Settings
const addQrCodeTemplate = async (data) => {
  const response = await apiWithFormData.post(
    "/dataTouchPoint/qrCodeTemplate/create",
    data
  );
  return response;
};

// EDIT Qr code template for customer and employee  Business Settings
const editQrCodeTemplate = async (data) => {
  const response = await apiWithFormData.post(
    "/dataTouchPoint/qrCodeTemplate/update",
    data
  );
  return response;
};

// Delete Qr code template for customer and employee  Business Settings
const deleteQrCodeTemplate = async (data) => {
  const response = await api.post(
    "/dataTouchPoint/qrCodeTemplate/delete",
    data
  );
  return response;
};

// Get All Qr Code Templates
const getAllQrCodeTemplates = async (data) => {
  const response = await api.post(
    "/dataTouchPoint/getAllQRCodeTemplates",
    data
  );
  return response;
};

const qrService = {
  addQrCodeTemplate,
  editQrCodeTemplate,
  deleteQrCodeTemplate,
  getAllQrCodeTemplates,
};

export default qrService;
