import api from "../../utility/api";

// Add New  Sticker Request
const addStickerRequest = async (data) => {
  const response = await api.post("/stickerRequest/create", data);
  return response;
};

// Edit Sticker Request
// const editStickerRequest = async (data) => {
//   const response = await api.put("/stickerRequest/update", data);
//   return response;
// };

// Update Status approve/sent Sticker Requests
const updateStickerRequest = async (data) => {
  const response = await api.put("/stickerRequest/updateStatus", data);
  return response;
};

// Get All Sticker Requests
const getAllStickerRequests = async (data) => {
  const response = await api.post("/stickerRequest/getAll", data);
  return response;
};

// Create note for Sticker Request by Admin
const createStickerNote = async (data) => {
  const response = await api.post("/stickerRequest/addNote", data);
  return response;
};

// Sticker Payment
const stickerPayment = async (data) => {
  const response = await api.put("/stickerRequest/stickerPayment", data);
  return response;
};

const questionService = {
  addStickerRequest,
  updateStickerRequest,
  getAllStickerRequests,
  createStickerNote,
  stickerPayment,
  //   editStickerRequest,
};

export default questionService;
