import axios from "axios";

// GET base URL from .env file
const BASE_URL = process.env.REACT_APP_BASE_URL;

const api = axios.create({
  baseURL: `${BASE_URL}`,
  //   baseURL: `http://localhost:5000/api`,
});

api.interceptors.request.use((req) => {
  if (localStorage.token) {
    req.headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      "x-auth-token": localStorage.token,
    };
  }
  return req;
});

export const apiWithFormData = axios.create({
  baseURL: `${BASE_URL}`,
});

apiWithFormData.interceptors.request.use((req) => {
  if (localStorage.token) {
    req.headers = {
      Accept: "application/json",
      "Content-Type": "multipart/form-data",
      "x-auth-token": localStorage.token,
    };
  }
  return req;
});

export default api;
