import React, { useEffect } from "react";

import axios from "axios";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
//================Private Routes ===========================
import PrivateRoute from "./routes/PrivateRoute";

//=================React other libraray imports here
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

//=================Import Redux functions=============
import { useSelector, useDispatch } from "react-redux";
import { logout } from "./redux/auth/authSlice";
//==============Other libs import and Pages================================

import Dashboard from "./pages/dashboard/Admin/dashboard";
import CDashboard from "./pages/dashboard/company/dashboard";
import SDashboard from "./pages/dashboard/DataTouchpoint/dashboard";
import Login from "./auth/login/Login";
import Review from "./pages/review/Review";
import ReviewResult from "./pages/review/ReviewResult";
import ForgetPassword from "./auth/ForgetPassword";
import ResetPassword from "./auth/ResetPassword";
import CRegister from "./auth/login/CRegister";
import NotFound from "./NotFound";

//======Styles================================
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";

const BASE_URL = process.env.REACT_APP_BASE_URL;
import io from "socket.io-client";
import TermsAndConditionPublic from "./pages/dashboard/Admin/termAndCondition/TermAndConditionPublic";
import { getAllCountries } from "./redux/CountryAndCities/CountryAndCitySlice";

// Define connection options
const connectionOptions = {
  "force new connection": true,
  reconnectionAttempts: "Infinity",
  timeout: 10,
};

// Create the socket object
export const socket = io.connect(process.env.REACT_APP_SOCKET_URL, connectionOptions);



// Attach event listeners after the socket object is created
socket.on("connect", () => {

});

// You can add more event listeners if needed
socket.on("disconnect", (reason) => {

});

socket.on("error", (error) => {

});

function App() {
  let dispatch = useDispatch();


  useEffect(() => {
    dispatch(getAllCountries())
  }, [])

  const { isAuthenticated, profile } = useSelector((state) => {
    return state.auth;
  });

  const isTokenExpired = async (token) => {
    try {
      const res = await axios.post(`${BASE_URL}/user/checkToken`, { token });
      if (res.data.status === "expired") {
        dispatch(logout()); // Dispatch logout action to log user out
        localStorage.clear();
        <Navigate to="/" />;
      }
    } catch (error) {
      if (error.response && error.response.data.error === "Token Expired") {
        dispatch(logout());
        localStorage.clear();
        <Navigate to="/" />;
      }
      console.error("Error checking token:", error);
    }
  };
  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      isTokenExpired(token);
    }
    //if (process.env.NODE_ENV != 'development') {
    //}
  }, []);

  useEffect(() => {
    if (!isAuthenticated && !profile) {
      <Navigate to="/" />;
    }
  }, [profile]);



 

  // var userLang = navigator.language || navigator.userLanguage;
  // alert("The language is: " + userLang);

  return (
    <>
    
      <ToastContainer autoClose={2500} />
      <Router>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/company/register" element={<CRegister />} />
          <Route path="/forgetPassword" element={<ForgetPassword />} />
          <Route path="/resetPassword" element={<ResetPassword />} />
          <Route path="/term-&-condition" element={ <div className="pb-4"><TermsAndConditionPublic  /></div>} />
          <Route
            element={
              <PrivateRoute
                auth={isAuthenticated && profile.role === "admin"}
              />
            }
          >
            <Route path="/admin/*" element={<Dashboard />} />
          </Route>

          <Route
            element={
              <PrivateRoute
                auth={isAuthenticated && profile.role === "business"}
              />
            }
          >
            <Route path="/company/*" element={<CDashboard />} />
          </Route>

          <Route path="/data-touchpoint/*" element={<SDashboard />} />
          <Route path="/review/:slug" element={<Review />} />
          <Route path="/result/:slug" element={<ReviewResult />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Router>
    </>
  );
}

export default App;
