import React, { useState, useEffect } from 'react';
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faPlay } from "@fortawesome/free-solid-svg-icons";
import goRateLogo from '../assets/images/icons/GoRateLogo.png';
import { Link, useNavigate } from 'react-router-dom';

//===================Redux===================
import { useSelector, useDispatch } from 'react-redux';
import './login/login.css';
import axios from 'axios';
import { toast } from 'react-toastify';
import { PASSWORD_REGEX } from '../constants';
//===============Verfication Code =================
const verificationCode = process.env.REACT_APP_VERIFICATION_TOKEN;
const BASE_URL = process.env.REACT_APP_BASE_URL;

const ResetPassword = () => {
  const navigate = useNavigate();
  const [PasswordVisible, setPasswordVisible] = useState(true);
  const [PasswordVisible2, setPasswordVisible2] = useState(true);
  //========ResetPassword Credentials =================
  const [formData, setFormData] = useState({
    email: '',
    resetCode: '',
    newPassword: '',
    confirmPassword: '',
  });

  const { email, resetCode, newPassword, confirmPassword } = formData;

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!newPassword) {
      return toast.error('New password is required');
    }

    if (!PASSWORD_REGEX.test(newPassword)) {
      return toast.error(
        'Password must contain at least 8 characters, 1 uppercase letter and 1 special character!'
      );
    }

    if (newPassword !== confirmPassword) {
      return toast.error('New password and confirm password do not match');
    } else {
      try {
        const res = await axios.post(`${BASE_URL}/user/resetPassword`, {
          verificationCode,
          email,
          resetCode,
          newPassword,
        });

        if (res.data.success === true) {
          toast.success(res?.data.msg);
          navigate('/');
        }

        if (res.data.success === false) {
          toast.error(res?.data.error);
        }
      } catch (err) {
        toast.error(err.response.data.error);
      }
    }
  };

  return (
    <>
      <div className='container'>
        <div className='row justify-content-center align-items-center vh-100 '>
          <div className='col-md-6 col-sm-12 mx-auto'>
            <div className='logincontent '>
              <div>
                <div className='text-center'>
                  <img
                    src={goRateLogo}
                    alt='Welcome'
                    className='img-fluid'
                    style={{
                      width: '250px',
                      height: '60px',
                      objectFit: 'contain',
                    }}
                  />
                </div>
                <p className=' text-nowrap text-center my-4'>
                  Please enter your details to reset Password
                </p>

                <form className='row'>
                  <div className='col-md-12'>
                    <label className='form-label'>Email</label>
                    <input
                      type='email'
                      className='form-control'
                      name='email'
                      value={email}
                      onChange={handleChange}
                      id='inputEmail4'
                    />
                  </div>
                  <div className='col-md-12'>
                    <label className='form-label'>Enter Code (4 Digits)</label>
                    <input
                      type='text'
                      className='form-control'
                      name='resetCode'
                      value={resetCode}
                      onChange={handleChange}
                      id='resetCode'
                      maxLength={4}
                    />
                  </div>
                  <div className='col-md-12 position-relative'>
                    <label className='form-label'>New Password</label>
                    <input
                      type={PasswordVisible ? 'password' : 'text'}
                      className='form-control'
                      name='newPassword'
                      value={newPassword}
                      onChange={handleChange}
                      id='inputPassword4'
                    />
                    {PasswordVisible ? (
                      <img
                        src='/assets/icons/EyeView.svg'
                        alt='DropDownIcon'
                        className='DropDownIconsInputField DatePickerDropDown c_pointer EyeIconsInAddressInSignUp'
                        onClick={() => {
                          setPasswordVisible(!PasswordVisible);
                        }}
                      />
                    ) : (
                      <img
                        src='/assets/icons/EyeViewNot.svg'
                        alt='DropDownIcon'
                        className='DropDownIconsInputField DatePickerDropDown c_pointer EyeIconsInAddressInSignUp'
                        onClick={() => {
                          setPasswordVisible(!PasswordVisible);
                        }}
                      />
                    )}
                  </div>
                  <div className='col-md-12 position-relative'>
                    <label className='form-label'>Confirm Password</label>
                    <input
                      type={PasswordVisible2 ? 'password' : 'text'}
                      className='form-control'
                      name='confirmPassword'
                      value={confirmPassword}
                      onChange={handleChange}
                      id='confirmPassword'
                    />
                    {PasswordVisible2 ? (
                      <img
                        src='/assets/icons/EyeView.svg'
                        alt='DropDownIcon'
                        className='DropDownIconsInputField DatePickerDropDown c_pointer EyeIconsInAddressInSignUp'
                        onClick={() => {
                          setPasswordVisible2(!PasswordVisible2);
                        }}
                      />
                    ) : (
                      <img
                        src='/assets/icons/EyeViewNot.svg'
                        alt='DropDownIcon'
                        className='DropDownIconsInputField DatePickerDropDown c_pointer EyeIconsInAddressInSignUp'
                        onClick={() => {
                          setPasswordVisible2(!PasswordVisible2);
                        }}
                      />
                    )}
                  </div>

                  <div className='col-md-12'>
                    <button
                      className='btn btn-warning w-100'
                      onClick={handleSubmit}
                    >
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ResetPassword;
