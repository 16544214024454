import api from "../../utility/api";

// Add New  Benchmark
const addBenchmark = async (data) => {
  const response = await api.post("/benchmark/create", data);
  return response;
};

// Edit Benchmark
const editBenchmark = async (data) => {
  const response = await api.put("/benchmark/editBenchmark", data);
  return response;
};

// Get All Benchmark
const getAllBenchmarks = async (data) => {
  const response = await api.post("/benchmark/getAllBenchmarkData", data);
  return response;
};

const benchMarkService = {
  addBenchmark,
  editBenchmark,
  getAllBenchmarks,
};

export default benchMarkService;
