import React from 'react'
import ReactLoading from 'react-loading';
const ButtonLoading = () => {
  return (
    <div className='btn btn-primary d-block w-100'>
        <ReactLoading type={"spinningBubbles"} color={"white"} height={21} width={31} />
    </div>
  )
}

export default ButtonLoading