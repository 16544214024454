import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faArrowLeft,
  faDownload,
  faRefresh,
  faPlus,
} from '@fortawesome/free-solid-svg-icons';
import Happy from './../../../../assets/images/icons/happy.svg';
import Dontknow from './../../../../assets/images/icons/dontknow.svg';
import Sad from './../../../../assets/images/icons/sad.svg';
import { Link, useNavigate, useParams } from 'react-router-dom';
import moment from 'moment';
import { BiLinkAlt } from 'react-icons/bi';
import { CSVLink } from 'react-csv';

import { useMyContext } from '../../../..';
import { Trans } from 'react-i18next';

//=========Redux===========
import { useSelector, useDispatch } from 'react-redux';
import {
  addReasonsToDtp,
  getOneDTP,
  updateDefaultCusQuestion,
  updateDefaultEmpQuestion,
  updateDtpRedirectUrl,
} from '../../../../redux/auth/authSlice';
import { toast } from 'react-toastify';
import { getAllCities } from '../../../../redux/CountryAndCities/CountryAndCitySlice';
import { Alert, Button, Modal } from 'react-bootstrap';
import api from '../../../../utility/api';
import { getAllQuestions } from '../../../../redux/question/questionSlice';
import { getAllQuestionReason } from '../../../../redux/reasonQuestion/reasonQuestionSlice';
import { addReason, getAllReasons } from '../../../../redux/reason/reasonSlice';

import './DTPView.css';
import Checkbox from '../../../../common/Checkbox';
import ButtonLoading from '../../../../common/ButtonLoading';
import { urlPattern } from '../../../../constants';

const verificationCode = process.env.REACT_APP_VERIFICATION_TOKEN;
const REACT_APP_BASE_URL_QR_CODE = process.env.REACT_APP_BASE_URL_QR_CODE;

const DTPView = () => {
  let dispatch = useDispatch();
  let navigate = useNavigate();

  let { slug } = useParams();

  //=======useSelector Data=====================
  const { oneDTPData, profile } = useSelector((state) => {
    return state.auth;
  });

  const defaultRedirectUrls = [
    { _id: 1, url: '', isActive: false, forRating: 1 },
    { _id: 2, url: '', isActive: false, forRating: 2 },
    { _id: 3, url: '', isActive: false, forRating: 3 },
  ];

  const [maxReviewHours, setMaxReviewHours] = useState();
  // const [redirectUrl, setRedirectUrl] = useState();

  const [customerResons, setCustomerResons] = useState([
    { language: 'English', reason: '' },
    { language: 'French', reason: '' },
    { language: 'Italian', reason: '' },
    { language: 'Spanish', reason: '' },
    { language: 'Portuguese', reason: '' },
  ]);
  const [showCustomerReason, setShowCustomerReason] = useState(false);
  const [selectedReasons, setSelectedReasons] = useState([]);

  const [redirectUrls, setRedirectUrls] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const [ValidationError, setValidationError] = useState('');

  const handleCloseCustomerReason = () => setShowCustomerReason(false);
  const handleShowCustomerReason = (rType) => {
    setShowCustomerReason(true);
    const reasons =
      oneDTPData?.dataTouchPoint?.dtpType === 'Customer Experience'
        ? oneDTPData?.dataTouchPoint?.customerReasons
        : oneDTPData?.dataTouchPoint?.employeeReasons;

    setSelectedReasons(
      reasons.map((reason) => {
        return reason._id;
      })
    );
  };

  const handleAddCusReason = (e) => {
    setValidationError('');
    e.preventDefault();
    if (!allReasons?.length) {
      return;
    }
    const body = {
      verificationCode: verificationCode,
      reasons: selectedReasons,
      slug,
    };
    dispatch(addReasonsToDtp(body)).then(() => {
      setShowCustomerReason(false);
    });
  };

  const handleCheckBoxClick = (e, id) => {
    if (e.target.checked) {
      setSelectedReasons((prevResons) => {
        return [...prevResons, id];
      });
    } else {
      const reasons = selectedReasons.filter((reason) => reason !== id);
      setSelectedReasons(reasons);
    }
  };

  const setRedirectUrl = (id, url) => {
    setRedirectUrls((prevUrls) =>
      prevUrls.map((item) => (item._id === id ? { ...item, url: url } : item))
    );
  };

  const handleCheckRedirectLink = (e, data) => {
    const itemIndex = redirectUrls?.findIndex(
      (link) => link?._id === data?._id
    );

    const newLinks = JSON.parse(JSON.stringify(redirectUrls));
    newLinks[itemIndex].isActive = newLinks[itemIndex].isActive ? false : true;
    setRedirectUrls(newLinks);
  };

  const getReasonForEnglishLanguage = (reasons) => {
    const englishReason = reasons.find(
      (reason) => reason.language === 'English'
    );
    return englishReason?.reason;
  };

  const { getCountries, getCities, isCitiesLoading } = useSelector(
    (state) => state.CountryAndCitySlice
  );

  const updateReviewHours = async () => {
    if (maxReviewHours === '') {
      return toast.error('Please include review hours');
    } else {
      try {
        const res = await api.put('/dataTouchPoint/create', {
          verificationCode,
          maxReviewHours,
          slug,
        });
        if (res.data) {
          toast.success(res.data?.message);
        }
      } catch (error) {
        toast.error(error?.message || error);
      }
    }
  };

  useEffect(() => {
    if (oneDTPData) {
      dispatch(getAllCities(oneDTPData?.dataTouchPoint?.country));
      setMaxReviewHours(oneDTPData?.dataTouchPoint?.maxReviewHours);
      if (oneDTPData?.dataTouchPoint?.redirectUrls?.length) {
        setRedirectUrls(oneDTPData?.dataTouchPoint?.redirectUrls);
      } else {
        setRedirectUrls(defaultRedirectUrls);
      }
    }
    return () => {};
  }, [oneDTPData]);

  //=========Get one Data Touchpoint =================
  useEffect(() => {
    dispatch(
      getOneDTP({
        verificationCode,
        slug,
      })
    );
  }, [dispatch, slug]);

  // Function to convert data to CSV format
  const [csvData, setCsvData] = useState([]);

  const prepareCSVData = () => {
    if (oneDTPData && oneDTPData?.npsData && oneDTPData?.npsData.length > 0) {
      const reviews = oneDTPData.npsData[0].reviews.map((review, i) => ({
        '#': i + 1,
        'Data Touchpoint': oneDTPData.dataTouchPoint.name,
        'Selected Question': oneDTPData.dataTouchPoint.question,
        'Selected Reason': review.answerReason,
        Ratings: review.rating,
        'Creation Date': moment(review.createdAt).format(
          'D MMMM, YYYY h:mm:ss A'
        ),
      }));
      setCsvData(reviews);
    }
  };

  //========Handle Back=================
  const handleBackToTable = () => {
    navigate('/company/touchpoints');
  };

  const { allQuestions } = useSelector((state) => {
    return state.question;
  });

  const { allQuestionReason, isReaQSuccess, isLoadingAddQuestionReason } =
    useSelector((state) => {
      return state.reasonQuestion;
    });

  const { allReasons, isSuccess, isLoadingAddReason } = useSelector((state) => {
    return state.reason;
  });

  const [defaultLanguage, setDefaultLanguage] = useState('');
  const [customerDefaultQues, setCustomerDefaultQues] = useState('');
  const [employeeDefaultQues, setEmployeeDefaultQues] = useState('');

  const handleCusDefaultQues = (e) => {
    setCustomerDefaultQues(e.target.value);
    if (e.target.value === '') {
      return toast.error('Please select a default question');
    } else {
      const question = allQuestions.find((q) => q._id === e.target.value);

      let body = {
        verificationCode: verificationCode,
        type: 'Customer Experience',
        questionId: e.target.value,
        defaultLanguage: defaultLanguage,
        slug: slug,
        question: question?.question,
      };
      dispatch(updateDefaultCusQuestion(body));
    }
  };

  const handleEmpDefaultQues = (e) => {
    setEmployeeDefaultQues(e.target.value);
    if (e.target.value === '') {
      return toast.error('Please select a default question');
    } else {
      const question = allQuestions.find((q) => q._id === e.target.value);

      let body = {
        verificationCode: verificationCode,
        type: 'Employee Sentiment',
        questionId: e.target.value,
        defaultLanguage: defaultLanguage,
        slug: slug,
        question: question?.question,
      };
      dispatch(updateDefaultEmpQuestion(body));
    }
  };

  const handleRedirectUrlUpdate = () => {
    for (const redirectUrl of redirectUrls) {
      if (redirectUrl?.isActive && !redirectUrl?.url) {
        return toast?.error('please uncheck empty urls');
      }
    }

    for (const redirectUrl of redirectUrls) {
      if (redirectUrl?.url && !urlPattern.test(redirectUrl?.url)) {
        toast.error(
          "Invalid URL. Make sure it looks like 'https://www.example.com'."
        );
        return;
      }
    }

    let body = {
      verificationCode: verificationCode,
      redirectUrls: redirectUrls?.map(({ _id, ...rest }) => rest),
      slug: slug,
    };

    setIsLoading(true);
    dispatch(updateDtpRedirectUrl(body)).then(() => setIsLoading(false));
  };

  //============Get All Reasons && Get All QRCODES (customer & employee)  =============================
  useEffect(() => {
    dispatch(getAllReasons({ verificationCode }));
    dispatch(getAllQuestionReason({ verificationCode }));
    dispatch(getAllQuestions({ verificationCode }));
  }, [dispatch]);

  useEffect(() => {
    setDefaultLanguage(oneDTPData?.dataTouchPoint?.defaultLanguage);
    setEmployeeDefaultQues(
      oneDTPData?.dataTouchPoint?.employeeQuestion?._id || ''
    );
    setCustomerDefaultQues(
      oneDTPData?.dataTouchPoint?.customerQuestion?._id || ''
    );
  }, [oneDTPData]);

  return (
    <>
      {oneDTPData && (
        <div className='container' id='viewUser'>
          <div className='main-body'>
            <div className='row gutters-sm'>
              <div className='col-md-12 d-flex justify-content-left'>
                <button onClick={handleBackToTable} className='btn btnPrint'>
                  {' '}
                  <FontAwesomeIcon icon={faArrowLeft} />{' '}
                </button>
              </div>
              <div className='col-md-3 mb-3'>
                <div className='card'>
                  <div className='card-body'>
                    <div className='d-flex flex-column align-items-left text-left'>
                      <img
                        src={
                          REACT_APP_BASE_URL_QR_CODE +
                          oneDTPData?.dataTouchPoint?.qrCode
                        }
                        onError={(e) => (e.target.src = '/assets/user.png')}
                        alt='Admin'
                      />
                      <div className='mt-4 text-center'>
                        <h4>{oneDTPData?.dataTouchPoint?.name}</h4>
                        <p className='text-secondary mb-1 text-capitalize'>
                          {oneDTPData?.dataTouchPoint?.role}
                        </p>
                        <p className='text-muted font-size-sm'>
                          {oneDTPData?.dataTouchPoint?.email}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-md-9'>
                <div className='card mb-3'>
                  <div className='card-body'>
                    <div className='row'>
                      <div className='col-sm-3'>
                        <h6 className='mb-0'>Data touchpoint</h6>
                      </div>
                      <div className='col-sm-9 text-secondary'>
                        <span>{oneDTPData?.dataTouchPoint?.name}</span>
                      </div>
                    </div>

                    <hr />

                    {oneDTPData?.dataTouchPoint?.description && (
                      <>
                        <div className='row'>
                          <div className='col-sm-3'>
                            <h6 className='mb-0'>
                              <Trans>Description</Trans>
                            </h6>
                          </div>
                          <div className='col-sm-9 text-secondary'>
                            <span>
                              {oneDTPData?.dataTouchPoint?.description}
                            </span>
                          </div>
                        </div>

                        <hr />
                      </>
                    )}

                    {oneDTPData?.dataTouchPoint?.contactNumber && (
                      <>
                        <div className='row'>
                          <div className='col-sm-3'>
                            <h6 className='mb-0'>
                              <Trans>Contact number</Trans>
                            </h6>
                          </div>
                          <div className='col-sm-9 text-secondary'>
                            <span>
                              {oneDTPData?.dataTouchPoint?.contactNumber}
                            </span>
                          </div>
                        </div>

                        <hr />
                      </>
                    )}

                    {oneDTPData?.dataTouchPoint?.city && (
                      <>
                        <div className='row'>
                          <div className='col-sm-3'>
                            <h6 className='mb-0'>City</h6>
                          </div>
                          <div className='col-sm-9 text-secondary'>
                            <span>
                              {/* {oneDTPData?.dataTouchPoint?.city} */}
                              {getCities
                                ?.filter((item) => {
                                  return (
                                    item?._id ===
                                    oneDTPData?.dataTouchPoint?.city
                                  );
                                })
                                .map((filteredItem, index) => (
                                  <div key={index}>
                                    <p>{filteredItem?.name_of_city}</p>
                                  </div>
                                ))}
                            </span>
                          </div>
                        </div>

                        <hr />
                      </>
                    )}

                    {oneDTPData?.dataTouchPoint?.country && (
                      <>
                        <div className='row'>
                          <div className='col-sm-3'>
                            <h6 className='mb-0'>
                              <Trans>Country</Trans>
                            </h6>
                          </div>
                          <div className='col-sm-9 text-secondary'>
                            <span>
                              {/* {oneDTPData?.dataTouchPoint?.country} */}
                              {getCountries?.countries
                                ?.filter((item) => {
                                  return (
                                    item?.countryId ===
                                    oneDTPData?.dataTouchPoint?.country
                                  );
                                })
                                .map((filteredItem, index) => (
                                  <div key={index}>
                                    <p>{filteredItem?.country}</p>
                                  </div>
                                ))}
                            </span>
                          </div>
                        </div>

                        <hr />
                      </>
                    )}

                    {oneDTPData?.dataTouchPoint?.location !== 0 && (
                      <>
                        <div className='row'>
                          <div className='col-sm-3'>
                            <h6 className='mb-0'>Location</h6>
                          </div>
                          <div className='col-sm-9 text-secondary'>
                            <span>{oneDTPData?.dataTouchPoint?.location}</span>
                          </div>
                        </div>
                        <hr />
                      </>
                    )}
                    {oneDTPData?.dataTouchPoint?.slugLink !== 0 && (
                      <>
                        <div className='row'>
                          <div className='col-sm-3'>
                            <h6 className='mt-1'>Link To Review</h6>
                          </div>
                          <div className='col-sm-9 text-secondary'>
                            <span>
                              {oneDTPData?.dataTouchPoint?.slugLink}{' '}
                              <button
                                className='btn btn-sm border mx-2'
                                onClick={() => {
                                  navigator.clipboard.writeText(
                                    oneDTPData?.dataTouchPoint?.slugLink
                                  );
                                  toast.success(
                                    'Voting url Copied Successfully'
                                  );
                                }}
                              >
                                <BiLinkAlt />
                              </button>
                            </span>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>

            {oneDTPData &&
              oneDTPData?.npsData &&
              oneDTPData?.npsData.length > 0 && (
                <>
                  <div className='card shadow-sm  my-3 '>
                    <div className='card-header'>
                      <h4 className='mb-0'>
                        <Trans>Voting Question Settings</Trans>
                      </h4>
                    </div>
                    <div className='card-body'>
                      <div className='row selectBorder'>
                        {oneDTPData?.dataTouchPoint?.dtpType ===
                        'Customer Experience' ? (
                          <div className='col-12 col-md-6'>
                            <label className='form-label fw-bold text-nowrap'>
                              <Trans>Customer Experience Voting Question</Trans>
                            </label>
                            <select
                              className='form-select'
                              value={customerDefaultQues}
                              onChange={handleCusDefaultQues}
                            >
                              <option value=''>
                                <Trans>Select from list</Trans>...
                              </option>
                              {allQuestions &&
                                allQuestions.map((question) => {
                                  if (
                                    question.inputType === 'Customer Experience'
                                    // && question.language === defaultLanguage
                                  ) {
                                    return (
                                      <option
                                        key={question._id}
                                        value={question._id}
                                      >
                                        {question.question}{' '}
                                        <span>({question.language})</span>
                                      </option>
                                    );
                                  }
                                  return null;
                                })}
                            </select>
                          </div>
                        ) : oneDTPData?.dataTouchPoint?.dtpType ===
                          'Employee Sentiment' ? (
                          <div className='col-12 col-md-6'>
                            <label className='form-label fw-bold'>
                              <Trans>Employee Sentiment Voting Question</Trans>
                            </label>
                            <select
                              className='form-select'
                              value={employeeDefaultQues}
                              onChange={handleEmpDefaultQues}
                            >
                              <option value=''>
                                <Trans>Select from list</Trans>...
                              </option>
                              {allQuestions &&
                                allQuestions.map((question) => {
                                  if (
                                    question.inputType === 'Employee Sentiment'
                                    // && question.language === defaultLanguage
                                  ) {
                                    return (
                                      <option
                                        key={question._id}
                                        value={question._id}
                                      >
                                        {question.question}{' '}
                                        <span>({question.language})</span>
                                      </option>
                                    );
                                  }
                                  return null;
                                })}
                            </select>
                          </div>
                        ) : (
                          ''
                        )}
                      </div>
                    </div>
                  </div>

                  <div className='row'>
                    <div className='col-12 mb-2'>
                      <div className='card'>
                        <div className='card-header'>
                          <h4 className='mb-0'>
                            <Trans>Reasons</Trans>
                          </h4>
                        </div>
                        <div className='card-body'>
                          <Link
                            className='btn btn-primary  px-1 mb-3'
                            onClick={(e) =>
                              handleShowCustomerReason('Customer Experience')
                            }
                          >
                            <FontAwesomeIcon icon={faPlus} />
                            <Trans>Add Reasons</Trans>
                          </Link>
                          <div className='row'>
                            {oneDTPData?.dataTouchPoint?.dtpType ===
                              'Customer Experience' &&
                            oneDTPData?.dataTouchPoint?.customerReasons?.length
                              ? oneDTPData?.dataTouchPoint?.customerReasons?.map(
                                  (data, index) =>
                                    data?.reasons?.length ? (
                                      <div className='col-12 col-md-4'>
                                        <select
                                          className='form-select'
                                          value={data?.reasons[0].reason}
                                          // onChange={handleCusDefaultQues}
                                        >
                                          {data?.reasons?.map((reason) => (
                                            <option
                                              key={reason._id}
                                              value={reason._id}
                                            >
                                              {reason?.reason}{' '}
                                              <span>({reason?.language})</span>
                                            </option>
                                          ))}
                                        </select>
                                      </div>
                                    ) : (
                                      ''
                                    )
                                )
                              : oneDTPData?.dataTouchPoint?.dtpType ===
                                  'Employee Sentiment' &&
                                oneDTPData?.dataTouchPoint?.employeeReasons
                                  ?.length
                              ? oneDTPData?.dataTouchPoint?.employeeReasons?.map(
                                  (data, index) =>
                                    data?.reasons?.length ? (
                                      <div className='col-12 col-md-4'>
                                        <select
                                          className='form-select'
                                          value={data?.reasons[0].reason}
                                          // onChange={handleCusDefaultQues}
                                        >
                                          {data?.reasons?.map((reason) => (
                                            <option
                                              key={reason._id}
                                              value={reason._id}
                                            >
                                              {reason?.reason}{' '}
                                              <span>({reason?.language})</span>
                                            </option>
                                          ))}
                                        </select>
                                      </div>
                                    ) : (
                                      ''
                                    )
                                )
                              : ''}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {oneDTPData?.dataTouchPoint && redirectUrls && (
                    <div className='row'>
                      <div className='col-12 mb-2'>
                        <div className='card'>
                          <div className='card-header'>
                            <h4 className='mb-0'>Redirect URL</h4>
                          </div>
                          <div className='card-body'>
                            <label className='form-label fw-bold'>
                              <Trans>
                                Set Redirect URLs (user will redirect there
                                after review)
                              </Trans>
                            </label>

                            <div
                              className='mt-4 mob-flex  '
                              style={{
                                justifyContent: 'space-evenly',
                              }}
                            >
                              {redirectUrls?.map((redirect, index) => (
                                <>
                                  <div className='row'>
                                    <div className='col-12 mb-2'>
                                      <div className='d-flex align-items-center'>
                                        <div className='ms-4 me-3'>
                                          {' '}
                                          <Checkbox
                                            onChange={(e) =>
                                              handleCheckRedirectLink(
                                                e,
                                                redirect
                                              )
                                            }
                                            checked={redirect?.isActive}
                                          />
                                        </div>

                                        <img
                                          key={redirect._id}
                                          src={
                                            index === 0
                                              ? Sad
                                              : index === 1
                                              ? Dontknow
                                              : index === 2
                                              ? Happy
                                              : ''
                                          }
                                          onClick={(e) =>
                                            handleCheckRedirectLink(e, redirect)
                                          }
                                          className={`img-fluid d-block reviewImg redirect-emoji`}
                                        />
                                      </div>

                                      <div className='d-flex mt-2'>
                                        <input
                                          type='text'
                                          value={redirect?.url}
                                          name='redirectUrl'
                                          onChange={(e) =>
                                            setRedirectUrl(
                                              redirect?._id,
                                              e.target.value
                                            )
                                          }
                                          className='form-control'
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </>
                              ))}
                            </div>
                            <div className='d-flex justify-content-end'>
                              {isLoading ? (
                                <div>
                                  {' '}
                                  <ButtonLoading />
                                </div>
                              ) : (
                                <button
                                  onClick={() => handleRedirectUrlUpdate()}
                                  className='btn btn-primary'
                                >
                                  Update All
                                </button>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}

                  <div className='row'>
                    <div className='col-12 mb-2'>
                      <div className='card'>
                        <div className='card-header'>
                          <h4 className='mb-0'>
                            Review <Trans>Settings</Trans>
                          </h4>
                        </div>
                        <div className='card-body'>
                          <label className='form-label fw-bold'>
                            <Trans>Review Setting (Set review hours)</Trans>
                          </label>
                          <div className='d-flex'>
                            <input
                              type='number'
                              value={maxReviewHours}
                              name='maxReviewHours'
                              onChange={(e) =>
                                setMaxReviewHours(e.target.value)
                              }
                              className='form-control'
                            />

                            <Button
                              variant='primary'
                              size='sm'
                              className='ms-2 h-25'
                              onClick={updateReviewHours}
                            >
                              <FontAwesomeIcon icon={faRefresh} />
                            </Button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className='row'>
                    <div className='col-md-12'>
                      <div className='d-flex justify-content-between'>
                        <div>
                          <h4 className='py-3'>Reviews</h4>
                        </div>
                        <div className='py-3'>
                          <button
                            onClick={prepareCSVData}
                            className='btn btn-primary'
                          >
                            Export CSV
                          </button>
                          {csvData.length > 0 && (
                            <CSVLink
                              data={csvData}
                              filename={`reviews.csv`}
                              // filename={`${csvData[0]?.DataTouchpoint}.csv`}
                              className='btn btn-primary mx-2'
                              target='_blank'
                            >
                              <FontAwesomeIcon icon={faDownload} />
                            </CSVLink>
                          )}
                        </div>
                      </div>

                      <div className='table-responsive'>
                        <table className='table'>
                          <thead>
                            <tr>
                              <th className='text-start'>#</th>
                              <th className='text-start'>Data Touchpoint</th>
                              <th className='text-center'>Selected Question</th>
                              <th className='text-center'>Selected Reason</th>
                              <th className='text-center'>Ratings</th>
                              <th className='text-center'>
                                <Trans>Creation Date</Trans>
                              </th>
                            </tr>
                          </thead>

                          <tbody>
                            {oneDTPData?.npsData[0]?.reviews.map(
                              (review, i) => (
                                <tr key={i}>
                                  <td className='text-start'>{i + 1}</td>
                                  <td className='text-start'>
                                    {oneDTPData?.dataTouchPoint?.name}
                                  </td>
                                  <td className='text-center'>
                                    {oneDTPData?.dataTouchPoint?.question}
                                  </td>
                                  <td className='text-center'>
                                    {review?.answerReason}
                                  </td>
                                  <td className='text-center'>
                                    {review?.rating}
                                  </td>
                                  <td className='text-center'>
                                    {moment(review?.createdAt).format(
                                      'D MMMM, YYYY h:mm:ss A'
                                    )}
                                  </td>
                                </tr>
                              )
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </>
              )}
          </div>
        </div>
      )}

      <Modal
        show={showCustomerReason}
        onHide={handleCloseCustomerReason}
        centered
        size='lg'
      >
        <Modal.Header closeButton>
          <Modal.Title>Add Reason</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='row mt-3'>
            <div className='row'>
              {allReasons?.length ? (
                allReasons.map((data, index) =>
                  data?.type === oneDTPData?.dataTouchPoint?.dtpType &&
                  data?.reasons?.length ? (
                    <div className='col-12 col-md-6'>
                      <Checkbox
                        id={data?._id}
                        label={getReasonForEnglishLanguage(data?.reasons)}
                        onChange={(e) => handleCheckBoxClick(e, data?._id)}
                        defaultChecked={selectedReasons?.includes(data?._id)}
                      />
                    </div>
                  ) : (
                    ''
                  )
                )
              ) : (
                <div>
                  No reason data found, please add reasons in business setting
                </div>
              )}
            </div>

            {ValidationError && <Alert>{ValidationError}</Alert>}

            <div className='d-flex justify-content-end mt-3'>
              <div className='d-flex justify-content-between gap-2'>
                <button
                  type='button'
                  className='btn btn-secondary w-100'
                  onClick={handleCloseCustomerReason}
                >
                  Cancel
                </button>
                {isLoadingAddReason ? (
                  <div>
                    {' '}
                    <ButtonLoading />
                  </div>
                ) : (
                  <button
                    type='button'
                    className='btn btn-primary d-block '
                    onClick={handleAddCusReason}
                  >
                    <Trans>Save</Trans>
                  </button>
                )}
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default DTPView;
