import React, { useState, useEffect } from 'react';
import goRateLogo from '../../assets/images/icons/GoRateLogo.png';
import { Link, useNavigate } from 'react-router-dom';
import { Trans } from 'react-i18next';
//===================Redux===================
import { useSelector, useDispatch } from 'react-redux';
import { reset, signupBusiness } from '../../redux/auth/authSlice';
// import { Country, City } from "country-state-city";
import { getAllSectors } from '../../redux/sector/sectorSlice';
import './login.css';
import { toast } from 'react-toastify';
import ReactLoading from 'react-loading';
import { getAllCities } from '../../redux/CountryAndCities/CountryAndCitySlice';
import { PASSWORD_REGEX } from '../../constants';
//===============Verfication Code =================
const verificationCode = process.env.REACT_APP_VERIFICATION_TOKEN;

const CRegister = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { isError, isSuccess, isLoading } = useSelector((state) => {
    return state.auth;
  });

  const { allSectors } = useSelector((state) => {
    return state.sector;
  });

  const { getCountries, getCities, isCitiesLoading } = useSelector(
    (state) => state.CountryAndCitySlice
  );

  useEffect(() => {
    dispatch(getAllSectors({ verificationCode }));
  }, [dispatch]);
  //========CRegister Credentials =================

  //========Create New Business ===================
  //===========Country and Cities Selection START=================================
  const [selectedCountry, setSelectedCountry] = useState('');
  const [cities, setCities] = useState([]);
  const [CitiesLoading, setCitiesLoading] = useState(false);
  const [PasswordVisible, setPasswordVisible] = useState(true);
  const handleCountryChange = async (event) => {
    const countryCode = event.target.value;
    setSelectedCountry(countryCode);
    setCitiesLoading(true);
    try {
      dispatch(getAllCities(countryCode));
    } catch (error) {
      console.error('Error during POST request:', error);
    }
  };
  //===========Country and Cities Selection END=================================

  const [formData, setFormData] = useState({
    name_of_business: '',
    sector: '',
    password: '',
    email: '',
    invoiceAddress: '',
    vat: '',
    postalCode: '',
    city: '',
    contactNumber: '',
    termAndCondition: false,
  });

  const [logo, setLogo] = useState(null);
  const handleLogoChange = (e) => {
    setLogo(e.target.files[0]);
  };
  const [preview, setPreview] = useState(null);

  // create a preview as a side effect, whenever selected file is changed
  useEffect(() => {
    if (!logo) {
      setPreview(undefined);
      return;
    }
    const objectUrl = URL.createObjectURL(logo);
    setPreview(objectUrl);

    // free memory when ever this component is unmounted
    return () => URL.revokeObjectURL(objectUrl);
  }, [logo]);

  const {
    name_of_business,
    sector,
    password,
    email,
    invoiceAddress,
    vat,
    postalCode,
    city,
    contactNumber,
    termAndCondition,
  } = formData;

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    const newValue = type === 'checkbox' ? checked : value;
    setFormData({
      ...formData,
      [name]: newValue,
    });
  };

  const handleCreateBusiness = async (e) => {
    e.preventDefault();
    if (termAndCondition === false) {
      return toast.error('Please accept terms and conditions');
    }

    if (!PASSWORD_REGEX.test(password)) {
      return toast.error(
        'Password must contain at least 8 characters, 1 uppercase letter and 1 special character!'
      );
    }

    var businessData = new FormData();
    businessData.append('verificationCode', verificationCode);
    businessData.append('name_of_business', name_of_business);
    businessData.append('sector', sector);
    businessData.append('password', password);
    businessData.append('email', email);
    businessData.append('invoiceAddress', invoiceAddress);
    businessData.append('vat', vat);
    businessData.append('postalCode', postalCode);
    businessData.append('city', city);
    businessData.append('country', selectedCountry);
    businessData.append('contactNumber', contactNumber);
    businessData.append('termAndCondition', termAndCondition);
    businessData.append('logo', logo);
    // dummy card details
    businessData.append('cardDetails', {
      cardHolder: 'Jenny Rosen',
      cardCvv: '123',
      cardNumber: '4242424242424242', // Stripe's default test card number
      cardMonth: '12',
      cardYear: '2024',
      cardBillingAddress: '1234 Main Street',
      cardCountry: 'US',
      cardCity: 'San Francisco',
      cardZipCode: '94111',
    });
    // dummy payment method
    businessData.append('paymentMethod', 'card');
    dispatch(signupBusiness(businessData));
  };

  //========Create New Business end===================

  //============== USE EFFECTS START=============================

  useEffect(() => {
    if (isSuccess) {
      navigate('/');
    }
    dispatch(reset());
  }, [isSuccess, navigate, dispatch]);
  //============== USE EFFECTS END=============================

  return (
    <>
      <div className='container'>
        <div className='row justify-content-center align-items-center vh-100 '>
          <div className='col-md-8 col-sm-12 mx-auto'>
            <div className='logincontent '>
              <div>
                <div className='text-center'>
                  <img
                    src={goRateLogo}
                    alt='Welcome'
                    className='img-fluid'
                    style={{
                      width: '250px',
                      height: '60px',
                      objectFit: 'contain',
                    }}
                  />
                </div>

                <form onSubmit={handleCreateBusiness}>
                  <div className='row'>
                    <div className='col-md-6'>
                      <div className='form-group'>
                        <label className='form-label'>
                          <Trans>Business Name</Trans>
                        </label>
                        <input
                          type='text'
                          className='form-control'
                          placeholder='Enter name'
                          name='name_of_business'
                          value={name_of_business}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                    <div className='col-md-6'>
                      <div className='form-group'>
                        <label className='form-label'>
                          <Trans>Sector</Trans>
                        </label>
                        <select
                          className='form-control'
                          name='sector'
                          value={sector}
                          onChange={handleChange}
                        >
                          <option value=''>
                            {' '}
                            <Trans>Select from list</Trans> ...
                          </option>
                          {allSectors &&
                            allSectors.length > 0 &&
                            allSectors.map((sec, i) => (
                              <option key={i} value={sec?.sector}>
                                {sec?.sector}
                              </option>
                            ))}
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col-md-6 position-relative'>
                      <div className='form-group'>
                        <label className='form-label'>
                          <Trans>Password</Trans>
                        </label>
                        <input
                          type={PasswordVisible ? 'password' : 'text'}
                          name='password'
                          value={password}
                          onChange={handleChange}
                          className='form-control'
                        />
                        {PasswordVisible ? (
                          <img
                            src='/assets/icons/EyeView.svg'
                            alt='DropDownIcon'
                            className='DropDownIconsInputField DatePickerDropDown c_pointer EyeIconsInAddressInSignUp'
                            onClick={() => {
                              setPasswordVisible(!PasswordVisible);
                            }}
                          />
                        ) : (
                          <img
                            src='/assets/icons/EyeViewNot.svg'
                            alt='DropDownIcon'
                            className='DropDownIconsInputField DatePickerDropDown c_pointer EyeIconsInAddressInSignUp'
                            onClick={() => {
                              setPasswordVisible(!PasswordVisible);
                            }}
                          />
                        )}
                      </div>
                    </div>
                    <div className='col-md-6'>
                      <div className='form-group'>
                        <label className='form-label'>Email</label>
                        <input
                          type='email'
                          className='form-control'
                          placeholder='Enter email'
                          name='email'
                          value={email}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col-md-6'>
                      <div className='form-group'>
                        <label className='form-label'>
                          <Trans>Country</Trans>
                        </label>
                        <select
                          className='form-select'
                          name='selectCountry'
                          value={selectedCountry}
                          onChange={handleCountryChange}
                        >
                          <option value=''>
                            <Trans>Select from list</Trans>...
                          </option>
                          {getCountries?.countries?.map((country) => (
                            <option
                              key={country.countryId}
                              value={country.countryId}
                            >
                              {country.country}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div className='col-md-6'>
                      <div className='form-group'>
                        <label className='form-label'>City</label>
                        {isCitiesLoading ? (
                          <div className='form-control d-flex justify-content-center'>
                            <ReactLoading
                              type={'spinningBubbles'}
                              color={'#000000'}
                              height={24}
                              width={17}
                            />
                          </div>
                        ) : (
                          <select
                            className='form-select'
                            name='city'
                            value={city}
                            onChange={handleChange}
                          >
                            <option value=''>
                              <Trans>Select from list</Trans>...
                            </option>
                            {getCities?.map((city, index) => (
                              <option key={index} value={city?._id}>
                                {city?.name_of_city}
                              </option>
                            ))}
                          </select>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className='row'>
                    <div className=' col-md-6'>
                      <div className='form-group'>
                        <label className='form-label'>
                          <Trans>Invoicing Address</Trans>
                        </label>
                        <input
                          type='text'
                          name='invoiceAddress'
                          value={invoiceAddress}
                          onChange={handleChange}
                          className='form-control'
                        />
                      </div>
                    </div>
                    <div className=' col-md-6'>
                      <div className='form-group'>
                        <label className='form-label'>
                          <Trans>VAT ID</Trans>
                        </label>
                        <input
                          type='text'
                          name='vat'
                          value={vat}
                          onChange={handleChange}
                          className='form-control'
                        />
                      </div>
                    </div>
                  </div>

                  <div className='row'>
                    <div className='col-md-6'>
                      <div className='form-group'>
                        <label className='form-label'>
                          <Trans>Contact number</Trans>
                        </label>
                        <input
                          type='text'
                          name='contactNumber'
                          value={contactNumber}
                          onChange={handleChange}
                          className='form-control'
                        />
                      </div>
                    </div>

                    <div className='col-md-6'>
                      <div className='form-group'>
                        <label className='form-label'>
                          <Trans>Postal Code</Trans>
                        </label>
                        <input
                          type='text'
                          name='postalCode'
                          value={postalCode}
                          onChange={handleChange}
                          className='form-control'
                        />
                      </div>
                    </div>
                  </div>

                  <div className='row'>
                    <div
                      className={`${preview ? 'col-md-9 mb-2' : 'col-md-12'}`}
                    >
                      <div className='form-group'>
                        <label className='form-label'>
                          <Trans>Upload Logo</Trans>
                        </label>
                        <input
                          type='file'
                          name='logo'
                          className='form-control'
                          accept='image/*'
                          onChange={handleLogoChange}
                        />
                      </div>
                    </div>

                    {logo && (
                      <div className={preview ? 'col-md-3 mb-2' : 'col-md-0'}>
                        <div className='form-group mt-3'>
                          <img
                            // className="rounded-circle"
                            width={100}
                            height={50}
                            src={preview}
                            alt=''
                            style={{
                              objectFit: 'cover',
                            }}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                  <div className='form-check mb-3'>
                    <input
                      className='form-check-input'
                      type='checkbox'
                      id='gridCheck'
                      name='termAndCondition'
                      value={termAndCondition}
                      onChange={handleChange}
                    />
                    <label
                      className='form-check-label mbl-text'
                      for='gridCheck'
                    >
                      <Link to={'/term-&-condition'}>
                        Accept terms and conditions
                      </Link>
                    </label>
                  </div>

                  <div className='col-md-12'>
                    <button
                      className='btn btn-warning w-100'
                      //   onClick={handleLogin}
                    >
                      Sign Up
                    </button>
                  </div>
                  <h5 className='text-center my-2'>
                    <u>OR</u>
                  </h5>
                  <h6 className='text-center mb-0'>
                    Already have account
                    <Link
                      to='/'
                      className='text-black  fw-600 mbl-text text-nowrap'
                    >
                      <u className='mx-2'> Login</u>
                    </Link>{' '}
                  </h6>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CRegister;
