
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import reviewService from "./reviewService";
import { toast } from "react-toastify";

const initialState = {
  allReviews: [],
  allReports: [],
  isLoading: false,
  isError: false,
  isSuccess: false,
  message: "",
};

// Add Review
export const addReview = createAsyncThunk(
  "auth/addReview",
  async (data, thunkAPI) => {
    try {
      const res = await reviewService.addReview(data);
     
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.error);
    }
  }
);

// Get All data touch point Report
export const allDtpReports = createAsyncThunk(
  "auth/allDtpReports",
  async (data, thunkAPI) => {
    try {
      const res = await reviewService.allDtpReports(data);
      return res.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.error);
    }
  }
);

export const review = createSlice({
  name: "review",
  initialState,
  reducers: {
    reset: (state) => {
      state.isLoading = false;
      state.isError = false;
      state.message = "";
      state.isSuccess = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(addReview.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(addReview.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        // state.allReviews = action.payload;
        toast.success(action.payload.msg);
      })
      .addCase(addReview.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        toast.error(action.payload);
      })
      .addCase(allDtpReports.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(allDtpReports.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.allReports = action.payload?.reverse();
      })
      .addCase(allDtpReports.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        toast.error(action.payload);
      });
  },
});

export const { reset } = review.actions;
export default review.reducer;
