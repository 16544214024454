import api from "../../utility/api";

// Add New  Question Reason
const addQuestionReason = async (data) => {
  const response = await api.post("/reasonQuestion/create", data);
  return response;
};

// Edit Question Reason
const editQuestionReason = async (data) => {
  const response = await api.post("/reasonQuestion/update", data);
  return response;
};

// Delete Question Reason
// const deleteQuestionReason = async (data) => {
//   const response = await api.post("/reasonQuestion/delete", data);
//   return response;
// };

// Get All Question Reasons
const getAllQuestionReason = async (data) => {
  const response = await api.post("/reasonQuestion/getAll", data);
  return response;
};

const reasonQuestionService = {
  getAllQuestionReason,
  addQuestionReason,
  editQuestionReason,
  //   deleteReason,
};

export default reasonQuestionService;
