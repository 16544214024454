import { configureStore } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import thunk from "redux-thunk";

import auth from "../redux/auth/authSlice";
import question from "../redux/question/questionSlice";
import sticker from "../redux/sticker/stickerSlice";
import stickerRequest from "../redux/stickerRequest/stickerRequestSlice";
import additionalDtpRequest from "../redux/additionalDtpRequest/additionalDtpSlice";
import benchmark from "../redux/benchmarks/benchMarkSlice";
import reason from "../redux/reason/reasonSlice";
import reasonQuestion from "../redux/reasonQuestion/reasonQuestionSlice";
import subscription from "../redux/subscription/subscriptionSlice";
import payment from "../redux/payment/paymentSlice";
import review from "../redux/review/reviewSlice";
import sector from "../redux/sector/sectorSlice";
import qrCode from "../redux/qrCode/qrSlice";
import CountryAndCitySlice from '../redux/CountryAndCities/CountryAndCitySlice'
const reducers = combineReducers({
  auth,
  question,
  sticker,
  reason,
  reasonQuestion,
  review,
  subscription,
  payment,
  qrCode,
  stickerRequest,
  additionalDtpRequest,
  benchmark,
  sector,
  CountryAndCitySlice,
});

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["auth"], // Add the "whitelist" option to specify which reducers to persist
};

const persistedReducer = persistReducer(persistConfig, reducers);

export const store = configureStore({
  reducer: persistedReducer,
  // devTools: process.env.NODE_ENV !== "production",
  middleware: [thunk],
});
