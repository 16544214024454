import React, { useState } from "react";
//=========Redux===========
import { useSelector, useDispatch } from "react-redux";

// import "./ssetting.css";

import { useMyContext } from '../../../..';
import { Trans } from 'react-i18next';

const SSetting = () => {
  const { profile } = useSelector((state) => {
    return state.auth;
  });

  const { language, setLanguage } = useMyContext();

  return (
    <>
      <div className="container-fluid my-3" id="userSetting">
        <div className="row">
          <div className="col-md-10">
            <h3 className="d-flex align-items-center mb-5 fw-500">
              User Settings
            </h3>
          </div>
          <div className="col-12 col-md-2 px-0 px-md-2">
            <select onChange={(e) => setLanguage(e.target.value)}>
              <option value={"en"} selected={language == "en"} >English</option>
              <option value={"es"} selected={language == "es"} >Spanish</option>
            </select>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <form className="row g-3">
              <div className="row">
                <div className="col-md-4">
                  <label className="form-label">Contact Name</label>
                  <input type="text" className="form-control" />
                </div>
                <div className="col-md-4">
                  <label className="form-label">Surname</label>
                  <input type="text" className="form-control" />
                </div>
              </div>
              <div className="row">
                <div className="col-md-4">
                  <label className="form-label"><Trans>Email Address</Trans></label>
                  <input type="email" className="form-control" />
                </div>
                <div className="col-md-4">
                  <label className="form-label"><Trans>Password</Trans></label>
                  <input type="password" className="form-control" />
                </div>
                <div className="col-md-4">
                  <label className="form-label"><Trans>Repeat Password</Trans></label>
                  <input type="password" className="form-control" />
                </div>
              </div>

              <div className="col-12">
                <button
                  type="submit"
                  className="btn btn-primary d-block ms-auto"
                >
                  <Trans>Submit</Trans>
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default SSetting;
