import React, { useState, useEffect, useRef } from "react";
import $ from "jquery";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleLeft,
  faSearch,
  faPaperclip,
  faArrowLeftLong,
  faImage,
  faPlus,
  faEnvelope,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import Profile from "../../../../assets/images/icons/profile.png";

import { useMyContext } from '../../../..';
import { Trans } from 'react-i18next';
//========Redux========
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
// import "./support.css";

const verificationCode = process.env.REACT_APP_VERIFICATION_TOKEN;
const BASE_URL_IMAGES = process.env.REACT_APP_BASE_URL_IMAGE;
const CHAT_IMAGES = process.env.REACT_APP_CHAT_IMAGE;
const BASE_URL = process.env.REACT_APP_BASE_URL;

//============socket Import============
import { io } from "socket.io-client";
import moment from "moment/moment";
const SOCKET_URL = process.env.REACT_APP_SOCKET_URL;

var connectionOptions = {
  "force new connection": true,
  reconnectionAttempts: "Infinity",
  timeout: 10000,
};

export var socket;
socket = io.connect(SOCKET_URL, connectionOptions);

const Support = () => {
  const { profile } = useSelector((state) => {
    return state.auth;
  });

  //------Add User----
  useEffect(() => {
    socket.emit("addUser", profile?._id);
  }, [profile?._id]);

  //===========Get Conversation  Id====
  const [conversations, setConversations] = useState([]);
  // Get the last conversation object from the conversations array
  const lastConversation = conversations[conversations.length - 1];

  // Set finalObj to the senderId object from the last conversation
  const finalObj =
    lastConversation?.senderId !== profile?._id ||
    lastConversation?.receiverId !== profile?._id;

  

  // Get the last conversation object from the conversations array
  const lastSConversation = conversations[conversations.length - 1];



  // Set finalObj to the senderId object from the last conversation
  const businessfinalObj = lastSConversation?.receiverId || {};

  

  useEffect(() => {
    if (profile?._id) {
      const getConversations = async () => {
        try {
          const rest = await axios.post(`${BASE_URL}/conversation/getConv`, {
            verificationCode,
            Id: profile?._id,
          });
          setConversations(rest.data);
          // const messagesid = document.getElementById("scrollhere");
          // $("#chatBody").animate({ scrollTop: messagesid.offsetTop }, "fast");
        } catch (err) {
        }
      };
      getConversations();
    }
  }, [profile?._id]);
  //===========Get Conversation  Id====
  const [newMessage, setNewMessage] = useState("");
  const [attachment, setAttachment] = useState([]);
  const [imagePreviews, setImagePreviews] = useState([]);

  const handleFileChange = (e) => {
    const files = Array.from(e.target.files);
    setAttachment(files);

    // Create image previews
    const imageFiles = files.filter((file) => file.type.startsWith("image/"));
    const previews = imageFiles.map((file) => URL.createObjectURL(file));
    setImagePreviews(previews);
  };

  const handleRemove = (index) => {
    const updatedAttachments = [...attachment];
    updatedAttachments.splice(index, 1);
    setAttachment(updatedAttachments);

    // Remove the corresponding image preview
    const updatedPreviews = [...imagePreviews];
    updatedPreviews.splice(index, 1);
    setImagePreviews(updatedPreviews);
  };

  const handleSendMessage = async (e) => {
    e.preventDefault();
    try {
      const formData = new FormData();
      formData.append("verificationCode", verificationCode);

      formData.append("senderId", profile?._id),
        formData.append(
          "receiverId",
          lastSConversation?.senderId._id === profile?._id
            ? profile?._id
            : lastSConversation?.senderId?._id
        );
      formData.append("text", newMessage);
      formData.append("type", "company");

      if (attachment !== null) {
        for (let i = 0; i < attachment.length; i++) {
          formData.append("attachments", attachment[i]);
        }
      }

      await axios.post(`${BASE_URL}/conversation/addNewMessage`, formData);

      socket.emit("send_Msg", {
        senderId: profile?._id,
        text: newMessage,
        receiverId:
          lastSConversation?.senderId?._id === profile?._id
            ? profile?._id
            : lastSConversation?.senderId?._id,
        // finalObj?._id === profile?._id ? profile?._id : finalObj?._id,
        type: "company",
      });

      const rest = await axios.post(`${BASE_URL}/conversation/getConv`, {
        verificationCode,
        Id: profile?._id,
      });

      setConversations(rest.data);
      setNewMessage("");
      setAttachment([]);

      // const messagesid = document.getElementById("scrollhere");
      // $("#chatBody").animate({ scrollTop: messagesid.offsetTop }, "fast");
    } catch (err) {
    }
  };

  const onKeyPress = async (e) => {
    if (e.key === "Enter" && newMessage !== "") {
      handleSendMessage(e);
    }
  };

  const lastMessageRef = useRef(null);

  useEffect(() => {
    // 👇️ scroll to bottom every time messages change
    lastMessageRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [conversations]);

  //===============Message send END =============================

  const [mapid, setMapid] = useState();
  useEffect(() => {
    if (mapid !== undefined) {
      let newCall = async () => {
        const rest = await axios.post(`${BASE_URL}/conversation/getConv`, {
          verificationCode,
          Id: profile?._id,
        });

        setConversations(rest.data);
        const messagesid = document.getElementById("scrollhere");
        $("#chatbody").animate({ scrollTop: messagesid.offsetTop }, "fast");
     
      };
      newCall();
    }
  }, [profile?._id, mapid]);

  socket.on("msgRecieve", (data) => {
   
    setMapid(data);
  });

  //=====Setting Messages Dates======

  const getMessageDate = (timestamp) => {
    const messageDate = moment(timestamp);
    const currentDate = moment();

    if (messageDate.isSame(currentDate, "day")) {
      return "Today";
    } else if (
      messageDate.isSame(currentDate.clone().subtract(1, "days"), "day")
    ) {
      return "Yesterday";
    } else {
      return messageDate.format("Do MMMM  YYYY"); // For other dates, display full date like "July 31st"
    }
  };

  return (
    <>
      <section className="" id="chatbox">
        <div className="container-fluid">
          <div className="row d-flex justify-content-end">
            <div className="col-md-12">
              <div className="bg-white rounded shadow">
                <div className="chatbox p-2 d-flex flex-column">
                  <div className="modal-dialog-scrollable">
                    {finalObj && (
                      <div className="msg-head">
                        <div className="row py-2 mx-2">
                          <div className="col-12">
                            <div className="d-flex align-items-center">
                              <span className="chat-icon">
                                <FontAwesomeIcon icon={faAngleLeft} />
                              </span>
                              <div className="flex-shrink-0">
                                <img
                                  src={BASE_URL_IMAGES + finalObj?.adminLogo}
                                  className="rounded-circle"
                                  width={40}
                                  height={40}
                                  onError={(e) => (e.target.src = "/assets/user.png")}
                                  alt=""
                                />
                              </div>
                              <div className="flex-grow-1 ms-3">
                                <h3 className="supportHeadn fw-500">
                                  {finalObj?.firstName} {finalObj?.lastName}
                                </h3>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}

                    <hr className="m-0" style={{ color: "#94A3B8" }} />
                    <div className="modal-content flex-grow-1" id="chatBody">
                      <div className="modal-body">
                        <div className="msg-body">
                          <ul>
                            {conversations &&
                              conversations.map((conv, i) => (
                                <>
                                  {i === 0 || // Render the header for the first message
                                    getMessageDate(conv.createdAt) !==
                                    getMessageDate(
                                      conversations[i - 1]?.createdAt
                                    ) ? (
                                    <li>
                                      <div className="divider">
                                        <h6>
                                          {getMessageDate(conv.createdAt)}
                                        </h6>
                                      </div>
                                    </li>
                                  ) : null}
                                  {conv?.receiverId?._id === profile?._id && (
                                    <li
                                      className="repaly"
                                      ref={
                                        i === conversations.length - 1
                                          ? lastMessageRef
                                          : null
                                      }
                                    >
                                      <div className="flex-shrink-0">
                                        <img
                                          src={
                                            BASE_URL_IMAGES +
                                            conv?.receiverId?.logo
                                          }
                                          alt="Logo"
                                          onError={(e) =>
                                            (e.target.src = "/assets/user.png")
                                          }
                                          className="rounded-circle ImageInChat"
                                          width={40}
                                          height={40}
                                        />
                                      </div>
                                      {conv?.text && <p>{conv?.text} </p>}
                                      {conv?.images.map((url, i) => {
                                        return (
                                          <div
                                            className="col-1 mx-1"
                                            style={{ padding: "4px" }}
                                            key={i}
                                          >
                                            <div
                                              style={{
                                                display: "flex",
                                                flexDirection: "row",
                                                padding: "4px",
                                              }}
                                            >
                                              <img
                                                src={CHAT_IMAGES + url}
                                                style={{
                                                  width: "50px",
                                                  height: "50px",
                                                  borderRadius: "7px",
                                                  objectFit: "cover",
                                                }}
                                                onError={(e) =>
                                                  (e.target.src = "/assets/user.png")
                                                }
                                                alt=""
                                              />
                                            </div>
                                          </div>
                                        );
                                      })}
                                      <span className="time">
                                        {" "}
                                        {moment(conv?.createdAt).format(
                                          "h:mm a"
                                        )}
                                      </span>
                                    </li>
                                  )}
                                  {conv?.senderId?._id !== profile?._id && (
                                    <li
                                      className="sender"
                                      key={i}
                                      ref={
                                        i === conversations.length - 1
                                          ? lastMessageRef
                                          : null
                                      }
                                    >
                                      <div className="flex-shrink-0 d-flex">
                                        {" "}
                                        <img
                                          src={
                                            BASE_URL_IMAGES +
                                            conv?.senderId?.adminLogo
                                          }
                                          alt="Logo"
                                          onError={(e) =>
                                            (e.target.src = "/assets/user.png")
                                          }
                                          className="rounded-circle ImageInChat"
                                          width={40}
                                          height={40}
                                        />
                                        {conv?.text && <p>{conv?.text} </p>}
                                        {conv?.images.map((url, i) => {
                                          return (
                                            <div
                                              className="col-1 mx-1"
                                              style={{ padding: "4px" }}
                                              key={i}
                                            >
                                              <div
                                                style={{
                                                  display: "flex",
                                                  flexDirection: "row",
                                                  padding: "4px",
                                                }}
                                              >
                                                <img
                                                  src={CHAT_IMAGES + url}
                                                  style={{
                                                    width: "50px",
                                                    height: "50px",
                                                    borderRadius: "7px",
                                                    objectFit: "cover",
                                                  }}
                                                  onError={(e) =>
                                                    (e.target.src = "/assets/user.png")
                                                  }
                                                  alt=""
                                                />
                                              </div>
                                            </div>
                                          );
                                        })}
                                      </div>
                                      <span className="time">
                                        {" "}
                                        {moment(conv?.createdAt).format(
                                          " h:mm a"
                                        )}
                                      </span>
                                    </li>
                                  )}
                                </>
                              ))}
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div className="send-box mt-auto">
                      <div className="d-flex">
                        {imagePreviews.map((preview, index) => (
                          <div key={index}>
                            <img
                              src={preview}
                              alt={`Preview ${index}`}
                              className="rounded-circle "
                              width={50}
                              height={50}
                            />
                            <FontAwesomeIcon
                              icon={faXmark}
                              className="imgCrossIcon"
                              onClick={() => handleRemove(index)}
                            />
                          </div>
                        ))}
                      </div>
                      <h6 className="answer"><Trans>Answer</Trans></h6>
                      <form>
                        <input
                          type="text"
                          className="form-control textArea"
                          aria-label="message…"
                          placeholder="Write Your Message…"
                          value={newMessage}
                          onKeyPress={onKeyPress}
                          onChange={(e) => setNewMessage(e.target.value)}
                        />

                        <div
                          className="d-flex justify-content-between"
                          id="chatButton"
                        >
                          <div className="">
                            <button
                              type="button"
                              className="btn clip m-none"
                              onClick={() =>
                                document
                                  .getElementById(
                                    "upload_signature_file_edit_customer"
                                  )
                                  .click()
                              }
                            >
                              <FontAwesomeIcon
                                icon={faPaperclip}
                                className="sendicon"
                              />
                            </button>
                            <input
                              type="file"
                              accept="image/*,.pdf"
                              name="upload_signature_file"
                              id="upload_signature_file_edit_customer"
                              aria-autocomplete="list"
                              autoCorrect="off"
                              style={{ display: "none" }}
                              multiple
                              onClick={(event) => {
                                event.target.value = null;
                              }}
                              onChange={handleFileChange}
                            />
                          </div>

                          <div className="">
                            <Link
                              onClick={handleSendMessage}
                              className="btn btn-sm btn-primary d-block m-auto"
                            >
                              <Trans>Send</Trans>
                            </Link>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Support;
