import React, { useEffect, useMemo, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faDownload,
  faEdit,
  faPlus,
  faSearch,
  faUpload,
} from "@fortawesome/free-solid-svg-icons";

import { useMyContext } from '../../../..';
import { Trans } from 'react-i18next';
import ReactLoading from 'react-loading';
//---chaartjs
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";

import { Modal } from "react-bootstrap";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";
import makeAnimated from "react-select/animated";

import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getAllSectors } from "../../../../redux/sector/sectorSlice";

// import { Country, City } from "country-state-city";
import {
  addBenchmark,
  editBenchmark,
  getAllBenchmarks,
  resetBMData,
} from "../../../../redux/benchmarks/benchMarkSlice";

import api from "../../../../utility/api";
import { getAllSubscriptions } from "../../../../redux/subscription/subscriptionSlice";
import {
  addMoreDTPReq,
  reset as additionalReqReset,
} from "../../../../redux/additionalDtpRequest/additionalDtpSlice";
import { getAllCities } from "../../../../redux/CountryAndCities/CountryAndCitySlice";
import ButtonLoading from "../../../../common/ButtonLoading";

// import "./bench.css";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

//======Utils
const verificationCode = process.env.REACT_APP_VERIFICATION_TOKEN;

const CBench = () => {
  const animatedComponents = makeAnimated();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [CitiesLoading, setCitiesLoading] = useState(false)
  const { language, setLanguage } = useMyContext();
  const { getCountries, getCities, isCitiesLoading } = useSelector((state) => state.CountryAndCitySlice);
  const { allSectors } = useSelector((state) => {
    return state.sector;
  });

  const { profile } = useSelector((state) => {
    return state.auth;
  });

  const { isBmSuccess, allBenchmarks , isLoadingAddBanchMark } = useSelector((state) => {
    return state.benchmark;
  });

  const { allSubscriptions } = useSelector((state) => {
    return state.subscription;
  });

  const isAdditionalDtpRequestSuccess = useSelector((state) => {
    return state.additionalDtpRequest.isSuccess;
  });

  //=========Get All Sectors & user Data USEEFFECT START =================================
  useEffect(() => {
    dispatch(getAllSectors({ verificationCode }));
    dispatch(getAllBenchmarks({ verificationCode }));
    dispatch(getAllSubscriptions({ verificationCode }));
  }, [dispatch]);

  //===================CHART DATA================
  function generateChartData(npsData) {
    const chartData = {
      labels: npsData.map((dataPoint) => dataPoint.overAllNps),
      datasets: [
        {
          label: "Line Chart",
          data: npsData.map((dataPoint) => dataPoint.overAllNps),
          fill: false,
          borderColor: "#1F3C76",
          tension: 0.4, // Increase the tension to make the curve smoother
        },
      ],
    };
    return chartData;
  }

  const chartOptions = {
    scales: {
      y: {
        display: false,
        beginAtZero: false,
        max: 100,
        min: -100,
        stepSize: 10,
      },
      x: {
        display: false,
      },
    },
    plugins: {
      legend: {
        display: false,
      },
    },
    elements: {
      point: {
        radius: 0,
      },
      line: {
        borderWidth: 1,
      },
    },
  };

  //---------Get user ----------
  const [userData, setUserData] = useState();
  useEffect(() => {
    getUser();
  }, []);

  const getUser = async () => {
    try {
      const user = await api.post("/user/getUser", {
        verificationCode,
      });
      if (user) {
        setUserData(user?.data);
      }
    } catch (error) {
      console.error(error);
    }
  };
  //---------Get user END----------
  //=========Get All Sectors & user Data USEEFFECT END =================================

  //=========Computed value for the BENCHMARK BM used START=============

  // const allowedBM = useMemo(() => {
  //   return profile?.selectedBenchMark?.benchMark ?? 0;
  // }, [profile?.selectedBenchMark]);

  const totalBMReq = useMemo(() => {
    return userData?.user?.totalBMReq ?? 0;
  }, [userData?.user]);

  // const result = allowedBM + totalBMReq;
  //=========Computed value for the BENCHMARK BM used END=============

  //-----------Search Filter --------------------
  const [searchQuery, setSearchQuery] = useState("");
  // Apply the filter based on the input text
  const filteredData = searchQuery
    ? allBenchmarks.filter((item) =>
      item.name.toLowerCase().includes(searchQuery.toLowerCase())
    )
    : allBenchmarks;

  //==================Bench Mark API and create Started =================================

  const [showModal, setShowModal] = useState(false);
  const handleCloseModal = () => setShowModal(false);
  const handleShowModal = () => {
    setFormValues({
      name: "",
      description: "",
    });
    setMultipleSectors([]);
    setPostalCodes([]);
    setSelectedCountry("");
    setSelectedCities([]);
    setShowModal(true);
  };

  //========Selection for Benchmark ==========================
  const [formValues, setFormValues] = useState({
    name: "",
    description: "",
  });

  const { name, description } = formValues;

  const handleOnChange = (e) => {
    setFormValues({
      ...formValues,
      [e.target.name]: e.target.value,
    });
  };

  const [selectedButton, setSelectedButton] = useState("Customer Experience");
  const handleButtonClick = (buttonName) => {
    setSelectedButton(buttonName);
  };

  //==========Handle multiple sector ============
  const [multipleSectors, setMultipleSectors] = useState([]);

  const handleSectorChange = (e) => {
    setMultipleSectors(e);
  };

  //==========Handle multiple postal codes============
  const [postalCodes, setPostalCodes] = useState([]);

  const handlePostalCodeChange = (e) => {
    setPostalCodes(e);
  };

  //=============//===========Country and Cities Selection START=================================
  const [selectedCountry, setSelectedCountry] = useState([]);
  const [cities, setCities] = useState([]);
  const [selectedCities, setSelectedCities] = useState([]);

  const handleCountryChange = async (selectedOptions) => {
    const selectedCountryCodes =
      selectedOptions &&
      selectedOptions?.map((opt) => ({
        value: opt.value,
        label: opt.label,
      }));
    setSelectedCountry(selectedCountryCodes);
    setCitiesLoading(true)
    if (selectedCountryCodes.length === 1) {
      try {
        dispatch(getAllCities(selectedCountryCodes[0].value))
      } catch (error) {
      }
    } else {
      setCities([]);
    }
  };

  const handleCityChange = (selectedOptions) => {
    const options = selectedOptions?.map((opt) => ({
      value: opt.value,
      label: opt.label,
    }));
    setSelectedCities(options);
  };

  //===========Handle Create BenchMarks =================================
  const handleAddBenchMark = (e) => {
    e.preventDefault();
    let body = {
      verificationCode: verificationCode,
      name: name,
      description: description,
      sectorList: multipleSectors,
      citiesList: selectedCountry.length !== 0 ? selectedCities : [],
      countriesList: selectedCountry.length !== 0 ? selectedCountry : [],
      postalCodes: postalCodes ? postalCodes : [],
      BMType: selectedButton,
    };
    dispatch(addBenchmark(body));
  };

  //===========Handle Update BenchMarks =================================
  const [editModal, setEditModal] = useState(false);
  const closeEditModal = () => setEditModal(false);

  const [editData, setEditData] = useState({});

  const handleEditData = (data) => {
    setEditData(data);
    setEditModal(true);
  };

  useEffect(() => {
    setFormValues({
      name: editData.name,
      description: editData.description,
    });

    setSelectedButton(editData?.BMType);
    const sectorOptions =
      editData &&
      editData?.sectorList?.map((item) => ({
        value: item.value,
        label: item.label,
        _id: item._id,
      }));
    setMultipleSectors(sectorOptions);
    if (editData && editData.countriesList) {
      const selectedCountryOptions = editData?.countriesList.map(
        (countryCode) => ({
          label: countryCode.label, // Replace with your logic
          value: countryCode.value,
        })
      );
      setSelectedCountry(selectedCountryOptions);
    }
    if (editData && editData.citiesList) {
      const allCitiesSelected = editData?.citiesList.map((city) => ({
        label: city.label, // Replace with your logic
        value: city.value,
      }));
      setSelectedCities(allCitiesSelected);
    }

    if (editData && editData.postalCodes) {
      const selectedPostalCodes = editData?.postalCodes.map((code) => ({
        label: code.label, // Replace with your logic
        value: code.value,
      }));
      setPostalCodes(selectedPostalCodes);
    }
  }, [editData]);

  const handleUpdateBenchMark = (e) => {
    e.preventDefault();
    let body = {
      verificationCode: verificationCode,
      Id: editData?._id,
      name: name,
      description: description,
      sectorList: multipleSectors,
      citiesList: selectedCountry.length !== 0 ? selectedCities : [],
      countriesList: selectedCountry ? selectedCountry : [],
      postalCodes: postalCodes ? postalCodes : [],
      BMType: selectedButton,
    };
    dispatch(editBenchmark(body));
  };

  //============Handle Get More BenchMark Start===================
  const [addMoreDTP, setAddMoreDTP] = useState(false);
  const handleCloseAddMoreDTP = () => setAddMoreDTP(false);
  const handleShowAddMoreDTP = () => setAddMoreDTP(true);

  const [subTotalPrice, setSubTotalPrice] = useState(0);
  const [DTPNumber, setDTPNumber] = useState(0);

  // Calculate total value based on the selected sticker size and units
  const calculateTotalSubVal = () => {
    const selectedSub =
      allSubscriptions &&
      allSubscriptions.find(
        (sub) => sub._id === profile?.selectedBenchMark?._id
      );

    const subPrice = selectedSub ? selectedSub.perUnitPrice : 0;
    const totalVal = parseFloat(subPrice) * parseFloat(DTPNumber);
    setSubTotalPrice(isNaN(totalVal) ? 0 : totalVal);
  };

  useEffect(() => {
    calculateTotalSubVal();
  }, [profile?.selectedBenchMark?._id, DTPNumber]);

  const handleRequestMoreDTP = (event) => {
    event.preventDefault();
    if (DTPNumber === 0) {
      return toast.warning("Please enter number for datatouchpoint.");
    }
    let body = {
      verificationCode: verificationCode,
      units: Number(DTPNumber),
      selectedSubscription:
        profile?.selectedBenchMark && profile?.selectedBenchMark._id,
      totalPrice: subTotalPrice,
      reqType: "Benchmark",
    };
    dispatch(addMoreDTPReq(body));
  };
  //============Handle Get More BenchMark END===================

  //===========Reset Data on Success =====================
  useEffect(() => {
    if (isBmSuccess) {
      setFormValues({
        name: "",
        description: "",
      });
      setSelectedButton("Customer Experience");
      setMultipleSectors([]);
      setPostalCodes([]);
      setSelectedCountry("");
      setSelectedCities([]);
      handleCloseModal();
      closeEditModal();
      dispatch(resetBMData());
    }
  }, [isBmSuccess]);

  useEffect(() => {
    if (isAdditionalDtpRequestSuccess) {
      setDTPNumber(0);
      handleCloseAddMoreDTP();
      navigate("/company/shop");
    }
    dispatch(additionalReqReset());
  }, [isAdditionalDtpRequestSuccess, navigate]);

  const [AllCountry, setAllCountry] = useState()

  


  return (
    <>
      <div className="container-fluid my-3">
        <div className="row">
          <div className="col-md-2">
            <h3 className=" mt-1 fw-500 text-nowrap"><Trans>Benchmarks</Trans></h3>
          </div>
          <div className="col-md-4">
            <div className="search-field search-bar">
              <input
                type="text"
                placeholder={language === "en" ? "Search" : "Búsqueda"}
                className="dashboardinput"
                id="userSearch"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
              <span className="search-icon">
                <FontAwesomeIcon icon={faSearch} className="usersearch" />
              </span>
            </div>
          </div>
          <div className="col-md-6">
            <div className="row g-3">


              <div className="col-md-4">
                <Link className="btn btn-primary w-100 " onClick={handleShowModal}>
                  <FontAwesomeIcon icon={faPlus} className="btnIcon" /> Benchmark
                </Link>
              </div>
              <div className="col-md-4">
                <Link to="#" className="btn btn-secondary w-100 ">
                  <FontAwesomeIcon icon={faDownload} className="btnIcon" />
                  <Trans>Export</Trans>
                </Link>
              </div>
              <div className="col-md-4">
                <Link to="#" className="btn btn-secondary w-100">
                  <FontAwesomeIcon icon={faUpload} className="btnIcon" />
                  <Trans>Upload</Trans>
                </Link>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-3 px-0 px-md-1">
            {/* <select onChange={(e) => setLanguage(e.target.value)}>
              <option value={"en"} selected={language == "en"} >English</option>
              <option value={"es"} selected={language == "es"} >Spanish</option>
            </select> */}
            <div className="d-flex mt-2">
              <h6 className="threeOut">
                {allBenchmarks?.length} /
                <span className="ms-1">{totalBMReq} Used</span>
              </h6>

              {/* {profile?.selectedBenchMark &&
                profile?.selectedBenchMark.subscriptionType && (
                  <Link
                    className="getMore ms-3"
                    onClick={() => handleShowAddMoreDTP()}
                  >
                    Get Additional
                  </Link>
                )} */}
            </div>
          </div>
        </div>



        <div className="row">
          {allBenchmarks &&
            filteredData.map((data, i) => (
              <div className="col-md-3 my-2" key={i}>
                <div className="sub-cards radius">
                  <div
                    className="d-flex mb-3"
                    style={{ justifyContent: "space-between" }}
                  >
                    <h6 className=" m-0">{data?.name}</h6>
                    <Link
                      className="editbench"
                      onClick={(e) => handleEditData(data)}
                    >
                      <FontAwesomeIcon icon={faEdit} className="" />
                    </Link>
                  </div>
                  <Line
                    data={generateChartData(data?.npsData)}
                    options={chartOptions}
                  />
                </div>
              </div>
            ))}
        </div>
      </div>

      {/* //==Adding New Benchmark START === */}
      <Modal show={showModal} onHide={handleCloseModal} centered>
        <Modal.Header closeButton>
          <Modal.Title><Trans>New Benchmark</Trans></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form>
            <div className="row">
              <div className="col-md-12">
                <label className="form-label">Name</label>
                <input
                  type="text"
                  name="name"
                  value={name}
                  onChange={handleOnChange}
                  className="form-control"
                // placeholder="Customer Experience Restaurants SP & FR"
                />
              </div>
              <div className="col-md-12">
                <label className="form-label"><Trans>Description</Trans></label>
                <input
                  type="text"
                  className="form-control"
                  name="description"
                  value={description}
                  onChange={handleOnChange}
                />
              </div>
              <div className="col-md-12 my-3">
                <div className="d-flex justify-content-between">
                  <button
                    type="button"
                    className={`btn btn-loginAs rounded-pill  ${selectedButton === "Customer Experience" ? "selected" : ""
                      }`}
                    onClick={() => handleButtonClick("Customer Experience")}
                  >
                    <Trans>Customer Experience</Trans>
                  </button>
                  <button
                    type="button"
                    className={`btn btn-loginAs  rounded-pill ${selectedButton === "Employee Sentiment" ? "selected" : ""
                      }`}
                    onClick={() => handleButtonClick("Employee Sentiment")}
                  >
                    <Trans>EmployeeSentiment</Trans>
                  </button>
                </div>
              </div>
              <div className="col-md-12 mb-2">
                <label className="form-label">Sector(s)</label>
                <Select
                  id="multiple-select-field"
                  isMulti
                  options={allSectors.map((sec) => ({
                    value: sec?.sector,
                    label: sec?.sector,
                  }))}
                  onChange={handleSectorChange}
                />
              </div>

              {/* {selectedCountry && selectedCountry.length === 1 && ( */}
              <div className="col-md-12 mb-2">
                <label className="form-label"><Trans>Postal Code(s)</Trans></label>
                {postalCodes && (
                  <CreatableSelect
                    isClearable={true}
                    isMulti={true}
                    components={animatedComponents}
                    onChange={handlePostalCodeChange}
                  />
                )}
              </div>
              {/* )} */}

              {postalCodes.length === 0 && (
                <>
                  <div className="col-md-12 mb-2">
                    <label className="form-label">
                      {selectedCountry && selectedCountry?.length === 1
                        ? "Country"
                        : "Countries"}
                    </label>
                    <Select
                      isClearable={true}
                      isMulti={true}
                      components={animatedComponents}
                      onChange={handleCountryChange}
                      options={getCountries?.countries?.map((country) => ({
                        label: country.country,
                        value: country.countryId,
                      }))}
                    />
                  </div>

                  {selectedCountry && selectedCountry.length === 1 && (
                    <div className="col-md-12 mb-2">
                      <label className="form-label">Cities</label>
                      <Select
                        id="multiple-select-field"
                        isMulti
                        options={getCities.map((city) => ({
                          value: city?._id,
                          label: city?.name_of_city,
                        }))}
                        onChange={handleCityChange}
                      />
                    </div>
                  )}
                </>
              )}

           
              <div className="d-flex justify-content-end mt-3">
              <div className="d-flex justify-content-between gap-2">
                <button   
                  type="button"      
                  className="btn btn-secondary w-100"      
                  onClick={handleCloseModal}      
                >       
                  Cancel      
                </button>   
                {isLoadingAddBanchMark ? <div> <ButtonLoading /></div>  : <button
                  type="button"
                  className="btn btn-primary d-block "
                  onClick={handleAddBenchMark}
                >
                  <Trans>Save</Trans>
                </button>
                }
              </div>
            </div>
            </div>
          </form>
        </Modal.Body>
      </Modal>
      {/* //==Adding New Benchmark END === */}

      {/* //==EDIT Benchmark START === */}
      <Modal show={editModal} onHide={closeEditModal} centered>
        <Modal.Header closeButton>
          <Modal.Title><Trans>Update Benchmark</Trans></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {editData && (
            <form>
              <div className="row">
                <div className="col-md-12">
                  <label className="form-label">Name</label>
                  <input
                    type="text"
                    name="name"
                    value={name}
                    onChange={handleOnChange}
                    className="form-control"
                    placeholder="Customer Experience Restaurants SP & FR"
                  />
                </div>
                <div className="col-md-12">
                  <label className="form-label"><Trans>Description</Trans></label>
                  <input
                    type="text"
                    className="form-control"
                    name="description"
                    value={description}
                    onChange={handleOnChange}
                  />
                </div>
                <div className="col-md-12 my-3">
                  <div className="d-flex justify-content-between">
                    <button
                      type="button"
                      className={`btn btn-loginAs rounded-pill  ${selectedButton === "Customer Experience"
                        ? "selected"
                        : ""
                        }`}
                      onClick={() => handleButtonClick("Customer Experience")}
                    >
                      <Trans>Customer Experience</Trans>
                    </button>
                    <button
                      type="button"
                      className={`btn btn-loginAs  rounded-pill ${selectedButton === "Employee Sentiment"
                        ? "selected"
                        : ""
                        }`}
                      onClick={() => handleButtonClick("Employee Sentiment")}
                    >
                      Employee Sentiment
                    </button>
                  </div>
                </div>
                <div className="col-md-12 mb-2">
                  <label className="form-label">Sector(s)</label>
                  <Select
                    id="multiple-select-field"
                    isMulti
                    options={allSectors.map((sec) => ({
                      value: sec?.sector,
                      label: sec?.sector,
                    }))}
                    onChange={handleSectorChange}
                    value={multipleSectors}
                  />
                </div>

                <div className="col-md-12 mb-2">
                  <label className="form-label"><Trans>Postal Code(s)</Trans></label>
                  {postalCodes && (
                    <CreatableSelect
                      isClearable={true}
                      isMulti={true}
                      components={animatedComponents}
                      onChange={handlePostalCodeChange}
                      value={postalCodes}
                    />
                  )}
                </div>

                {postalCodes.length === 0 && (
                  <>
                    <div className="col-md-12 mb-2">
                      <label className="form-label">
                        {selectedCountry?.length === 1
                          ? "Country"
                          : "Countries"}
                      </label>
                      <Select
                        isClearable={true}
                        isMulti={true}
                        components={animatedComponents}
                        onChange={handleCountryChange}
                        options={getCountries?.countries?.map((country) => ({
                          label: country?.country,
                          value: country?.countryId,
                        }))}
                        value={selectedCountry}
                      />
                    </div>

                    {selectedCountry.length === 1 && (
                      <div className="col-md-12 mb-2">
                        <label className="form-label">Cities</label>
                        {CitiesLoading ? <div className="form-control d-flex justify-content-center">
                          <ReactLoading type={"spinningBubbles"} color={"#000000"} height={24} width={17} />
                        </div> :
                          <div>
                            <Select
                              id="multiple-select-field"
                              isMulti
                              options={cities.map((city) => ({
                                value: city?.name,
                                label: city?.name,
                              }))}
                              onChange={handleCityChange}
                              value={selectedCities}
                            />
                          </div>
                        }
                      </div>
                    )}
                  </>
                )}
                {/* {selectedCountry.length === 1 && ( */}

                {/* )} */}

                <div className="row mx-auto">
                  <button
                    type="button"
                    className="btn btn-primary d-block mt-3 ms-auto"
                    onClick={handleUpdateBenchMark}
                  >
                    Update
                  </button>
                </div>
              </div>
            </form>
          )}
        </Modal.Body>
      </Modal>
      {/* //==EDIT Benchmark END === */}

      {/* //======================ADD MORE BENCHMARK REQUEST Start ====================== */}
      <Modal show={addMoreDTP} onHide={handleCloseAddMoreDTP}>
        <Modal.Header closeButton>
          <Modal.Title>Add More BenchMark</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-md-12">
              <label className="form-label"><Trans>Subscription</Trans> </label>
              <select
                className="form-select"
                defaultValue={profile?.selectedBenchMark?._id || ""}
              >
                {allSubscriptions &&
                  allSubscriptions
                    .filter(
                      (sub) => sub?._id === profile?.selectedBenchMark?._id
                    )
                    .map((sub, i) => (
                      <option value={sub._id} key={i}>
                        {sub?.name} (
                        <span className="text-success">{`Unit Price ${sub?.perUnitPrice}`}</span>
                        )
                      </option>
                    ))}
              </select>
            </div>
            <div className="col-md-12">
              <div className="form-group">
                <label className="form-label">Number of BenchMark</label>
                <input
                  type="number"
                  className="form-control"
                  placeholder="Enter number of BenchMark"
                  name="DTPNumber"
                  value={DTPNumber}
                  onChange={(e) => setDTPNumber(e.target.value)}
                />
              </div>
            </div>
            <div className="col-md-6 mx-auto mt-3">
              <h3 className="text-center fw-500">Total : € {subTotalPrice}</h3>
            </div>

            <div className="mx-auto d-flex justify-content-center my-3">
              <button
                className="btn btn-sm btn-primary"
                onClick={handleRequestMoreDTP}
              >
                <Trans>Submit</Trans>
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      {/* //======================ADD MORE BENCHMARK REQUEST End ====================== */}
    </>
  );
};

export default CBench;
