import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faPlus,
  faEdit,
  faUpload,
  faRefresh,
  faTrash,
  faSection,
  faEyeSlash,
  faEye,
} from '@fortawesome/free-solid-svg-icons';
import { Button, Modal, Tab, Tabs } from 'react-bootstrap';

import { useMyContext } from '../../../..';
import { Trans } from 'react-i18next';
import Swal from 'sweetalert2';
//=========Redux===========
import { useSelector, useDispatch } from 'react-redux';
import {
  addQuestion,
  editQuestion,
  getAllQuestions,
  handlerDeleteReason,
  reset,
} from '../../../../redux/question/questionSlice';
import {
  benchSetToFalse,
  benchSetToTrue,
  customerDefaultVotingAdmin,
  employeeDefaultVotingAdmin,
  getBenchSettings,
  updateAdminSettings,
} from '../../../../redux/auth/authSlice';
import api from '../../../../utility/api';
import { toast } from 'react-toastify';
import {
  addSticker,
  editSticker,
  getAllStickers,
  reset as stickerReset,
} from '../../../../redux/sticker/stickerSlice';

import {
  addSector,
  editSector,
  getAllSectors,
  reset as sectorReset,
  uploadSector,
} from '../../../../redux/sector/sectorSlice';
import moment from 'moment/moment';
import { Alert } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import ButtonLoading from '../../../../common/ButtonLoading';

// import "./setting.css";

const verificationCode = process.env.REACT_APP_VERIFICATION_TOKEN;
const BASE_URL_IMAGES = process.env.REACT_APP_BASE_URL_IMAGE;

const Setting = () => {
  const dispatch = useDispatch();

  const { profile, showBench, loadingUpdateAdminSettings } = useSelector(
    (state) => {
      return state.auth;
    }
  );
  const { allQuestions, isSuccess, isLoadingAddQuestion } = useSelector(
    (state) => {
      return state.question;
    }
  );

  const { allStickers, isLoadingAddSticker } = useSelector((state) => {
    return state.sticker;
  });

  const { allSectors, isLoadingUploadSector, isLoadingAddSector } = useSelector(
    (state) => {
      return state.sector;
    }
  );

  const isSticketSuccess = useSelector((state) => state.sticker.isSuccess);
  const isSectorSuccess = useSelector((state) => state.sector.isSuccess);

  //============Get All Questions =============================

  const { language, setLanguage } = useMyContext();

  useEffect(() => {
    dispatch(getAllQuestions({ verificationCode }));
    dispatch(getAllStickers({ verificationCode }));
    dispatch(getAllSectors({ verificationCode }));
    dispatch(getBenchSettings({ verificationCode }));
  }, [dispatch]);

  //============Get All Questions =============================

  //========================Update Settings========================
  const [logo, setLogo] = useState(null);
  const [profileLogo, setProfileLogo] = useState();
  const [PasswordVisible, setPasswordVisible] = useState(true);
  const [PasswordVisible2, setPasswordVisible2] = useState(true);
  const handleLogoChange = (e) => {
    setLogo(e.target.files[0]);
  };
  const [preview, setPreview] = useState(null);

  // create a preview as a side effect, whenever selected file is changed
  useEffect(() => {
    if (!logo) {
      setPreview(undefined);
      return;
    }
    const objectUrl = URL.createObjectURL(logo);
    setPreview(objectUrl);

    // free memory when ever this component is unmounted
    return () => URL.revokeObjectURL(objectUrl);
  }, [logo]);

  const [formUpdateData, setFormUpdateData] = useState({
    firstName: profile?.firstName,
    lastName: profile?.lastName,
    email: profile?.email,
    role: profile?.role,
    password: '',
    repeatPassword: '',
  });

  useEffect(() => {
    setFormUpdateData({
      firstName: profile?.firstName,
      lastName: profile?.lastName,
      email: profile?.email,
      role: profile?.role,
    });
    setProfileLogo(profile?.adminLogo);
  }, [profile]);

  //====Handler fields change =================

  const handleUpdateData = (e) => {
    setFormUpdateData({ ...formUpdateData, [e.target.name]: e.target.value });
  };

  const { firstName, lastName, email, role, password, repeatPassword } =
    formUpdateData;

  const handleUpdateSetting = async (e) => {
    e.preventDefault();
    if (password && password !== repeatPassword) {
      toast.error('Password do not match !');
    } else {
      var updateSettingData = new FormData();
      updateSettingData.append('verificationCode', verificationCode);
      updateSettingData.append('adminId', profile?._id);
      updateSettingData.append('firstName', firstName);
      updateSettingData.append('lastName', lastName);
      updateSettingData.append('password', password ? password : '');
      updateSettingData.append('adminLogo', logo);
      dispatch(updateAdminSettings(updateSettingData));
    }
  };

  //===========Modal and close handlers================================

  const [showModal, setShowModal] = useState(false);
  const handleCloseModal = () => setShowModal(false);
  const handleShowModal = () => {
    setSelectedButton('Customer Experience');
    setShowModal(true);
  };

  //=========Add New Question=========================
  const [selectedButton, setSelectedButton] = useState('Customer Experience');
  const handleButtonClick = (buttonName) => {
    setSelectedButton(buttonName);
  };

  const [questions, setQuestions] = useState([{ question: '', language: '' }]);

  const handleChange = (index, event) => {
    const { name, value } = event.target;
    const updatedQuestions = [...questions];
    updatedQuestions[index][name] = value;
    setQuestions(updatedQuestions);
  };

  const handleAddQuestion = () => {
    setQuestions([...questions, { question: '', language: '' }]);
  };

  const handleRemoveQuestion = (index) => {
    setQuestions((prevQuestions) =>
      prevQuestions.filter((_, i) => i !== index)
    );
  };

  const handleSaveQuestion = (e) => {
    e.preventDefault();
    const questionNew = {
      verificationCode: verificationCode,
      questions: questions,
      inputType: selectedButton,
    };
    dispatch(addQuestion(questionNew));
  };

  //=========Edit Question===================================

  const [showEditQuestionModal, setShowEditQuestionModal] = useState(false);
  const handleEditQuestionCloseModal = () => setShowEditQuestionModal(false);
  // const handleEditQuestionShowModal = () => setShowEditQuestionModal(true);

  const [questionEditData, setQuestionEditData] = useState();

  const handleEditChange = (e) => {
    setQuestionEditData({
      ...questionEditData,
      [e.target.name]: e.target.value,
    });
  };

  const handleEditQuestionData = (question) => {
    setQuestionEditData(question);
    setSelectedButton(question?.inputType);
    setShowEditQuestionModal(true);
  };

  const handleUpdateQuestion = (e) => {
    e.preventDefault();
    let body = {
      verificationCode: verificationCode,
      questionId: questionEditData._id,
      question: questionEditData.question,
      language: questionEditData.language,
      inputType: selectedButton,
    };
    dispatch(editQuestion(body));
  };

  //=============Default Customer Experience and Employee Question START ===========
  const [defaultLanguage, setDefaultLanguage] = useState('');
  const [customerDefaultQues, setCustomerDefaultQues] = useState('');
  const [employeeDefaultQues, setEmployeeDefaultQues] = useState('');

  const handleCusDefaultQues = (e) => {
    setCustomerDefaultQues(e.target.value);
    if (e.target.value === '') {
      return toast.error('Please select a default question');
    } else {
      let body = {
        verificationCode: verificationCode,
        type: 'Customer Experience',
        questionId: e.target.value,
        defaultLanguage: defaultLanguage,
      };
      dispatch(customerDefaultVotingAdmin(body));
    }
  };

  const handleEmpDefaultQues = (e) => {
    setEmployeeDefaultQues(e.target.value);
    if (e.target.value === '') {
      return toast.error('Please select a default question');
    } else {
      let body = {
        verificationCode: verificationCode,
        type: 'Employee Sentiment',
        questionId: e.target.value,
        defaultLanguage: defaultLanguage,
      };
      dispatch(employeeDefaultVotingAdmin(body));
    }
  };

  useEffect(() => {
    setDefaultLanguage(profile?.defaultLanguage);
    setEmployeeDefaultQues(profile?.employeeQuestion?._id);
    setCustomerDefaultQues(profile?.customerQuestion?._id);
  }, [profile]);

  //=============Default Customer Experience and Employee Question END ===========

  //===========Review hours settings START==============================

  const [hoursData, setHoursData] = useState();
  const [maxReviewHours, setMaxReviewHours] = useState();

  useEffect(() => {
    getReviewSettings();
    setMaxReviewHours(hoursData);
  }, [hoursData]);

  //-----GET-------
  const getReviewSettings = async () => {
    try {
      const res = await api.post('/reviewHoursSetting/get', {
        verificationCode,
      });
      if (res.data) {
        setHoursData(res.data?.maxReviewHours);
      }
    } catch (error) {
      toast.error(error);
    }
  };

  //-----UPDATE-------

  const updateReviewHours = async () => {
    if (maxReviewHours === '') {
      return toast.error('Please include review hours');
    } else {
      try {
        const res = await api.put('/reviewHoursSetting/create', {
          verificationCode,
          maxReviewHours,
        });
        if (res.data) {
          toast.success(res.data?.message);
        }
      } catch (error) {
        toast.error(error);
      }
    }
  };
  //===========Review hours settings END==============================

  //===========BenchMark  Settings START alex===============================
  const handleShowBenchmark = () => {
    // e.preventDefault();

    dispatch(
      benchSetToTrue({
        verificationCode: verificationCode,
      })
    );
  };

  const handleHideBenchmark = () => {
    // e.preventDefault();

    dispatch(
      benchSetToFalse({
        verificationCode: verificationCode,
      })
    );
  };

  //===========BenchMark  Settings END=================================

  //===========Sticker Settings START=================================
  const [addStickerModal, setAddStickerModal] = useState(false);
  const handleAddStickerCloseModal = () => setAddStickerModal(false);
  const handleAddStickerShowModal = () => {
    setAddStickerModal(true);
  };

  const [stickers, setStickers] = useState([
    { size: '', unitPerPrice: 0, vat: 0, pricePlusVat: 0 },
  ]);

  const handleStickerOnChange = (index, event) => {
    const { name, value } = event.target;
    const updatedStickers = [...stickers];
    updatedStickers[index][name] = value;

    // Calculate pricePlusVat based on unitPerPrice and vat
    const unitPerPrice = parseFloat(updatedStickers[index].unitPerPrice);
    const vat = parseFloat(updatedStickers[index].vat);
    const vatAmount = (unitPerPrice * vat) / 100;
    updatedStickers[index].pricePlusVat = unitPerPrice + vatAmount;

    setStickers(updatedStickers);
  };

  const handleAddSticker = () => {
    setStickers([
      ...stickers,
      { size: '', unitPerPrice: 0, vat: 0, pricePlusVat: 0 },
    ]);
  };

  const handleRemoveSticker = (index) => {
    setStickers((prevStickers) => prevStickers.filter((_, i) => i !== index));
  };

  const handleSaveSticker = (e) => {
    e.preventDefault();
    const stickerNew = {
      verificationCode: verificationCode,
      stickers: stickers,
    };
    dispatch(addSticker(stickerNew));
  };

  //=========Edit Sticker===================================

  const [showEditStickerModal, setShowEditStickerModal] = useState(false);
  const handleEditStickerCloseModal = () => setShowEditStickerModal(false);

  const [stickerEditData, setStickerEditData] = useState();

  const handleEditStickerChange = (e) => {
    const { name, value } = e.target;
    setStickerEditData((prevData) => {
      const updatedData = { ...prevData, [name]: value };

      // Calculate pricePlusVat based on unitPerPrice and vat
      const unitPerPrice = parseFloat(updatedData.unitPerPrice || 0);
      const vat = parseFloat(updatedData.vat || 0);
      const vatAmount = (unitPerPrice * vat) / 100;
      updatedData.pricePlusVat = (unitPerPrice + vatAmount).toFixed(2);

      return updatedData;
    });
  };

  const handleEditStickerData = (stickerData) => {
    setStickerEditData(stickerData);
    setShowEditStickerModal(true);
  };

  const handleUpdateSticker = (e) => {
    e.preventDefault();
    let body = {
      verificationCode: verificationCode,
      stickerId: stickerEditData._id,
      size: stickerEditData.size,
      unitPerPrice: stickerEditData.unitPerPrice,
      vat: stickerEditData.vat,
      pricePlusVat: stickerEditData.pricePlusVat,
    };
    dispatch(editSticker(body));
  };

  //===========Sticker Settings END===================================

  //======================Sector Settings API MODALS START================

  //======Upload sector Settings =================================
  const [sectorUploadShow, setSectorUploadShow] = useState(false);
  const [fileSector, setFileSector] = useState(null);

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    setFileSector(selectedFile);
  };

  const handleUploadSector = (e) => {
    e.preventDefault();
    if (!fileSector) {
      return toast.error('Please select xlsx file.');
    }
    const formData = new FormData();
    formData.append('verificationCode', verificationCode);
    formData.append('file', fileSector);
    dispatch(uploadSector(formData));
  };
  //======Upload sector Settings End=================================

  const [sectorAddModal, setSectorAddModal] = useState(false);
  const handleCloseAddSectorModal = () => setSectorAddModal(false);
  const handleOpenAddSectorModal = () => setSectorAddModal(true);
  const [sectors, setSectors] = useState([{ sector: '' }]);

  const handleSectorChange = (index, event) => {
    const { name, value } = event.target;
    const updatedSectors = [...sectors];
    updatedSectors[index][name] = value;
    setSectors(updatedSectors);
  };

  const handleAddSector = () => {
    setSectors([...sectors, { sector: '' }]);
  };
  const handleRemoveSector = (index) => {
    setSectors((prevSectors) => prevSectors.filter((_, i) => i !== index));
  };

  const handleSaveSector = (e) => {
    e.preventDefault();
    const sectorNew = {
      verificationCode: verificationCode,
      sectors: sectors,
    };
    dispatch(addSector(sectorNew));
  };

  //-------Add Sector End
  //====================Handle subscription package pricing===============
  const [editSectorModal, setEditSectorModal] = useState(false);
  const handleCloseEditSectorModal = () => setEditSectorModal(false);

  const [editSectorData, setEditSectorData] = useState(null);

  const handleEditSectorOpenModal = (sub) => {
    setEditSectorData(sub);

    setEditSectorModal(true);
  };

  const handleEditSectorChange = (e) => {
    setEditSectorData({ ...editSectorData, [e.target.name]: e.target.value });
  };

  const handleUpdateSector = (e) => {
    e.preventDefault();
    let body = {
      verificationCode: verificationCode,
      sectorId: editSectorData._id,
      sector: editSectorData.sector,
    };
    dispatch(editSector(body));
  };

  //======================Sector Settings API MODALS END==================

  //====================Handle subscription package pricing END===============

  //===========Reset Data on Success =====================
  useEffect(() => {
    if (isSuccess) {
      setQuestions([{ question: '', language: '' }]);
      setStickers([{ size: '', unitPerPrice: 0, vat: 0 }]);
      handleCloseModal();
      setAddStickerModal(false);
      handleEditQuestionCloseModal();
    }
    dispatch(reset());
  }, [isSuccess]);

  useEffect(() => {
    if (isSticketSuccess) {
      setStickers([{ size: '', unitPerPrice: 0, vat: 0 }]);
      setAddStickerModal(false);
      setShowEditStickerModal(false);
    }
    dispatch(stickerReset());
  }, [isSticketSuccess]);

  //====Sector Reset
  useEffect(() => {
    if (isSectorSuccess) {
      setSectors([{ sector: '' }]);
      handleCloseAddSectorModal();
      handleCloseEditSectorModal();
      setSectorUploadShow(false);
      setFileSector(null);
    }
    dispatch(sectorReset());
  }, [isSectorSuccess]);

  const [SelectedId, setSelectedId] = useState(null);

  function handlerDelete(id) {
    Swal.fire({
      title: 'Are you sure you would like to delete?',
      showCancelButton: true,
      confirmButtonColor: '#DD6B55',
      confirmButtonText: 'Yes!',
    }).then((result) => {
      if (result.isConfirmed) {
        // The user clicked "Yes," so navigate to the desired page
        dispatch(
          handlerDeleteReason({
            data: {
              verificationCode: 123123123,
            },
            id: id,
          })
        );
      }
    });
  }

  return (
    <>
      <div className='container-fluid my-3' id='userSetting'>
        <div className='row'>
          <div className='col-12 mt-2'>
            <div className='d-flex justify-content-between align-items-center'>
              <select onChange={(e) => setLanguage(e.target.value)}>
                <option value={'en'} selected={language == 'en'}>
                  English
                </option>
                <option value={'es'} selected={language == 'es'}>
                  Spanish
                </option>
              </select>
              <p>
                Default Language :{' '}
                <span className='Language'>
                  {language === 'en' ? 'English' : 'Spanish'}
                </span>
              </p>
            </div>
          </div>
        </div>
        <div className='card shadow-sm'>
          <div className='card-header'>
            <h4 className='mb-0'>
              Admin <Trans>Settings</Trans>
            </h4>
          </div>
          <div className='card-body'>
            {/* <div className="row">
              <div className="col-md-12 d-flex">
                <h3 className="d-flex align-items-center mb-5 fw-500"></h3>
              </div>
            </div> */}
            <div className='row'>
              <div className='col-md-12'>
                <form className='' onSubmit={handleUpdateSetting}>
                  <div className='row g-3'>
                    <div className='col-md-4'>
                      <label className='form-label'>First Name</label>
                      <input
                        type='text'
                        value={firstName}
                        name='firstName'
                        onChange={handleUpdateData}
                        className='form-control'
                      />
                    </div>
                    <div className='col-md-4'>
                      <label className='form-label'>Last Name</label>
                      <input
                        type='text'
                        value={lastName}
                        name='lastName'
                        onChange={handleUpdateData}
                        className='form-control'
                      />
                    </div>
                    <div className='col-md-4'>
                      <label className='form-label'>
                        <Trans>Role</Trans>
                      </label>
                      <input
                        type='text'
                        value={role}
                        name='role'
                        readOnly
                        className='form-control'
                      />
                    </div>
                    <div className='col-md-4'>
                      <label className='form-label'>
                        <Trans>Email Address</Trans>
                      </label>
                      <input
                        type='email'
                        value={email}
                        name='email'
                        readOnly
                        className='form-control'
                      />
                    </div>
                    <div className='col-md-4 position-relative'>
                      <label className='form-label'>
                        <Trans>Password</Trans>
                      </label>
                      <input
                        type={PasswordVisible ? 'password' : 'text'}
                        value={password}
                        name='password'
                        onChange={handleUpdateData}
                        className='form-control'
                      />
                      {PasswordVisible ? (
                        <img
                          src='/assets/icons/EyeView.svg'
                          alt='DropDownIcon'
                          className='DropDownIconsInputField DatePickerDropDown c_pointer EyeIconsInAddressInSignUp'
                          onClick={() => {
                            setPasswordVisible(!PasswordVisible);
                          }}
                        />
                      ) : (
                        <img
                          src='/assets/icons/EyeViewNot.svg'
                          alt='DropDownIcon'
                          className='DropDownIconsInputField DatePickerDropDown c_pointer EyeIconsInAddressInSignUp'
                          onClick={() => {
                            setPasswordVisible(!PasswordVisible);
                          }}
                        />
                      )}
                    </div>
                    <div className='col-md-4 position-relative'>
                      <label className='form-label'>
                        <Trans>Repeat Password</Trans>
                      </label>
                      <input
                        type={PasswordVisible2 ? 'password' : 'text'}
                        value={repeatPassword}
                        name='repeatPassword'
                        onChange={handleUpdateData}
                        className='form-control'
                      />
                      {PasswordVisible2 ? (
                        <img
                          src='/assets/icons/EyeView.svg'
                          alt='DropDownIcon'
                          className='DropDownIconsInputField DatePickerDropDown c_pointer EyeIconsInAddressInSignUp'
                          onClick={() => {
                            setPasswordVisible2(!PasswordVisible2);
                          }}
                        />
                      ) : (
                        <img
                          src='/assets/icons/EyeViewNot.svg'
                          alt='DropDownIcon'
                          className='DropDownIconsInputField DatePickerDropDown c_pointer EyeIconsInAddressInSignUp'
                          onClick={() => {
                            setPasswordVisible2(!PasswordVisible2);
                          }}
                        />
                      )}
                    </div>
                    <div className={`${preview ? 'col-md-4' : 'col-md-4'}`}>
                      <div className='admin-field mt-4'>
                        <div className='review-drag'>
                          <div className='d-flex align-items-center justify-content-center'>
                            <FontAwesomeIcon
                              icon={faUpload}
                              className='uploadbgIcon'
                            />

                            <span className='text-center greenHeading ms-2'>
                              <Trans>Upload Logo</Trans>
                            </span>
                          </div>
                          <input
                            type='file'
                            accept='image/*'
                            name='upload_signature_file'
                            onChange={handleLogoChange}
                          />
                        </div>
                      </div>
                    </div>
                    {logo && (
                      <div className={preview ? 'col-md-6' : 'col-md-6'}>
                        <div className='form-group mt-2'>
                          <img
                            className='rounded-circle'
                            width={70}
                            height={70}
                            src={preview}
                            alt=''
                          />
                        </div>
                      </div>
                    )}
                    {profileLogo && !logo && (
                      <div className={'col-md-3 mt-2'}>
                        <div className='form-group mt-2'>
                          <img
                            width={150}
                            height={50}
                            src={BASE_URL_IMAGES + profileLogo}
                            alt=''
                            style={{
                              objectFit: 'cover',
                            }}
                          />
                        </div>
                      </div>
                    )}
                    <div className='d-flex justify-content-end mt-3 col-md-3 col-lg-12'>
                      {loadingUpdateAdminSettings ? (
                        <div>
                          <ButtonLoading />
                        </div>
                      ) : (
                        <div className='d-flex gap-2'>
                          <Link to={'/admin'}>
                            <button
                              type='button'
                              className='btn btn-secondary w-100'
                            >
                              Cancel
                            </button>
                          </Link>
                          <Button
                            className='btn btn-primary'
                            onClick={handleUpdateSetting}
                          >
                            <FontAwesomeIcon icon={faRefresh} />{' '}
                            <Trans>Update Settings</Trans>
                          </Button>
                        </div>
                      )}
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>

        <div className='row my-2'>
          <div className='col-md-6'>
            <div className='card shadow-sm'>
              <div className='card-header'>
                <h4 className='mb-0'>
                  Question <Trans>Settings</Trans>
                </h4>
              </div>
              <div className='card-body '>
                <div className='row  selectBorder'>
                  <div className='col-md-12'>
                    <form>
                      <Button
                        className='btn btn-primary mt-4'
                        onClick={handleShowModal}
                      >
                        <FontAwesomeIcon icon={faPlus} /> Questions
                      </Button>

                      <div className='mt-3 card-height'>
                        {allQuestions &&
                          allQuestions?.map((question, i) => (
                            <div className='whiteShadow' key={i}>
                              <div className='d-flex justify-content-between'>
                                <h6 className='text-muted fw-light'>
                                  {question?.question}
                                  <span className='ms-3'>
                                    ({question?.language})
                                  </span>
                                </h6>
                                <div className='d-flex gap-2'>
                                  <FontAwesomeIcon
                                    icon={faEdit}
                                    className='pink cursor-pointer'
                                    onClick={() =>
                                      handleEditQuestionData(question)
                                    }
                                  />

                                  <Link
                                    className='deleteButton DeleteHover'
                                    onClick={() => {
                                      setSelectedId(question?._id);
                                      handlerDelete(question?._id);
                                    }}

                                    // onClick={(e) => handleDeleteSub(e, sub?._id)}
                                  >
                                    <FontAwesomeIcon icon={faTrash} />
                                  </Link>
                                </div>
                              </div>
                            </div>
                          ))}
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='col-md-6'>
            <div className='card'>
              <div className='card-header'>
                <h4 className='mb-0'>
                  <Trans>Sticker Pricing</Trans>
                </h4>
              </div>
              <div className='card-body'>
                <div className='col-md-12'>
                  <Button
                    className='btn btn-primary'
                    onClick={handleAddStickerShowModal}
                  >
                    <FontAwesomeIcon icon={faPlus} /> Sticker
                  </Button>

                  <div className='mt-3 card-height'>
                    <div className='d-flex justify-content-between'>
                      <h6 className='text-center'>Size</h6>
                      <h6 className='text-center'>Price Per Sticker (Total)</h6>
                      <h6 className=''>
                        <Trans>Action</Trans>
                      </h6>
                    </div>
                    {allStickers &&
                      allStickers?.map((sticker, i) => (
                        <div className='whiteShadow' key={i}>
                          <div className='d-flex justify-content-between'>
                            <div className='text-center'>
                              <h6 className='text-muted text-center'>
                                {sticker?.size}
                              </h6>
                            </div>
                            <div className='text-center'>
                              <span className='ms-3'>
                                {sticker?.pricePlusVat.toFixed(2)} €
                              </span>
                            </div>
                            {/* <div className="text-center">
                        <span className="ms-3">{sticker?.vat}</span>
                      </div> */}
                            <div className='text-center'>
                              <FontAwesomeIcon
                                icon={faEdit}
                                className='pink cursor-pointer'
                                onClick={() => handleEditStickerData(sticker)}
                              />
                            </div>
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='row '>
          <div className='col-12 col-md-6'>
            <div className='card'>
              <div className='card-header '>
                <div className='d-flex justify-content-between'>
                  <div className=''>
                    <h4 className='mt-2'>
                      Sector <Trans>Settings</Trans>
                    </h4>
                  </div>
                  <div className=''>
                    <label
                      htmlFor='fileInput'
                      className='btn btn-primary btn-sm'
                      style={{ height: '45px' }}
                      onClick={() => {
                        setFileSector(null);
                        setSectorUploadShow(true);
                      }}
                    >
                      <FontAwesomeIcon icon={faUpload} />
                    </label>
                  </div>
                </div>
              </div>
              <div className='card-body'>
                <h3 className='fw-500'>
                  <span>
                    {' '}
                    <Button
                      variant='primary'
                      size='sm'
                      className='ms-2 h-25'
                      onClick={handleOpenAddSectorModal}
                    >
                      <FontAwesomeIcon icon={faPlus} /> Sector
                    </Button>{' '}
                  </span>
                </h3>

                <div className='mt-3 card-height'>
                  <div className='d-flex justify-content-between'>
                    <h6 className='ms-4'>Sector</h6>
                    <h6 className=' ps-3'>Created At</h6>
                    <h6 className=''>
                      <Trans>Action</Trans>
                    </h6>
                  </div>
                  {allSectors &&
                    allSectors?.map((sec, i) => (
                      <div className='whiteShadow' key={i}>
                        <div className='d-flex justify-content-between'>
                          <div className=''>
                            <h6 className='text-muted fw-light'>
                              {sec?.sector}
                            </h6>
                          </div>
                          <div className=''>
                            <span className='ms-3'>
                              {moment(sec.createdAt).format('MM-DD-YYYY')}
                            </span>
                          </div>
                          <div className=''>
                            <FontAwesomeIcon
                              icon={faEdit}
                              className='pink cursor-pointer'
                              onClick={() => handleEditSectorOpenModal(sec)}
                            />
                          </div>
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </div>
          <div className='col-12 col-md-6'>
            <div className='row'>
              <div className='col-12'>
                <div className='card'>
                  <div className='card-header'>
                    <h4 className='mb-0'>
                      Benchmark <Trans>Settings</Trans>
                    </h4>
                  </div>
                  <div className='card-body'>
                    <div className='d-flex'>
                      <input
                        type='checkbox'
                        checked={showBench}
                        onChange={() => {
                          if (showBench) {
                            handleHideBenchmark();
                          } else {
                            handleShowBenchmark();
                          }
                        }}
                      />
                      <span className='m-1 px-2'>
                        {showBench
                          ? 'Show Benchmark (Uncheck to hide)'
                          : 'Unhide Benchmark (Check to show) '}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/*================ MODALS START ============================ */}

      {/* ----------- QUESTION SETTINGS START --------- */}

      {/*  Create New Question */}
      <Modal show={showModal} onHide={handleCloseModal} centered size='lg'>
        <Modal.Header closeButton>
          <Modal.Title>New Question</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='d-flex '>
            <button
              type='button'
              className={`btn btn-loginAs rounded-pill  ${
                selectedButton === 'Customer Experience' ? 'selected' : ''
              }`}
              onClick={() => handleButtonClick('Customer Experience')}
            >
              <Trans>Customer Experience</Trans>
            </button>
            <button
              type='button'
              className={`btn btn-loginAs ms-3 rounded-pill ${
                selectedButton === 'Employee Sentiment' ? 'selected' : ''
              }`}
              onClick={() => handleButtonClick('Employee Sentiment')}
            >
              <Trans>EmployeeSentiment</Trans>
            </button>
          </div>
          <div className='row mt-3' id='popupSelect'>
            {questions.map((question, index) => (
              <>
                <div className='col-md-6' key={index}>
                  <h6 className='text-muted'>Question</h6>
                  <textarea
                    className='form-control'
                    name='question'
                    value={question.question}
                    onChange={(e) => handleChange(index, e)}
                    rows={3}
                    placeholder='How likely are you to recommend our product to a friend or colleague?'
                  />
                </div>
                <div className='col-md-4 my-auto'>
                  <h6 className='text-muted'>Language</h6>
                  <select
                    className='form-select my-auto'
                    name='language'
                    value={question.language}
                    onChange={(e) => handleChange(index, e)}
                    key={index}
                  >
                    <option>
                      <Trans>Select from list</Trans>...
                    </option>
                    <option value='English'>English</option>
                    <option value='French'>French</option>
                    <option value='Spanish'>Spanish</option>
                    <option value='Italian'>Italian</option>
                    <option value='Portuguese'>Portuguese</option>
                  </select>
                </div>
                <div className='col-md-2'>
                  <div className='d-flex'>
                    <button
                      type='button'
                      className='btn btn-success d-block mt-5 ms-auto'
                      onClick={handleAddQuestion}
                    >
                      <FontAwesomeIcon icon={faPlus} />
                    </button>
                    {index > 0 && (
                      <button
                        type='button'
                        className='btn btn-danger d-block mt-5 ms-auto'
                        onClick={() => handleRemoveQuestion(index)}
                      >
                        <FontAwesomeIcon icon={faTrash} />
                      </button>
                    )}
                  </div>
                </div>
              </>
            ))}
            <div className='d-flex justify-content-end mt-5'>
              <div className='d-flex justify-content-between gap-2'>
                <button
                  type='button'
                  className='btn btn-secondary w-100'
                  onClick={handleCloseModal}
                >
                  Cancel
                </button>
                {isLoadingAddQuestion ? (
                  <div>
                    {' '}
                    <ButtonLoading />
                  </div>
                ) : (
                  <button
                    type='button'
                    className='btn btn-primary d-block '
                    onClick={handleSaveQuestion}
                  >
                    <Trans>Save</Trans>
                  </button>
                )}
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      {/*  Create New Question End*/}

      {/*  Edit Question */}
      <Modal
        show={showEditQuestionModal}
        onHide={handleEditQuestionCloseModal}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Edit Question</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='d-flex justify-content-between'>
            <button
              type='button'
              className={`btn btn-loginAs rounded-pill  ${
                selectedButton === 'Customer Experience' ? 'selected' : ''
              }`}
              onClick={() => handleButtonClick('Customer Experience')}
            >
              <Trans>Customer Experience</Trans>
            </button>
            <button
              type='button'
              className={`btn btn-loginAs  rounded-pill ${
                selectedButton === 'Employee Sentiment' ? 'selected' : ''
              }`}
              onClick={() => handleButtonClick('Employee Sentiment')}
            >
              Employee Sentiment
            </button>
          </div>
          <div className='row mt-3' id='popupSelect'>
            <div className='col-md-8'>
              <h6 className='text-muted'>Question</h6>
              <textarea
                className='form-control'
                name='question'
                value={questionEditData?.question}
                onChange={handleEditChange}
                rows={3}
                placeholder='How likely are you to recommend our product to a friend or colleague?'
              />
            </div>
            <div className='col-md-4 my-auto'>
              <h6 className='text-muted'>Language</h6>
              <select
                className='form-select my-auto'
                name='language'
                value={questionEditData?.language}
                onChange={handleEditChange}
              >
                <option>
                  <Trans>Select from list</Trans>...
                </option>
                <option value='English'>English</option>
                <option value='French'>French</option>
                <option value='Spanish'>Spanish</option>
                <option value='Italian'>Italian</option>
                <option value='Spanish'>Spanish</option>
                <option value='Portuguese'>Portuguese</option>
              </select>

              <button
                type='button'
                className='btn btn-primary d-block mt-5 ms-auto'
                onClick={handleUpdateQuestion}
              >
                Update
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      {/*  Edit Question End*/}

      {/* ----------- QUESTION SETTINGS END--------- */}

      {/* ----------- STICKER SETTINGS START --------- */}

      {/*  Create New STICKER START*/}
      <Modal
        show={addStickerModal}
        onHide={handleAddStickerCloseModal}
        centered
        size='lg'
      >
        <Modal.Header closeButton>
          <Modal.Title>New Sticker</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='row' id='popupSelect'>
            {stickers.map((sticker, index) => (
              <>
                <div className='col-md-4' key={index}>
                  <h6 className='text-muted'>Sticker</h6>
                  <input
                    type='text'
                    className='form-control'
                    name='size'
                    value={sticker.size}
                    onChange={(e) => handleStickerOnChange(index, e)}
                    placeholder='Small...'
                  />
                </div>
                <div className='col-md-2 '>
                  <h6 className='text-muted '>Unit Price</h6>
                  <input
                    type='number'
                    className='form-control'
                    name='unitPerPrice'
                    value={sticker.unitPerPrice}
                    onChange={(e) => handleStickerOnChange(index, e)}
                    placeholder='120'
                  />
                </div>
                <div className='col-md-2 '>
                  <h6 className='text-muted text-cneter'>
                    <Trans>VAT</Trans> %
                  </h6>
                  <input
                    type='number'
                    className='form-control'
                    name='vat'
                    value={sticker.vat}
                    onChange={(e) => handleStickerOnChange(index, e)}
                    placeholder='2'
                  />
                </div>
                <div className='col-md-2 '>
                  <h6 className='text-muted'>
                    Price with <Trans>VAT</Trans>
                  </h6>
                  <input
                    type='number'
                    className='form-control'
                    name='pricePlusVat'
                    value={sticker.pricePlusVat}
                    // onChange={(e) => handleStickerOnChange(index, e)}
                    readOnly
                    placeholder='0'
                  />
                </div>
                <div className='col-md-2'>
                  <div className='d-flex gap-2'>
                    <button
                      type='button'
                      className='btn btn-success w-100 d-block mt-4 '
                      onClick={handleAddSticker}
                    >
                      <FontAwesomeIcon icon={faPlus} />
                    </button>
                    {index > 0 && (
                      <button
                        type='button'
                        className='btn btn-danger w-100 d-block mt-4'
                        onClick={() => handleRemoveSticker(index)}
                      >
                        <FontAwesomeIcon icon={faTrash} />
                      </button>
                    )}
                  </div>
                </div>
              </>
            ))}
            <div className='d-flex justify-content-end mt-3'>
              <div className='d-flex justify-content-between gap-2'>
                <button
                  type='button'
                  className='btn btn-secondary w-100'
                  onClick={handleAddStickerCloseModal}
                >
                  Cancel
                </button>
                {isLoadingAddSticker ? (
                  <div>
                    {' '}
                    <ButtonLoading />
                  </div>
                ) : (
                  <button
                    type='button'
                    className='btn btn-primary d-block'
                    onClick={handleSaveSticker}
                  >
                    <Trans>Save</Trans>
                  </button>
                )}
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      {/*  Create New STICKER END*/}

      {/*  Edit STICKER */}
      <Modal
        show={showEditStickerModal}
        onHide={handleEditStickerCloseModal}
        centered
        size='lg'
      >
        <Modal.Header closeButton>
          <Modal.Title>Edit Sticker</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {stickerEditData && (
            <div className='row' id='popupSelect'>
              <div className='col-md-5'>
                <h6 className='text-muted'>Sticker</h6>
                <input
                  type='text'
                  className='form-control'
                  name='size'
                  value={stickerEditData.size}
                  onChange={handleEditStickerChange}
                />
              </div>
              <div className='col-md-3'>
                <h6 className='text-muted text-nowrap'>Unit Price</h6>
                <input
                  type='number'
                  className='form-control'
                  name='unitPerPrice'
                  value={stickerEditData.unitPerPrice}
                  onChange={handleEditStickerChange}
                />
              </div>
              <div className='col-md-2 '>
                <h6 className='text-muted'>
                  <Trans>VAT</Trans> %
                </h6>
                <input
                  type='number'
                  className='form-control'
                  name='vat'
                  value={stickerEditData.vat}
                  onChange={handleEditStickerChange}
                  placeholder='2'
                />
              </div>
              <div className='col-md-2 '>
                <h6 className='text-muted'>Price with VAT</h6>
                <input
                  type='number'
                  className='form-control'
                  name='pricePlusVat'
                  value={stickerEditData.pricePlusVat}
                  // onChange={(e) => handleStickerOnChange(index, e)}
                  readOnly
                  placeholder='2'
                />
              </div>
              <button
                type='button'
                className='btn btn-primary d-block mt-4 ms-auto'
                onClick={handleUpdateSticker}
              >
                Update
              </button>
            </div>
          )}
        </Modal.Body>
      </Modal>
      {/*  Edit STICKER End*/}

      {/* ----------- STICKER SETTINGS END --------- */}

      {/* //=====SECTOR RELATED MODALS START=========== */}
      {/*  Create New Sector Start */}
      <Modal
        show={sectorAddModal}
        onHide={handleCloseAddSectorModal}
        centered
        size='md'
      >
        <Modal.Header closeButton>
          <Modal.Title>New Sector</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='row mt-3' id='popupSelect'>
            {sectors.map((sector, index) => (
              <>
                <div className='col-md-10' key={index}>
                  <h6 className='text-muted'>Sector</h6>
                  <input
                    type='text'
                    className='form-control'
                    name='sector'
                    value={sector.sector}
                    onChange={(e) => handleSectorChange(index, e)}
                    placeholder='Resturant etc'
                  />
                </div>
                <div className='col-md-2'>
                  <div className='d-flex gap-2'>
                    <button
                      type='button'
                      className='btn btn-success d-block mt-4 pt-1 w-100'
                      onClick={handleAddSector}
                    >
                      <FontAwesomeIcon icon={faPlus} />
                    </button>
                    {index > 0 && (
                      <button
                        type='button'
                        className='btn btn-danger d-block mt-4 pt-1 w-100'
                        onClick={() => handleRemoveSector(index)}
                      >
                        <FontAwesomeIcon icon={faTrash} />
                      </button>
                    )}
                  </div>
                </div>
              </>
            ))}
            <div className='d-flex justify-content-end mt-3'>
              <div className='d-flex justify-content-between gap-2'>
                <button
                  type='button'
                  className='btn btn-secondary w-100'
                  onClick={handleCloseAddSectorModal}
                >
                  Cancel
                </button>
                {isLoadingAddSector ? (
                  <div>
                    {' '}
                    <ButtonLoading />
                  </div>
                ) : (
                  <button
                    type='button'
                    className='btn btn-primary d-block'
                    onClick={handleSaveSector}
                  >
                    <Trans>Save</Trans>
                  </button>
                )}
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      {/*  Create New Sector END */}
      {/*  EDIT New Sector Start */}

      <Modal
        show={editSectorModal}
        onHide={handleCloseEditSectorModal}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Edit Sector</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='row mt-3' id='popupSelect'>
            <div className='col-md-12'>
              <h6 className='text-muted'>Sector</h6>
              <input
                type='text'
                className='form-control'
                name='sector'
                value={editSectorData?.sector}
                onChange={handleEditSectorChange}
              />
              <button
                type='button'
                className='btn btn-primary d-block mt-2 ms-auto'
                onClick={handleUpdateSector}
              >
                Update
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      {/*  EDIT New Sector END */}

      {/* //=====Sector Upoload file ===== */}

      <Modal
        show={sectorUploadShow}
        onHide={() => setSectorUploadShow(false)}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Upload Sector</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='row mt-3' id='popupSelect'>
            {fileSector && (
              <div className='col-12'>
                <Alert className='p-1 bg-danger text-white text-center'>
                  This will replace sector's existing data.
                </Alert>
              </div>
            )}
            <div className='col-md-12'>
              <h6 className='text-muted'>Upload Excel (.xlsx)</h6>
              <input
                type='file'
                id='fileInput'
                className='form-control'
                accept='.xlsx'
                onChange={handleFileChange}
              />
            </div>
            <div className='d-flex justify-content-end mt-2'>
              <div className='d-flex justify-content-between gap-2'>
                <button
                  type='button'
                  className='btn btn-secondary w-100'
                  onClick={() => setSectorUploadShow(false)}
                >
                  Cancel
                </button>
                {isLoadingUploadSector ? (
                  <div>
                    {' '}
                    <ButtonLoading />
                  </div>
                ) : (
                  <button
                    type='button'
                    className='btn btn-primary d-block  ms-auto'
                    onClick={handleUploadSector}
                  >
                    <Trans>Upload</Trans>
                  </button>
                )}
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {/* //=====SECTOR RELATED MODALS END=========== */}
    </>
  );
};

export default Setting;
