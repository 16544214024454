import { useEffect, useState } from "react";
import axios from "axios";

const verificationCode = process.env.REACT_APP_VERIFICATION_TOKEN;
const BASE_URL = process.env.REACT_APP_BASE_URL;

const TermsAndCondition = () => {
  //=========Fetching Terms and Conditions Start =================================

  const [allTermsAndConditions, setallTermsAndConditions] = useState([]);

  useEffect(() => {
    getTermsAndConditions();
  }, []);

  const getTermsAndConditions = async () => {
    try {
      const res = await axios.post(`${BASE_URL}/termAndCondition/getAll`, {
        verificationCode,
      });

      if (res.data) setallTermsAndConditions(res?.data);
    } catch (err) {
    }
  };
  //=========Fetching Terms and Conditions END =================================

  //=================Edit Term and condition Modal end==============
  return (
    <div>
      <section className="profile-infos d-block">
        <div className="container">
          <div className="col-12 col-md-12 mx-auto">
            <div className="container  rounded">
              <div className="row mt-4">
                {allTermsAndConditions.length > 0 ? (
                  allTermsAndConditions.map((CPolicy, idx) => (
                    <>
                      <h2 className="" key={idx}>
                        {CPolicy.title}
                      </h2>

                      <div className="col-md-12 mx-auto">
                        <div
                          dangerouslySetInnerHTML={{ __html: CPolicy?.content }}
                        />
                      </div>
                    </>
                  ))
                ) : (
                  <div className="col-md-12 mx-auto">
                    <p>No content added yet !</p>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default TermsAndCondition;
