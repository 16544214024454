import React, { useState, useEffect } from "react";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faPlay } from "@fortawesome/free-solid-svg-icons";
import goRateLogo from "../assets/images/icons/GoRateLogo.png";
import { Link, useNavigate } from "react-router-dom";

//===================Redux===================
import { useSelector, useDispatch } from "react-redux";
import "./login/login.css";
import axios from "axios";
import { toast } from "react-toastify";
//===============Verfication Code =================
const verificationCode = process.env.REACT_APP_VERIFICATION_TOKEN;
const BASE_URL = process.env.REACT_APP_BASE_URL;

const ForgetPassword = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  //========ForgetPassword Credentials =================
  const [email, setEmail] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const res = await axios.post(`${BASE_URL}/user/forgetPassword`, {
        email,
        verificationCode,
      });

      if (res.data.success === true) {
        toast.success(res?.data.msg);
        // navigate("/resetPassword");
      }

      if (res.data.success === false) {
        toast.error(res?.data.error);
      }
    } catch (err) {
      toast.error(err.response.data.error);
    }
  };

  return (
    <>
      <div className="container">
        <div className="row justify-content-center align-items-center vh-100 ">
          <div className="col-md-6 col-sm-12 mx-auto">
            <div className="logincontent ">
              <div>
                <div className="text-center">
                  <img
                    src={goRateLogo}
                    alt="Welcome"
                    className="img-fluid"
                    style={{
                      width: "250px",
                      height: "60px",
                      objectFit: "contain",
                    }}
                  />
                </div>
                <p className=" text-nowrap text-center my-4">
                  Please enter your email to reset Password
                </p>

                <form className="row">
                  <div className="col-md-12">
                    <label className="form-label">Email</label>
                    <input
                      type="email"
                      className="form-control"
                      name="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      id="inputEmail4"
                    />
                  </div>

                  <div className="col-md-12">
                    <button
                      className="btn btn-warning w-100"
                      onClick={handleSubmit}
                    >
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ForgetPassword;
